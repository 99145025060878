import React from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

import FormTextField from '../forms/FormTextField';
import FormDateTime from '../forms/FormDateTime';
import PaperCard from '../ui-elements/PaperCard';
import EditorPage from '../ui-elements/EditorPage';
import FormTeamMembers from '../forms/FormTeamMembers';
import FormSectionList from '../forms/FormSectionList';
import FormPlaceList from '../forms/FormPlaceList';
import FormCheckBox from '../forms/FormCheckBox';
import useT from '../utility/useT';

function EditTripPage() {
  const t = useT();
  return (
    <EditorPage
      idName="tripId"
      localStorageName="EditingTrips"
      serviceName="trip-info"
      detailRoute={id => `/trips/detail/${id}`}
      objectTypeText={t('trip')}
      parentRoute="/trips"
    >
      <Grid container alignItems="stretch">
        <PaperCard>
          <FormTextField t_label="Trip name" name="name" />
          <FormTextField t_label="Description" name="description" multiline />
          <FormDateTime t_label="Start of trip" name="startTm" />
          <FormDateTime t_label="End of trip" name="endTm" />
          <FormCheckBox t_label="This trip is private (visible only for team members)" name="isPrivate" />
        </PaperCard>
        <PaperCard>
          <FormTeamMembers label={t('Team members')} />
        </PaperCard>
        <PaperCard>
          <FormSectionList label={t('Planned sections')} />
        </PaperCard>
        <PaperCard>
          <FormPlaceList label={t('Important places')} />
        </PaperCard>
      </Grid>
    </EditorPage>
  );
}

export default EditTripPage;
