import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import DashboardCard from '../ui-elements/DashboardCard';
import useT from '../utility/useT';
import useWindowSize from '../utility/useWindowSize';
import LoadingInfo from '../ui-elements/LoadingInfo';
import { Bar } from 'react-chartjs-2';
import useFetchApi from '../utility/useFetchApi';
import AuthProvider from '../providers/AuthProvider';

const useStyles = makeStyles(theme => ({
  placeRoot: {
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    color: 'gray',
  },
  text: {
    marginLeft: '10px',
    flexGrow: 1,
  },
}));

export default function MyTrainingsWidget({ includeGrid, count = 4 }) {
  const classes = useStyles();
  const t = useT();
  const size = useWindowSize();
  const width = size.width - 30;
  const { auth } = AuthProvider.useAuth();
  const { accountId } = auth || {};

  const trainings = useFetchApi.get('training-info/group', { accountId, by: 'month' });
  if (!trainings || trainings.length == 0) {
    return null;
  }
  const data = _.isArray(trainings) ? [...trainings.slice(0, count)].reverse() : [];

  return (
    <DashboardCard title={t('Trainings')} includeGrid={includeGrid} link="/trainings" clickOnWidget>
      <Bar
        data={{
          labels: data.map(x => `${x.month}/${x.year}`),
          datasets: [
            {
              label: t('TrainingCount'),
              backgroundColor: '#90CAA1',
              data: data.map(x => x.count),
            },
            {
              label: t('HourCount'),
              backgroundColor: '#1495b7',
              data: data.map(x => x.durationMs / 3600 / 1000),
            },
            {
              label: t('EskimoRollCount'),
              // backgroundColor: '#fcb26d',
              backgroundColor: '#fea14b',
              data: data.map(x => x.eskimoRollCount),
            },
          ],
        }}
        // data={[
        //   ['Year', 'Sales', 'Expenses', 'Profit'],
        //   ['2014', 1000, 400, 200],
        //   ['2015', 1170, 460, 250],
        //   ['2016', 660, 1120, 300],
        //   ['2017', 1030, 540, 350],
        // ]}
        width={`${width}px`}
        // height={`${Math.round(width * 0.7)}px`}
        height={'200px'}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
      {/* <Chart
        width={`${width}px`}
        height={'300px'}
        chartType="Bar"
        loader={<div>Loading Chart</div>}
        data={[
          ['Year', 'Sales', 'Expenses', 'Profit'],
          ['2014', 1000, 400, 200],
          ['2015', 1170, 460, 250],
          ['2016', 660, 1120, 300],
          ['2017', 1030, 540, 350],
        ]}
        options={{
          // Material design options
          chart: {
            title: 'Company Performance',
            subtitle: 'Sales, Expenses, and Profit: 2014-2017',
          },
          legend: { position: 'none' },
        }}
        // For tests
        rootProps={{ 'data-testid': '2' }}
      /> */}
      {/* <Chart
        width={`${width}px`}
        // height={`${Math.round(width * 0.7)}px`}
        height="300px"
        // chartType="Bar"
        chartType="ColumnChart"
        loader={<LoadingInfo message={t('Loading chart')} />}
        data={[['Trainings', 'Eskimo rolls', 'Time'], ['01/2020', 2, 5], ['02/2020', 1, 6]]}
        options={{
          title: 'Population of Largest U.S. Cities',
          chartArea: { width: '50%' },
          hAxis: {
            title: 'Total Population',
            minValue: 0,
          },
          vAxis: {
            title: 'City',
          },
        }}
        // For tests
        rootProps={{ 'data-testid': '1' }}
      /> */}
    </DashboardCard>
  );
}
