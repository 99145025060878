import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import useReactRouter from 'use-react-router';
import AuthProvider from '../providers/AuthProvider';

import fetchApi from '../utility/fetchApi';
import PaperCard from '../ui-elements/PaperCard';
import getAccountInfoName from '../utility/getAccountInfoName';
import { handleGeolocationError } from '../utility/useLocationParam';
import { useSnackbar } from 'notistack';

import AppLayout from '../layout/AppLayout';

function PaddlemeterPage() {
  const { match, location } = useReactRouter();
  const [isMeasuring, setIsMeasuring] = React.useState(false);
  const [arrayToSave, setArrayToSave] = React.useState(null);
  const { auth } = AuthProvider.useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [infoCounter, setInfoCounter] = React.useState(0);

  const eventsArrayRef = React.useRef([]);
  const watchIdRef = React.useRef(null);

  const startTmRef = React.useRef(null);
  const stopTmRef = React.useRef(null);

  const handleDeviceMotion = React.useCallback(event =>
    eventsArrayRef.current.push({
      type: 'motion',
      time: new Date().getTime(),

      rotationRate: {
        alpha: event.rotationRate.alpha,
        beta: event.rotationRate.beta,
        gamma: event.rotationRate.gamma,
      },
      interval: event.interval,

      acc: {
        x: event.acceleration.x,
        y: event.acceleration.y,
        z: event.acceleration.z,
      },
      accG: {
        x: event.accelerationIncludingGravity.x,
        y: event.accelerationIncludingGravity.y,
        z: event.accelerationIncludingGravity.z,
      },
    })
  );

  const handleDeviceOrientation = React.useCallback(event =>
    eventsArrayRef.current.push({
      type: 'orientation',
      time: new Date().getTime(),

      absolute: event.absolute,
      alpha: event.alpha,
      beta: event.beta,
      gamma: event.gamma,
    })
  );
  const gyroscopeCallback = gyroscope => event =>
    eventsArrayRef.current.push({
      type: 'gyroscope',
      time: new Date().getTime(),

      x: gyroscope.x,
      y: gyroscope.y,
      z: gyroscope.z,
    });
  const accelerometerCallback = accelerometer => event =>
    eventsArrayRef.current.push({
      type: 'accelerometer',
      time: new Date().getTime(),

      x: accelerometer.x,
      y: accelerometer.y,
      z: accelerometer.z,
    });

  const handlePosition = React.useCallback(event =>
    eventsArrayRef.current.push({
      type: 'position',
      time: new Date().getTime(),

      latitude: event.coords.latitude,
      longitude: event.coords.longitude,
    })
  );
  const handleSaveToServer = async () => {
    if (arrayToSave.length > 0) {
      await fetchApi.post(
        `event-stream-info/create`,
        {
          accountId: auth.accountId,
          events: arrayToSave,
          startTm: startTmRef.current,
          stopTm: stopTmRef.current,
        },
        {
          headers: { 'x-auth-token': auth.authToken },
          auth,
        }
      );
      enqueueSnackbar(`Saved to server: ${arrayToSave.length} events`, { variant: 'info' });
    }
    setArrayToSave(null);
  };

  const handleClear = () => {
    setArrayToSave(null);
  };

  const onLocationError = err => {
    handleGeolocationError(err, enqueueSnackbar);
  };

  React.useEffect(() => {
    if (isMeasuring) {
      let accMeter = null;
      let gyroscope = null;
      let handleAccelerometer;
      if (window.Accelerometer) {
        accMeter = new window.Accelerometer({ frequency: 50 });
        handleAccelerometer = accelerometerCallback(accMeter);
        accMeter.addEventListener('reading', handleAccelerometer);
        accMeter.start();
      }
      let handleGyroscope;
      if (window.Gyroscope) {
        gyroscope = new window.Gyroscope({ frequency: 50 });
        handleGyroscope = gyroscopeCallback(gyroscope);
        gyroscope.addEventListener('reading', handleGyroscope);
        gyroscope.start();
      }

      window.addEventListener('devicemotion', handleDeviceMotion);
      window.addEventListener('deviceorientation', handleDeviceOrientation);
      startTmRef.current = new Date().getTime();

      var locationOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      watchIdRef.current = navigator.geolocation.watchPosition(handlePosition, onLocationError, locationOptions);

      const timer = window.setInterval(() => setInfoCounter(x => x + 1), 1000);
      return () => {
        if (accMeter) {
          accMeter.removeEventListener('reading', handleAccelerometer);
          accMeter.stop();
        }
        if (gyroscope) {
          gyroscope.removeEventListener('reading', handleGyroscope);
          gyroscope.stop();
        }
        window.removeEventListener('devicemotion', handleDeviceMotion);
        window.removeEventListener('deviceorientation', handleDeviceOrientation);
        navigator.geolocation.clearWatch(watchIdRef.current);
        window.clearInterval(timer);
        stopTmRef.current = new Date().getTime();

        setArrayToSave([...eventsArrayRef.current]);
        eventsArrayRef.current = [];
      };
    }
  }, [isMeasuring]);

  return (
    <AppLayout title="Paddlemeter">
      <PaperCard>This page is experimental, please don't use it, if you don't know, what you are doing.</PaperCard>
      {(window.DeviceOrientationEvent || window.DeviceMotionEvent) && (
        <PaperCard>
          {!isMeasuring && eventsArrayRef.current.length == 0 && !arrayToSave && (
            <Button variant="contained" color="primary" onClick={() => setIsMeasuring(true)}>
              Start capture motion
            </Button>
          )}
          {isMeasuring && (
            <Button variant="contained" color="primary" onClick={() => setIsMeasuring(false)}>
              Stop capture motion
            </Button>
          )}
          {arrayToSave && (
            <Button variant="contained" color="primary" onClick={handleSaveToServer}>
              Save motion to server
            </Button>
          )}
        </PaperCard>
      )}
      {(eventsArrayRef.current.length > 0 || arrayToSave) && (
        <PaperCard>
          {_.toPairs(_.mapValues(_.groupBy(arrayToSave || eventsArrayRef.current, 'type'), val => val.length)).map(
            x => (
              <div>
                {x[0]}: {x[1]}
              </div>
            )
          )}

          {arrayToSave && (
            <Button variant="contained" color="primary" onClick={handleClear}>
              Clear measurement
            </Button>
          )}
        </PaperCard>
      )}
    </AppLayout>
  );
}

export default PaddlemeterPage;
