import React from 'react';
import PointsMap from '../locations/PointsMap';
import useFormik from '../utility/useFormik';

export default function MapPointEditor({ name }) {
  const { values, setFieldValue } = useFormik();
  const point = values[name] || [14.4248281, 50.1129869];

  return (
    <>
      <PointsMap
        points={[{ coordinates: point.slice(0, 2) }]}
        onClick={({ latLng }) => {
          setFieldValue(name, [...latLng].reverse().map(x => parseFloat(x.toFixed(6))));
        }}
      />
    </>
  );
}
