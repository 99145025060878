import React from 'react';
import fetchApi from '../utility/fetchApi';
import useStorage from '../utility/useStorage';
import { useSnackbar } from 'notistack';
import useT from '../utility/useT';

const md5 = require('md5');

const AuthContext = React.createContext(null);

function getPasswordHash(password) {
  return md5(`G^52*${password}8734f=bksk{A`);
}

function AuthProvider({ children }) {
  const [auth, setAuth] = useStorage('AuthObject', localStorage);
  const { enqueueSnackbar } = useSnackbar();
  const t = useT();

  const authProviderObject = {
    async receivedGoogleAuth(googleResp) {
      // const { name, email, googleId } = profileObj;
      const basicProfile = googleResp.getBasicProfile();
      const googleId = basicProfile.getId();
      const email = basicProfile.getEmail();
      const name = basicProfile.getName();

      const data = await fetchApi.post(`account-info/login`, {
        accountId: `google:${googleId}`,
        email,
        displayName: name,
      });
      setAuth(data);
    },
    setAuth(value) {
      setAuth(value);
    },
    auth,
    logout() {
      setAuth(null);
    },
    async callGoogleLogin() {
      if (window.gapi && window.gapi.auth2) {
        const auth2 = window.gapi.auth2.getAuthInstance();
        const res = await auth2.signIn();
        authProviderObject.receivedGoogleAuth(res);
      } else {
        enqueueSnackbar(t('Google API not available'), { variant: 'error' });
      }
    },
    async callEmailLogin(email, password, enqueueSnackbar) {
      const resp = await fetchApi.post('account-info/login-by-email', { email, password: getPasswordHash(password) });
      const { state, account, message } = resp || {};
      if (state === 'ok') {
        setAuth(account);
        enqueueSnackbar(t('Login succesful'), { variant: 'info' });
        return true;
      }
      if (state === 'error') {
        enqueueSnackbar(message, { variant: 'error' });
        return false;
      }
    },
    async callRegister(email, password, enqueueSnackbar) {
      const resp = await fetchApi.post('account-info/register-by-email', {
        email,
        password: getPasswordHash(password),
      });
      const { state, account, message } = resp || {};
      if (state === 'ok') {
        setAuth(account);
        enqueueSnackbar(t('Succesfully registered'), { variant: 'info' });
        return true;
      }
      if (state === 'error') {
        enqueueSnackbar(message, { variant: 'error' });
        return false;
      }
    },
    async callResetPassword(email, enqueueSnackbar) {
      const resp = await fetchApi.post('account-info/reset-password', {
        email,
      });
      const { state, message } = resp || {};
      if (state === 'ok') {
        enqueueSnackbar(t('Succesfully sent password reset email'), { variant: 'info' });
        return true;
      }
      if (state === 'error') {
        enqueueSnackbar(message, { variant: 'error' });
        return false;
      }
    },
    async callDoResetPassword(accountId, resetToken, password, password2, enqueueSnackbar) {
      if (password != password2) {
        enqueueSnackbar(t("Passwords don't match"), { variant: 'error' });
        return false;
      }

      const resp = await fetchApi.post('account-info/do-reset-password', {
        accountId,
        resetToken,
        password: getPasswordHash(password),
      });
      const { state, message } = resp || {};
      if (state === 'ok') {
        enqueueSnackbar(t('Password reset was succesful'), { variant: 'info' });
        return true;
      }
      if (state === 'error') {
        enqueueSnackbar(message, { variant: 'error' });
        return false;
      }
    },
    updateFavoriteRivers(favoriteRivers) {
      setAuth(x => ({
        ...x,
        favoriteRivers,
      }));
    },
  };

  return <AuthContext.Provider value={authProviderObject}>{children}</AuthContext.Provider>;
}

AuthProvider.useAuth = function() {
  return React.useContext(AuthContext);
};

export default AuthProvider;
