import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import useReactRouter from 'use-react-router';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import TerrainIcon from '@material-ui/icons/Terrain';
import LockIcon from '@material-ui/icons/Lock';

import SmallPaddlerAvatar from '../people/SmallPaddlerAvatar';
import useT from '../utility/useT';

const useStyles = makeStyles(theme => ({
  root: {
    alignSelf: 'flex-end',
    width: '100%',
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
    color: theme.typography.color,
    padding: '0 16px',
  },
  time: {
    fontStyle: 'italic',
  },
  description: {
    fontFamily: theme.typography.fontFamilyCondensed,
  },
  editing: {
    color: theme.editingColor,
    fontStyle: 'italic',
  },
  avatars: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
}));

export default function TripListItem({
  onClick,
  tripId,
  name,
  description,
  startTm,
  endTm,
  team,
  isEditing,
  isPrivate,
}) {
  const nowTm = new Date().getTime();
  const t = useT();
  const { history } = useReactRouter();
  const classes = useStyles();
  const time =
    nowTm >= startTm && nowTm <= endTm
      ? t('TripProceeding')
      : nowTm > endTm
      ? moment(endTm).fromNow()
      : moment(startTm).fromNow();

  return (
    <ListItem
      button
      alignItems="flex-start"
      onClick={() => {
        history.push(isEditing ? `/trips/edit/${tripId}` : `/trips/detail/${tripId}`);
      }}
    >
      <ListItemAvatar>
        <Avatar>
          <TerrainIcon />
        </Avatar>
      </ListItemAvatar>
      <div className={classes.root}>
        <div>
          {' '}
          {isPrivate && <LockIcon style={{ width: 15, height: 15 }} />}
          {name}
        </div>
        <div className={classes.description}>{description}</div>
        <div className={classes.time}>{time}</div>
        {team && (
          <div className={classes.avatars}>
            {team.map(avatar => (
              <SmallPaddlerAvatar {...avatar} />
            ))}
          </div>
        )}
        {isEditing && <div className={classes.editing}>Editing</div>}
      </div>
    </ListItem>
  );
}
