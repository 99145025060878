import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.yaml';
import cs from './translations/cs.yaml';

console.log('translation_cs', cs);

i18n
  // load translation using xhr -> see /public/locales
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // debug: true,
    defaultNS: 'common',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      en: { common: en },
      cs: { common: cs },
    },
    react: {
      useSuspense: false,
    },

    // saveMissing: true,
    // missingKeyHandler: (lng, ns, key, fallbackValue) => {
    //   const missingKeys = window.missingTranslationKeys || [];
    //   if (!missingKeys.includes(key)) missingKeys.push(key);
    //   window.missingTranslationKeys = missingKeys;
    //   window.missingTranslationKeysFormatted = missingKeys.map(key => `${key}: ${key}`).join('\n');
    // },
  });

export default i18n;
