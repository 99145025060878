import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Trans } from 'react-i18next';

import useT from '../utility/useT';
import FormikForm from '../forms/FormikForm';
import FormDateTime from '../forms/FormDateTime';
import useFormik from '../utility/useFormik';
import ErrorInfo from './ErrorInfo';

function TimeIntervalDialogCore({ title, onClose, onSelect, minLength, maxLength, ...other }) {
  const { values } = useFormik();
  const t = useT();

  function handleClose() {
    onClose();
  }

  function handleConfirm() {
    onClose();
    if (onSelect) onSelect(values.startTm, values.endTm);
  }

  let error = null;
  if (values.endTm - values.startTm < minLength) error = t('Interval too short');
  if (values.endTm - values.startTm > maxLength) error = t('Interval too long');

  if (values.startTm)
    return (
      <Dialog onClose={handleClose} aria-labelledby="confirm-dialog-title" {...other}>
        <DialogTitle id="interval-dialog-title">
          <Trans>Choose interval</Trans>
        </DialogTitle>
        <DialogContent>
          <FormDateTime t_label="Start" name="startTm" />
          <FormDateTime t_label="End" name="endTm" />
          {!!error && <ErrorInfo message={error} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus disabled={!!error}>
            <Trans>OK</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    );
}

function TimeIntervalDialog({ startTm, endTm, ...other }) {
  return (
    <FormikForm initialValues={{ startTm, endTm }}>
      <TimeIntervalDialogCore {...other} />
    </FormikForm>
  );
}

export default TimeIntervalDialog;
