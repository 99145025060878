import React from "react";

import BoatKayakIcon from "../icons/BoatKayak";
import BoatCanoeIcon from "../icons/BoatCanoe";
import BoatRaftIcon from "../icons/BoatRaft";
import BoatTouristCanoeIcon from "../icons/BoatTouristCanoe";
import BoatOtherIcon from "../icons/BoatOther";
import PersonIcon from "@material-ui/icons/Person";

export default function Flag({ boatType, size }) {
  if (!boatType) return <PersonIcon style={{ width: size, height: size }} />;
  if (boatType.toLowerCase().includes("k1"))
    return <BoatKayakIcon size={size} />;
  if (boatType.toLowerCase().includes("c1"))
    return <BoatCanoeIcon size={size} />;
  if (boatType.toLowerCase().includes("c2"))
    return <BoatTouristCanoeIcon size={size} />;
  if (boatType.toLowerCase().includes("raft"))
    return <BoatRaftIcon size={size} />;
  if (boatType.toLowerCase().includes("other"))
    return <BoatOtherIcon size={size} />;
  return <PersonIcon style={{ width: size, height: size }} />;
}
