import React from 'react';
import fetchApi from './fetchApi';
import AuthProvider from '../providers/AuthProvider';
import stableStringify from 'json-stable-stringify';

export default function useFetchApi(url, defValue = [], onLoaded, dependencies = []) {
  const [value, setValue] = React.useState(defValue);

  const { auth } = AuthProvider.useAuth();

  async function loadValue() {
    if (url) setValue(await fetchApi(url, { auth }));
    else setValue(defValue);
    if (onLoaded) onLoaded();
  }
  React.useEffect(() => {
    loadValue();
  }, [url, ...dependencies]);

  return value;
}

useFetchApi.get = function useFetchApi(url, params, defValue = [], onLoaded) {
  const [value, setValue] = React.useState(defValue);

  const { auth } = AuthProvider.useAuth();

  const { reloadToken, setLoadingState, resetOnParamsChange, ...restParams } = params;

  async function loadValue() {
    if (setLoadingState) setLoadingState(true);
    if (resetOnParamsChange) setValue(defValue);
    setValue(await fetchApi.get(url, restParams, { auth }));
    if (setLoadingState) setLoadingState(false);
    if (onLoaded) onLoaded();
  }
  React.useEffect(() => {
    setValue(defValue);
  }, [reloadToken]);
  React.useEffect(() => {
    loadValue();
  }, [url, reloadToken, stableStringify(restParams)]);

  return value;
};
