import React from 'react';
import _ from 'lodash';
import AppLayout from '../layout/AppLayout';
import TabControl from '../layout/RouteTabControl';
import ListControl from '../layout/ListControl';
import useReactRouter from 'use-react-router';

import AuthProvider from '../providers/AuthProvider';
import { getLocalStorage } from '../utility/storage-cache';
import getEditingObjects from '../utility/getEditingObjects';
import useFetchApi from '../utility/useFetchApi';
import PlacesMap from './PlacesMap';
import useLocationParam from '../utility/useLocationParam';

import PlaceListItem from './PlaceListItem';
import useT from '../utility/useT';

function MyPlacesMap({ highlightedPlace, locationParam }) {
  const { auth } = AuthProvider.useAuth();
  const myPlaces = useFetchApi(`place-info/find?accountId=${auth.accountId}&${locationParam}`);
  const editingPlaces = getLocalStorage('EditingPlaces');
  const allPlaces = {
    ..._.keyBy(myPlaces, 'placeId'),
    ...editingPlaces,
  };
  return <PlacesMap places={_.values(allPlaces)} highlightedPlace={highlightedPlace} />;
}

function PlacesPage() {
  const { auth } = AuthProvider.useAuth();
  const { history } = useReactRouter();
  const [auxState, setAuxState] = React.useState(null);
  const locationParam = useLocationParam();
  const t = useT();

  return (
    <AppLayout t_title="Places" mainIconMeaning="top" showRefreshButton>
      <TabControl>
        <TabControl.Page t_label="My Places" path={'/places'}>
          {locationParam == null ? (
            t('Getting current position')
          ) : (
            <ListControl
              routerStateField="myPlacesList"
              showSearch
              auxState={auxState}
              extraItems={_.sortBy(getEditingObjects('EditingPlaces', 'placeId'), 'startTm').reverse()}
              idName="placeId"
              url={`place-info/find?accountId=${auth.accountId}&${locationParam}`}
              component={PlaceListItem}
              onAdd={() => history.push('/places/edit')}
            />
          )}
        </TabControl.Page>
        <TabControl.Page t_label="Map" path={'/places/map'}>
          <MyPlacesMap />
        </TabControl.Page>
        <TabControl.Page t_label="All Places" path={'/places/all'}>
          {locationParam == null ? (
            t('Getting current position')
          ) : (
            <ListControl
              routerStateField="allPlacesList"
              auxState={auxState}
              url={`place-info/find?isPublished=true&${locationParam}`}
              component={PlaceListItem}
            />
          )}
        </TabControl.Page>
      </TabControl>
    </AppLayout>
  );
}

export default PlacesPage;

// PlacesPage.MyPlaces = withProps({ publicPlaces: false })(PlacesPage);
// PlacesPage.PublicPlaces = withProps({ publicPlaces: true })(PlacesPage);
