import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, Card, CardContent, CardMedia } from '@material-ui/core';
import useReactRouter from 'use-react-router';
// import { Carousel, CarouselSlide } from 'material-ui-carousel';
import CheckIcon from '@material-ui/icons/Check';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MailIcon from '@material-ui/icons/Mail';
import StorageIcon from '@material-ui/icons/Storage';
import FacebookIcon from '../icons/Facebook';
import InstagramIcon from '../icons/Instagram';
import AndroidIcon from '@material-ui/icons/Android';

import LoginDialog, { useLoginDialog } from '../ui-elements/LoginDialog';
import AppLayout from '../layout/AppLayout';
import AuthProvider from '../providers/AuthProvider';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import InterestingSectionsWidget from './InterestingSectionsWidget';
import TopEventsWidget from './TopEventsWidget';

import rulesEn from '../translations/rules.en';

const useStyles = makeStyles(theme => ({
  header: {
    [theme.breakpoints.up('md')]: {
      backgroundImage: 'url(./images/home-header-large.jpg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      height: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./images/home-header-small.jpg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      height: '200px',
    },
    position: 'relative',
  },
  overlay: {
    [theme.breakpoints.up('md')]: {
      height: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    background: theme.homeBg,
  },
  mainTitle: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '100px',
    },
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    fontSize: '15pt',
    textAlign: 'center',
    color: theme.homeText,
    zIndex: 100,
  },
  title: {
    fontSize: '15pt',
    color: theme.homeText,
    margin: '10px',
  },
  subtitle: {
    margin: '10px',
    color: theme.homeText,
  },
  iconWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    width: '80px',
    height: '80px',
    margin: '10px',
  },
  text: {
    textAlign: 'center',
    margin: '10px',
    color: theme.homeText,
  },
  screenshotWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
  screenshot: {
    margin: '10px',
    maxWidth: '50%',
  },
  article: {},
  bulletPoint: {
    display: 'flex',
    margin: '0px 15px',
  },
  bulletText: {
    marginLeft: '10px',
  },
  footer: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    borderTop: '1px solid #ccc',
  },
  footerPlaceholder: {
    height: '70px',
  },
  footerButton: {},
  expheader: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px',
    fontSize: '12pt',
  },
  expbody: {
    margin: '10px',
  },
  expandable: {
    color: theme.homeText,
  },
  contact: {
    display: 'flex',
    alignItems: 'center',
  },
  ml1: {
    marginLeft: '10px',
  },
  social: {
    display: 'flex',
    justifyContent: 'center',
  },
  socialLink: {
    color: theme.homeText,
    textDecoration: 'none',
  },
  dataSource: {
    color: theme.homeText,
    textDecoration: 'none',
  },
}));

function Article({ title, text, bullets }) {
  const classes = useStyles();
  return (
    <div className={classes.article}>
      <div className={classes.title}>
        <Trans>{title}</Trans>
      </div>
      {text && (
        <div className={classes.subtitle}>
          <Trans>{text}</Trans>
        </div>
      )}
      {bullets.map(x => (
        <div className={classes.bulletPoint}>
          <CheckIcon style={{ color: 'green' }} />
          <div className={classes.bulletText}>
            <Trans>{x}</Trans>
          </div>
        </div>
      ))}
    </div>
  );
}

function DataSource({ url, label }) {
  const classes = useStyles();
  return (
    <div className={classes.dataSource}>
      <a href={url} target="_blank" rel="noopener noreferrer" className={classes.dataSource}>
        {label || url.replace('http://', '').replace('/', '')}
      </a>
    </div>
  );
}

function Expandable({ title, children }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div className={classes.expandable}>
      <div className={classes.expheader} onClick={() => setExpanded(x => !x)}>
        <div>
          <Trans>{title}</Trans>
        </div>
        <div>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
      </div>
      {expanded && <div className={classes.expbody}>{children}</div>}
    </div>
  );
}

const pictures = [
  { image: '/images/screenshot1.jpg', t_title: 'Followed event list' },
  { image: '/images/screenshot2.jpg', t_title: 'App dashboard' },
  { image: '/images/screenshot3.jpg', t_title: 'Logged event detail' },
];

function HomePage() {
  const { auth } = AuthProvider.useAuth();
  const { history } = useReactRouter();
  const classes = useStyles();
  const [openLogin, loginProps] = useLoginDialog();
  const muiTheme = useTheme();
  const t = useT();

  // const themeSwitch = useThemeSwitch();
  // React.useEffect(() => {
  //   themeSwitch.pushTheme('home');
  //   return () => {
  //     themeSwitch.popTheme();
  //   };
  // }, []);

  return (
    <AppLayout
      title={t('RiverLog')}
      mainIconMeaning={'menu'}
      showLanguageSelector
      appBarOverlay
      // appBarBackground={muiTheme.homeBg}
      appBarBackground="#00000000"
      appBarTextColor={muiTheme.homeText}
      absoluteAppBar
    >
      <div className={classes.header}>
        <div className={classes.overlay} />
      </div>
      <div className={classes.mainTitle}>
        <Trans>LogForPaddlersTitle1</Trans>
        <br />
        <Trans>LogForPaddlersTitle2</Trans>
      </div>

      <Grid container>
        <Grid item xs={12} md={4}>
          <div className={classes.iconWrap}>
            <img src="/appicon.png" className={classes.icon} />
          </div>

          <div className={classes.text}>
            <Trans>HomeText1</Trans>
          </div>

          <div className={classes.screenshotWrap}>
            <img className={classes.screenshot} src="/images/homescr1.jpg" />
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <Article
            title="HomeTitle1"
            bullets={['HomePoint_1_1', 'HomePoint_1_2', 'HomePoint_1_3', 'HomePoint_1_4', 'HomePoint_1_5']}
          />

          <div className={classes.screenshotWrap}>
            <img className={classes.screenshot} src="/images/homescr2.jpg" />
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <Article title="HomeTitle2" bullets={['HomePoint_2_1', 'HomePoint_2_2', 'HomePoint_2_3']} />

          <div className={classes.screenshotWrap}>
            <img className={classes.screenshot} src="/images/homescr2.jpg" />
          </div>

          <Article
            title="HomeTitle3"
            text="HomeSubtitle3"
            bullets={['HomePoint_3_1', 'HomePoint_3_2', 'HomePoint_3_3', 'HomePoint_3_4']}
          />
        </Grid>
      </Grid>

      <div className={classes.title}>
        <Trans>HomeTitle4</Trans>
      </div>

      <Grid container alignItems="stretch">
        <Grid item xs={12} md={6}>
          <InterestingSectionsWidget hasEvents />
        </Grid>
        <Grid item xs={12} md={6}>
          <TopEventsWidget />
        </Grid>
      </Grid>

      <Expandable title="AboutUs">
        <p>
          <Trans>AboutUs_1</Trans>
        </p>
        <p>
          <Trans>AboutUs_2</Trans>
        </p>
        <p>
          <Trans>AboutUs_3</Trans>
        </p>
        <p>
          <Trans>AboutUs_4</Trans>
        </p>
        <p>
          <Trans>AboutUs_5</Trans>
        </p>
      </Expandable>
      <Expandable title="Contacts">
        <p className={classes.contact}>
          <MailIcon /> <div className={classes.ml1}>riverlog.info@gmail.com</div>
        </p>
        <p className={classes.contact}>
          <StorageIcon /> <div className={classes.ml1}>Virtual Server Hosting: Web4ce.cz</div>
        </p>
        <div className={classes.social}>
          <a href="https://www.facebook.com/riverdiary" className={classes.socialLink}>
            <FacebookIcon size={30} />
          </a>
          <div style={{ width: '15px' }} />
          <a href="https://www.instagram.com/riverlog.info" className={classes.socialLink}>
            <InstagramIcon size={30} />
          </a>
        </div>
      </Expandable>
      <Expandable title="HowToAddIcon">
        <p className={classes.contact}>
          <AndroidIcon />{' '}
          <div className={classes.ml1}>
            <a className={classes.socialLink} href="/images/add-to-home-screen.mp4">
              Android
            </a>
          </div>
        </p>
      </Expandable>

      <Expandable title="Data sources">
        <DataSource url="https://www.rivermap.ch/" label={t('SectionsImportedFromRivermap')} />
        <DataSource url="https://riverlog.info/api/public-sections" label={t('ExportSectionsJson')} />
        <DataSource url="http://hydro.chmi.cz/" />
        <DataSource url="https://riverzone.eu/" />
        <DataSource url="http://www.ktn.gv.at/" />
        <DataSource url="http://www.tirol.gv.at/" />
        <DataSource url="http://www.pvl.cz/" />
        <DataSource url="http://www.pla.cz/" />
        <DataSource url="http://www.poh.cz/" />
        <DataSource url="http://www.salzburg.gv.at/" />
        <DataSource url="http://hydrodaten.admin.ch/" />
        <DataSource url="http://www.arso.gov.si/" />
      </Expandable>

      <Expandable title="RulesOfUse">
        <div dangerouslySetInnerHTML={{ __html: rulesEn }} />
      </Expandable>

      <div className={classes.footerPlaceholder}>&nbsp;</div>

      <div className={classes.footer}>
        <div>
          {!auth && (
            <Button variant="contained" color="primary" className={classes.button} onClick={openLogin}>
              <Trans>Sign in</Trans>
            </Button>
          )}
          {auth && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => history.push('/dashboard')}
            >
              <Trans>Dashboard</Trans>
            </Button>
          )}
        </div>
      </div>

      <LoginDialog {...loginProps} />
    </AppLayout>
  );
}

export default HomePage;
