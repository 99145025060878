export default function getAccountInfoName(auth) {
  const { displayName, email } = auth || {};
  if (displayName) return displayName;
  if (email) {
    const match = email.match(/(.*)@/);
    if (match) return match[1];
    return email;
  }
  return '???';
}
