import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import useFormik from '../utility/useFormik';
import { withStyles } from '@material-ui/core/styles';
import StarRatings from 'react-star-ratings';

const styles = theme => ({
  stars: {
    // padding: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
    flex: '1',
    display: 'flex',
    // marginBottom: theme.spacing.unit,
    // paddingTop: theme.spacing.unit,
    // paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  deleteButton: {
    alignSelf: 'center',
  },
  starsDiv: {
    alignSelf: 'center',
  },
  mainFlex: {
    display: 'flex',
    margin: theme.spacing.unit,
  },
});

const beerSvg = `M396.86,189.696h-51.816v-8.471c16.876-12.499,27.84-32.548,27.84-55.113c0-33.43-24.055-61.349-55.764-67.356
C307.903,24.725,276.851,0.001,240.165,0c-20.304,0.001-39.79,7.852-54.44,21.513c-5.231-1.368-10.64-2.072-16.077-2.072
c-25.849,0-48.398,15.683-58.222,38.235c-1.34-0.079-2.687-0.118-4.037-0.118c-37.8,0-68.553,30.753-68.553,68.553
c0,20.813,9.335,39.475,24.024,52.058v283.526c0,16.5,13.5,30,30,30h222.184c16.5,0,30-13.5,30-30v-44h51.816
c30.878,0,56-25.122,56-56v-116C452.86,214.817,427.738,189.696,396.86,189.696z M304.331,156.665l-175.536,0v61.051
c0,10.493-8.507,19-19,19c-10.493,0-19-8.507-19-19v-65.971c-8.393-5.452-13.959-14.902-13.959-25.634
c0-16.847,13.706-30.553,30.553-30.553c3.792,0,7.503,0.694,11.032,2.062c5.636,2.185,11.976,1.559,17.075-1.689
c5.099-3.248,8.348-8.728,8.751-14.759c0.889-13.307,12.046-23.731,25.401-23.731c4.356,0,8.485,1.06,12.27,3.149
c8.375,4.622,18.88,2.297,24.523-5.427C214.409,44.256,226.701,38,240.165,38c22.277,0,40.586,17.408,41.682,39.631
c0.251,5.1,2.545,9.885,6.365,13.274c3.819,3.39,8.842,5.104,13.936,4.744c0.884-0.062,1.578-0.09,2.183-0.09
c16.847,0,30.553,13.706,30.553,30.553S321.178,156.665,304.331,156.665z M400.86,361.696c0,2.131-1.869,4-4,4h-51.816v-124h51.816
c2.131,0,4,1.869,4,4V361.696z`;

function FormRating({ name, classes, label, image }) {
  const { values, setValues } = useFormik();
  const svgProps =
    image === 'beer'
      ? {
          svgIconPath: beerSvg,
          svgIconViewBox: '0 0 491.696 491.696',
        }
      : {};
  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <div className={classes.stars}>
        <div className={classes.starsDiv}>
          <StarRatings
            starRatedColor="#CCCC80"
            starHoverColor="#FFFF00"
            rating={values[name] || undefined}
            starDimension="30px"
            changeRating={val => setValues({ ...values, [name]: val })}
            {...svgProps}
          />
        </div>
        <IconButton
          aria-label="Delete"
          className={classes.deleteButton}
          disabled={!values[name]}
          onClick={() => setValues({ ...values, [name]: null })}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </FormGroup>
  );
}

export default withStyles(styles)(FormRating);
