import { createMuiTheme } from '@material-ui/core/styles';

const origTheme = createMuiTheme();

const defaultThemeConfig = {
  typography: {
    useNextVariants: true,
    fontFamilyCondensed: '"Roboto Condensed", ' + origTheme.typography.fontFamily,
  },
  palette: {
    primary: {
      main: '#1495b7',
    },
    secondary: {
      main: '#40dc53',
    },
    text: {
      primary: '#4E4848',
      dashboard: '#4E4848',

      // primary: '#686363',
      // dashboard: '#686363',
    },
    // background: {
    //   paper: '#E5E5E5',
    //   default: '#FFF',
    // },
  },
  // sectionColor: '#008080',
  // sectionColor: '#1495b7',
  sectionColor: '#686363',
  editingColor: '#aa0',
  homeBg: '#FFFFFFCC',
  homeText: '#4E4848',
  overrides: {
    MuiListItem: {
      gutters: {
        [origTheme.breakpoints.down('md')]: {
          paddingLeft: 5,
          paddingRight: 5,
        },
      },
    },
  },

  // typography: {
  //   // Use the system font instead of the default Roboto font.
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Roboto',
  //     '"Helvetica Neue"',
  //     'Arial',
  //     'sans-serif',
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(','),
  // },
};

// const homeThemeConfig = {
//   ...defaultThemeConfig,

//   palette: {
//     primary: {
//       main: '#ffffff',
//     },
//     secondary: {
//       main: '#cccccc',
//     },
//     text: {
//       primary: '#4E4848',
//       dashboard: '#4E4848',
//     },
//   },
// };

const defaultTheme = createMuiTheme(defaultThemeConfig);
// const homeTheme = createMuiTheme(homeThemeConfig);

function getTheme(name) {
  return defaultTheme;
}

export default getTheme;
