import React from 'react';

import useFetchApi from '../utility/useFetchApi';
import AuthProvider from '../providers/AuthProvider';
import useReactRouter from 'use-react-router';
import DetailPageLayout from '../layout/DetailPageLayout';
import PaperRows from '../ui-elements/PaperRows';
import NameValueView from '../ui-elements/NameValueView';
import GpsView from '../ui-elements/GpsView';
import useT from '../utility/useT';
import { makeStyles } from '@material-ui/core/styles';
import CommentsListView from '../comments/CommentsListView';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginLeft: theme.spacing.unit,
  },
}));

function PlayspotDetailPage({ match }) {
  const { playspotId } = match.params;
  const { history } = useReactRouter();
  const t = useT();
  const classes = useStyles();

  const detail = useFetchApi(`playspot-info/find?playspotId=${playspotId}`)[0];
  const detailSafe = detail || {};
  const { auth } = AuthProvider.useAuth();
  const { accountId, accountName, name, coordinates, isActive, activationError } = detailSafe;

  const isMyPlayspot = auth && auth.accountId === accountId;

  return (
    <DetailPageLayout
      isWritable={isMyPlayspot}
      editUrl={`/playspots/edit/${playspotId}`}
      parentRoute="/playspots"
      idName="playspotId"
      idValue={playspotId}
      serviceName="playspot-info"
      entityName="playspot"
      title={name}
      objectTitle={`playspot ${name}`}
      mainIconMeaning="back"
    >
      <PaperRows>
        <NameValueView t_name="Name" value={name} />
        <NameValueView t_name="Owner" value={accountName} />
        <NameValueView t_name="Active" value={isActive ? t('yes') : t('no')} />
        <NameValueView t_name="Error" value={activationError} />
        <div className={classes.wrapper}>
          <GpsView name={t('GPS')} coordinates={coordinates} />
        </div>
      </PaperRows>
      <CommentsListView
        context={{
          playspotId,
          target: 'playspot',
        }}
        isInlineView
      />
    </DetailPageLayout>
  );
}

export default PlayspotDetailPage;
