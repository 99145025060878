import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
// import useT, { getLocalizedMessage } from '../utility/useT';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
}));

export default function ContentPadding({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}
