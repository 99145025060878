import React from 'react';
import ListControl from '../layout/ListControl';
import SectionListItem from './SectionListItem';

import WavesIcon from '@material-ui/icons/Waves';
import MapIcon from '@material-ui/icons/Map';
import WarningIcon from '@material-ui/icons/Warning';
import TimelineIcon from '@material-ui/icons/Timeline';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import CommentIcon from '@material-ui/icons/Comment';
import EditIcon from '@material-ui/icons/Edit';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const groupDefinitions = [
  {
    t_title: 'river',
    icon: WavesIcon,
    groupBy: 'river',
  },
  {
    t_title: 'country',
    icon: MapIcon,
    groupBy: 'country',
  },
  {
    t_title: 'difficulty',
    icon: WarningIcon,
    groupBy: 'difficultyGroup',
  },
  {
    t_title: 'water level',
    icon: TimelineIcon,
    groupBy: 'waterLevel',
  },
];

const filterDefinitions = [
  {
    t_title: 'runnable',
    icon: DirectionsBoatIcon,
    filterField: 'isRunnable',
    filterValue: 'true',
  },
  {
    t_title: 'with comments',
    icon: CommentIcon,
    filterField: 'hasComments',
    filterValue: 'true',
  },
  {
    t_title: 'with runs',
    icon: EditIcon,
    filterField: 'hasEvents',
    filterValue: 'true',
  },
  {
    t_title: 'without runs',
    icon: HelpOutlineIcon,
    filterField: 'hasEvents',
    filterValue: 'false',
  },
];

const groupContentConfig = {
  rivers: true,
  countries: true,
  sectionStats: true,
};

export default function SectionListControl({ filterParams, isRawVariant, noDataContentCallback, isInline, ...other }) {
  return (
    <ListControl
      isInline={isInline}
      idName="sectionId"
      serviceName="section-info"
      filterParams={filterParams}
      findParams="joinAccountAvatar=1"
      component={SectionListItem}
      showSearch={!isRawVariant}
      noDataContentCallback={noDataContentCallback}
      routerStateField="listSections"
      groupDefinitions={isRawVariant ? [] : groupDefinitions}
      filterDefinitions={isRawVariant ? [] : filterDefinitions}
      groupContentConfig={groupContentConfig}
      {...other}
    />
  );
}
