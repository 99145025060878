import React from 'react';
import { Formik } from 'formik';
import FormikContext from './FormikContext';

function FormikFormInner({ props, onLoad, onUnload, children, formRef }) {
  const currentPropsHolder = React.useRef();
  currentPropsHolder.current = props;
  React.useEffect(() => {
    if (onLoad) onLoad(props);
    return () => {
      if (onUnload) onUnload(currentPropsHolder.current);
    };
  }, []);

  return (
    <form onSubmit={props.handleSubmit} ref={formRef}>
      {children}
    </form>
  );
}

function FormikForm({ initialValues, children, onSubmit, formRef, onLoad, onUnload }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={props => (
        <FormikContext.Provider value={props}>
          <FormikFormInner props={props} formRef={formRef} onLoad={onLoad} onUnload={onUnload}>
            {children}
          </FormikFormInner>
        </FormikContext.Provider>
      )}
    />
  );
}

export default FormikForm;
