import React from 'react';

import WavesIcon from '@material-ui/icons/Waves';
import MapIcon from '@material-ui/icons/Map';

import ListControl from '../layout/ListControl';
import GaugeListItem from './GaugeListItem';
import ShowChartIcon from '@material-ui/icons/ShowChart';

const groupDefinitions = [
  {
    t_title: 'river',
    icon: WavesIcon,
    groupBy: 'river',
  },
  {
    t_title: 'country',
    icon: MapIcon,
    groupBy: 'country',
    translateGroupValue: true,
  },
];

const groupContentConfig = {
  rivers: true,
  countries: true,
  gaugeStats: true,
};

const filterDefinitions = [
  {
    t_title: 'active',
    icon: ShowChartIcon,
    filterField: 'isActive',
    filterValue: 'true',
    default: true,
  },
  {
    t_title: 'used',
    icon: ShowChartIcon,
    filterField: 'isUsedInSection',
    filterValue: 'true',
    default: true,
  },
];

export default function GaugeListControl({ filterParams, ...other }) {
  return (
    <ListControl
      // idName="accountId"
      serviceName="last-measurement"
      filterParams={filterParams}
      // findParams="sort=river,gauge"
      component={GaugeListItem}
      showSearch
      routerStateField="listGauges"
      groupDefinitions={groupDefinitions}
      groupContentConfig={groupContentConfig}
      filterDefinitions={filterDefinitions}
      {...other}
    />
  );
}
