import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useViewportSize from '../utility/useViewportSize';

const useStyles = makeStyles(theme => ({
  articleWrapper: {
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    backgroundColor: '#F2F3F5',
    padding: '5px',
  },
  source: {
    textTransform: 'uppercase',
  },
  title: {
    fontWeight: 'bold',
  },
}));

export default function CommentArticleView({ title, description, image, source, url }) {
  const classes = useStyles();
  const size = useViewportSize();
  return (
    <div className={classes.articleWrapper}>
      <a href={url} target="_blank">
        <img src={image} alt={source} style={{ maxWidth: size.width - 50 }} />
      </a>
      <div className={classes.source}>{source}</div>
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{description}</div>
    </div>
  );
}
