import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PaperCard from './PaperCard';
import { AndroidView } from 'react-device-detect';
import { Trans } from 'react-i18next';
import GpsView from './GpsView';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing.unit,
  },
  name: {
    color: 'gray',
  },
  value: {
    color: 'black',
    // color: theme.palette.text.main,
  },
}));

export default function PlaceView({ name, place, className }) {
  if (!place) return null;
  const { geocoding, coordinates, river, section, geotag } = place;
  if (!coordinates) return null;

  const classes = useStyles();
  return (
    <PaperCard>
      <div className={classes.root}>
        <Typography component="div">
          <div variant="body1" className={classes.name}>
            {name}
          </div>
          {geocoding && (
            <div variant="body2" className={`${className || ''} ${classes.value}`}>
              {geocoding.city}
            </div>
          )}
          <GpsView name="GPS" coordinates={coordinates} river={river} section={section} geotag={geotag} />
          {/* <div variant="body2" className={`${className || ''} ${classes.value}`}>
            {coordinates[1]}, {coordinates[0]}
          </div> */}
        </Typography>
        {/* <Button
          color="primary"
          variant="contained"
          href={`http://maps.google.com/maps?daddr=${coordinates[1]},${coordinates[0]}`}
          target="_blank"
        >
          <Trans>Google maps</Trans>
        </Button>
        <AndroidView>
          <Button color="primary" variant="contained" href={`geo:${coordinates[1]},${coordinates[0]}`}>
            <Trans>Choose map</Trans>
          </Button>
        </AndroidView> */}
      </div>
    </PaperCard>
  );
}
