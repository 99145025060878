import React from 'react';
import AppLayout from '../layout/AppLayout';
import EventGroupListControl from './EventGroupListControl';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';

function SectionEventGroupsPage() {
  const t = useT();
  const { history, match } = useReactRouter();
  const { sectionId } = match.params;

  return (
    <AppLayout title={t('Runs')} mainIconMeaning="top" showRefreshButton>
      <EventGroupListControl routerStateField="listAll" filterParams={`&sectionId=${sectionId}`} />
    </AppLayout>
  );
}

export default SectionEventGroupsPage;
