import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import useFormik from '../utility/useFormik';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import { AST_Label } from 'terser';
import useT from '../utility/useT';

const styles = theme => ({
  field: {
    // margin: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

function FormSelect({ name, classes, label, t_label, children, ...otherProps }) {
  // return (
  //   <TextField name={name} {...otherProps} />
  // );
  const t = useT();

  const { handleChange, handleBlur, values } = useFormik();
  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{t_label ? t(t_label) : label}</InputLabel>
      <NativeSelect
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.field}
        value={values && values[name]}
        name={name}
        {...otherProps}
      >
        {children}
      </NativeSelect>
    </FormGroup>
  );

  // return <TextField label="Section" fullwidth />;
  // return <input type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} name="name" />;
}

export default withStyles(styles)(FormSelect);
