import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import RiversPage from './rivers/RiversPage';
import DashboardPage from './dashboard/DashboardPage';
import HomePage from './dashboard/HomePage';
import HomePage2 from './dashboard/HomePage2';
import RiverDetailPage from './rivers/RiverDetailPage';
import PeoplePage from './people/PeoplePage';
import PeopleDetailPage from './people/PeopleDetailPage';
import EventsPage from './events/EventsPage';
import EventGroupsPage from './events/EventGroupsPage';
import IconListPage from './icons/IconListPage';
import EventDetailPage from './events/EventDetailPage';
import SectionsPage from './sections/SectionsPage';
import SectionDetailPage from './sections/SectionDetailPage';
import EditSectionPage from './sections/EditSectionPage';
import EditEventPage from './events/EditEventPage';
import EditProfilePage from './people/EditProfilePage';
import MyProfilePage from './people/MyProfilePage';
import AuthProvider from './providers/AuthProvider';
import LocationListPage from './locations/LocationListPage';
import PlacesPage from './places/PlacesPage';

import GaugeDetailPage from './gauges/GaugeDetailPage';
import GaugesPage from './gauges/GaugesPage';
import EditPlacePage from './places/EditPlacePage';
import PlaceDetailPage from './places/PlaceDetailPage';
import NotificationsPage from './notifications/NotificationsPage';
import ResetPasswordPage from './people/ResetPasswordPage';
import EditTripPage from './trips/EditTripPage';
import TripsPage from './trips/TripsPage';
import TripDetailPage from './trips/TripDetailPage';
import EditNotificationsPage from './people/EditNotificationsPage';
import EditTrainingPage from './trainings/EditTrainingPage';
import PaddlemeterPage from './paddlemeter/PaddlemeterPage';
import TrainingsPage from './trainings/TrainingsPage';
import GAListener from './GAListener';
import PlayspotsPage from './playspots/PlayspotsPage';
import PlayspotDetailPage from './playspots/PlayspotDetailPage';
import EditPlayspotPage from './playspots/EditPlayspotPage';
import EventGroupDetailPage from './events/EventGroupDetailPage';
import SectionEventGroupsPage from './events/SectionEventGroupsPage';
import RiverEventGroupsPage from './events/RiverEventGroupsPage';
import RiverSectionsPage from './sections/RiverSectionsPage';
import MyGaugesPage from './gauges/MyGaugesPage';
import SectionsNearPage from './sections/SectionsNearPage';

function PrivateRoute({ path, component: Component }) {
  const { auth } = AuthProvider.useAuth();
  return <Route path={path} render={() => (auth ? <Component /> : <Redirect to="/" />)} />;
}

function AppRoutes() {
  const { auth } = AuthProvider.useAuth();

  return (
    <BrowserRouter>
      <GAListener>
        <Switch>
          <Route path="/rivers/:river" component={RiverDetailPage} />
          <Route path="/rivers" component={RiversPage} />

          <Route path="/people/detail/:accountId" component={PeopleDetailPage} />
          <Route path="/events/detail/:diaryId" component={EventDetailPage} />
          <Route path="/sections/detail/:sectionId" component={SectionDetailPage} />
          <PrivateRoute path="/sections/create-from-river/:createFromRiverName/:createFromRiverCountry" component={EditSectionPage} />
          <PrivateRoute path="/sections/edit/:sectionId" component={EditSectionPage} />
          <PrivateRoute path="/sections/edit" component={EditSectionPage} />
          <Route path="/gauges/:engine/:river/:gauge" component={GaugeDetailPage} />
          <PrivateRoute path="/events/edit/:diaryId" component={EditEventPage} />
          <PrivateRoute path="/events/copy/:copyFromDiaryId" component={EditEventPage} />
          <PrivateRoute path="/events/create-from-section/:createFromSectionId" component={EditEventPage} />
          <PrivateRoute path="/events/edit" component={EditEventPage} />
          <Route path="/people" component={PeoplePage} />
          <PrivateRoute path="/profile/edit" component={EditProfilePage} />
          <PrivateRoute path="/profile/notifications" component={EditNotificationsPage} />
          <PrivateRoute path="/my-profile" component={MyProfilePage} />
          <PrivateRoute path="/my-locations" component={LocationListPage} />

          <PrivateRoute path="/places/edit/:placeId" component={EditPlacePage} />
          <PrivateRoute path="/places/detail/:placeId" component={PlaceDetailPage} />
          <PrivateRoute path="/places/edit" component={EditPlacePage} />
          <PrivateRoute path="/places/create-from-location/:createFromLocationId" component={EditPlacePage} />
          <PrivateRoute path="/places/copy/:createFromPlaceId" component={EditPlacePage} />
          <PrivateRoute path="/places/create-from-section/:createFromSectionId" component={EditPlacePage} />
          <PrivateRoute path="/places" component={PlacesPage} />
          <PrivateRoute path="/notifications" component={NotificationsPage} />

          <PrivateRoute path="/trainings/create/:createFromPlayspotId" component={EditTrainingPage} />
          <PrivateRoute path="/trainings/edit/:trainingId" component={EditTrainingPage} />
          <Route path="/trainings" component={TrainingsPage} />
          <Route path="/playspots/detail/:playspotId" component={PlayspotDetailPage} />
          <PrivateRoute path="/playspots/edit/:playspotId" component={EditPlayspotPage} />
          <PrivateRoute path="/playspots/create" component={EditPlayspotPage} />
          <Route path="/playspots" component={PlayspotsPage} />

          <PrivateRoute path="/trips/edit/:tripId" component={EditTripPage} />
          <PrivateRoute path="/trips/edit" component={EditTripPage} />
          <PrivateRoute path="/trips/detail/:tripId" component={TripDetailPage} />
          <PrivateRoute path="/trips" component={TripsPage} />
          <PrivateRoute path="/paddlemeter" component={PaddlemeterPage} />

          <Route path="/events" component={EventsPage} />
          <Route path="/event-groups" component={EventGroupsPage} />
          <Route path="/section-event-groups/:sectionId" component={SectionEventGroupsPage} />
          <Route path="/river-event-groups/:river" component={RiverEventGroupsPage} />
          <Route path="/river-sections/:river" component={RiverSectionsPage} />
          <Route path="/event-group/:day/:sectionId" component={EventGroupDetailPage} />
          <Route path="/sections-near/:latitude/:longitude" component={SectionsNearPage} />
          <Route
            path="/sections/runnable"
            component={props => <SectionsPage {...props} defaultFilters={{ isRunnable: 'true' }} />}
          />
          <Route
            path="/sections/with-events"
            component={props => <SectionsPage {...props} defaultFilters={{ isRunnable: 'true', hasEvents: 'true' }} />}
          />
          <Route
            path="/sections/without-events"
            component={props => <SectionsPage {...props} defaultFilters={{ isRunnable: 'true', hasEvents: 'false' }} />}
          />
          <Route path="/sections" component={SectionsPage} />
          <Route path="/reset-password/:accountId/:resetToken" component={ResetPasswordPage} />
          <Route path="/gauges" component={GaugesPage} />
          <Route path="/my-gauges" component={MyGaugesPage} />
          <Route path="/icon-list" component={IconListPage} />

          <PrivateRoute path="/dashboard" component={DashboardPage} />
          <Route path="/home2" component={HomePage2} />
          <Route path="/home" component={HomePage} />
          <Route exact path="/" render={() => (auth ? <Redirect to="/dashboard" /> : <HomePage />)} />
        </Switch>
      </GAListener>
    </BrowserRouter>
  );
}

//const AppRoutesComposed = withRouter(AppRoutes);

export default AppRoutes;
