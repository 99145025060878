import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  paper: {
    // ...theme.mixins.gutters(),
    padding: theme.spacing.unit,
    // paddingTop: theme.spacing.unit,
    // paddingBottom: theme.spacing.unit,
    margin: theme.spacing.unit,
    display: 'flex',
  },
});

function PaperCols({ children, classes, ...other }) {
  return <Paper className={classes.paper}  {...other}>{children}</Paper>;
}

export default withStyles(styles)(PaperCols);
