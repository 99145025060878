import React from 'react';
import JSEMOJI from 'emoji-js';

import asciiAliases from './emoji/asciiAliases';
import asciiRegex from './emoji/asciiRegex';

const asciiAliasesRegex = asciiRegex();

function replaceAsciiAliases(...match) {
  const asciiAliasKeys = Object.keys(asciiAliases);

  for (let i in asciiAliasKeys) {
    const alias = asciiAliasKeys[i];
    const data = asciiAliases[alias];
    const aliasFound = match[2];

    if (data.includes(aliasFound)) {
      const isEdgeCase = match[1];

      if (!isEdgeCase) {
        return `:${alias}:`;
      }

      // return the original word to replace its value in aliasesRegex
      return match[0];
    }
  }
}

function replaceEmoji(text) {
  const jsemoji = new JSEMOJI();
  jsemoji.img_set = 'emojione';
  jsemoji.img_sets.emojione.path = 'https://cdn.jsdelivr.net/emojione/assets/3.0/png/32/';
  // jsemoji.img_sets.emojione.path = 'https://cdnjs.cloudflare.com/ajax/libs/emojione/2.2.7/assets/png/';

  jsemoji.supports_css = false;
  jsemoji.allow_native = false;
  jsemoji.replace_mode = 'unified';
  return jsemoji.replace_colons(text);
}

function replaceUrlRegex(url) {
  const urlText = url.length > 30 ? url.substring(0, 30) + '…' : url;
  return `<a href='${url}' target='_blank'>${urlText}</a>`;
}

export default function CommentTextView({ text }) {
  const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: replaceEmoji(
          (text || '')
            .replace(/<\/?[^>]+(>|$)/g, '')
            .replace(asciiAliasesRegex, replaceAsciiAliases)
            .replace(urlRegex, replaceUrlRegex)
        ),
      }}
    />
  );
}
