import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import DifficultyIcon from '../icons/Difficulty';
import NameValueView from '../ui-elements/NameValueView';
import PaperCols from '../ui-elements/PaperCols';
import PaperRows from '../ui-elements/PaperRows';
import Flag from '../icons/Flag';
import ImageStars from '../icons/ImageStars';
import PaperCard from '../ui-elements/PaperCard';
import PlaceView from '../ui-elements/PlaceView';
import { waterLevelNames } from '../icons/WaterLevel';
import TeamMembersView from '../people/TeamMembersView';
import useT from '../utility/useT';

const useStyles = makeStyles(theme => ({}));

function TripDetailView({ tripId, name, description, startTm, endTm, team, accountName }) {
  const classes = useStyles();
  const t = useT();

  return (
    <Grid container alignItems="stretch">
      <PaperCard>
        <NameValueView t_name="Name" value={name} />
        <NameValueView t_name="Owner" value={accountName} />
        <NameValueView t_name="Description" value={description} />
        <NameValueView t_name="Start" value={moment(startTm).format('D. M. YYYY HH:mm')} />
        <NameValueView t_name="End" value={moment(endTm).format('D. M. YYYY HH:mm')} />
      </PaperCard>
      {team && (
        <PaperCard>
          <TeamMembersView team={team} label={t('Team members')} />
        </PaperCard>
      )}
    </Grid>
  );
}

export default TripDetailView;
