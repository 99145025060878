import React from 'react';
import useReactRouter from 'use-react-router';

export default function useRouterDialog(routerStateField) {
  const { history } = useReactRouter();

  const setOpen = value =>
    history.push(history.location.pathname, {
      ...history.location.state,
      [`${routerStateField}_dialogIsOpen`]: value,
    });

  function handleClickOpen() {
    setOpen(true);
  }

  const onClose = () => {
    history.goBack();
    // setOpen(false);
  };

  const open = (history.location.state && history.location.state[`${routerStateField}_dialogIsOpen`]) || false;

  const dialogProps = {
    open,
    onClose,
  };

  return [handleClickOpen, dialogProps];
}
