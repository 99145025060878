import React from "react";

export default function Beer({ size }) {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512.001 512.001"
      enable-background="new 0 0 512.001 512.001"
      width={size}
      height={size}
    >
      <path
        fill="#FA9C1C"
        d="M349.059,186.206c-14.871,0-28.08-7.001-36.597-17.859c-5.658,1.481-11.565,2.349-17.687,2.349
      c-13.202,0-25.502-3.733-36.029-10.1c-7.664,15.178-23.355,25.611-41.52,25.611c-17.458,0-32.649-9.629-40.609-23.851
      c-12.793,14.591-31.519,23.851-52.449,23.851c-17.901,0-34.175-6.797-46.53-17.877v2.367v275.756
      c0,31.869,25.836,57.705,57.705,57.705h163.766c31.869,0,57.705-25.836,57.705-57.705V185.509
      C354.288,185.933,351.708,186.206,349.059,186.206z"
      />
      <path
        fill="#FFFFFF"
        d="M124.167,46.618c7.414,0,14.548,1.177,21.251,3.319c10.722-24.76,35.354-42.094,64.052-42.094
      c23.467,0,44.179,11.62,56.832,29.379c8.702-3.897,18.319-6.113,28.472-6.113c36.809,0,66.891,28.515,69.534,64.648
      c18.196,6.32,31.28,23.572,31.28,43.92c0,25.698-20.832,46.53-46.53,46.53c-14.871,0-28.08-7.001-36.597-17.859
      c-5.658,1.481-11.565,2.349-17.687,2.349c-13.202,0-25.502-3.733-36.029-10.1c-7.664,15.178-23.355,25.611-41.52,25.611
      c-17.458,0-32.649-9.629-40.609-23.851c-12.793,14.591-31.519,23.851-52.449,23.851c-38.546,0-69.794-31.248-69.794-69.794
      C54.373,77.865,85.621,46.618,124.167,46.618z"
      />
      <path
        fill="#1D1D1B"
        d="M444.973,197.765c-11.523-16.718-27.006-29.997-45.098-38.771c2.291-6.007,3.558-12.516,3.558-19.318
      c0-21.4-12.597-40.673-31.822-49.436c-5.244-37.935-38.077-66.976-76.836-66.976c-8.876,0-17.563,1.504-25.902,4.478
      C254.178,10.269,232.403,0,209.471,0c-28.672,0-54.774,15.758-68.261,40.673c-5.594-1.261-11.302-1.898-17.043-1.898
      c-42.808,0-77.637,34.828-77.637,77.637c0,21.653,8.92,41.253,23.265,55.349v274.691c0,36.143,29.405,65.549,65.549,65.549h163.765
      c36.143,0,65.549-29.405,65.549-65.549v-67.329c26.525-3.553,50.953-16.111,69.419-35.845
      c20.246-21.635,31.395-49.877,31.395-79.522C465.47,240.04,458.382,217.221,444.973,197.765z M62.215,116.412
      c0-34.16,27.791-61.952,61.952-61.952c6.395,0,12.743,0.991,18.865,2.948l6.762,2.159l2.82-6.514
      c9.832-22.701,32.15-37.369,56.856-37.369c19.95,0,38.807,9.752,50.445,26.087l3.728,5.233l5.864-2.627
      c8.042-3.601,16.544-5.428,25.266-5.428c32.252,0,59.359,25.204,61.713,57.379l0.376,5.144l4.872,1.693
      c15.558,5.404,26.01,20.076,26.01,36.512c0,21.332-17.355,38.687-38.687,38.687c-11.929,0-23.02-5.416-30.427-14.857l-3.176-4.049
      l-4.98,1.303c-5.31,1.39-10.593,2.093-15.703,2.093c-11.216,0-22.271-3.102-31.969-8.968l-7.245-4.382l-3.816,7.557
      c-6.635,13.14-19.862,21.303-34.52,21.303c-13.979,0-26.916-7.602-33.765-19.839l-5.423-9.687l-7.319,8.348
      c-11.8,13.459-28.768,21.178-46.553,21.178C90.006,178.364,62.215,150.572,62.215,116.412z M299.109,496.315H135.343
      c-27.494,0-49.864-22.368-49.864-49.863V183.68c11.399,6.582,24.605,10.369,38.686,10.369c18.996,0,37.241-7.014,51.364-19.508
      c10.23,12.223,25.461,19.508,41.695,19.508c17.903,0,34.283-8.649,44.384-23.016c10.372,4.928,21.696,7.506,33.165,7.506
      c4.875,0,9.848-0.501,14.838-1.492c10.235,10.843,24.314,16.976,39.359,17v252.405C348.971,473.946,326.602,496.315,299.109,496.315
      z M364.657,363.272V191.758c11.072-3.322,20.665-10.083,27.541-19.039c35.178,16.626,57.587,51.704,57.587,91.036
      C449.785,313.55,413.023,355.725,364.657,363.272z"
      />
      <g>
        <path
          fill="#FBB049"
          d="M108.657,201.716v224.892c0,8.566,6.944,15.51,15.51,15.51l0,0c8.566,0,15.51-6.944,15.51-15.51
        V201.716"
        />
        <path
          fill="#FBB049"
          d="M170.697,201.716v224.892c0,8.566,6.944,15.51,15.51,15.51l0,0c8.566,0,15.51-6.944,15.51-15.51
        V201.716"
        />
        <path
          fill="#FBB049"
          d="M232.736,201.716v224.892c0,8.566,6.944,15.51,15.51,15.51l0,0c8.566,0,15.51-6.944,15.51-15.51
        V201.716"
        />
        <path
          fill="#FBB049"
          d="M294.775,201.716v224.892c0,8.566,6.944,15.51,15.51,15.51l0,0c8.566,0,15.51-6.944,15.51-15.51
        V201.716"
        />
      </g>
      <g>
        <path
          fill="#1D1D1B"
          d="M124.167,449.961c-12.876,0-23.352-10.476-23.352-23.352V201.716H116.5v224.892
        c0,4.228,3.439,7.667,7.667,7.667c4.228,0,7.667-3.439,7.667-7.667V201.716h15.685v224.892
        C147.519,439.485,137.042,449.961,124.167,449.961z"
        />
        <path
          fill="#1D1D1B"
          d="M186.206,449.961c-12.876,0-23.352-10.476-23.352-23.352V201.716h15.685v224.892
        c0,4.228,3.439,7.667,7.667,7.667s7.667-3.439,7.667-7.667V201.716h15.685v224.892
        C209.559,439.485,199.082,449.961,186.206,449.961z"
        />
        <path
          fill="#1D1D1B"
          d="M248.246,449.961c-12.876,0-23.352-10.476-23.352-23.352V201.716h15.685v224.892
        c0,4.228,3.439,7.667,7.667,7.667s7.667-3.439,7.667-7.667V201.716h15.685v224.892
        C271.598,439.485,261.121,449.961,248.246,449.961z"
        />
        <path
          fill="#1D1D1B"
          d="M310.284,449.961c-12.876,0-23.352-10.476-23.352-23.352V201.716h15.685v224.892
        c0,4.228,3.439,7.667,7.667,7.667s7.668-3.439,7.668-7.667V201.716h15.685v224.892
        C333.637,439.485,323.162,449.961,310.284,449.961z"
        />
        <path
          fill="#1D1D1B"
          d="M108.745,108.657H93.06c0-17.152,13.955-31.108,31.107-31.108v15.685
        C115.663,93.234,108.745,100.153,108.745,108.657z"
        />
      </g>
      <rect
        x="131.924"
        y="465.299"
        fill="#FFFFFF"
        width="170.604"
        height="15.685"
      />
    </svg>
  );
}
