import React from 'react';
import _ from 'lodash';
import AppLayout from '../layout/AppLayout';
import TabControl from '../layout/RouteTabControl';
import ListControl from '../layout/ListControl';
import useReactRouter from 'use-react-router';

import AuthProvider from '../providers/AuthProvider';
import getEditingObjects from '../utility/getEditingObjects';
import TripListItem from './TripListItem';

function TripsPage() {
  const { auth } = AuthProvider.useAuth();
  const { history } = useReactRouter();

  return (
    <AppLayout t_title="Trips" mainIconMeaning="top" showRefreshButton>
      {auth ? (
        <TabControl>
          <TabControl.Page t_label="My Trips" path={'/trips'}>
            <ListControl
              routerStateField="myTripsList"
              extraItems={_.sortBy(getEditingObjects('EditingTrips', 'tripId'), 'startTm').reverse()}
              idName="placeId"
              url={`trip-info/find?teamAccountId=${auth.accountId}`}
              component={TripListItem}
              onAdd={() => history.push('/trips/edit')}
            />
          </TabControl.Page>
          <TabControl.Page t_label="All Trips" path={'/trips/all'}>
            <ListControl routerStateField="allTripsList" url={`trip-info/find`} component={TripListItem} />
          </TabControl.Page>
        </TabControl>
      ) : (
        <ListControl routerStateField="freeTripsList" url={`trip-info/find`} component={TripListItem} />
      )}
    </AppLayout>
  );
}

export default TripsPage;
