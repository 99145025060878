import React from 'react';

export default function Flag({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 3000 2000">
      <g id="layer1" transform="translate(1722.8572,739.06641)">
        <rect width="3000" height="2000" x="-1722.8572" y="-739.06641" id="rect4081" style={{ fill: '#ffffff' }} />
        <rect
          width="3000"
          height="666.66602"
          x="-1722.8572"
          y="-739.06641"
          id="rect4083"
          style={{ fill: '#dd0000', fillOpacity: 1 }}
        />
        <rect
          width="3000"
          height="666.66602"
          x="-1722.8572"
          y="594.2666"
          id="rect4085"
          style={{ fill: '#003893', fillOpacity: 1 }}
        />
        <path
          id="path4238-3-5"
          d="M -477.5983,-3.9929 H 32.917099 v 18.10367 282.83071 c 0,37.76911 9.7012,135.23783 -61.843799,175.44369 -46.0796,25.58539 -137.0268,19.49342 -192.8076,54.82583 -56.9934,-35.33241 -146.7277,-29.24066 -192.8075,-54.82615 -71.5448,-40.20577 -63.0565,-137.67426 -63.0565,-175.44337 z"
          style={{
            fill: '#dd0000',
            fillOpacity: 1,
            stroke: '#dd0000',
            strokeWidth: 7.29293346,
            strokeOpacity: 1,
          }}
        />
        <g id="g4525" transform="translate(-840.4125,197.11331)">
          <path
            id="path4238-5-1"
            d="m 366.8142,-201.10621 501.3554,0 c 0,-0.60756 0,300.16906 0,300.16906 0,37.67318 9.527,134.89388 -60.7341,174.99747 C 762.1827,299.5808 672.8672,293.50426 618.0874,328.74681 562.1165,293.50426 473.9925,299.5804 428.7394,274.06012 358.4783,233.95653 366.8142,136.73603 366.8142,99.06285 c 0,0 0,-300.16906 0,-300.16906 z"
            style={{
              fill: '#dd0000',
              fillOpacity: 1,
              stroke: '#dd0000',
              strokeWidth: 6.14597464,
              strokeMiterlimit: 4,
              strokeOpacity: 1,
              strokeDasharray: 'none',
            }}
          />
          <path
            id="path4240-5-7"
            d="m 667.3622,-198.33046 0,101.72352 98.8468,0 0,-101.72352 z m 98.8468,101.72352 0,100.87126 98.9166,0 0,-100.87126 z m 0,100.87126 -98.8468,0 0,100.87126 98.8162,0 0,100.87146 89.9317,-1.43407 c 3.377,-9.06843 8.6325,-32.43359 9.465,-63.47479 l 0,-35.9626 -99.3661,0 z m -0.03,201.74272 -98.816,0 -1.1472,99.29713 C 699.8645,295.83365 736.6,293.32193 766.179,285.0621 z m -98.816,0 0,-100.87146 -98.8467,0 0,100.87146 z m -98.8467,0 -98.8468,0 -0.2867,78.76808 c 29.5789,8.26004 65.7718,11.05853 99.4203,20.52905 z m -98.8468,0 0,-100.87146 -100.1037,0 0.2868,41.41227 c 1.4043,31.32797 6.6352,49.81721 10.008,58.88564 z m 0,-100.87146 98.8468,0 0,-100.87126 -98.8468,0 z m 0,-100.87126 0,-100.87126 -100.1333,0 0.287,100.87126 z m 0,-100.87126 98.8468,0 0,-101.801 -98.8468,0.28677 z m 98.8468,0 0,100.87126 98.8467,0 0,-100.87126 z"
            style={{
              fill: '#ffffff',
              fillOpacity: 1,
              strokeWidth: 6.1953578,
              strokeMiterlimit: 4,
              strokeDasharray: 'none',
            }}
          />
        </g>
      </g>
    </svg>
  );
}
