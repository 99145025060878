import _ from 'lodash';
import fetchApi from '../utility/fetchApi';
import { getLocalStorage, setLocalStorage } from '../utility/storage-cache';
import processSaveResponse from '../utility/processSaveResponse';

export default async function saveLocations(enqueueSnackbar, auth) {
  const editingLocations = getLocalStorage('EditingLocations');
  for (const location of _.values(editingLocations)) {
    console.log('Saving location', location);
    const saveData = await fetchApi.post(
      `location-info/save`,
      { entity: location },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    if (!processSaveResponse(saveData, enqueueSnackbar, null)) return;
  }
  setLocalStorage('EditingLocations', {});
}
