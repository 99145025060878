import React from 'react';

export default function Barrel({ size }) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 470 470"
      enable-background="new 0 0 470 470"
      width={size}
      height={size}
    >
      <g>
        <path
          fill="#FFFFFF"
          d="M378.824,379.228c-22.975,6.75-70.213,17.102-143.814,17.102
        c-73.62-0.001-120.859-10.353-143.835-17.103c1.863,4.906,3.839,9.779,5.915,14.59c27.845,8.514,73.588,18.154,137.909,18.154
        c64.318,0,110.063-9.64,137.91-18.154C374.986,389.005,376.961,384.132,378.824,379.228z"
        />
        <path
          fill="#FFFFFF"
          d="M91.176,90.772c22.976-6.75,70.217-17.102,143.814-17.102
        c73.62,0.001,120.859,10.352,143.835,17.102c-1.863-4.905-3.838-9.777-5.915-14.589C345.062,67.669,299.317,58.028,235,58.028
        c-64.323,0-110.065,9.641-137.909,18.154C95.014,80.994,93.039,85.867,91.176,90.772z"
        />
        <path
          fill="#FFE6B8"
          d="M311.625,422.084c-1.767,8.599-3.666,16.947-5.673,24.982c25.657-6.215,40.646-14.221,45.034-16.788
        c3.309-5.61,6.486-11.442,9.51-17.454C346.941,416.238,330.658,419.532,311.625,422.084z"
        />
        <path
          fill="#FFE6B8"
          d="M319.532,93.914c6.259,44.21,9.433,91.615,9.433,141.086c0,49.47-3.174,96.876-9.433,141.086
        c30.355-4.081,50.488-9.618,61.128-13.113c12.727-39.956,19.197-82.982,19.197-127.973c0-44.99-6.47-88.017-19.197-127.973
        C370.02,103.532,349.887,97.995,319.532,93.914z"
        />
        <path
          fill="#FFE6B8"
          d="M305.966,22.988c2.002,8.018,3.896,16.347,5.66,24.927c19.033,2.552,35.315,5.847,48.871,9.26
        c-3.021-6.008-6.196-11.836-9.502-17.442C346.604,37.19,331.549,29.207,305.966,22.988z"
        />
        <path
          fill="#FFAD61"
          d="M242.5,426.924v27.996c17.891-0.378,33.585-2.087,47.096-4.455
        c2.237-8.453,4.349-17.329,6.313-26.522C279.809,425.602,262.014,426.698,242.5,426.924z"
        />
        <path
          fill="#FFAD61"
          d="M235,351.331c4.142,0,7.5,3.358,7.5,7.5v22.46c23.449-0.229,43.951-1.519,61.606-3.366
        c6.537-44.633,9.859-92.677,9.859-142.925c0-50.248-3.322-98.292-9.859-142.925c-17.656-1.847-38.158-3.137-61.606-3.366v240.122
        c0,4.142-3.358,7.5-7.5,7.5C234.997,341.384,234.997,346.385,235,351.331z"
        />
        <path
          fill="#FFAD61"
          d="M242.5,43.076c19.514,0.226,37.31,1.321,53.409,2.981c-1.961-9.178-4.069-18.042-6.303-26.482
        c-13.533-2.386-29.237-4.11-47.106-4.493V43.076z"
        />
        <path
          fill="#FFE6B8"
          d="M227.5,426.924c-19.515-0.226-37.31-1.321-53.409-2.98c1.961,9.177,4.069,18.04,6.302,26.482
        c13.533,2.386,29.237,4.111,47.106,4.493V426.924z"
        />
        <path
          fill="#FFE6B8"
          d="M148.571,242.5c0.026,0,0.052,0,0.078,0c4.095,0,7.442,3.292,7.498,7.399
        c0.605,44.972,3.886,88.004,9.745,128.026c17.656,1.848,38.159,3.137,61.609,3.367v-22.46c0-4.141,3.357-7.499,7.498-7.5
        c0-4.946,0-9.947,0-15c-4.141-0.001-7.498-3.359-7.498-7.5V88.709c-23.45,0.229-43.953,1.519-61.609,3.367
        c-5.858,40.022-9.14,83.054-9.745,128.026c-0.056,4.107-3.403,7.399-7.498,7.399c-0.026,0-0.052,0-0.078,0
        c-0.017,2.495-0.034,4.991-0.034,7.5C148.537,237.509,148.554,240.004,148.571,242.5z"
        />
        <path
          fill="#FFE6B8"
          d="M227.5,43.076V15.081c-17.869,0.382-33.573,2.107-47.106,4.493
        c-2.233,8.441-4.341,17.304-6.302,26.482C190.19,44.397,207.985,43.301,227.5,43.076z"
        />
        <path
          fill="#FFAD61"
          d="M164.034,447.012c-2.002-8.018-3.896-16.348-5.659-24.927c-19.033-2.552-35.315-5.846-48.871-9.26
        c3.021,6.008,6.196,11.835,9.501,17.439C123.418,432.82,138.471,440.798,164.034,447.012z"
        />
        <path
          fill="#FFAD61"
          d="M150.471,376.086c-5.592-39.53-8.73-81.854-9.324-125.985c-0.056-4.142,3.257-7.544,7.398-7.601
        c0.009-2.496-0.008-4.992-0.008-7.501c0-2.509,0.017-5.004,0.034-7.5c-4.167-0.056-7.48-3.459-7.424-7.601
        c0.594-44.132,3.732-86.455,9.323-125.985c-30.356,4.081-50.49,9.618-61.131,13.113C76.613,146.983,70.143,190.009,70.143,235
        c0,44.991,6.47,88.018,19.197,127.972C99.981,366.467,120.115,372.005,150.471,376.086z"
        />
        <path
          fill="#FFAD61"
          d="M158.374,47.915c1.763-8.579,3.657-16.909,5.659-24.926c-25.562,6.214-40.616,14.191-45.029,16.747
        c-3.306,5.604-6.48,11.431-9.501,17.439C123.059,53.762,139.341,50.467,158.374,47.915z"
        />
        <path
          fill="#4D3D36"
          d="M414.857,235c0-44.555-6.139-87.298-18.242-127.216c1.786-1.989,2.404-4.785,1.554-7.353
        c-3.726-11.268-8.067-22.514-12.902-33.427c-0.869-1.962-2.538-3.458-4.583-4.109c-0.32-0.102-0.657-0.205-0.982-0.308
        c-5.096-11.246-10.723-22.045-16.763-32.134c-0.022-0.037-0.049-0.07-0.071-0.107c-0.083-0.134-0.172-0.265-0.264-0.395
        c-0.056-0.079-0.112-0.157-0.171-0.233c-0.087-0.113-0.179-0.222-0.273-0.33c-0.075-0.087-0.151-0.172-0.229-0.255
        c-0.083-0.087-0.169-0.171-0.257-0.254c-0.1-0.095-0.2-0.188-0.304-0.276c-0.077-0.065-0.156-0.128-0.236-0.191
        c-0.121-0.095-0.244-0.187-0.371-0.274c-0.037-0.026-0.07-0.055-0.108-0.08C358.929,26.913,317.476,0,235,0
        S111.071,26.913,109.346,28.059c-0.034,0.022-0.063,0.048-0.096,0.071c-0.135,0.092-0.265,0.19-0.394,0.292
        c-0.072,0.057-0.144,0.114-0.214,0.173c-0.112,0.095-0.219,0.194-0.326,0.296c-0.08,0.076-0.159,0.153-0.235,0.232
        c-0.086,0.09-0.169,0.184-0.251,0.279c-0.086,0.1-0.171,0.2-0.251,0.303c-0.066,0.086-0.13,0.174-0.193,0.263
        c-0.083,0.118-0.164,0.236-0.24,0.358c-0.027,0.043-0.058,0.083-0.085,0.127c-6.039,10.089-11.666,20.888-16.763,32.134
        c-0.326,0.102-0.662,0.206-0.983,0.308c-2.045,0.651-3.714,2.146-4.583,4.108c-4.833,10.908-9.174,22.154-12.902,33.428
        c-0.849,2.568-0.231,5.364,1.554,7.353C61.282,147.702,55.143,190.445,55.143,235c0,44.555,6.139,87.299,18.242,127.215
        c-1.786,1.989-2.404,4.786-1.554,7.353c3.729,11.275,8.07,22.522,12.902,33.428c0.87,1.962,2.538,3.458,4.583,4.108
        c0.321,0.102,0.657,0.205,0.983,0.308c5.097,11.247,10.724,22.047,16.763,32.134c0.018,0.03,0.04,0.055,0.058,0.084
        c0.148,0.24,0.307,0.473,0.483,0.697c0.016,0.02,0.03,0.042,0.046,0.062c0.184,0.23,0.385,0.448,0.599,0.657
        c0.068,0.067,0.14,0.128,0.211,0.191c0.144,0.13,0.292,0.256,0.447,0.377c0.094,0.073,0.19,0.144,0.287,0.212
        c0.053,0.037,0.1,0.078,0.154,0.114C111.071,443.086,152.524,470,235,470s123.93-26.914,125.654-28.06
        c0.054-0.036,0.101-0.077,0.154-0.114c0.097-0.068,0.193-0.139,0.287-0.212c0.156-0.12,0.304-0.247,0.448-0.377
        c0.07-0.064,0.142-0.124,0.21-0.191c0.214-0.209,0.415-0.428,0.599-0.657c0.016-0.02,0.03-0.042,0.045-0.062
        c0.176-0.224,0.336-0.458,0.484-0.698c0.018-0.029,0.04-0.053,0.057-0.083c6.04-10.09,11.667-20.889,16.763-32.133
        c0.326-0.102,0.662-0.206,0.983-0.308c2.045-0.65,3.714-2.146,4.583-4.109c4.835-10.912,9.176-22.159,12.902-33.428
        c0.849-2.568,0.231-5.365-1.554-7.353C408.718,322.297,414.857,279.554,414.857,235z M242.5,454.92v-27.996
        c19.515-0.226,37.31-1.321,53.409-2.98c-1.964,9.193-4.076,18.069-6.313,26.522C276.085,452.833,260.391,454.541,242.5,454.92z
         M180.393,450.425c-2.233-8.442-4.341-17.304-6.302-26.482c16.099,1.659,33.894,2.755,53.409,2.98v27.995
        C209.63,454.536,193.927,452.811,180.393,450.425z M235,411.971c-64.321,0-110.064-9.64-137.909-18.154
        c-2.076-4.811-4.052-9.684-5.915-14.59c22.975,6.75,70.215,17.102,143.814,17.103c73.622-0.001,120.86-10.352,143.835-17.102
        c-1.863,4.904-3.838,9.777-5.915,14.589C345.062,402.331,299.318,411.971,235,411.971z M227.5,15.081v27.995
        c-19.515,0.226-37.31,1.321-53.409,2.98c1.961-9.178,4.069-18.041,6.302-26.482C193.927,17.188,209.631,15.464,227.5,15.081z
         M289.606,19.574c2.233,8.441,4.342,17.304,6.303,26.482c-16.099-1.659-33.895-2.755-53.409-2.981V15.081
        C260.369,15.464,276.073,17.188,289.606,19.574z M235,58.028c64.317,0,110.062,9.641,137.91,18.154
        c2.077,4.813,4.052,9.685,5.915,14.589c-22.975-6.75-70.215-17.102-143.814-17.102C161.392,73.67,114.151,84.022,91.176,90.772
        c1.863-4.905,3.838-9.778,5.915-14.589C124.935,67.669,170.677,58.028,235,58.028z M380.66,362.973
        c-10.64,3.495-30.773,9.032-61.128,13.113c6.259-44.21,9.433-91.615,9.433-141.086c0-49.471-3.174-96.876-9.433-141.086
        c30.355,4.081,50.488,9.618,61.128,13.113c12.727,39.956,19.197,82.982,19.197,127.973
        C399.857,279.99,393.387,323.017,380.66,362.973z M311.625,47.916c-1.763-8.58-3.658-16.909-5.66-24.927
        c25.583,6.218,40.638,14.202,45.029,16.746c3.306,5.605,6.481,11.433,9.502,17.442C346.941,53.762,330.658,50.468,311.625,47.916z
         M119.005,39.736c4.413-2.556,19.466-10.534,45.029-16.747c-2.002,8.018-3.896,16.347-5.659,24.926
        c-19.033,2.552-35.316,5.847-48.871,9.26C112.525,51.168,115.699,45.34,119.005,39.736z M89.34,107.027
        c10.64-3.495,30.775-9.032,61.131-13.113c-5.592,39.53-8.73,81.853-9.323,125.985c-0.056,4.142,3.257,7.544,7.398,7.601
        c0.051,0,0.077,0,0.103,0c4.095,0,7.442-3.292,7.498-7.399c0.605-44.972,3.886-88.004,9.745-128.026
        c17.656-1.848,38.159-3.137,61.609-3.367v240.122c0,4.141,3.357,7.499,7.498,7.5c4.144,0,7.502-3.358,7.502-7.5V88.709
        c23.449,0.229,43.951,1.519,61.606,3.366c6.537,44.633,9.859,92.677,9.859,142.925c0,50.248-3.322,98.292-9.859,142.925
        c-17.655,1.847-38.157,3.137-61.606,3.366v-22.46c0-4.142-3.358-7.5-7.5-7.5c-4.143,0.001-7.5,3.359-7.5,7.5v22.46
        c-23.45-0.229-43.953-1.519-61.609-3.367c-5.858-40.022-9.14-83.054-9.745-128.026c-0.056-4.107-3.402-7.399-7.498-7.399
        c-0.026,0-0.052,0-0.078,0c-4.167,0.056-7.48,3.459-7.424,7.601c0.594,44.131,3.732,86.455,9.324,125.985
        c-30.356-4.081-50.49-9.619-61.131-13.114C76.613,323.018,70.143,279.991,70.143,235C70.143,190.009,76.613,146.983,89.34,107.027z
         M158.374,422.084c1.763,8.58,3.657,16.909,5.659,24.927c-25.563-6.214-40.615-14.192-45.029-16.748
        c-3.305-5.604-6.48-11.431-9.501-17.439C123.059,416.238,139.341,419.533,158.374,422.084z M350.987,430.279
        c-4.388,2.567-19.377,10.572-45.034,16.788c2.007-8.035,3.906-16.383,5.673-24.982c19.033-2.552,35.315-5.846,48.871-9.26
        C357.473,418.837,354.295,424.669,350.987,430.279z"
        />
      </g>
    </svg>
  );
}
