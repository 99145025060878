import _ from 'lodash';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AuthProvider from '../providers/AuthProvider';
import TabControl from '../layout/TabControl';
import ListControl from '../layout/ListControl';
import SectionListItem from './SectionListItem';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseButtonDialogTitle from '../ui-elements/CloseButtonDialogTitle';
// import useLocationParam from '../utility/useLocationParam';
import { Trans } from 'react-i18next';
import useT from '../utility/useT';
import SectionListControl from './SectionListControl';
import LocationContainer from '../ui-elements/LocationContainer';

function ChooseSectionDialog({ classes, onClose, onChange, stayOpenOnSelect, open, isCheckedFunc, ...other }) {
  const { auth } = AuthProvider.useAuth();
  // const locationParam = useLocationParam({ locationRequired: open });
  // const sortParam = locationParam || 'sort=river,section';
  const t = useT();

  function handleChooseSection(sectionId) {
    if (onChange && _.isString(sectionId)) onChange(sectionId);
    if (!stayOpenOnSelect) onClose();
  }

  function handleClose() {
    onClose();
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="choose-section-dialog-title" open={open} {...other}>
      <CloseButtonDialogTitle id="choose-section-dialog-title" onClose={onClose}>
        <Trans>Choose section</Trans>
      </CloseButtonDialogTitle>

      <LocationContainer
        allowFakeLocation
        // disableCache
        renderContent={location => (
          <SectionListControl
            showSearch
            filterParams={`&isPublished=true&latitude=${location.latitude}&longitude=${location.longitude}`}
            findParams="findMeasurement=1"
            component={SectionListItem.Custom(sectionId => handleChooseSection(sectionId), isCheckedFunc)}
          />
        )}
      />

      {/* <SectionListControl
        component={SectionListItem.Custom(sectionId => handleChooseSection(sectionId), isCheckedFunc)}
        findParams="findMeasurement=1"
        /> */}

      {/* <TabControl>
        <TabControl.Page t_label="Public">
          {locationParam == null ? (
            t('Getting current position')
          ) : (
            <ListControl
              showSearch
              url={`section-info/find?isPublished=true&${sortParam}&findMeasurement=1`}
              component={SectionListItem.Custom(sectionId => handleChooseSection(sectionId), isCheckedFunc)}
            />
          )}
        </TabControl.Page>
        {auth && (
          <TabControl.Page t_label="My">
            <ListControl
              showSearch
              url={`section-info/find?accountId=${auth.accountId}&sort=river,section&findMeasurement=1`}
              component={SectionListItem.Custom(sectionId => handleChooseSection(sectionId), isCheckedFunc)}
            />
          </TabControl.Page>
        )}
      </TabControl> */}
    </Dialog>
  );
}

export default withMobileDialog()(ChooseSectionDialog);
