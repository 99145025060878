import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
// import useT, { getLocalizedMessage } from '../utility/useT';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    // margin: theme.spacing(2),
    padding: theme.spacing(2),
    // backgroundColor: theme.palette.background.paper,
  },
  wrapperInline: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    // backgroundColor: theme.palette.background.paper,
  },
  iconStyle: {
    width: '18px',
    height: '18px',
    marginRight: theme.spacing(1),
  },
  iconLinkStyle: {
    width: '18px',
    height: '18px',
    color: 'blue',
  },
  flex: {
    display: 'flex',
  },
  grower: {
    flexGrow: 1,
  },
  action: {
    marginRight: theme.spacing(1),
    color: 'blue',
  },
}));

export function extractErrorMessage(error) {
  if (error && error.message) return error.message;
  if (error) return error.toString();
  return null;
}

export default function ErrorInfo({ message, inline, isWarning, actionTitle, actionIcon, onActionClick }) {
  const classes = useStyles();
  const background = { backgroundColor: isWarning ? 'rgb(255, 244, 229)' : 'rgb(253, 236, 234)' };
  const ActionIcon = actionIcon;

  return (
    <div className={inline ? classes.wrapperInline : classes.wrapper} style={background}>
      {isWarning ? <WarningIcon className={classes.iconStyle} /> : <ErrorIcon className={classes.iconStyle} />}
      {message}
      <div className={classes.grower} />
      {ActionIcon && onActionClick && <ActionIcon className={classes.iconLinkStyle} onClick={onActionClick} />}
      {actionTitle && onActionClick && (
        <div className={classes.action} onClick={onActionClick}>
          {actionTitle}
        </div>
      )}
    </div>
  );

  // // const t = useT();
  // return (
  //   <div className={classes.wrapper}>
  //     <Alert severity="error">
  //       <AlertTitle>
  //         <Trans>Error</Trans>
  //       </AlertTitle>
  //       {message}
  //     </Alert>
  //   </div>
  // );
}
