import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import useReactRouter from 'use-react-router';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/styles';
import { ListItemAvatar } from '@material-ui/core';

import createShortName from '../utility/create-short-name';
import ImageStars from '../icons/ImageStars';
import { getWaterLevelColor, waterLevelTwoLetterNames } from '../icons/WaterLevel';
import DifficultyIcon from '../icons/Difficulty';
import AuthProvider from '../providers/AuthProvider';
import SmallPaddlerAvatar from '../people/SmallPaddlerAvatar';
import CommentCountIcon from '../icons/CommentCount';
import { Trans } from 'react-i18next';
import EmojiChooser from '../ui-elements/EmojiChooser';
import median from '../utility/median';

const useStyles = makeStyles(theme => ({
  root: {
    alignSelf: 'flex-end',
    width: '100%',
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
    color: theme.typography.color,
    padding: '0 16px',
  },
  river: {
    // fontWeight: 'bold',
    flexGrow: 1,
    fontSize: '110%',
  },
  // stars: {
  //   flexGrow: 1,
  //   // marginLeft: '5px',
  // },
  section: {
    flexGrow: 1,
    color: theme.sectionColor,
    fontWeight: 'bold',
  },
  trip: {
    fontStyle: 'italic',
  },
  dangerInfo: {
    fontWeight: 'bold',
    color: 'red',
  },
  flexLine: {
    display: 'flex',
    width: '100%',
  },
  account: {
    fontWeight: 'bold',
  },
  waterLevel: {
    width: '15px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '2px',
    fontSize: '8pt',
    writingMode: 'vertical-rl',
    textAlign: 'center',
  },
  gaugeLine: {
    fontWeight: 'bold',
  },
  avatar: {
    // display: 'inline-block',
    marginLeft: '5px',
  },
  commentCountWrapper: {
    marginLeft: '8px',
    marginTop: '8px',
  },
}));

function EventGroupListItem({
  river,
  section,
  sectionId,
  gauges,
  commentCount,
  difficulty,
  waterLevel,
  enjoyLevel,
  items,
}) {
  const { auth } = AuthProvider.useAuth();
  const classes = useStyles();
  const { history } = useReactRouter();

  const gaugeState = [];
  const gauge1 = gauges && gauges[0];
  if (gauge1) {
    const { heightCentimeters, flowCumecs } = gauge1;
    if (heightCentimeters) gaugeState.push(<span>{heightCentimeters} cm</span>);
    if (flowCumecs) {
      if (gaugeState.length > 0) gaugeState.push(', ');
      gaugeState.push(<span>{flowCumecs} m3/s</span>);
    }
  }
  // const waterLevel = median(items.map(x => x.waterLevel));
  // const enjoyLevel = median(items.map(x => x.enjoyLevel));
  return (
    <ListItem
      alignItems="flex-start"
      button
      onClick={() => history.push(`/event-group/${moment(items[0].startTm).format('YYYY-MM-DD')}/${sectionId}`)}
    >
      <ListItemAvatar>
        <DifficultyIcon difficulty={difficulty} />
        {!!commentCount && (
          <div className={classes.commentCountWrapper}>
            <CommentCountIcon commentCount={commentCount} />
          </div>
        )}
      </ListItemAvatar>

      <div className={classes.root}>
        <div className={classes.flexLine}>
          <div className={classes.river}>{river}</div>
          <div className={classes.stars}>
            <ImageStars size={15} stars={enjoyLevel} />
          </div>
        </div>
        <div className={classes.flexLine}>
          <div className={classes.section}>{section}</div>
          <Hidden xsDown>
            <div>{moment(items[0].startTm).format('D. M. YYYY')}</div>
          </Hidden>
        </div>
        <Hidden smUp>
          <div>{moment(items[0].startTm).format('D. M. YYYY')}</div>
        </Hidden>
        {_.uniqBy(items, 'accountId').map(item => (
          <div className={classes.flexLine}>
            <div className={classes.account}>{item.accountName}</div>
            {item.accountAvatar && <SmallPaddlerAvatar className={classes.avatar} {...item.accountAvatar} />}
          </div>
        ))}
        <div className={classes.gaugeLine}>{gaugeState}</div>
        {_.uniq(items.filter(item => item.dangerInfo).map(item => item.dangerInfo)).map(x => (
          <div className={classes.dangerInfo}>{x}</div>
        ))}
      </div>

      <div className={classes.waterLevel} style={{ backgroundColor: getWaterLevelColor(waterLevel) }}>
        {waterLevelTwoLetterNames[waterLevel || 0]}
      </div>
    </ListItem>
  );
}

export default EventGroupListItem;
