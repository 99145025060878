import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  label: {
    padding: '3px',
    position: 'absolute',
    color: '#666',
    left: 0,
    top: 0,
    borderBottomRightRadius: '5px',
  },
}));

export default function HeaderLabel({ label, labelColor }) {
  const classes = useStyles();

  return (
    <div className={classes.label} style={{ backgroundColor: labelColor }}>
      {label}
    </div>
  );
}
