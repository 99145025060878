import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import useFormik from '../utility/useFormik';
import { withStyles } from '@material-ui/core/styles';
import WaterLevel, { waterLevelNames } from '../icons/WaterLevel';

const styles = theme => ({
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  mainFlex: {
    display: 'flex',
    margin: theme.spacing.unit,
  },
});

function FormWaterLevel({ name, classes, label }) {
  const { values, setFieldValue } = useFormik();
  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>
        {label}: {waterLevelNames[values[name] || 0]}
      </InputLabel>
      <div className={classes.mainFlex}>
        {waterLevelNames.map((waterLevel, index) => (
          <WaterLevel
            waterLevel={index}
            selected={values[name] == index}
            selectable
            onClick={() => setFieldValue(name, index)}
          />
        ))}
      </div>
    </FormGroup>
  );
}

export default withStyles(styles)(FormWaterLevel);
