export default function processSaveResponse(saveData, enqueueSnackbar, savedMessage = 'Successfully saved') {
  console.log('Save response', saveData);

  if (saveData && saveData.name === 'MoleculerError') {
    enqueueSnackbar(saveData.message, { variant: 'error' });
    return false;
  } else {
    if (savedMessage) enqueueSnackbar(savedMessage, { variant: 'info' });
    return true;
  }
}
