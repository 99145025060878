import _ from 'lodash';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseButtonDialogTitle from '../ui-elements/CloseButtonDialogTitle';
import { Trans } from 'react-i18next';
import useT from '../utility/useT';
import { difficultyNames, getDifficultyColor } from '../icons/Difficulty';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  item: {
    margin: '5px',
    padding: '10px 0px',
    textAlign: 'center',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
  },
}));

function DifficultyDialog({ onClose, onChange, value, open, ...other }) {
  const classes = useStyles();
  const t = useT();

  function handleClose() {
    onClose();
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="choose-section-dialog-title" open={open} {...other}>
      <CloseButtonDialogTitle id="choose-section-dialog-title" onClose={onClose}>
        <Trans>Choose difficulty</Trans>
      </CloseButtonDialogTitle>

      <Grid container>
        {difficultyNames.map(difficulty => (
          <Grid item xs={3} key={difficulty}>
            <div
              className={classes.item}
              style={{
                backgroundColor: getDifficultyColor(difficulty),
                border: value == difficulty ? '3px solid black' : undefined,
                margin: value == difficulty ? '2px' : undefined,
              }}
              onClick={() => {
                onClose();
                onChange(difficulty);
              }}
            >
              {difficulty == 'not set' ? 'n/a' : difficulty}
            </div>
          </Grid>
        ))}
      </Grid>
    </Dialog>
  );
}

// export default DifficultyDialog;
export default withMobileDialog()(DifficultyDialog);
