import React from 'react';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import BoatIcon from '../icons/Boat';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: '20px',
    height: '20px',
  },
}));

export default function SmallPaddlerAvatar({ boatColor, boatType, displayName, className }) {
  const classes = useStyles();

  return (
    <Avatar style={{ backgroundColor: boatColor }} className={clsx(classes.avatar, className)} title={displayName}>
      <BoatIcon size={15} boatType={boatType} />
    </Avatar>
  );
}
