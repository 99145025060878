import _ from 'lodash';
import React from 'react';
import fetchApi from '../utility/fetchApi';
import usePromise from '../utility/usePromise';
import * as intervalOperations from 'interval-operations';
import {
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorageExpired,
  removeLocalStorage,
} from '../utility/storage-cache';
import useRefreshTime from '../utility/useRefreshTime';

export const MS_PER_DAY = 60 * 60 * 24 * 1000;
export const MAX_CACHED_LENGTH = MS_PER_DAY * 50;

async function fetchGaugeData(river, gauge, engine, fromTm, toTm) {
  const gaugeData = await fetchApi(
    `measurement/find?river=${river}&gauge=${gauge}&engine=${engine}&from=${fromTm}&to=${toTm}`
  );
  return gaugeData;
}

async function loadGaugeData(river, gauge, engine, fromTm, toTm, clearCache) {
  const storageKey = `measurements_${river}_${gauge}_${engine}`;

  if (clearCache) {
    removeLocalStorage(storageKey);
  }

  const existing = getLocalStorage(storageKey);
  let { intervals, measurements } = existing || {};
  let changed = false;

  const toLoad = intervalOperations.arrayDifference([[fromTm, toTm]], intervals || []);
  for (const interval of toLoad) {
    const loaded = await fetchGaugeData(river, gauge, engine, interval[0], interval[1]);
    if (loaded.length > 0) {
      let end = interval[1];
      const last = loaded.find(x => x.isLast);
      if (last) end = last.measuredTm;
      measurements = [...(measurements || []), ...loaded];
      measurements = _.sortBy(measurements, 'measuredTm');
      measurements = _.sortedUniqBy(measurements, 'measuredTm');
      intervals = intervalOperations.arrayUnion(intervals || [], [[interval[0], end]]);
      changed = true;
    }
  }

  const sumLength = _.sumBy(intervals || [], i => i[1] - i[0]);
  if (sumLength > MAX_CACHED_LENGTH) {
    intervals = intervalOperations.arrayIntersection(intervals || [], [[fromTm, toTm]]);
    measurements = (measurements || []).filter(x => x.measuredTm >= fromTm && x.measuredTm <= toTm);
  }

  if (changed) {
    setLocalStorage(storageKey, {
      intervals,
      measurements,
    });
    setLocalStorage(`expiration_${storageKey}`, new Date().getTime() + 5 * MS_PER_DAY);
    deleteLocalStorageExpired();
  }

  return (measurements || []).filter(x => x.measuredTm >= fromTm && x.measuredTm <= toTm);
}

export default function useGaugeData(river, gauge, engine, fromDate, toDate) {
  const [refreshTime] = useRefreshTime();
  const loadedTimeRef = React.useRef(new Date().getTime());

  React.useEffect(() => {
    if (refreshTime > loadedTimeRef.current) {
      setResult([]);
      loadValue(true);
    }
  }, [refreshTime]);

  const [result, setResult] = React.useState([]);

  async function loadValue(clearCache) {
    const res = await loadGaugeData(river, gauge, engine, fromDate.getTime(), toDate.getTime(), clearCache);
    setResult(res);
  }
  React.useEffect(() => {
    loadValue();
  }, [fromDate.getTime(), toDate.getTime()]);

  return result;
}
