import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import useFormik from '../utility/useFormik';
import { makeStyles } from '@material-ui/styles';
import SectionListItem from '../sections/SectionListItem';
import fetchApi from '../utility/fetchApi';
import Button from '@material-ui/core/Button';
import useRouterDialog from '../utility/useRouterDialog';
import ChooseSectionDialog from '../sections/ChooseSectionDialog';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  stars: {
    // padding: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
    flex: '1',
    display: 'flex',
    // marginBottom: theme.spacing.unit,
    // paddingTop: theme.spacing.unit,
    // paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  chooseButton: {
    marginBottom: theme.spacing.unit,
  },
  starsDiv: {
    alignSelf: 'center',
  },
  mainFlex: {
    display: 'flex',
    margin: theme.spacing.unit,
  },
}));

function FormSectionSelector({ name, sectionObjectField, copyNames, label }) {
  const classes = useStyles();
  const { values, setFieldValue } = useFormik();

  const [openSectionDialog, sectionDialogProps] = useRouterDialog('choose-section');

  const MySectionListItem = SectionListItem.Custom(() => openSectionDialog());

  const onChangeSection = async (sectionId) => {
    const sectionObject = ((await fetchApi(
      `section-info/find?sectionId=${sectionId}`
    )) || [])[0];

    setFieldValue(name, sectionId);
    setFieldValue(sectionObjectField, sectionObject);
    if (copyNames) {
      setFieldValue('section', sectionObject.section);
      setFieldValue('river', sectionObject.river);
      setFieldValue('difficulty', sectionObject.difficulty);
    }
  };

  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <MySectionListItem {...values[sectionObjectField]} />
      <Button variant="contained" color="primary" onClick={openSectionDialog} className={classes.chooseButton}>
        <Trans>Choose section</Trans>
      </Button>
      <ChooseSectionDialog {...sectionDialogProps} onChange={onChangeSection} />
    </FormGroup>
  );
}

export default FormSectionSelector;
