import React from 'react';
import AppLayout from '../layout/AppLayout';
import TabControl from '../layout/RouteTabControl';
import useReactRouter from 'use-react-router';

import AuthProvider from '../providers/AuthProvider';
import SectionListItem from './SectionListItem';
import getEditingObjects from '../utility/getEditingObjects';
import useLocationParam from '../utility/useLocationParam';
import SectionListControl from './SectionListControl';
import useT from '../utility/useT';
import LocationContainer from '../ui-elements/LocationContainer';

function SectionsPage({ defaultFilters }) {
  const { history } = useReactRouter();
  const { auth } = AuthProvider.useAuth();
  // const locationParam = useLocationParam();
  const t = useT();
  // const sortParam = locationParam || 'sort=river,section';

  return (
    <AppLayout t_title="Sections" mainIconMeaning="top" showRefreshButton>
      <LocationContainer
        allowFakeLocation
        // disableCache
        renderContent={location => (
          <SectionListControl
            showSearch
            defaultFilters={defaultFilters}
            routerStateField="listSection"
            filterParams={`&isPublished=true&latitude=${location.latitude}&longitude=${location.longitude}`}
            findParams="findMeasurement=1"
            onAdd={() => history.push('/sections/edit')}
          />
        )}
      />
    </AppLayout>
  );
}

export default SectionsPage;
