import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import useFormik from '../utility/useFormik';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  field: {
    // margin: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

function FormTextField({ name, classes, label, ...otherProps }) {
  // return (
  //   <TextField name={name} {...otherProps} />
  // );

  const { handleChange, handleBlur, values, setValues } = useFormik();
  
  React.useEffect(() => {
    if (!values[name]) setValues({ ...values, [name]: new Date().getTime() });
  }, []);

  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <DatePicker
        onChange={val => setValues({ ...values, [name]: val.toDate().getTime() })}
        onBlur={handleBlur}
        ampm={false}
        className={classes.field}
        value={values && values[name] && new Date(values[name])}
        format="YYYY-MM-DD"
        name={name}
        {...otherProps}
      />
    </FormGroup>
  );

  // return <TextField label="Section" fullwidth />;
  // return <input type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} name="name" />;
}

export default withStyles(styles)(FormTextField);
