import React from 'react';
import DashboardCard from '../ui-elements/DashboardCard';
import useT from '../utility/useT';
import useFetchApi from '../utility/useFetchApi';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import LoadingInfo from '../ui-elements/LoadingInfo';
import useReactRouter from 'use-react-router';
import { getWaterLevelColor, waterLevelTwoLetterNames } from '../icons/WaterLevel';
import { getDifficultyColor, getDifficultyDifference, getShortDifficultyName } from '../icons/Difficulty';
import AuthProvider from '../providers/AuthProvider';

const useStyles = makeStyles(theme => ({
  riverRoot: {
    margin: '10px',
    cursor: 'pointer',
  },
  riverLine: {
    display: 'flex',
    fontWeight: 'bold',
  },
  sectionLine: {
    display: 'flex',
    marginLeft: '15px',
  },
  waterLevel: {
    marginLeft: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    marginRight: '5px',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
  },
  difficultyLevel: {
    paddingLeft: '5px',
    paddingRight: '5px',
    marginRight: '5px',
    borderRadius: '5px',
    fontSize: '12px',
    marginTop: '2px',
    marginBottom: '2px',
    whiteSpace: 'nowrap',
  },
  section: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexShrink: 1,
  },
  gaugeValue: {
    whiteSpace: 'nowrap',
  },
}));

function getReasonableSections(sections) {
  let oldSections = sections;

  if (sections.length <= 3) return sections.length == 0 ? oldSections : sections;
  oldSections = sections;
  sections = sections.filter(x => x.waterLevel != null);

  if (sections.length <= 3) return sections.length == 0 ? oldSections : sections;
  oldSections = sections;
  sections = sections.filter(x => x.waterLevel >= 2);

  if (sections.length <= 3) return sections.length == 0 ? oldSections : sections;
  oldSections = sections;
  sections = sections.filter(x => x.waterLevel <= 4);

  return sections.length == 0 ? oldSections : sections;
}

function getLimitedSections(sections, auth) {
  return _.sortBy(sections, s => getDifficultyDifference(auth.difficulty || 'WW III', s.difficulty)).slice(0, 5);
}

function SectionItem({ section }) {
  const classes = useStyles();
  const { gauges } = section;
  const [gauge0] = gauges || [];
  const { unit, lw, mw: mwOriginal, hw, heightCentimeters, flowCumecs, gauge, waterLevelPercent } = gauge0 || {};
  const gaugeValue = unit === 'cm' ? heightCentimeters : flowCumecs;

  return (
    <div className={classes.sectionLine}>
      {section.difficulty && (
        <div className={classes.difficultyLevel} style={{ backgroundColor: getDifficultyColor(section.difficulty) }}>
          {getShortDifficultyName(section.difficulty)}
        </div>
      )}

      <div className={classes.section}>{section.section}</div>
      {section.waterLevel && (
        <div className={classes.waterLevel} style={{ backgroundColor: getWaterLevelColor(section.waterLevel) }}>
          {waterLevelTwoLetterNames[section.waterLevel || 0]}
        </div>
      )}
      {gaugeValue && (
        <div className={classes.gaugeValue}>
          {gaugeValue} {unit}
        </div>
      )}
    </div>
  );
}

function RiverItem({ riverObj, sections }) {
  const { auth } = AuthProvider.useAuth();
  const classes = useStyles();
  const { match, history } = useReactRouter();
  const infoData = [];
  const { gauge, engine, river, heightCentimeters, flowCumecs, measuredTm, sourceName } = riverObj;
  if (flowCumecs) infoData.push(`${flowCumecs} m3/s`);
  if (heightCentimeters) infoData.push(`${heightCentimeters} cm`);

  return (
    <div className={classes.riverRoot} onClick={() => history.push(`/rivers/${encodeURIComponent(river)}`)}>
      <div className={classes.riverLine}>{<div className={classes.river}>{river}</div>}</div>
      {getLimitedSections(getReasonableSections(sections), auth).map(section => (
        <SectionItem section={section} />
      ))}
    </div>
  );
}

export default function RiversWidget({ filter, includeGrid, title, link, emptyContent }) {
  const t = useT();
  const [reloadToken, setReloadToken] = React.useState(0);
  const rivers = useFetchApi.get('river-info/find', { ...filter, reloadToken }, null);
  const sections = useFetchApi.get('section-info/find', { ...filter, findMeasurement: true, reloadToken }, null);

  return (
    <DashboardCard
      title={title || t('Rivers')}
      includeGrid={includeGrid}
      link={link}
      onReload={() => setReloadToken(x => x + 1)}
      isLoading={!rivers || !sections}
    >
      {(rivers || []).map(river => (
        <RiverItem key={river.river} riverObj={river} sections={(sections || []).filter(x => x.river == river.river)} />
      ))}
      {!!rivers && rivers.length == 0 && emptyContent}
    </DashboardCard>
  );
}
