import React from 'react';

export default function useStateDialog() {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  const onClose = () => {
    setOpen(false);
  };

  const dialogProps = {
    open,
    onClose,
  };

  return [handleClickOpen, dialogProps];
}
