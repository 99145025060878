import React from 'react';
import AppLayout from '../layout/AppLayout';
import PeopleListControl from './PeopleListControl';

export default function PeoplePage() {
  return (
    <AppLayout t_title="People" mainIconMeaning="top" showRefreshButton>
      <PeopleListControl routerStateField="listPeople" />
    </AppLayout>
  );
}
