import React from 'react';
import DashboardCard from '../ui-elements/DashboardCard';
import useT from '../utility/useT';
import LocationContainer from '../ui-elements/LocationContainer';
import { SectionList } from './InterestingSectionsNearby';

export default function InterestingSectionsWidget({ hasEvents, includeGrid }) {
  const t = useT();
  const [reloadToken, setReloadToken] = React.useState(0);

  return (
    <DashboardCard
      title={hasEvents ? t('Interesting sections nearby') : t('Not yet logged nearby sections')}
      link={hasEvents ? '/sections/with-events' : '/sections/without-events'}
      includeGrid={includeGrid}
      onReload={() => setReloadToken(x => x + 1)}
    >
      <LocationContainer
        allowFakeLocation
        renderContent={location => <SectionList location={location} hasEvents={hasEvents} reloadToken={reloadToken} />}
      />
    </DashboardCard>
  );
}
