import React from 'react';
import uuidv1 from 'uuid/v1';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import useFetchApi from '../utility/useFetchApi';
import AuthProvider from '../providers/AuthProvider';
import PaperRows from '../ui-elements/PaperRows';
import GaugeView from './GaugeView';
import ChooseGaugeDialog from '../gauges/ChooseGaugeDialog';

import fetchApi from '../utility/fetchApi';
import useStateDialog from '../utility/useStateDialog';

const GaugePaper = ({ children }) => <div>{children}</div>;

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing.unit,
    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
  },
  wrapper: {
    marginBottom: '30px',
  },
}));

function GaugeSubscriptionsView() {
  const classes = useStyles();
  const { auth } = AuthProvider.useAuth();
  const { accountId } = auth || {};
  const [reloadToken, setReloadToken] = React.useState(null);

  const onAddGauge = async ({ river, gauge, engine, sourceName }) => {
    const subscriptionId = uuidv1();
    const newSubscription = await fetchApi.post(
      'gauge-subscription/save',
      {
        entity: {
          accountId,
          subscriptionId,
          river,
          gauge,
          engine,
          sourceName,
        },
      },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    setReloadToken(new Date());
  };

  const [openGaugeDialog, gaugeDialogProps] = useStateDialog();

  const subscriptions = useFetchApi.get('gauge-subscription/find', { accountId, reloadToken });
  return (
    <>
      {subscriptions.map(({ river, gauge, engine, subscriptionId }) => (
        <div className={classes.wrapper}>
          <GaugeView
            river={river}
            engine={engine}
            gauge={gauge}
            subscriptionId={subscriptionId}
            paperClass={GaugePaper}
            showRemoveButton
            onRemove={() => setReloadToken(new Date())}
          />
        </div>
      ))}
      <Fab color="secondary" aria-label="Add" className={classes.fab} onClick={openGaugeDialog}>
        <AddIcon />
      </Fab>
      <ChooseGaugeDialog {...gaugeDialogProps} onChange={onAddGauge} />
    </>
  );
}

export default GaugeSubscriptionsView;
