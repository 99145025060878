import React from "react";

export default function BoatOther({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 59 59"
      enableBackground="new 0 0 59 59"
    >
      <g>
        <path
          d="M50.5,50c-1.421,0-2.675,0.675-3.5,1.706C46.175,50.675,44.921,50,43.5,50s-2.675,0.675-3.5,1.706
		C39.175,50.675,37.921,50,36.5,50s-2.675,0.675-3.5,1.706C32.175,50.675,30.921,50,29.5,50s-2.675,0.675-3.5,1.706
		C25.175,50.675,23.921,50,22.5,50s-2.675,0.675-3.5,1.706C18.175,50.675,16.921,50,15.5,50s-2.675,0.675-3.5,1.706
		C11.175,50.675,9.921,50,8.5,50C6.018,50,4,52.018,4,54.5S6.018,59,8.5,59c1.421,0,2.675-0.675,3.5-1.706
		C12.825,58.325,14.079,59,15.5,59s2.675-0.675,3.5-1.706C19.825,58.325,21.079,59,22.5,59s2.675-0.675,3.5-1.706
		C26.825,58.325,28.079,59,29.5,59s2.675-0.675,3.5-1.706C33.825,58.325,35.079,59,36.5,59s2.675-0.675,3.5-1.706
		C40.825,58.325,42.079,59,43.5,59s2.675-0.675,3.5-1.706C47.825,58.325,49.079,59,50.5,59c2.481,0,4.5-2.019,4.5-4.5
		S52.981,50,50.5,50z M8.5,57C7.121,57,6,55.878,6,54.5S7.121,52,8.5,52s2.5,1.122,2.5,2.5S9.879,57,8.5,57z M15.5,57
		c-1.379,0-2.5-1.122-2.5-2.5s1.121-2.5,2.5-2.5s2.5,1.122,2.5,2.5S16.879,57,15.5,57z M22.5,57c-1.379,0-2.5-1.122-2.5-2.5
		s1.121-2.5,2.5-2.5s2.5,1.122,2.5,2.5S23.879,57,22.5,57z M29.5,57c-1.379,0-2.5-1.122-2.5-2.5s1.121-2.5,2.5-2.5
		s2.5,1.122,2.5,2.5S30.879,57,29.5,57z M36.5,57c-1.379,0-2.5-1.122-2.5-2.5s1.121-2.5,2.5-2.5s2.5,1.122,2.5,2.5
		S37.879,57,36.5,57z M43.5,57c-1.379,0-2.5-1.122-2.5-2.5s1.121-2.5,2.5-2.5s2.5,1.122,2.5,2.5S44.879,57,43.5,57z M50.5,57
		c-1.379,0-2.5-1.122-2.5-2.5s1.121-2.5,2.5-2.5s2.5,1.122,2.5,2.5S51.879,57,50.5,57z"
        />
        <path
          d="M5,49h21h6h21c0.553,0,1-0.448,1-1s-0.447-1-1-1H32v-3h7.001c0.782,0,1.513-0.385,1.956-1.029
		c0.442-0.645,0.539-1.465,0.254-2.208l-0.004-0.008c-0.775-2.018-0.889-3.014-1.162-5.415l-0.107-0.943l-1.105-0.664l-0.579-1.735
		l-1.323-0.189l0.879-0.527l-0.875-1.75l2.28-0.651l0.006-0.748c0.037-4.429,0.744-8.652,1.996-11.907
		c0.28-0.73,0.184-1.551-0.259-2.196C40.514,13.385,39.783,13,39.001,13H32V9.033h-2.007C29.994,9.021,30,9.012,30,9V7.023
		c0-0.016,0-0.031,0-0.046V1c0-0.321-0.154-0.623-0.415-0.811C29.323,0,28.988-0.05,28.683,0.051l-9,3C19.275,3.187,19,3.569,19,4
		s0.275,0.813,0.684,0.949L28,7.72V9c0,0.012,0.006,0.022,0.007,0.033H26V13h-7.001c-0.782,0-1.513,0.385-1.956,1.029
		c-0.442,0.645-0.539,1.465-0.256,2.203c2.646,6.879,2.646,17.657-0.003,24.543c-0.28,0.73-0.184,1.551,0.259,2.196
		C17.486,43.615,18.217,44,18.999,44H26v3H5c-0.553,0-1,0.448-1,1S4.447,49,5,49z M28,5.613L23.162,4L28,2.387V5.613z M28,11.033h2
		V13h-2V11.033z M18.999,42c-0.171,0-0.266-0.101-0.308-0.162c-0.041-0.06-0.102-0.186-0.037-0.353
		c2.847-7.403,2.847-18.568-0.003-25.979c-0.062-0.16-0.001-0.285,0.04-0.345C18.733,15.101,18.828,15,18.999,15H26v0.017h6V15
		h7.001c0.171,0,0.266,0.101,0.308,0.162c0.041,0.06,0.102,0.186,0.034,0.36c-1.258,3.272-2.001,7.453-2.11,11.844l-3.166,0.904
		l1.125,2.25l-4.121,2.473l5.677,0.811l0.421,1.265l0.895,0.537c0.282,2.481,0.416,3.626,1.28,5.875l0.934-0.359l-0.928,0.374
		c0.062,0.16,0.001,0.285-0.04,0.345C39.266,41.899,39.172,42,39.001,42H32h-6H18.999z M28,44h2v3h-2V44z"
        />
      </g>
    </svg>
  );
}
