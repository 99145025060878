import React from 'react';
import { Avatar } from '@material-ui/core';

import BoatIcon from '../icons/Boat';

export default function ListAccountAvatar({ boatColor, boatType, displayName }) {
  return (
    <Avatar style={{ backgroundColor: boatColor }} title={displayName}>
      <BoatIcon size={25} boatType={boatType} />
    </Avatar>
  );
}
