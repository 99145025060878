import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import useFormik from '../utility/useFormik';
import AddTeamMembersDialog from '../people/AddTeamMembersDialog';
import TeamMembersView from '../people/TeamMembersView';
import AuthProvider from '../providers/AuthProvider';
import useFetchApi from '../utility/useFetchApi';
import useRouterDialog from '../utility/useRouterDialog';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  stars: {
    // padding: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
    flex: '1',
    display: 'flex',
    // marginBottom: theme.spacing.unit,
    // paddingTop: theme.spacing.unit,
    // paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  chooseButton: {
    marginBottom: theme.spacing.unit,
  },
  starsDiv: {
    alignSelf: 'center',
  },
  mainFlex: {
    display: 'flex',
    margin: theme.spacing.unit,
  },
}));

function FormTeamMembers({ label }) {
  const classes = useStyles();
  const { values, setFieldValue } = useFormik();
  const { auth } = AuthProvider.useAuth();
  const { accountId } = auth;
  const { startTm } = values;
  const trip = useFetchApi.get(startTm && 'trip-info/nearest-trip', { accountId, startTm }, null);
  const t = useT();

  const [openTeamDialog, teamDialogProps] = useRouterDialog('select-team');

  const team = values.team || [];

  const deleteAccount = account => {
    setFieldValue('team', team.filter(x => x !== account));
  };

  const beforeCloseDialog = () => {
    if (team.length > 0 && !team.find(x => x.accountId === auth.accountId)) {
      setFieldValue('team', [auth, ...team]);
    }
  };

  const copyFromTrip = () => {
    setFieldValue('team', trip.team);
  };

  const clear = () => {
    setFieldValue('team', []);
  };

  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <TeamMembersView team={team} onDelete={account => deleteAccount(account)} />

      <Button variant="contained" color="primary" onClick={openTeamDialog} className={classes.chooseButton}>
        <Trans>Edit</Trans>
      </Button>
      {trip && trip.team && trip.team.length > 0 && (
        <>
          <div>{t('Trip {{name}}: {{team.length}} members', trip)}</div>
          <Button variant="contained" color="primary" onClick={copyFromTrip} className={classes.chooseButton}>
            <Trans>Copy from trip</Trans>
          </Button>
        </>
      )}
      {team && team.length > 0 && (
        <Button variant="contained" color="primary" onClick={clear} className={classes.chooseButton}>
          <Trans>Clear</Trans>
        </Button>
      )}

      <Grid container>
        <Grid item xs={6} />
        <Grid item xs={6} />
      </Grid>
      <AddTeamMembersDialog
        {...teamDialogProps}
        team={team}
        onChange={team => setFieldValue('team', team)}
        onBeforeClose={beforeCloseDialog}
      />
    </FormGroup>
  );
}

export default FormTeamMembers;
