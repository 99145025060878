import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Map from 'pigeon-maps';
// import Marker from '../maps/Marker';
import useViewportSize from '../utility/useViewportSize';
import { mapProviders, mapAttribution } from '../locations/PointsMap';
import Marker from '../locations/Marker';
// import { extractMapPoints } from '../datalayer/extractors';
import useStateDialog from '../utility/useStateDialog';
// import GpsDetailDialog from './GpsDetailDialog';
import useOnlineStatus from '../utility/useOnlineStatus';
import distance from '../utility/distance';
import { useDetailMapType } from '../utility/globalstate';
import GpsDetailDialog from '../ui-elements/GpsDetailDialog';
import HeaderLabel from './HeaderLabel';
// import { distance } from '../utility/mathutil';

const useStyles = makeStyles(theme => ({
  relative: {
    position: 'relative',
  },
  headerImage: {
    [theme.breakpoints.up('md')]: {
      backgroundImage: 'url(/images/home-header-large.jpg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      height: '250px',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(/images/home-header-small.jpg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      width: '100vw',
      height: '160px',
    },
  },
}));

function getDefaultZoom(diam) {
  // console.log('DIAM', diam);
  if (diam > 7) return 10;
  if (diam > 4) return 11;
  if (diam > 2.5) return 12;
  if (diam > 1) return 13;
  if (diam > 0.3) return 14;
  if (diam > 0.1) return 15;
  return 9;
}

export function computeCenterZoom(places) {
  if (!places || !places.length) return [null, null];
  const latitudes = places.map(x => x.coordinates[1]);
  const longitudes = places.map(x => x.coordinates[0]);
  const minlat = _.min(latitudes);
  const minlon = _.min(longitudes);
  const maxlat = _.max(latitudes);
  const maxlon = _.maxBy(longitudes);
  const diam = distance(minlat, minlon, maxlat, maxlon);
  return [
    {
      latitude: (minlat + maxlat) / 2,
      longitude: (minlon + maxlon) / 2,
    },
    getDefaultZoom(diam),
  ];
}

export default function HeaderMap({ places, label, labelColor }) {
  // console.log(places);
  const classes = useStyles();
  // const { gps } = rkmDetail || {};

  const [selectedPlace, setSelectedPlace] = React.useState(null);
  const [openDialog, dialogProps] = useStateDialog();
  const online = useOnlineStatus();
  const mapType = useDetailMapType();
  const [centerCounter, setCenterCounter] = React.useState(0);

  const width = useViewportSize().width;

  // if (!center || !online) return <div className={classes.headerImage} />;

  const [center, zoom] = React.useMemo(() => computeCenterZoom(places), [places]);
  React.useEffect(() => {
    setCenterCounter(x => x + 1);
  }, [places]);

  if (!places || !places.length || !center || !online)
    return (
      <div className={classes.headerImage}>
        <HeaderLabel label={label} labelColor={labelColor} />
      </div>
    );
  // // if (!width) return null;

  return (
    <div key={centerCounter} className={classes.relative}>
      <Map
        defaultCenter={[center.latitude, center.longitude]}
        defaultZoom={zoom}
        defaultWidth={width}
        // must be set defaultWidth instead of width, otherwise mouse wheel zoom will not work
        // width={width}

        height={160}
        // provider={mapProviders[mapType]}
        provider={mapProviders.mapycz_turist}
        attribution={mapAttribution.mapycz}
      >
        {places.map((point, index) => (
          <Marker
            anchor={[point.coordinates[1], point.coordinates[0]]}
            key={index}
            geotag={point.geotag}
            onClick={() => {
              setSelectedPlace(point);
              openDialog();
            }}
          />
        ))}
      </Map>
      <GpsDetailDialog {...selectedPlace} dialogProps={dialogProps} />
      <HeaderLabel label={label} labelColor={labelColor} />
    </div>
  );
}
