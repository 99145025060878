import React from 'react';
import _ from 'lodash';
import ChooseFromListDialog from '../ui-elements/ChooseFromListDialog';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useSnackbar } from 'notistack';
import uuidv1 from 'uuid/v1';
import { getLocalStorage, setLocalStorage } from '../utility/storage-cache';
import saveLocations from './saveLocations';
import AuthProvider from '../providers/AuthProvider';
import useReactRouter from 'use-react-router';
import geoTags from './geoTags';
import { handleGeolocationError } from '../utility/useLocationParam';
import useT from '../utility/useT';

const geoTagIcons = _.mapValues(_.keyBy(geoTags, 'text'), 'icon');

export function findGeoTagIcon(geotag) {
  return geoTagIcons[geotag] || LocationOnIcon;
}

export default function ChooseGeoTagDialog({ onChoose, onChange, addOnChoose, goToMyLocations, ...other }) {
  const { enqueueSnackbar } = useSnackbar();
  const { auth } = AuthProvider.useAuth();
  const { history } = useReactRouter();
  const t = useT();

  const addLocation = geotag => {
    if (navigator.geolocation) {
      var locationOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(receivePosition(geotag), onError, locationOptions);
    } else {
      enqueueSnackbar('Geolocation is not supported by this device', { variant: 'error' });
    }
  };

  const receivePosition = geotag => async position => {
    const locationId = uuidv1();
    const editingLocations = getLocalStorage('EditingLocations');

    console.log('Obtained position', position);
    const { altitude, accuracy, altitudeAccuracy } = position.coords;
    const newLocations = {
      ...editingLocations,
      [locationId]: {
        locationId,
        coordinates: [position.coords.longitude, position.coords.latitude],
        createTm: new Date().getTime(),
        geotag,
        accountId: auth.accountId,
        altitude,
        accuracy,
        altitudeAccuracy,
      },
    };
    setLocalStorage('EditingLocations', newLocations);
    await saveLocations(enqueueSnackbar, auth);
    if (onChange) onChange();
    if (goToMyLocations) history.push('/my-locations');
    enqueueSnackbar(t('Current position saved, +-{{accuracy}} m', { accuracy: Math.round(accuracy) }), {
      variant: 'info',
    });
  };

  const onError = err => {
    handleGeolocationError(err, enqueueSnackbar);
  };

  const items = geoTags.map(tag => ({
    ...tag,
    onClick: addOnChoose ? () => addLocation(tag.text) : () => onChoose(tag.text),
  }));
  return <ChooseFromListDialog items={items} {...other} />;
}
