import React from 'react';
import getCurrentLocation from '../utility/getCurrentLocation';
import LoadingInfo from './LoadingInfo';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Chip } from '@material-ui/core';
import ErrorInfo from './ErrorInfo';
import useT from '../utility/useT';

export default function LocationContainer({ renderContent, allowFakeLocation, disableCache }) {
  const [location, setLocation] = React.useState(null);
  const [allowPrompt, setAllowPrompt] = React.useState(false);
  const t = useT();
  const initLocation = async () => {
    const value = await getCurrentLocation({ disableCache, allowFakeLocation, allowPrompt, t });
    setLocation(value);
  };

  React.useEffect(() => {
    initLocation();
  }, [allowPrompt]);

  // console.log('LOCATION', location);

  return (
    <>
      {location == null ? (
        <LoadingInfo message={t('Getting current position')} />
      ) : (
        <div>
          {location.askPermission && (
            <ErrorInfo
              message={t('GPS permission not granted')}
              isWarning
              inline
              actionIcon={LocationOnIcon}
              actionTitle={t('Allow GPS')}
              onActionClick={() => setAllowPrompt(true)}
            />
          )}
          {location.warning && <ErrorInfo message={location.warning} isWarning inline />}
          {location.error && <ErrorInfo message={location.error} inline />}
          {renderContent(location)}
        </div>
      )}
    </>
  );
}

// <Chip color="primary" label="Allow GPS" onClick={() => setAllowPrompt(true)} />
