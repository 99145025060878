import React from 'react';
import uuidv1 from 'uuid/v1';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import useReactRouter from 'use-react-router';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoIcon from '@material-ui/icons/Photo';
import { makeStyles } from '@material-ui/core/styles';
import useFetchApi from '../utility/useFetchApi';
import FormikForm from '../forms/FormikForm';
import FormTextField from '../forms/FormTextField';
import useFormik from '../utility/useFormik';
import fetchApi, { getStorageBase } from '../utility/fetchApi';
import processSaveResponse from '../utility/processSaveResponse';
import { useSnackbar } from 'notistack';
import AuthProvider from '../providers/AuthProvider';
import getAccountInfoName from '../utility/getAccountInfoName';
import useViewportSize from '../utility/useViewportSize';
import ConfirmDialog, { useConfirmDialog } from '../ui-elements/ConfirmDialog';
import CommentTextView from './CommentTextView';
import CommentArticleView from './CommentArticleView';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import AddCommentTextField from './AddCommentTextField';

const useStyles = makeStyles(theme => ({
  commentWrapper: {
    margin: '20px',
  },
  noComments: {
    marginLeft: '20px',
    fontStyle: 'italic',
  },
  commentText: {
    borderRadius: '5px',
    backgroundColor: '#F2F3F5',
    padding: '5px',
  },
  commentsContainer: ({ isInlineView }) =>
    isInlineView
      ? {}
      : {
          flexGrow: 1,
          overflowY: 'scroll',
        },
  author: {
    fontWeight: 'bold',
  },
  commentWindow: ({ isInlineView }) =>
    isInlineView
      ? {}
      : {
          display: 'flex',
          flexDirection: 'column',
        },
  commentActions: {
    marginLeft: '5px',
    fontSize: '80%',
  },
  commentsHeadline: {
    marginLeft: '20px',
    fontSize: '130%',
    fontWeight: 'bold',
    marginTop: '15px',
  },
  photo: {
    maxWidth: '100%',
    marginTop: theme.spacing.unit,
  },
}));

export default function CommentsListView({ context, contextFilter, isInlineView }) {
  const t = useT();
  const [aux, setAux] = React.useState(() => new Date().getTime());
  const [isLoading, setLoadingState] = React.useState(false);
  const [selectedCommentId, setSelectedCommentId] = React.useState();
  const containerRef = React.useRef();
  const scrollToBottom = () => {
    // console.log('containerRef.current', containerRef.current);
    if (!containerRef.current) return;
    const scrollHeight = containerRef.current.scrollHeight;
    const height = containerRef.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    containerRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  const checkParsedArticle = async comment => {
    const actualComment = (await fetchApi.get(`comment-info/find`, { commentId: comment.commentId }))[0];
    if (actualComment && !actualComment.isArticleParsed) {
      setTimeout(() => checkParsedArticle(comment), 500);
      return;
    }
    if (actualComment && actualComment.isArticleParsed) {
      setAux(new Date().getTime());
    }
  };

  const comments = useFetchApi.get(
    'comment-info/find',
    {
      ...(contextFilter || context),
      sort: 'createTm',
      reloadToken: aux,
      setLoadingState,
    },
    [],
    scrollToBottom
  );
  const classes = useStyles({ isInlineView });
  const addedComment = comment => {
    setAux(new Date().getTime());
    checkParsedArticle(comment);
  };
  const size = useViewportSize();
  const [openConfirm, confirmDialogProps] = useConfirmDialog();
  const { auth } = AuthProvider.useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const doDelete = async () => {
    const saveData = await fetchApi.post(
      `comment-info/save`,
      {
        entity: {
          commentId: selectedCommentId,
          isDeleted: true,
        },
      },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    processSaveResponse(saveData, enqueueSnackbar, null);
    setAux(new Date().getTime());
  };

  React.useEffect(scrollToBottom, []);

  return (
    <div className={classes.commentWindow} style={isInlineView ? {} : { width: size.width, height: size.height }}>
      {isInlineView && (
        <div className={classes.commentsHeadline}>
          <Trans>Comments</Trans>
        </div>
      )}
      <div className={classes.commentsContainer} ref={containerRef}>
        {isLoading && t('Loading comments')}
        {!isLoading && comments.length === 0 && (
          <div className={classes.noComments}>{t('No comments available yet')}</div>
        )}
        {comments.map(comment => (
          <div className={classes.commentWrapper}>
            <div className={classes.commentText}>
              <Link component={RouterLink} to={`/people/detail/${comment.accountId}`} className={classes.author}>
                {comment.accountName}
              </Link>{' '}
              <CommentTextView text={comment.text} />
            </div>
            {comment.article && <CommentArticleView {...comment.article} />}
            {comment.fileId && comment.thumbnailFileId && (
              <a href={`${getStorageBase()}/${comment.fileId}`}>
                <img src={`${getStorageBase()}/${comment.thumbnailFileId}`} className={classes.photo} />
              </a>
            )}
            <div className={classes.commentActions}>
              {auth && auth.accountId === comment.accountId && (
                <Link
                  onClick={() => {
                    setSelectedCommentId(comment.commentId);
                    openConfirm();
                  }}
                >
                  {t('Delete')}
                </Link>
              )}
              {' • '}
              <span className={classes.time} title={moment(comment.createTm).format('D. M. YYYY HH:mm')}>
                {moment(comment.createTm).fromNow()}
              </span>
            </div>
          </div>
        ))}
      </div>
      {auth && (
        <FormikForm>
          <AddCommentTextField context={context} onAdded={addedComment} />
        </FormikForm>
      )}
      <ConfirmDialog
        title={t('Really delete comment?')}
        positiveButton={t('Delete')}
        negativeButton={t('Cancel')}
        onConfirm={doDelete}
        {...confirmDialogProps}
      />
    </div>
  );
}
