import React from 'react';
import useFetchApi from '../utility/useFetchApi';
import moment from 'moment';
import _ from 'lodash';
import DifficultyIcon from '../icons/Difficulty';
import { makeStyles } from '@material-ui/core/styles';
import DashboardCard from '../ui-elements/DashboardCard';
import useT from '../utility/useT';
import { getWaterLevelColor, waterLevelTwoLetterNames } from '../icons/WaterLevel';
import SmallPaddlerAvatar from '../people/SmallPaddlerAvatar';
import Avatar from '@material-ui/core/Avatar';
import { findGeoTagIcon } from '../locations/ChooseGeoTagDialog';
import { coordinatesToString, DriveToButton, DriveToIconButton } from '../ui-elements/GpsView';

const useStyles = makeStyles(theme => ({
  placeRoot: {
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    color: 'gray',
  },
  text: {
    marginLeft: '10px',
    flexGrow: 1,
  },
}));

function PlaceItem({ place }) {
  const { geocoding, geotag, description, coordinates, river, section } = place || {};
  const { formattedAddress, city, country, countryCode } = geocoding || {};
  const classes = useStyles();
  const Icon = findGeoTagIcon(geotag);

  return (
    <div className={classes.placeRoot}>
      <Avatar>
        <Icon />
      </Avatar>
      <div className={classes.text}>
        <div>{city || coordinatesToString(coordinates)}</div>
        <div className={classes.description}>{geotag || description}</div>
      </div>
      <DriveToIconButton coordinates={coordinates} geotag={geotag} river={river} section={section} />
    </div>
  );
}

export default function PlacesWidget({ places, link, includeGrid }) {
  const classes = useStyles();
  const t = useT();
  if (!_.isArray(places)) return null;
  return (
    <DashboardCard title={t('Places')} link={link} includeGrid={includeGrid}>
      {places.map(place => (
        <PlaceItem place={place} key={place.placeId} />
      ))}
    </DashboardCard>
  );
}
