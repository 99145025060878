import React from 'react';
import AppRoutes from './AppRoutes';
import AuthProvider from './providers/AuthProvider';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import getTheme from './getTheme';
import { Helmet } from 'react-helmet';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider, useSnackbar } from 'notistack';
import ConfirmDialog, { useConfirmDialog } from './ui-elements/ConfirmDialog';
import { RefreshTimeProvider } from './utility/useRefreshTime';
import SnackbarCloseButton from './ui-elements/SnackbarCloseButton';
import { DetailMapTypeProvider } from './utility/globalstate';
import ThemeSwitchContext, { useThemeSwitch } from './ui-elements/ThemeSwitchContext';

window.initGoogle = function() {
  window.gapi.load('auth2', async function() {
    if (!window.gapi.auth2.getAuthInstance()) {
      await window.gapi.auth2.init({
        client_id: '478729297535-k5ug7kv1nicdiocgmf1kvditsqdmfo4a.apps.googleusercontent.com',
      });
    }
  });

  // window.gapi.load("auth2", () => {
  //   this.enableButton();
  //   if (!window.gapi.auth2.getAuthInstance()) {
  //     window.gapi.auth2.init(params).then(
  //       res => {
  //         if (isSignedIn && res.isSignedIn.get()) {
  //           this.handleSigninSuccess(res.currentUser.get());
  //         }
  //       },
  //       err => onFailure(err)
  //     );
  //   }
  //   if (autoLoad) {
  //     this.signIn();
  //   }
  // });
};

function AppCore() {
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmReload, confirmReloadDialogProps] = useConfirmDialog();

  const doReloadPage = () => window.location.reload();

  React.useEffect(() => {
    window.showInfoMessage = message => enqueueSnackbar(message, { variant: 'info' });
    window.showErrorMessage = message => enqueueSnackbar(message, { variant: 'error' });
    window.showReloadDialog = openConfirmReload;
  }, {});

  return (
    <>
      <AppRoutes />
      <ConfirmDialog
        t_title="New web app version available, reload page?"
        t_positiveButton="Reload"
        t_negativeButton="Cancel"
        onConfirm={doReloadPage}
        {...confirmReloadDialogProps}
      />
    </>
  );
}

function ThemedApp() {
  const themeSwitch = useThemeSwitch();
  return (
    <MuiThemeProvider theme={getTheme(themeSwitch.currentTheme)}>
      <RefreshTimeProvider>
        <DetailMapTypeProvider>
          <AppCore />
        </DetailMapTypeProvider>
      </RefreshTimeProvider>
    </MuiThemeProvider>
  );
}

function App() {
  return (
    <>
      <CssBaseline />
      <Helmet>
        <title>RiverLog</title>
        <script src="https://apis.google.com/js/platform.js" onload="initGoogle()" />
      </Helmet>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider maxSnack={3} action={key => <SnackbarCloseButton key={key} />}>
          <AuthProvider>
            <ThemeSwitchContext>
              <ThemedApp />
            </ThemeSwitchContext>
          </AuthProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </>
  );
}

export default App;
