import React from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import useReactRouter from 'use-react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';

import useFormik from '../utility/useFormik';
import AuthProvider from '../providers/AuthProvider';
import FormTextField from '../forms/FormTextField';
import FormTime from '../forms/FormTime';
import FormCheckBox from '../forms/FormCheckBox';
import FormDifficulty from '../forms/FormDifficulty';
import FormRating from '../forms/FormRating';
import FormWaterLevel from '../forms/FormWaterLevel';
import FormSectionSelector from '../forms/FormSectionSelector';
import FormDateTime from '../forms/FormDateTime';
import fetchApi from '../utility/fetchApi';
import getAccountInfoName from '../utility/getAccountInfoName';
import PaperCard from '../ui-elements/PaperCard';
import FormTeamMembers from '../forms/FormTeamMembers';
import EditorPage from '../ui-elements/EditorPage';
import GaugeListEditor from '../gauges/GaugeListEditor';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import DescriptionDetailView from '../comments/DescriptionDetailView';

const GAUGE_FIELDS = ['heightCentimeters', 'flowCumecs'];
const OMIT_FIELDS = ['isAutoGauge'];

function eventToForm(event) {
  return GaugeListEditor.storageToForm([...GAUGE_FIELDS, ...OMIT_FIELDS])(event);
}

function formToEvent(event) {
  const res = GaugeListEditor.formToStorage(GAUGE_FIELDS, OMIT_FIELDS)(event);
  if (!res.diaryId) {
    res.isPrivate = false;
  }
  if (res.team) res.team = res.team.filter(x => x.accountId || x.displayName);

  return res;
}

async function reloadGauge(values, setFieldValue, name, enqueueSnackbar, setLoadingVisible) {
  const gaugeObject = values[name];
  const { startTm } = values;
  if (!startTm) return;

  if (gaugeObject && gaugeObject.gaugeEngine) {
    const { gauge, river, gaugeEngine } = gaugeObject;

    try {
      setLoadingVisible(true);
      const measurement = await fetchApi(
        `measurement/compute?engine=${gaugeEngine}&river=${encodeURIComponent(river)}&gauge=${encodeURIComponent(
          gauge
        )}&time=${startTm}`
      );
      if (measurement) {
        const { heightCentimeters, flowCumecs } = measurement;
        setFieldValue(`${name}_heightCentimeters`, heightCentimeters);
        setFieldValue(`${name}_flowCumecs`, flowCumecs);
      } else {
        setFieldValue(`${name}_isAutoGauge`, false);
        enqueueSnackbar(`Failed to load gauge ${gauge}`, { variant: 'warning' });
      }
    } finally {
      setLoadingVisible(false);
    }
  }
}

function AutoGaugeCheckBox({ name, setLoadingVisible }) {
  const { enqueueSnackbar } = useSnackbar();

  const onAutoGaugeChange = (value, { values, setFieldValue }) => {
    if (!value) return;
    reloadGauge(values, setFieldValue, name, enqueueSnackbar, setLoadingVisible);
  };

  const t = useT();

  return (
    <FormCheckBox
      label={t('Load water level from server')}
      compactVersion
      name={`${name}_isAutoGauge`}
      onChange={onAutoGaugeChange}
    />
  );
}

function GaugeEditor({ name }) {
  const { values, setFieldValue } = useFormik();
  const { enqueueSnackbar } = useSnackbar();
  const isAutoGauge = values[`${name}_isAutoGauge`];
  const canBeLoaded = values[name] && values[name].gaugeEngine;
  const [isLoadingVisible, setLoadingVisible] = React.useState(false);
  const lastStartTm = React.useRef(values.startTm);
  React.useEffect(() => {
    if (isAutoGauge) reloadGauge(values, setFieldValue, name, enqueueSnackbar, setLoadingVisible);
  }, []);
  if (lastStartTm.current !== values.startTm) {
    if (isAutoGauge) reloadGauge(values, setFieldValue, name, enqueueSnackbar, setLoadingVisible);
    lastStartTm.current = values.startTm;
  }
  const t = useT();
  return (
    <Grid container>
      {canBeLoaded && (
        <>
          <Grid xs={10}>
            <AutoGaugeCheckBox name={name} setLoadingVisible={setLoadingVisible} />
          </Grid>
          <Grid xs={2}>{isLoadingVisible && <CircularProgress />}</Grid>
        </>
      )}
      <Grid item xs={6}>
        <FormTextField name={`${name}_flowCumecs`} label={t('Flow (m3/s)')} disabled={isAutoGauge} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField name={`${name}_heightCentimeters`} label={t('Height (cm)')} disabled={isAutoGauge} />
      </Grid>
    </Grid>
  );
}

function AddGaugesFromSectionButton() {
  const { values, setFieldValue } = useFormik();
  const { sectionObject, gaugeCount } = values;
  const { gauges } = sectionObject || {};

  function doAdd() {
    let newCount = gaugeCount;
    for (const gaugeElem of gauges || []) {
      let found = false;
      for (let i = 0; i < gaugeCount || 0; i++) {
        const gaugeObj = values[`gauge${i}`];
        const { gauge, river } = gaugeObj || {};
        if (gauge === gaugeElem.gauge && river === gaugeElem.river) {
          found = true;
          break;
        }
      }
      if (!found) {
        const { river, gauge, gaugeEngine } = gaugeElem;
        setFieldValue(`gauge${newCount}`, { river, gauge, gaugeEngine });
        setFieldValue(`gauge${newCount}_isAutoGauge`, true);
        newCount++;
      }
    }
    setFieldValue('gaugeCount', newCount);
  }

  for (const gaugeElem of gauges || []) {
    let found = false;
    for (let i = 0; i < gaugeCount || 0; i++) {
      const gaugeObj = values[`gauge${i}`];
      const { gauge, river } = gaugeObj || {};
      if (gauge === gaugeElem.gauge && river === gaugeElem.river) {
        found = true;
        break;
      }
    }
    if (!found) {
      return (
        <FormGroup>
          <Button variant="contained" onClick={doAdd} color="primary">
            <Trans>Add gauges from section</Trans>
          </Button>
        </FormGroup>
      );
    }
  }
  return null;
}

function SectionDescription() {
  const { values } = useFormik();
  const { sectionObject } = values;
  const t = useT();

  if (!sectionObject) return null;

  return (
    <DescriptionDetailView
      target="section"
      showOnlyMy
      sectionId={sectionObject.sectionId}
      buttonText={t('Set section description')}
      labelText={t('Section description')}
    />
  );
}

function EditEventPage() {
  const { history, match } = useReactRouter();
  const { diaryId: paramsDiaryId, copyFromDiaryId, createFromSectionId } = match.params;
  const { auth } = AuthProvider.useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const t = useT();

  async function fetchDiaryEvent(objectId) {
    if (copyFromDiaryId) {
      const res = ((await fetchApi(`diary-info/find?diaryId=${copyFromDiaryId}&joinSection=1`, { auth })) || [])[0];
      return {
        ...res,
        diaryId: null,
        copiedFromDiaryId: res.diaryId,
        accountId: auth.accountId,
        accountName: getAccountInfoName(auth),
        boatModel: auth.boatModel,
        beerCount: auth.beerCount,
        description: `Copied from ${res.accountName}. ${res.description || ''}`,
      };
    } else if (createFromSectionId) {
      const sectionObject = ((await fetchApi(`section-info/find?sectionId=${createFromSectionId}&findMeasurement=1`, {
        auth,
      })) || [])[0];

      const gauges = [];
      if (sectionObject.gauges) {
        for (const { gaugeEngine, river, gauge } of sectionObject.gauges) {
          gauges.push({ gaugeEngine, river, gauge, isAutoGauge: true });
        }
      }

      return {
        boatModel: auth.boatModel,
        beerCount: auth.beerCount,
        startTm: new Date().getTime(),
        objectId,

        sectionObject,
        sectionId: sectionObject.sectionId,
        section: sectionObject.section,
        river: sectionObject.river,
        difficulty: sectionObject.difficulty,
        accountId: auth.accountId,
        accountName: getAccountInfoName(auth),
        waterLevel: sectionObject.waterLevel,
        gauges,
      };
    } else {
      if (paramsDiaryId) {
        const res = ((await fetchApi(`diary-info/find?diaryId=${paramsDiaryId}&joinSection=1`, { auth })) || [])[0];
        return res;
      } else {
        return {
          objectId,
          boatModel: auth.boatModel,
          beerCount: auth.beerCount,
          startTm: new Date().getTime(),
          accountId: auth.accountId,
          accountName: getAccountInfoName(auth),
        };
      }
    }
  }

  return (
    <EditorPage
      idName="diaryId"
      localStorageName="EditingEvents"
      serviceName="diary-info"
      detailRoute={id => `/events/detail/${id}`}
      objectTypeText={t('river run')}
      storageToForm={eventToForm}
      formToStorage={formToEvent}
      fetchObject={fetchDiaryEvent}
      containsSection
      parentRoute="/events"
    >
      <Grid container alignItems="stretch">
        <PaperCard>
          <FormSectionSelector label="Section" name="sectionId" sectionObjectField="sectionObject" copyNames />
          <FormDateTime label={t('Start of trip')} name="startTm" disableFuture />
        </PaperCard>
        <PaperCard>
          <FormTextField label={t('Description')} name="description" multiline />
          <FormTextField label={t('Private description')} fullwidth name="privateDescription" multiline />
          <SectionDescription />
          <FormTextField label={t('Danger info')} name="dangerInfo" multiline />
          <FormTextField label={t('Boat model')} name="boatModel" />

          <Grid container>
            <Grid item xs={6}>
              <FormTextField label={t('LengthInKm')} name="lengthInKm" type="number" />
            </Grid>
            <Grid item xs={6}>
              <FormTime label={t('DurationOfTrip')} name="durationMs" />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={4}>
              <FormTextField label={t('Run count')} name="runCount" type="number" />
            </Grid>
            <Grid item xs={4}>
              <FormTextField label={t('Eskimo rolls')} name="eskimoRollCount" type="number" />
            </Grid>
            <Grid item xs={4}>
              <FormTextField label={t('Swim count')} name="swimCount" type="number" />
            </Grid>
          </Grid>

          <FormCheckBox label={t('This event is private')} name="isPrivate" />
        </PaperCard>
        <PaperCard>
          <GaugeListEditor fieldList={[]} editControl={GaugeEditor} newGaugeFields={{ isAutoGauge: true }} />
          <AddGaugesFromSectionButton />
        </PaperCard>
        <PaperCard>
          <FormDifficulty label={t('Difficulty')} name="difficulty" />
          <FormWaterLevel label={t('Water level')} name="waterLevel" />
          <FormRating label={t('How did you enjoy it')} name="enjoyLevel" />
          <FormRating label={t("Yesterday's party level")} name="beerCount" image="beer" />
        </PaperCard>
        <PaperCard>
          <FormTeamMembers label={t('Team members')} />
        </PaperCard>
      </Grid>
    </EditorPage>
  );
}

export default EditEventPage;
