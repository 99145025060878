import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import useReactRouter from 'use-react-router';
import BoatIcon from '../icons/Boat';
import useT from '../utility/useT';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 16px',
    width: '100%',
    position: 'relative',
  },
  name: {
    fontWeight: 'bold',
  },
  longLine: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  inactive: {
    fontStyle: 'italic',
  },
}));

export default function PlayspotListItem({ name, playspotId, isActive }) {
  const t = useT();
  const classes = useStyles();
  const { history } = useReactRouter();

  return (
    <ListItem alignItems="flex-start" button onClick={() => history.push(`/playspots/detail/${playspotId}`)}>
      <ListItemAvatar>
        <Avatar>{name && name[0] && name[0].toUpperCase()}</Avatar>
      </ListItemAvatar>
      <div className={classes.root}>
        <div className={classes.name}>{name}</div>
        <div className={classes.inactive}>{!isActive ? `(${t('Inactive')})` : ''}</div>
      </div>
    </ListItem>
  );
}
