import React from 'react';
import PointsMap from './PointsMap';
import useFetchApi from '../utility/useFetchApi';
import { getLocalStorage, setLocalStorage } from '../utility/storage-cache';
import _ from 'lodash';

export default function LocationsMap({ url, isHighlighted }) {
  const myLocations = useFetchApi(url);
  const editingLocations = getLocalStorage('EditingLocations');
  const allLocations = {
    ..._.keyBy(myLocations, 'locationId'),
    ...editingLocations,
  };
  return <PointsMap points={_.values(allLocations)} isHighlighted={isHighlighted} />;
}
