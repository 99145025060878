import React from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonsWrapper: {
    // position: 'absolute',
    // right: theme.spacing.unit,
    // top: theme.spacing.unit,
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

function CloseButtonDialogTitle({ children, classes, onClose, headerButtons }) {
  const handleClose = e => {
    e.stopPropagation();
    if (onClose) onClose();
  };
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      <div className={classes.buttonsWrapper}>
        {headerButtons}
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </DialogTitle>
  );
}

export default withStyles(styles)(CloseButtonDialogTitle);
