import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Card, CardContent, CardMedia } from '@material-ui/core';
import useReactRouter from 'use-react-router';
// import { Carousel, CarouselSlide } from 'material-ui-carousel';

import EditIcon from '@material-ui/icons/Edit';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PeopleIcon from '@material-ui/icons/People';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '@material-ui/icons/Home';
import CupIcon from '../icons/Cup';

import LoginDialog, { useLoginDialog } from '../ui-elements/LoginDialog';
import AppLayout from '../layout/AppLayout';
import AuthProvider from '../providers/AuthProvider';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import InterestingSectionsWidget from './InterestingSectionsWidget';
import TopEventsWidget from './TopEventsWidget';
import NativeRouterSearchField from '../ui-elements/NativeRouterSearchField';
import RiverSectionSearchList from './RiverSectionSearchList';

const useStyles = makeStyles(theme => ({
  header: {
    [theme.breakpoints.up('md')]: {
      backgroundImage: 'url(./images/home-header-large.jpg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      height: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./images/home-header-small.jpg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      height: '250px',
    },
    position: 'relative',
  },
  buttons: {
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  bodyFeatures: {
    backgroundImage: 'url(./images/wave.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'fixed',
    paddingBottom: '35px',
  },
  bodyAbout: {
    backgroundImage: 'url(./images/wave-color.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'fixed',
    paddingBottom: '35px',
  },
  bodyScreen: {
    paddingBottom: '35px',
  },
  bodyCenter: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '1200px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  smallWidgetWrapper: {
    paddingLeft: '35px',
    paddingRight: '35px',
    paddingTop: '35px',
    // opacity: '0.7',
  },

  smallWidget: {
    minHeight: '160px',
    position: 'relative',
  },

  smallWidgetText: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  smallWidgetBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '5px',
    boxShadow: '4px 4px 4px rgba(0,0,0,0.5)',
    // boxShadow: '4px 4px 4px red',
  },

  smallWidgetBackground_blue: {
    opacity: '0.6',
    backgroundColor: '#C8DBE1',
  },

  smallWidgetBackground_green: {
    opacity: '0.6',
    backgroundColor: '#B0D0B6',
  },

  widgetTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '140%',
    fontWeight: 'bold',
    textAlign: 'center',
    // color: 'black',
    textStrokeColor: '#CCC',
    // textStrokeWidth: '1px',
    marginBottom: '8px',
    // color: '#4E4848',
  },
  widgetText: {
    margin: '8px',
  },
  widgetIcon: {
    textAlign: 'center',
    color: theme.palette.text.dashboard,
    marginTop: '8px',
  },
  heading: {
    textAlign: 'center',
    paddingTop: '35px',
  },
  dataSource: {
    textAlign: 'center',
  },
  contacts: {
    textAlign: 'center',
  },
  screenshot: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '300px',
    marginTop: '15px',
  },
  searchbox: {
    fontSize: '1rem',
    padding: '15px',
    backgroundColor: '#FFFFFFCC',
    width: '80vw',
    // margin: '0 15vw',
    outline: 'none',
    border: '0px solid',
    borderRadius: '10px',
  },
  searchboxWrapper: {
    padding: '90px 10vw 20px 10vw',
    display: 'flex',
    // justifyContent: 'space-around',
  },
}));

const pictures = [
  { image: '/images/screenshot1.jpg', t_title: 'Followed event list' },
  { image: '/images/screenshot2.jpg', t_title: 'App dashboard' },
  { image: '/images/screenshot3.jpg', t_title: 'Logged event detail' },
];
const SmallWidget = ({ title, t_title, children, theme, classes, icon: Icon, link }) => {
  const { history } = useReactRouter();
  const t = useT();
  return (
    <Grid item xs={12} md={6} className={classes.smallWidgetWrapper} onClick={link ? () => history.push(link) : null}>
      <div className={classes.smallWidget}>
        <div className={classes.smallWidgetBackground + ' ' + classes[`smallWidgetBackground_${theme}`]} />
        <div className={classes.smallWidgetText}>
          <div className={classes.widgetVerticalAlign}>
            <div className={classes.widgetIcon}>{Icon && <Icon />}</div>
            <div className={classes.widgetTitle}>{t_title ? t(t_title) : title}</div>
            <div className={classes.widgetText}>{children}</div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

function DataSource({ url }) {
  const classes = useStyles();
  return (
    <div className={classes.dataSource}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    </div>
  );
}

function Heading({ children }) {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.heading}>
      {children}
    </Typography>
  );
}

function getSearchValue(history, routerStateField) {
  const search = history.location && history.location.state && history.location.state[`${routerStateField}_search`];
  return search;
}

function HomePage() {
  const { auth } = AuthProvider.useAuth();
  const { history } = useReactRouter();
  const classes = useStyles();
  const [openLogin, loginProps] = useLoginDialog();
  const t = useT();

  const [filter, setFilter] = React.useState(getSearchValue(history, 'homepage'));

  const handleRunFilter = filter => {
    setFilter(filter);
  };

  return (
    <AppLayout title={t('RiverLog')} mainIconMeaning={filter ? 'back' : 'menu'} showLanguageSelector>
      <div className={classes.header}>
        <div className={classes.searchboxWrapper}>
          <NativeRouterSearchField
            routerStateField="homepage_search"
            onRunFilter={handleRunFilter}
            className={classes.searchbox}
            placeholder={t('Search river or section')}
          />
        </div>

        <div className={classes.buttons}>
          {!auth && (
            <Button variant="contained" color="secondary" className={classes.button} onClick={openLogin}>
              <Trans>Sign in</Trans>
            </Button>
          )}
          {auth && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => history.push('/dashboard')}
            >
              <Trans>Dashboard</Trans>
            </Button>
          )}
        </div>
      </div>

      {!filter && (
        <>
          <div className={classes.bodyFeatures}>
            <div className={classes.bodyCenter}>
              <Grid container>
                <SmallWidget
                  theme="blue"
                  t_title="Log your trips"
                  classes={classes}
                  icon={EditIcon}
                  link="/event-groups"
                >
                  <Trans i18nKey="logYourTripsDesc" />
                </SmallWidget>
                <SmallWidget theme="green" t_title="Trainings" classes={classes} icon={CupIcon} link="/trainings">
                  <Trans i18nKey="trainingsDesc" />
                </SmallWidget>
                <SmallWidget theme="blue" t_title="Stay in touch" classes={classes} icon={PeopleIcon} link="/people">
                  <Trans i18nKey="stayInTouchDesc" />
                </SmallWidget>
                <SmallWidget
                  theme="green"
                  t_title="Use sections"
                  classes={classes}
                  icon={SubdirectoryArrowRightIcon}
                  link="/sections"
                >
                  <Trans i18nKey="useSectionsDesc">
                    <a href="https://rivermap.ch" target="_blank">
                      rivermap.ch
                    </a>
                  </Trans>
                </SmallWidget>
                <SmallWidget theme="blue" t_title="Online gauges" classes={classes} icon={ShowChartIcon} link="/gauges">
                  <Trans i18nKey="onlineGaugesDesc" />
                </SmallWidget>
                <SmallWidget theme="green" t_title="Work with location" classes={classes} icon={LocationOnIcon}>
                  <Trans i18nKey="workWithLocationDesc" />
                </SmallWidget>
                <Hidden smUp>
                  <SmallWidget theme="blue" t_title="Add to home screen" classes={classes} icon={HomeIcon}>
                    <Trans i18nKey="addToHomeScreenDesc">
                      <a href="/images/add-to-home-screen.mp4">how to do this</a>
                    </Trans>
                  </SmallWidget>
                </Hidden>
              </Grid>
            </div>
          </div>

          <Grid container alignItems="stretch">
            <Grid item xs={12} md={6}>
              <InterestingSectionsWidget hasEvents />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
          <InterestingSectionsWidget hasEvents={false} />
        </Grid> */}
            <Grid item xs={12} md={6}>
              <TopEventsWidget />
            </Grid>
          </Grid>

          {/* <Hidden smUp>
        <Heading>
          <Trans>App screens</Trans>
        </Heading>
        <Carousel>
          {pictures.map(({ image, t_title }) => (
            <CarouselSlide key={image}>
              <Card>
                <CardMedia
                  image={image}
                  title={t(t_title)}
                  className={classes.screenshot}
                  style={{
                    height: 0,
                    paddingTop: '75%',
                  }}
                />
                <CardContent>
                  <Typography>{t(t_title)}</Typography>
                </CardContent>
              </Card>
            </CarouselSlide>
          ))}
        </Carousel>
      </Hidden>

      <Hidden xsDown>
        <div className={classes.bodyScreen}>
          <div className={classes.bodyCenter}>
            <Grid container>
              {pictures.map(({ image, t_title }) => (
                <Grid item xs={12} md={4}>
                  <Heading>{t(t_title)}</Heading>
                  <CardMedia
                    image={image}
                    title={t(t_title)}
                    className={classes.screenshot}
                    style={{
                      height: 0,
                      paddingTop: '75%',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </Hidden> */}

          <div className={classes.bodyAbout}>
            <div className={classes.bodyCenter}>
              <Heading>
                <Trans i18nKey="authorsAndConctacs" />
              </Heading>
              <Typography className={classes.contacts}>
                <div>Jan Procházka</div>
                <div>Eliška Kadubcová</div>
                <div>
                  <Trans>E-mail</Trans>: <a href="mailto:riverlog.info@gmail.com">riverlog.info@gmail.com</a>
                </div>
                <div>
                  <a href="https://www.facebook.com/River-Log-2308084576142418/">
                    <Trans>RiverLog on Facebook</Trans>
                  </a>
                </div>
                <div>
                  <a href="https://www.instagram.com/riverlog.info">
                    <Trans>RiverLog on Instagram</Trans>
                  </a>
                </div>
                <div>
                  <Trans>Virtual server hosting</Trans>: <a href="https://www.web4ce.cz/">Web4ce</a>
                </div>
              </Typography>

              <Heading>
                <Trans>Section data</Trans>
              </Heading>
              <Typography className={classes.contacts}>
                <div>
                  <Trans i18nKey="sectionsImportedFrom">
                    <a href="https://www.rivermap.ch/">Rivermap</a>
                  </Trans>
                </div>
                <div>
                  <Trans i18nKey="riverLogSectionsJson">
                    <a href="https://riverlog.info/api/public-sections">JSON data</a>
                  </Trans>{' '}
                  (
                  <a href="https://creativecommons.org/licenses/by-sa/4.0/">
                    <Trans>license</Trans>
                  </a>
                  )
                </div>
              </Typography>

              <Heading>
                <Trans>River data sources</Trans>
              </Heading>
              <DataSource url="http://hydro.chmi.cz/" />
              <DataSource url="https://riverzone.eu/" />
              <DataSource url="http://www.ktn.gv.at/" />
              <DataSource url="http://www.tirol.gv.at/" />
              <DataSource url="http://www.pvl.cz/" />
              <DataSource url="http://www.pla.cz/" />
              <DataSource url="http://www.poh.cz/" />
              <DataSource url="http://www.salzburg.gv.at/" />
              <DataSource url="http://hydrodaten.admin.ch/" />
              <DataSource url="http://www.arso.gov.si/" />
            </div>
          </div>
        </>
      )}

      <RiverSectionSearchList filter={filter} />

      <LoginDialog {...loginProps} />
    </AppLayout>
  );
}

export default HomePage;
