import React from 'react';
import RiverListControl from '../rivers/RiverListControl';
import SectionListControl from '../sections/SectionListControl';
import ContentPadding from '../ui-elements/ContentPadding';
import { Trans } from 'react-i18next';

export default function RiverSectionSearchList({filter}) {
  const [riversNoData, setRiversNoData] = React.useState(false);
  const [sectionsNoData, setSectionsNoData] = React.useState(false);
  return (
    <>
      {!!filter && (
        <RiverListControl isRawVariant isInline searchValue={filter} noDataContentCallback={setRiversNoData} />
      )}
      {!!filter && (
        <SectionListControl isRawVariant isInline searchValue={filter} noDataContentCallback={setSectionsNoData} />
      )}
      {!!filter && sectionsNoData && riversNoData && (
        <ContentPadding>
          <Trans>No matching rivers or sections found</Trans>
        </ContentPadding>
      )}
    </>
  );
}
