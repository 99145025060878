import _ from 'lodash';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withProps } from 'recompose';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Hidden from '@material-ui/core/Hidden';
import useReactRouter from 'use-react-router';

// import PublicIcon from '@material-ui/icons/Public';

import AuthProvider from '../providers/AuthProvider';
import { getWaterLevelColor, waterLevelTwoLetterNames, waterLevelColors } from '../icons/WaterLevel';
import DifficultyIcon from '../icons/Difficulty';
import ImageStars from '../icons/ImageStars';
import Flag from '../icons/Flag';
import CommentCountIcon from '../icons/CommentCount';
import useT from '../utility/useT';

const useStyles = makeStyles(theme => ({
  root: {
    alignSelf: 'flex-end',
    width: '100%',
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
    color: theme.typography.color,
    padding: '0 16px',
  },
  river: {
    // fontWeight: 'bold',
    flexGrow: 1,
  },
  stars: {
    marginLeft: '5px',
  },
  section: {
    flexGrow: 1,
    fontWeight: 'bold',
    color: theme.sectionColor,
  },
  dangerInfo: {
    fontWeight: 'bold',
    color: 'red',
  },
  flexLine: {
    display: 'flex',
    // width: '100%',
  },
  flagImage: {
    alignSelf: 'center',
    marginRight: '18px',
  },
  eventCount: {},
  editing: {
    color: theme.editingColor,
    fontStyle: 'italic',
  },
  waterLevel: {
    width: '15px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '2px',
    fontSize: '8pt',
    writingMode: 'vertical-rl',
    textAlign: 'center',
  },
  lw: {
    color: waterLevelColors[1],
  },
  mw: {
    color: waterLevelColors[2],
  },
  hw: {
    color: waterLevelColors[3],
  },
  gaugeValue: {
    fontWeight: 'bold',
  },
  commentCountWrapper: {
    marginLeft: '8px',
    marginTop: '8px',
  },
}));

function SectionListItem({
  sectionId,
  river,
  section,
  difficulty,
  dangerInfo,
  enjoyLevel,
  country,
  diaryItemCount,
  onClick,
  distance,
  isEditing,
  waterLevel,
  waterLevelPercent,
  isFakeCoordinates,
  gauges,
  commentCount,
  isCheckedFunc,
  messageAggregate,
  // isPublished,
}) {
  const { auth } = AuthProvider.useAuth();
  const classes = useStyles();
  const { history } = useReactRouter();
  const t = useT();

  const [gauge0] = gauges || [];
  const { unit, lw, mw: mwOriginal, hw, heightCentimeters, flowCumecs, gauge } = gauge0 || {};
  const gaugeValue = unit === 'cm' ? heightCentimeters : flowCumecs;
  const mw = mwOriginal || (lw + hw) / 2;

  const marks = _.sortBy(
    [
      { mark: <span className={classes.lw}>{lw}</span>, value: lw },
      { mark: <span className={classes.mw}>{mw}</span>, value: mw },
      { mark: <span className={classes.hw}>{hw}</span>, value: hw },
      { mark: <span className={classes.gaugeValue}>{gaugeValue}</span>, value: gaugeValue },
    ],
    x => parseFloat(x.value)
  );

  const marksDelimited = [];
  for (const mark of marks) {
    marksDelimited.push(mark.mark);
    marksDelimited.push(' - ');
  }
  marksDelimited.splice(marksDelimited.length - 1, 1);

  const sectionObject = { sectionId, river, section };

  const isChecked = isCheckedFunc && isCheckedFunc(sectionObject);
  const checkedStyle = isChecked ? { border: '4px solid black' } : {};

  return (
    <ListItem
      alignItems="flex-start"
      button
      onClick={
        onClick
          ? () => onClick(sectionId)
          : () => {
              history.push(isEditing ? `/sections/edit/${sectionId}` : `/sections/detail/${sectionId}`);
            }
      }
    >
      <ListItemAvatar>
        <DifficultyIcon difficulty={difficulty} style={checkedStyle} />
        {!!commentCount && (
          <div className={classes.commentCountWrapper}>
            <CommentCountIcon commentCount={commentCount} />
          </div>
        )}
      </ListItemAvatar>

      <div className={classes.root}>
        <div className={classes.flexLine}>
          {/* {isPublished && <PublicIcon style={{ width: 15, height: 15 }} />} */}
          <div className={classes.section}>{section}</div>
          <div className={classes.stars}>
            <ImageStars size={15} stars={enjoyLevel} />
          </div>
        </div>
        <div className={classes.flexLine}>
          <div className={classes.river}>{river}</div>
          {/* <div className={classes.eventCount}>{t('{{count}} events', { count: diaryItemCount })}</div> */}
        </div>
        {gaugeValue && lw && hw && (
          <div>
            {marksDelimited} <span className={classes.unit}>{unit}</span>
            <Hidden smDown> ({gauge})</Hidden>
          </div>
        )}
        {distance && !isFakeCoordinates && (
          <div>{t('{{distance}} km from here', { distance: distance.toFixed(1) })}</div>
        )}
        <div className={classes.dangerInfo}>{dangerInfo}</div>
        {isEditing && <div className={classes.editing}>Editing</div>}
      </div>
      <div className={classes.flagImage}>
        <Flag name={country} size={30} />
      </div>
      <div className={classes.waterLevel} style={{ backgroundColor: getWaterLevelColor(waterLevel) }}>
        {waterLevelTwoLetterNames[waterLevel || 0]}
        {waterLevelPercent && `: ${waterLevelPercent}%`}
      </div>
    </ListItem>
  );
}

SectionListItem.Custom = (onClick, isCheckedFunc) => withProps({ onClick, isCheckedFunc })(SectionListItem);

export default SectionListItem;
