import React from 'react';
import _ from 'lodash';

import ChoosePlaceDialog from '../places/ChoosePlaceDialog';
import FormItemList from './FormItemList';

function FormSectionList({ label }) {
  return (
    <FormItemList
      routerStateField="choose-places"
      label={label}
      dialog={ChoosePlaceDialog}
      itemsField="places"
      idField="placeId"
      serviceName="place-info"
      pickFunc={section => _.pick(section, ['section', 'placeId', 'geotag', 'geocoding', 'description'])}
      getLinkFunc={place => `/places/detail/${place.placeId}`}
      renderItem={place => `${place.description || place.geotag}`}
    />
  );
}

export default FormSectionList;
