import React from 'react';
import { withProps } from 'recompose';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import useReactRouter from 'use-react-router';
import moment from 'moment';
import { findGeoTagIcon } from './ChooseGeoTagDialog';

function LocationListItem({ onClick, ...location }) {
  const { locationId, geotag, geocoding, coordinates, createTm } = location;
  const { formattedAddress, city, country } = geocoding || {};

  const Icon = findGeoTagIcon(geotag);
  return (
    <ListItem button alignItems="flex-start" onClick={() => onClick(location)}>
      <ListItemAvatar>
        <Avatar>
          <Icon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={country || city ? `${country}, ${city}` : geotag}
        secondary={`${moment(createTm).format('D. M. YYYY HH:mm')} (${geotag})`}
      />
    </ListItem>
  );
}

export default onClick => withProps({ onClick })(LocationListItem);
