import _ from 'lodash';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import AuthProvider from '../providers/AuthProvider';
import TabControl from '../layout/TabControl';
import ListControl from '../layout/ListControl';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseButtonDialogTitle from '../ui-elements/CloseButtonDialogTitle';
import PeopleListItem from './PeopleListItem';
import useT from '../utility/useT';

const useStyles = makeStyles(theme => ({
  inputLabel: {
    margin: theme.spacing.unit,
  },
  editor: {
    margin: theme.spacing.unit,
  },
}));

function AddTeamMembersDialog({ team, onClose, onChange, onBeforeClose, dialogTitle, headerButtons, ...other }) {
  const { auth } = AuthProvider.useAuth();
  const classes = useStyles();
  const t = useT();

  function handleClose() {
    if (onBeforeClose) onBeforeClose();
    onClose();
  }

  function handleItemClick(account) {
    if (team.find(x => x.accountId === account.accountId)) {
      onChange(team.filter(x => x.accountId !== account.accountId));
    } else {
      onChange([...team, account]);
    }
  }

  const itemComponent = PeopleListItem.Custom(handleItemClick, account =>
    team.find(x => x.accountId === account.accountId)
  );

  const textNames = team
    .filter(x => !x.accountId)
    .map(x => x.displayName)
    .join('\n');

  const changeTextNames = e => {
    const names = (e.target.value || '').split('\n');
    onChange([...team.filter(x => x.accountId), ...names.map(displayName => ({ displayName }))]);
  };

  if (!auth) return null;

  return (
    <Dialog onClose={handleClose} aria-labelledby="choose-add-team-members-dialog-title" {...other}>
      <CloseButtonDialogTitle
        id="choose-add-team-members-dialog-title"
        onClose={handleClose}
        headerButtons={headerButtons}
      >
        {dialogTitle || t('Edit team members')}
      </CloseButtonDialogTitle>

      <TabControl>
        <TabControl.Page t_label="Following">
          <ListControl
            showSearch
            url={`account-info/find?sort=-rating&followedBy=${auth.accountId}`}
            component={itemComponent}
          />
        </TabControl.Page>
        <TabControl.Page t_label="All">
          <ListControl showSearch url={`account-info/find?sort=-rating`} component={itemComponent} />
        </TabControl.Page>
        <TabControl.Page t_label="Names">
          <FormGroup>
            <InputLabel className={classes.inputLabel}>Type one team member per line</InputLabel>
            <TextField
              multiline
              rows={5}
              variant="outlined"
              className={classes.editor}
              value={textNames}
              onChange={changeTextNames}
            />
          </FormGroup>
        </TabControl.Page>
      </TabControl>
    </Dialog>
  );
}

export default withMobileDialog()(AddTeamMembersDialog);
