import React from "react";

export default function Flag(props) {
  const { size } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 640 480">
      <g fill-rule="evenodd">
        <path fill="#fff" d="M640 480H0V0h640z" />
        <path fill="#df0000" d="M640 480H0V320h640zm0-319.9H0V.1h640z" />
      </g>{" "}
    </svg>
  );
}
