import React from 'react';
import AppLayout from '../layout/AppLayout';
import EventGroupListControl from './EventGroupListControl';
import useT from '../utility/useT';

function EventGroupsPage() {
  const t = useT();

  return (
    <AppLayout title={t('Runs')} mainIconMeaning="top" showRefreshButton>
      <EventGroupListControl routerStateField="listAll" filterParams={''} />
    </AppLayout>
  );
}

export default EventGroupsPage;
