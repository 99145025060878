const _ = require('lodash');
const apiBase = process.env.REACT_APP_API_BASE || `${window.location.origin}/api`;

function mergeOptions(fixedOptions, options) {
  const optionsCopy = { ...options };
  if ('headers' in optionsCopy) {
    delete optionsCopy.headers;
  }
  if ('auth' in optionsCopy) {
    delete optionsCopy.auth;
    if (options.auth && options.auth.accountId) {
      if (!options.headers) options.headers = {};
      options.headers['x-account-id'] = options.auth.accountId;
    }
  }

  return {
    ...fixedOptions,
    ...optionsCopy,
    headers: {
      ...fixedOptions.headers,
      ...(options.headers || {}),
    },
  };
}

async function fetchApi(url, options = {}) {
  if (url == null) return undefined;
  const apikey = '0abad3c2-5595-4653-bcf2-06f29a848d2b';
  const resp = await fetch(
    `${apiBase}/${url}`,
    mergeOptions(
      {
        method: 'GET',
        headers: {
          'x-api-key': apikey,
        },
      },
      options
    )
  );
  const data = await resp.json();
  return data;
}

function encodeParams(params) {
  const paramsCopy = { ...params };
  for (const key of _.keys(params)) {
    const value = params[key];
    if (_.isPlainObject(value)) {
      for (const subkey of _.keys(value)) {
        paramsCopy[`${key}[${subkey}]`] = value[subkey];
      }
      delete paramsCopy[key];
    }
  }
  return _.keys(paramsCopy)
    .filter(key => paramsCopy[key] != null)
    .map(key => `${key}=${encodeURIComponent(paramsCopy[key])}`)
    .join('&');
}

fetchApi.get = async function(url, params, options = {}) {
  if (url == null) return undefined;
  const apikey = '0abad3c2-5595-4653-bcf2-06f29a848d2b';
  const paramsText = encodeParams(params);
  const resp = await fetch(
    `${apiBase}/${url}?${paramsText}`,
    mergeOptions(
      {
        method: 'GET',
        headers: {
          'x-api-key': apikey,
        },
      },
      options
    )
  );
  const data = await resp.json();
  return data;
};

fetchApi.post = async function post(url, data, options = {}) {
  const apikey = '0abad3c2-5595-4653-bcf2-06f29a848d2b';
  const resp = await fetch(
    `${apiBase}/${url}`,
    mergeOptions(
      {
        method: 'POST',
        headers: {
          'x-api-key': apikey,
          'content-type': 'application/json',
        },
        body: data && JSON.stringify(data),
      },
      options
    )
  );
  const respData = await resp.json();
  return respData;
};

export function getApiBase() {
  return apiBase;
}

export function getStorageBase() {
  return `${window.location.origin}/storage`;
}

export default fetchApi;
