import React from 'react';

import useFetchApi from '../utility/useFetchApi';
import AuthProvider from '../providers/AuthProvider';
import TabControl from '../layout/RouteTabControl';
import useReactRouter from 'use-react-router';
import CommentsListView from '../comments/CommentsListView';
import TripDetailView from './TripDetailView';
import EventListControl from '../events/EventListControl';
import ListControl from '../layout/ListControl';
import SectionListItem from '../sections/SectionListItem';
import DetailPageLayout from '../layout/DetailPageLayout';
import fetchApi, { getApiBase } from '../utility/fetchApi';
import PlaceListItem from '../places/PlaceListItem';
import useStateDialog from '../utility/useStateDialog';
import ConfirmDialog from '../ui-elements/ConfirmDialog';
import useRefreshTime from '../utility/useRefreshTime';

function TripDetailPage() {
  const { history, match } = useReactRouter();
  const { tripId } = match.params;

  const detail = useFetchApi(`trip-info/find-by-id?tripId=${tripId}`, {});
  const detailSafe = detail || {};
  const { auth } = AuthProvider.useAuth();
  const { accountId, team } = detailSafe;

  const tripUrlBase = `/trips/detail/${tripId}`;
  const isMyTrip = auth && auth.accountId === accountId;

  const [openConfirm, confirmDialogProps] = useStateDialog();

  const [, callRefresh] = useRefreshTime();

  const subMenuItems = [];
  if (!isMyTrip && team && team.find(x => x.accountId === auth.accountId)) {
    subMenuItems.push({
      label: 'Leave from trip',
      handler: openConfirm,
    });
  }

  const doLeave = async () => {
    const saveData = await fetchApi.post(
      `trip-info/leave`,
      { tripId, accountId: auth.accountId },
      {
        auth,
      }
    );
    console.log('Leave response:', saveData);
    callRefresh();
    history.goBack();
  };

  return (
    <DetailPageLayout
      isWritable={isMyTrip}
      editUrl={`/trips/edit/${tripId}`}
      parentRoute="/trips"
      idName="tripId"
      idValue={tripId}
      serviceName="trip-info"
      entityName="trip"
      title={detailSafe.name || 'Trip'}
      objectTitle={`trip ${detailSafe.name}`}
      mainIconMeaning="back"
      shareMenuItems={[
        {
          t_label: 'Export to Excel',
          handler: () => window.open(`${getApiBase()}/excel-export/trip?tripId=${tripId}&pi=3.14`),
        },
      ]}
      subMenuItems={subMenuItems}
    >
      <TabControl>
        <TabControl.Page t_label="Info" path={`${tripUrlBase}`}>
          <TripDetailView {...detailSafe} />
          <CommentsListView context={{ tripId, target: 'trip' }} isInlineView />
        </TabControl.Page>
        <TabControl.Page t_label="Sections" path={`${tripUrlBase}/sections`}>
          <ListControl
            url={`section-info/find?tripId=${tripId}&findMeasurement=1`}
            component={SectionListItem}
            showSearch
            routerStateField="listSection"
          />
        </TabControl.Page>
        <TabControl.Page t_label="Places" path={`${tripUrlBase}/places`}>
          <ListControl
            url={`place-info/find?tripId=${tripId}`}
            routerStateField="placesList"
            component={PlaceListItem}
          />
        </TabControl.Page>
        <TabControl.Page t_label="River runs" path={`${tripUrlBase}/events`}>
          <EventListControl filterParams={`&tripId=${tripId}`} routerStateField="listTripEvents" />
        </TabControl.Page>
      </TabControl>
      <ConfirmDialog
        t_title="Really leave trip?"
        t_positiveButton="Leave"
        t_negativeButton="Cancel"
        onConfirm={doLeave}
        {...confirmDialogProps}
      />
    </DetailPageLayout>
  );
}

export default TripDetailPage;
