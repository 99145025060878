import React from 'react';
import AppLayout from '../layout/AppLayout';
import UserPofile from './UserProfile';
import AuthProvider from '../providers/AuthProvider';

function MyProfilePage() {
  const { auth } = AuthProvider.useAuth();
  return <AppLayout t_title="My profile" mainIconMeaning='top'>{!!auth && <UserPofile {...auth} />}</AppLayout>;
}

export default MyProfilePage;
