import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import useFormik from '../utility/useFormik';
import { makeStyles } from '@material-ui/core/styles';
import useT from '../utility/useT';

const useStyles = makeStyles(theme => ({
  field: {
    margin: theme.spacing.unit,
  },
  fieldCompact: {
    margin: 0,
  },
}));

function FormCheckBox({ name, label, t_label, onChange, compactVersion, defaultValue, ...otherProps }) {
  const classes = useStyles();
  const formikContext = useFormik();
  const t = useT();
  const { values, setFieldValue } = formikContext;
  const content = (
    <FormControlLabel
      control={
        <Checkbox
          checked={values[name] == null ? defaultValue || false : values[name]}
          onChange={(ev, value) => {
            setFieldValue(name, value);
            if (onChange) onChange(value, formikContext);
          }}
          {...otherProps}
        />
      }
      label={t_label ? t(t_label) : label}
      className={compactVersion ? classes.fieldCompact : classes.field}
    />
  );

  // return compactVersion ? content : <FormGroup>{content}</FormGroup>;
  return <FormGroup>{content}</FormGroup>;
}

export default FormCheckBox;
