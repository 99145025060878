import React from 'react';
import FlagAt from './FlagAt';
import FlagCh from './FlagCh';
import FlagCz from './FlagCz';
import FlagDe from './FlagDe';
import FlagEs from './FlagEs';
import FlagFr from './FlagFr';
import FlagIt from './FlagIt';
import FlagSi from './FlagSi';
import FlagGr from './FlagGr';
import FlagHr from './FlagHr';
import FlagGb from './FlagGb';

export default function Flag({ code, language,  name, size }) {
  if (code === 'AT') return <FlagAt size={size} />;
  if (code === 'CH') return <FlagCh size={size} />;
  if (code === 'CZ') return <FlagCz size={size} />;
  if (code === 'DE') return <FlagDe size={size} />;
  if (code === 'ES') return <FlagEs size={size} />;
  if (code === 'FR') return <FlagFr size={size} />;
  if (code === 'IT') return <FlagIt size={size} />;
  if (code === 'SI') return <FlagSi size={size} />;
  if (code === 'GR') return <FlagGr size={size} />;
  if (code === 'HR') return <FlagHr size={size} />;

  if (language === 'cs') return <FlagCz size={size} />;
  if (language === 'en') return <FlagGb size={size} />;

  if (name === 'Austria') return <FlagAt size={size} />;
  if (name === 'Czechia') return <FlagCz size={size} />;
  if (name === 'Switzerland') return <FlagCh size={size} />;
  if (name === 'Slowenia' || name === 'Slovenia') return <FlagSi size={size} />;
  if (name === 'Italy') return <FlagIt size={size} />;
  if (name === 'Spain') return <FlagEs size={size} />;
  if (name === 'France') return <FlagFr size={size} />;
  if (name === 'Germany') return <FlagDe size={size} />;
  if (name === 'Greece') return <FlagGr size={size} />;
  if (name === 'Croatia') return <FlagHr size={size} />;

  return null;
}
