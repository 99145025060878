import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { compose } from 'recompose';
import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import AuthProvider from '../providers/AuthProvider';
import FormikForm from '../forms/FormikForm';
import FormTextField from '../forms/FormTextField';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Trans } from 'react-i18next';

const styles = theme => ({
  root: {},
  info: {
    margin: '15px 5px 5px 5px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
  },
  buttonWrapper: {
    margin: '10px 20px 10px 20px',
  },
  button: {
    width: '13em',
  },
  linkWrapper: {
    margin: '20px',
    fontWeight: 'bold',
  },
});

function LoginDialog({
  classes,

  chooserProps,
  emailLoginProps,
  registerProps,
  resetPasswordProps,

  handleOpenEmail,
  handleOpenRegister,
  enqueueSnackbar,
  handleCloseEmail,
  handleCloseChooser,
  handleCloseRegister,
  handleCloseResetPassword,
  handleOpenResetPassword,
}) {
  const { callGoogleLogin, callEmailLogin, callRegister, callResetPassword } = AuthProvider.useAuth();

  function handleGoogleLogin() {
    handleCloseChooser();
    callGoogleLogin();
  }

  return (
    <>
      <Dialog onClose={handleCloseChooser} aria-labelledby="login-dialog-chooser-title" {...chooserProps}>
        <DialogTitle id="login-dialog-chooser-title">
          <Trans>Login</Trans>
        </DialogTitle>
        <div>
          <div className={classes.info}>Google sign-in</div>
          <div className={classes.buttonWrapper}>
            <Button variant="contained" color="secondary" onClick={handleGoogleLogin} className={classes.button}>
              <Trans>Sign in with Google</Trans>
            </Button>
          </div>
          <div className={classes.info}>Sign-in with email</div>
          <div className={classes.buttonWrapper}>
            <Button variant="contained" color="secondary" onClick={handleOpenEmail} className={classes.button}>
              <Trans>I have account</Trans>
            </Button>
          </div>
          <div className={classes.buttonWrapper}>
            <Button variant="contained" color="secondary" onClick={handleOpenRegister} className={classes.button}>
              <Trans>Create new account</Trans>
            </Button>
          </div>
          <div className={classes.linkWrapper}>
            {' • '}
            <Link component={RouterLink} onClick={handleOpenResetPassword} className={classes.link}>
              <Trans>Reset password</Trans>
            </Link>
          </div>
        </div>
      </Dialog>

      <Dialog onClose={handleCloseEmail} aria-labelledby="login-dialog-email-title" {...emailLoginProps}>
        <DialogTitle id="login-dialog-email-title">
          <Trans>Login by email</Trans>
        </DialogTitle>
        <DialogContent>
          <FormikForm
            onSubmit={async values => {
              const { email, password } = values;
              if (await callEmailLogin(email, password, enqueueSnackbar)) {
                handleCloseEmail();
              }
            }}
          >
            <FormTextField label="Email" name="email" />
            <FormTextField label="Password" name="password" type="password" />
            <DialogActions>
              <Button type="submit" color="secondary" variant="contained">
                <Trans>Login</Trans>
              </Button>
            </DialogActions>
          </FormikForm>
        </DialogContent>
      </Dialog>

      <Dialog onClose={handleCloseRegister} aria-labelledby="login-dialog-register-title" {...registerProps}>
        <DialogTitle id="login-dialog-register-title">Create new account</DialogTitle>
        <DialogContent>
          <FormikForm
            onSubmit={async values => {
              const { email, password } = values;
              if (await callRegister(email, password, enqueueSnackbar)) {
                handleCloseRegister();
              }
            }}
          >
            <FormTextField label="Email" name="email" />
            <FormTextField label="Password" name="password" type="password" />
            <DialogActions>
              <Button type="submit" color="secondary" variant="contained">
                <Trans>Register</Trans>
              </Button>
            </DialogActions>
          </FormikForm>
        </DialogContent>
      </Dialog>

      <Dialog onClose={handleCloseResetPassword} aria-labelledby="login-dialog-resetpwd-title" {...resetPasswordProps}>
        <DialogTitle id="login-dialog-resetpwd-title">
          <Trans>Reset password</Trans>
        </DialogTitle>
        <DialogContent>
          <div>
            <Trans>Please type your email. Instructions for reseting password will be sent to your email.</Trans>
          </div>
          <FormikForm
            onSubmit={async values => {
              const { email } = values;
              if (await callResetPassword(email, enqueueSnackbar)) {
                handleCloseResetPassword();
              }
            }}
          >
            <FormTextField label="Email" name="email" />
            <DialogActions>
              <Button type="submit" color="secondary" variant="contained">
                <Trans>Reset password</Trans>
              </Button>
            </DialogActions>
          </FormikForm>
        </DialogContent>
      </Dialog>
    </>
  );
}

export function useLoginDialog() {
  const [openChooser, setOpenChooser] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openRegister, setOpenRegister] = React.useState(false);
  const [openResetPassword, setOpenResetPassword] = React.useState(false);

  function handleClickOpen() {
    setOpenChooser(true);
  }

  function handleOpenEmail() {
    setOpenChooser(false);
    setOpenEmail(true);
  }

  function handleOpenRegister() {
    setOpenChooser(false);
    setOpenRegister(true);
  }

  function handleOpenResetPassword() {
    setOpenChooser(false);
    setOpenResetPassword(true);
  }

  function handleCloseEmail() {
    setOpenEmail(false);
  }

  function handleCloseRegister() {
    setOpenRegister(false);
  }

  function handleCloseResetPassword() {
    setOpenResetPassword(false);
  }

  const handleCloseChooser = () => {
    setOpenChooser(false);
  };

  const dialogProps = {
    chooserProps: {
      open: openChooser,
    },
    emailLoginProps: {
      open: openEmail,
    },
    registerProps: {
      open: openRegister,
    },
    resetPasswordProps: {
      open: openResetPassword,
    },
    handleOpenEmail,
    handleOpenRegister,
    handleCloseEmail,
    handleCloseChooser,
    handleCloseRegister,
    handleCloseResetPassword,
    handleOpenResetPassword,
  };

  return [handleClickOpen, dialogProps];
}

export default compose(
  withStyles(styles),
  withSnackbar
)(LoginDialog);
