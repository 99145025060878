import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '3px',
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

export default function AvatarChip({ label, onClick, color = undefined }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Chip label={label} onClick={onClick} color={color || 'primary'} />
    </div>
  );
}
