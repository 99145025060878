import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import useFormik from '../utility/useFormik';
import { makeStyles } from '@material-ui/core/styles';
import useT from '../utility/useT';

const useStyles = makeStyles(theme => ({
  field: {
    // margin: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
}));

function FormDateTime({ name, label, t_label, ...otherProps }) {
  const classes = useStyles();
  // return (
  //   <TextField name={name} {...otherProps} />
  // );
  const t = useT();

  const { handleChange, handleBlur, values, setValues } = useFormik();

  React.useEffect(() => {
    if (!values[name]) setValues({ ...values, [name]: new Date().getTime() });
  }, []);

  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{t_label ? t(t_label) : label}</InputLabel>
      <DateTimePicker
        onChange={val => setValues({ ...values, [name]: val.toDate().getTime() })}
        onBlur={handleBlur}
        ampm={false}
        minutesStep={5}
        className={classes.field}
        value={values && values[name] && new Date(values[name])}
        format="YYYY-MM-DD HH:mm"
        name={name}
        {...otherProps}
      />
    </FormGroup>
  );

  // return <TextField label="Section" fullwidth />;
  // return <input type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} name="name" />;
}

export default FormDateTime;
