import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import useReactRouter from 'use-react-router';
import { makeStyles } from '@material-ui/styles';

import useGroupNestingLevel from '../utility/useGroupNestingLevel';
import DifficultyIcon from '../icons/Difficulty';
import WaterLevel from '../icons/WaterLevel';
import Flag from '../icons/Flag';
import useT from '../utility/useT';
import round10 from '../utility/round10';

export function formatDuration(duration) {
  if (!duration) return 'n/a';
  if (duration < 1000) {
    return `${Math.round(duration)} ms`;
  }
  if (duration < 1000 * 3600) {
    return `${Math.round(duration / 60)} min`;
  }
  if (duration < 1000 * 3600 * 24) {
    return `${Math.round(duration / (1000 * 3600))} h`;
  }

  return `${round10(duration / (1000 * 3600 * 24))} d`;
}

const useStyles = makeStyles(theme => ({
  root: {
    alignSelf: 'flex-end',
    width: 'calc(100% - 40px)',
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
    color: theme.typography.color,
    padding: '0 16px',
  },
  title: {
    fontSize: '120%',
    fontWeight: 'bold',
    // textAlign: 'center',
  },
  groupLine: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

function GroupListItem(props) {
  const {
    groupBy,
    groupDefinitions = [],
    groupContentConfig = {},

    year,
    month,
    river,
    accountName,
    tripName,
    country,
    difficultyGroup,
    waterLevel,
    routerStateField,
    rivers,
    sections,
    runCount,
    eskimoRollCount,
    swimCount,
    lengthInKm,
    durationMs,
    count,
    countries,
    diaryItemCount,
    sectionCount,
  } = props;
  const { history } = useReactRouter();
  const groupNestingLevel = useGroupNestingLevel();
  const classes = useStyles();
  const historySubState = { ...history.location.state };
  delete historySubState[`${routerStateField}_filter_groupBy`];
  delete historySubState[`${routerStateField}_loadedInfo`];
  const t = useT();

  const groupDefinition = groupDefinitions.find(x => x.groupBy === groupBy);

  if (groupBy === 'month') {
    historySubState[`${routerStateField}_filter_month`] = month;
    historySubState[`${routerStateField}_filter_year`] = year;
  } else if (groupDefinition) {
    historySubState[`${routerStateField}_filter_${groupBy}`] = props[groupBy];
  }
  historySubState.groupNestingLevel = groupNestingLevel + 1;
  historySubState[`${routerStateField}_filter_groupBy`] = '#DELETED#';

  const { icon: Icon } = groupDefinition || {};

  return (
    <ListItem alignItems="flex-start" button onClick={() => history.push(history.location.pathname, historySubState)}>
      {Icon && groupBy !== 'difficultyGroup' && groupBy !== 'waterLevel' && groupBy !== 'country' && (
        <ListItemAvatar>
          <Avatar>
            <Icon />
          </Avatar>
        </ListItemAvatar>
      )}
      {groupBy === 'difficultyGroup' && (
        <ListItemAvatar>
          <DifficultyIcon difficulty={difficultyGroup} />
        </ListItemAvatar>
      )}
      {groupBy === 'waterLevel' && (
        <ListItemAvatar>
          <WaterLevel waterLevel={waterLevel} />
        </ListItemAvatar>
      )}
      {groupBy === 'country' && (
        <ListItemAvatar>
          <Flag name={country} size={40} />
        </ListItemAvatar>
      )}

      <div className={classes.root}>
        <div className={classes.title}>
          {groupBy === 'river' && river}
          {groupBy === 'month' && `${month}/${year}`}
          {groupBy === 'year' && `Year ${year}`}
          {groupBy === 'accountName' && accountName}
          {groupBy === 'country' && t(country)}
          {groupBy === 'tripName' && (tripName || '(not trip)')}
        </div>
        {groupContentConfig.rivers && groupBy !== 'river' && rivers && (
          <div className={classes.groupLine}>
            {t('{{count}} rivers', { count: rivers.length })}: {rivers.join(', ')}
          </div>
        )}
        {groupContentConfig.sections && sections && (
          <div className={classes.groupLine}>
            {t('{{count}} sections', { count: sections.length })}: {sections.join(', ')}
          </div>
        )}
        {groupContentConfig.eventStats && (
          <div className={classes.groupLine}>
            {t('{{count}} events', { count })}
            {', '}
            {t('{{count}} runs', { count: runCount })}
            {', '}
            {t('{{count}} eskimos', { count: eskimoRollCount })}
            {', '}
            {t('{{count}} swims', { count: swimCount })}
          </div>
        )}
        {groupContentConfig.eventStats && (
          <div className={classes.groupLine}>
            {t('KmOnWater', { value: round10(lengthInKm) })}
            {', '}
            {t('HoursOnWater', { value: formatDuration(durationMs) })}
          </div>
        )}

        {groupContentConfig.countries && countries && groupBy !== 'country' && (
          <div className={classes.groupLine}>
            {t('{{count}} countries', { count: countries.length })}: {countries.map(t).join(', ')}
          </div>
        )}
        {groupContentConfig.sectionStats && (
          <div className={classes.groupLine}>{t('{{count}} sections', { count })}</div>
        )}
        {groupContentConfig.accountStats && (
          <div className={classes.groupLine}>{t('{{count}} paddlers', { count })}</div>
        )}
        {groupContentConfig.gaugeStats && <div className={classes.groupLine}>{t('{{count}} gauges', { count })}</div>}
        {groupContentConfig.riverStats && (
          <div className={classes.groupLine}>
            {t('{{count}} rivers', { count })}
            {', '}
            {t('{{count}} sections', { count: sectionCount })}
            {', '}
            {t('{{count}} events', { count: diaryItemCount })}
          </div>
        )}
      </div>
    </ListItem>
  );
}

export default GroupListItem;
