import fetchApi from './fetchApi';

async function getLocationFromIp() {
  // const resp = await fetch('http://ip-api.com/json');
  // const json = await resp.json();
  const json = await fetchApi('ip-api');
  if (json.status != 'success') {
    throw new Error('Failed getting GPS from IP');
  }
  return {
    state: 'ok',
    latitude: json.lat,
    longitude: json.lon,
    source: 'ip',
  };
}

async function getCurrentLocationCore({ allowPrompt, t }) {
  return new Promise(async (resolve, reject) => {
    const permission = await navigator.permissions.query({ name: 'geolocation' });

    const handleIpLocation = async () => {
      const permission = await navigator.permissions.query({ name: 'geolocation' });
      try {
        const ipLocation = await getLocationFromIp();
        if (permission.state == 'prompt') ipLocation.askPermission = true;
        if (permission.state == 'denied') ipLocation.warning = t('Location permission denied');
        resolve(ipLocation);
      } catch (e) {
        console.error(e);
        const res = {
          state: 'error',
          error: t('Error getting location'),
        };
        if (permission.state == 'prompt') res.askPermission = true;
        resolve(res);
      }
    };

    const receivePosition = position => {
      const { latitude, longitude } = position.coords;
      resolve({
        state: 'ok',
        latitude,
        longitude,
        source: 'gps',
      });
    };
    const onError = err => {
      handleIpLocation();
    };

    if (navigator.geolocation && (allowPrompt || permission.state == 'granted')) {
      navigator.geolocation.getCurrentPosition(receivePosition, onError, { timeout: 5000 });
    } else {
      handleIpLocation();
    }
  });
}

const fakeLocation = {
  latitude: 50.0864586,
  longitude: 14.4116544,
  state: 'ok',
  source: 'fake',
};

function getCurrentLocationLimited({ allowPrompt, t }) {
  const promise1 = getCurrentLocationCore({ allowPrompt, t });
  const promise2 = new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const location = await getLocationFromIp();
        resolve(location);
      } catch (err) {
        console.error(err);
        const res = {
          state: 'error',
          error: t('Timeout, error getting location'),
        };
        resolve(res);
      }
    }, 5000);
  });
  return Promise.race([promise1, promise2]);
}

export default async function getCurrentLocation({ disableCache, allowFakeLocation, allowPrompt, t } = {}) {
  if (disableCache) {
    return await getCurrentLocationLimited({ allowPrompt, t });
  }
  if (allowPrompt) {
    const location = await getCurrentLocationLimited({ allowPrompt, t });
    console.log('GOT LOCATION', location);
    localStorage.setItem('lastLocation', JSON.stringify(location));
    localStorage.setItem('lastLocationTm', JSON.stringify(new Date().getTime()));
    return location;
  }
  let lastLocation = localStorage.getItem('lastLocation');
  const lastLocationTm = localStorage.getItem('lastLocationTm');
  if (!lastLocation || new Date().getTime() - JSON.parse(lastLocationTm) > 1000 * 60 * 5) {
    let location = await getCurrentLocationLimited({ allowPrompt, t });
    if (location.state == 'error') {
      if (lastLocation) {
        // prolong last location cache time
        location = JSON.parse(lastLocation);
      } else {
        if (allowFakeLocation) {
          location = {
            ...fakeLocation,
            warning: t('Location request failed'),
          };
        }
      }
    }
    console.log('GOT LOCATION', location);
    localStorage.setItem('lastLocation', JSON.stringify(location));
    localStorage.setItem('lastLocationTm', JSON.stringify(new Date().getTime()));
    return location;
  }
  return JSON.parse(lastLocation);
}
