import React from "react";

export default function BoatRaft({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 501.436 501.436"
      enableBackground="new 0 0 501.436 501.436"
    >
      <path
        d="M500.849,167.79l-13.785-78.116c-0.701-3.911-1.97-7.698-3.768-11.241c-0.023-0.054-0.014-0.111-0.038-0.164l-0.078-0.108
			c-1.932-3.774-4.442-7.224-7.439-10.224l-42.232-42.233c-2.999-2.997-6.448-5.506-10.222-7.438l-0.109-0.079
			c-0.054-0.025-0.112-0.016-0.166-0.039c-3.544-1.797-7.332-3.066-11.244-3.767L333.656,0.596
			c-4.812-0.828-9.732-0.793-14.531,0.104l-0.008-0.001l-0.007,0.003c-1.84,0.33-3.655,0.788-5.433,1.368l-52.931,17.642
			c-6.002,2.009-11.456,5.382-15.935,9.853l-81.677,81.677l-23.603-4.72c-2.894-0.58-5.887,0.326-7.974,2.414l-22.59,22.59
			c-2.088,2.087-2.994,5.08-2.414,7.974l4.72,23.603l-81.677,81.677c-3.106,3.131-5.685,6.744-7.638,10.699
			c-0.03,0.051-0.083,0.077-0.112,0.129c-0.036,0.064-0.031,0.136-0.066,0.2c-0.786,1.59-1.466,3.229-2.038,4.908L2.1,313.647
			c-2.139,6.435-2.646,13.3-1.474,19.979l13.785,78.116c0.701,3.911,1.97,7.698,3.768,11.241c0.023,0.054,0.014,0.111,0.038,0.164
			c0.025,0.055,0.069,0.093,0.095,0.147c1.93,3.76,4.435,7.196,7.422,10.185l42.233,42.233c2.999,2.997,6.448,5.506,10.222,7.438
			l0.109,0.079c0.05,0.023,0.105,0.012,0.155,0.034c3.547,1.8,7.339,3.071,11.254,3.772l78.112,13.784
			c2.333,0.409,4.696,0.616,7.065,0.616c0.496,0,0.984-0.101,1.479-0.119l0.038,0.007c0.49,0,0.978-0.04,1.461-0.121l0.021-0.008
			c3.369-0.203,6.699-0.824,9.914-1.85l52.931-17.642c6.002-2.009,11.456-5.382,15.935-9.853l81.677-81.677l23.603,4.72
			c2.895,0.581,5.888-0.325,7.974-2.414l22.59-22.59c2.088-2.087,2.994-5.08,2.414-7.974l-4.72-23.603l81.677-81.677
			c0.917-0.916,1.759-1.904,2.584-2.904c0.328-0.333,0.762-0.504,1.041-0.898c0.199-0.369,0.37-0.753,0.51-1.148
			c2.453-3.35,4.381-7.055,5.718-10.985l17.642-52.931c0.258-0.774,0.416-1.575,0.627-2.363c0.533-0.958,0.882-2.008,1.028-3.094
			c0.008-0.464-0.022-0.929-0.092-1.388C501.617,176.569,501.587,172.134,500.849,167.79z M422.917,40.076l38.452,38.452
			l-44.943,20.445L402.472,85.02L422.917,40.076z M330.587,17.984l76.818,13.557l-20.333,44.699
			c-0.284-0.047-0.557-0.145-0.843-0.182l-47.664-6.19c-0.404-0.054-0.81,0.024-1.215-0.009l-6.995-51.896L330.587,17.984z
			 M332.867,87.768c1.092-0.406,2.267-0.54,3.422-0.392l47.659,6.19c1.565,0.207,3.019,0.921,4.138,2.034l17.759,17.759
			c1.112,1.118,1.827,2.57,2.034,4.134l6.19,47.664c0.15,1.157,0.014,2.334-0.397,3.427l-7.984,21.3l-94.126-94.126L332.867,87.768z
			 M133.339,267.65l43.987-43.987l100.457,100.457l-43.987,43.987L133.339,267.65z M221.313,380.589l-15.752,15.752L105.104,295.885
			l15.752-15.752L221.313,380.589z M189.809,211.18l15.752-15.752l100.457,100.457l-15.752,15.752L189.809,211.18z M218.044,182.945
			l49.636-49.636l100.457,100.457l-49.636,49.636L218.044,182.945z M280.162,120.826l15.752-15.752l100.457,100.457l-15.752,15.752
			L280.162,120.826z M312.937,20.929l7.115,52.789l-20.724,7.772l-29.399-46.226L312.937,20.929z M140.699,124.76l7.432,1.487
			l-21.855,21.855l-1.487-7.432L140.699,124.76z M254.866,44.476l29.809,46.871l-23.239,23.236l-0.009,0.013l-62.108,62.108
			l-28.237,28.233l-0.009,0.013l-56.449,56.449l-0.012,0.008l-23.482,23.486l-48.863-27.817L254.866,44.476z M18.85,319.234
			l15.457-46.374l47.075,26.8l-10.11,26.971c-0.102,0.273-0.112,0.57-0.204,0.846l-52.472-6.973
			C18.709,320.086,18.713,319.645,18.85,319.234z M19.401,338.42l51.368,6.826l5.318,40.954v0.004
			c0.037,0.285,0.134,0.556,0.182,0.839L31.57,407.375L19.401,338.42z M78.559,461.339l-38.452-38.452l44.943-20.445l13.954,13.954
			L78.559,461.339z M94.071,469.874l20.333-44.698c0.284,0.047,0.556,0.145,0.843,0.182l41.93,5.445l8.623,51.73L94.071,469.874z
			 M168.608,413.647c-1.092,0.405-2.267,0.54-3.422,0.392l-47.659-6.19c-1.565-0.207-3.019-0.921-4.138-2.034L95.63,388.057
			c-1.113-1.119-1.828-2.573-2.034-4.138l-6.19-47.659c-0.15-1.157-0.014-2.334,0.397-3.427l7.984-21.3l94.126,94.126
			L168.608,413.647z M183.63,482.123l-8.669-52.002l27.133-10.175l26.739,47.111L183.63,482.123z M360.776,376.656l-7.432-1.487
			l21.855-21.855l1.487,7.432L360.776,376.656z M244.557,458.993l-27.764-48.916l23.247-23.244l0.009-0.013l56.448-56.448
			l0.013-0.009l28.233-28.237l62.108-62.108l0.013-0.009l22.962-22.966l44.85,31.83L244.557,458.993z M465.137,234.652
			l-45.318-32.163l7.917-21.121l52.796,7.095L465.137,234.652z M431.586,164.07c-0.032-0.397,0.043-0.794-0.008-1.19l-6.19-47.668
			c-0.037-0.285-0.134-0.556-0.182-0.839l44.699-20.333l13.556,76.818c0.011,0.061-0.001,0.123,0.009,0.184L431.586,164.07z"
      />
    </svg>
  );
}
