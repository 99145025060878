import _ from 'lodash';
import React from 'react';
import Beer from './Beer';
import Barrel from './Barrel';

export default function ImageBeers({ beers, size }) {
  if (!beers) return null;
  if (beers === 5) {
    return [..._.fill(Array(4), <Beer size={size} />), <Barrel size={size} />];
  }
  return _.fill(Array(beers), <Beer size={size} />);
}
