import React from 'react';
import Grid from '@material-ui/core/Grid';
import useReactRouter from 'use-react-router';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

import AppLayout from '../layout/AppLayout';
import AuthProvider from '../providers/AuthProvider';
import FormikForm from '../forms/FormikForm';
import fetchApi from '../utility/fetchApi';
import processSaveResponse from '../utility/processSaveResponse';
import { Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  nowrap: {
    whiteSpace: 'nowrap',
  },
}));

function MyProfileEditor({ children, title, t_title, formToStorage, storageToForm }) {
  const { history } = useReactRouter();
  const { auth, setAuth } = AuthProvider.useAuth();
  const { accountId } = auth;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const loadProfile = async ({ setValues }) => {
    const profile = ((await fetchApi(`account-info/find?accountId=${accountId}`, { auth })) || [])[0];
    if (profile) {
      setValues(storageToForm ? storageToForm(profile) : profile);
    }
  };

  const save = async values => {
    const saveData = await fetchApi.post(
      `account-info/save`,
      { entity: formToStorage ? formToStorage(values) : values },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    processSaveResponse(saveData, enqueueSnackbar, 'Successfully saved profile');
    if (saveData && saveData.authToken) {
      setAuth(saveData);
    }
  };

  return (
    <FormikForm
      initialValues={{}}
      onLoad={loadProfile}
      onSubmit={async values => {
        await save(values);
        history.goBack();
      }}
    >
      <AppLayout
        title={title}
        t_title={t_title}
        mainIconMeaning="top"
        customButtons={
          <div className={classes.nowrap}>
            <Button type="submit" color="inherit">
              <Trans>Save</Trans>
            </Button>
            <Button color="inherit" onClick={() => history.goBack()}>
              <Trans>Discard</Trans>
            </Button>
          </div>
        }
      >
        <Grid container alignItems="stretch">
          {children}
        </Grid>
      </AppLayout>
    </FormikForm>
  );
}

export default MyProfileEditor;
