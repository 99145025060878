import React from 'react';

export default function Smile({ size = 30 }) {
  return (
    <svg width={size} height={size} viewBox="0 0 368 368" enable-background="new 0 0 368 368">
      <g>
        <g>
          <g>
            <path
              fill="#777"
              d="M184,0C82.544,0,0,82.544,0,184s82.544,184,184,184s184-82.544,184-184S285.456,0,184,0z M184,352
         c-92.64,0-168-75.36-168-168S91.36,16,184,16s168,75.36,168,168S276.64,352,184,352z"
            />
            <path
              fill="#777"
              d="M144,152c0-13.232-10.768-24-24-24s-24,10.768-24,24s10.768,24,24,24S144,165.232,144,152z M112,152c0-4.408,3.592-8,8-8
         s8,3.592,8,8s-3.592,8-8,8S112,156.408,112,152z"
            />
            <path
              fill="#777"
              d="M248,128c-13.232,0-24,10.768-24,24s10.768,24,24,24s24-10.768,24-24S261.232,128,248,128z M248,160
         c-4.408,0-8-3.592-8-8s3.592-8,8-8c4.408,0,8,3.592,8,8S252.408,160,248,160z"
            />
            <path
              fill="#777"
              d="M261.336,226.04c-3.296-2.952-8.36-2.664-11.296,0.624C233.352,245.312,209.288,256,184,256
         c-25.28,0-49.352-10.688-66.04-29.336c-2.952-3.288-8-3.576-11.296-0.624c-3.296,2.944-3.568,8-0.624,11.296
         C125.76,259.368,154.176,272,184,272c29.832,0,58.248-12.64,77.96-34.664C264.904,234.04,264.624,228.984,261.336,226.04z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
