import React from 'react';

import Filter1 from '@material-ui/icons/Filter1';
import Filter2 from '@material-ui/icons/Filter2';
import Filter3 from '@material-ui/icons/Filter3';
import Filter4 from '@material-ui/icons/Filter4';
import Filter5 from '@material-ui/icons/Filter5';
import Filter6 from '@material-ui/icons/Filter6';
import Filter7 from '@material-ui/icons/Filter7';
import Filter8 from '@material-ui/icons/Filter8';
import Filter9 from '@material-ui/icons/Filter9';
import Filter9Plus from '@material-ui/icons/Filter9Plus';

function CommentCount({ commentCount }) {
  if (!commentCount) return null;
  if (commentCount == 1) return <Filter1 />;
  if (commentCount == 2) return <Filter2 />;
  if (commentCount == 3) return <Filter3 />;
  if (commentCount == 4) return <Filter4 />;
  if (commentCount == 5) return <Filter5 />;
  if (commentCount == 6) return <Filter6 />;
  if (commentCount == 7) return <Filter7 />;
  if (commentCount == 8) return <Filter8 />;
  if (commentCount == 9) return <Filter9 />;
  if (commentCount > 9) return <Filter9Plus />;
  return null;
}

export default CommentCount;
