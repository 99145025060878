import React from 'react';
import AppLayout from '../layout/AppLayout';
import EventGroupListControl from './EventGroupListControl';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';
import { extractRiverParam } from '../utility/extractParams';

function RiverEventGroupsPage() {
  const t = useT();
  const { history, match } = useReactRouter();
  const river = extractRiverParam(match);

  return (
    <AppLayout title={t('Runs')} mainIconMeaning="top" showRefreshButton>
      <EventGroupListControl routerStateField="listAll" filterParams={`&river=${encodeURIComponent(river)}`} />
    </AppLayout>
  );
}

export default RiverEventGroupsPage;
