import React from 'react';
import moment from 'moment';
import useReactRouter from 'use-react-router';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/styles';
import { ListItemAvatar } from '@material-ui/core';

import createShortName from '../utility/create-short-name';
import ImageStars from '../icons/ImageStars';
import { getWaterLevelColor, waterLevelTwoLetterNames } from '../icons/WaterLevel';
import DifficultyIcon from '../icons/Difficulty';
import AuthProvider from '../providers/AuthProvider';
import SmallPaddlerAvatar from '../people/SmallPaddlerAvatar';
import CommentCountIcon from '../icons/CommentCount';
import { Trans } from 'react-i18next';
import EmojiChooser from '../ui-elements/EmojiChooser';

const useStyles = makeStyles(theme => ({
  root: {
    alignSelf: 'flex-end',
    width: '100%',
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
    color: theme.typography.color,
    padding: '0 16px',
  },
  river: {
    // fontWeight: 'bold',
    flexGrow: 1,
    fontSize: '110%',
  },
  // stars: {
  //   flexGrow: 1,
  //   // marginLeft: '5px',
  // },
  section: {
    flexGrow: 1,
    color: theme.sectionColor,
    fontWeight: 'bold',
  },
  description: {
    // fontStyle: 'italic',
    fontFamily: theme.typography.fontFamilyCondensed,
  },
  trip: {
    fontStyle: 'italic',
  },
  dangerInfo: {
    fontWeight: 'bold',
    color: 'red',
  },
  flexLine: {
    display: 'flex',
    width: '100%',
  },
  team: {},
  account: {
    fontWeight: 'bold',
  },
  editing: {
    color: theme.editingColor,
    fontStyle: 'italic',
  },
  waterLevel: {
    width: '15px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '2px',
    fontSize: '8pt',
    writingMode: 'vertical-rl',
    textAlign: 'center',
  },
  gaugeLine: {
    fontWeight: 'bold',
  },
  avatar: {
    // display: 'inline-block',
    marginLeft: '5px',
  },
  commentCountWrapper: {
    marginLeft: '8px',
    marginTop: '8px',
  },
}));

function EventListItem({
  diaryId,
  river,
  section,
  difficulty,
  description,
  dangerInfo,
  team,
  enjoyLevel,
  waterLevel,
  // beerCount,
  startTm,
  gauges,
  isPrivate,
  accountName,
  accountId,
  isEditing,
  accountAvatar,
  tripName,
  commentCount,
  emotions,
}) {
  const { auth } = AuthProvider.useAuth();
  const classes = useStyles();
  const { history } = useReactRouter();

  const teamText = (team || []).map(x => createShortName(x.displayName || '')).join(', ');
  const gaugeState = [];
  const gauge1 = gauges && gauges[0];
  if (gauge1) {
    const { heightCentimeters, flowCumecs } = gauge1;
    if (heightCentimeters) gaugeState.push(<span>{heightCentimeters} cm</span>);
    if (flowCumecs) {
      if (gaugeState.length > 0) gaugeState.push(', ');
      gaugeState.push(<span>{flowCumecs} m3/s</span>);
    }
  }
  return (
    <ListItem
      alignItems="flex-start"
      button
      onClick={() => history.push(isEditing ? `/events/edit/${diaryId}` : `/events/detail/${diaryId}`)}
    >
      <ListItemAvatar>
        <DifficultyIcon difficulty={difficulty} />
        {!!commentCount && (
          <div className={classes.commentCountWrapper}>
            <CommentCountIcon commentCount={commentCount} />
          </div>
        )}
      </ListItemAvatar>

      <div className={classes.root}>
        {auth && auth.accountId === accountId ? null : (
          <div className={classes.flexLine}>
            <div className={classes.account}>{accountName}</div>
            {accountAvatar && <SmallPaddlerAvatar className={classes.avatar} {...accountAvatar} />}
          </div>
        )}
        {auth && auth.accountId === accountId && <div className={classes.trip}>{tripName}</div>}
        <div className={classes.flexLine}>
          {isPrivate && <LockIcon style={{ width: 15, height: 15 }} />}
          <div className={classes.river}>{river}</div>
          <div className={classes.stars}>
            <ImageStars size={15} stars={enjoyLevel} />
          </div>
        </div>
        <div className={classes.flexLine}>
          <div className={classes.section}>{section}</div>
          <Hidden xsDown>
            <div>{moment(startTm).format('D. M. YYYY HH:mm')}</div>
          </Hidden>
        </div>
        <Hidden smUp>
          <div>{moment(startTm).format('D. M. YYYY HH:mm')}</div>
        </Hidden>
        <div className={classes.gaugeLine}>{gaugeState}</div>
        <div className={classes.dangerInfo}>{dangerInfo}</div>
        <div className={classes.description}>{description}</div>
        <Hidden xsDown>
          <div className={classes.team}>{teamText}</div>
        </Hidden>
        {isEditing && (
          <div className={classes.editing}>
            <Trans>Editing</Trans>
          </div>
        )}

        {/* <EmojiChooser context={{ diaryId, target: 'event' }} emotions={emotions} accountId={accountId} usedInList /> */}
      </div>

      <div className={classes.waterLevel} style={{ backgroundColor: getWaterLevelColor(waterLevel) }}>
        {waterLevelTwoLetterNames[waterLevel || 0]}
      </div>
    </ListItem>
  );
}

export default EventListItem;
