import React from 'react';
import moment from 'moment';
import useReactRouter from 'use-react-router';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/styles';
import { ListItemAvatar } from '@material-ui/core';

import createShortName from '../utility/create-short-name';
import ImageStars from '../icons/ImageStars';
import { getWaterLevelColor, waterLevelTwoLetterNames } from '../icons/WaterLevel';
import DifficultyIcon from '../icons/Difficulty';
import AuthProvider from '../providers/AuthProvider';
import ListAccountAvatar from '../people/ListAccountAvatar';
import CommentCountIcon from '../icons/CommentCount';
import { Trans } from 'react-i18next';
import EmojiChooser from '../ui-elements/EmojiChooser';
import useT from '../utility/useT';

function formatDuration(duration) {
  if (!duration || duration < 60 * 1000) return '';

  const hours = Math.floor(duration / (1000 * 3600));
  return `${hours}:${Math.floor((duration - hours * 1000 * 3600) / (1000 * 60))
    .toString()
    .padStart(2, '0')}`;
}

const useStyles = makeStyles(theme => ({
  root: {
    alignSelf: 'flex-end',
    width: '100%',
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
    color: theme.typography.color,
    padding: '0 16px',
  },
  playspot: {
    flexGrow: 1,
    color: theme.sectionColor,
    fontWeight: 'bold',
  },
  description: {
    // fontStyle: 'italic',
    fontFamily: theme.typography.fontFamilyCondensed,
  },
  // listItem: {
  //   backgroundColor: 'white',
  //   opacity: 0.8,
  // },
}));

function TrainingListItem({
  startTm,
  trainingId,
  playspotName,
  description,
  accountId,
  accountName,
  accountAvatar,
  durationMs,
  eskimoRollCount,
}) {
  const { auth } = AuthProvider.useAuth();
  const classes = useStyles();
  const { history } = useReactRouter();
  const t = useT();

  return (
    <ListItem
      // className={classes.listItem}
      alignItems="flex-start"
      button
      onClick={auth && auth.accountId === accountId ? () => history.push(`/trainings/edit/${trainingId}`) : null}
    >
      <ListItemAvatar>
        <ListAccountAvatar {...accountAvatar} />
      </ListItemAvatar>

      <div className={classes.root}>
        {auth && auth.accountId === accountId ? null : (
          <div className={classes.flexLine}>
            <div className={classes.account}>{accountName}</div>
          </div>
        )}
        <div className={classes.flexLine}>
          <div className={classes.playspot}>{playspotName}</div>
          <Hidden xsDown>
            <div>{moment(startTm).format('D. M. YYYY')}</div>
          </Hidden>
        </div>
        <Hidden smUp>
          <div>{moment(startTm).format('D. M. YYYY')}</div>
        </Hidden>
        <div className={classes.description}>{description}</div>
        {eskimoRollCount && <div>{t('{{count}} eskimos', { count: eskimoRollCount })}</div>}
        {durationMs && <div>{formatDuration(durationMs)} h</div>}
      </div>
    </ListItem>
  );
}

export default TrainingListItem;
