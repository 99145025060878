import React from 'react';
import _ from 'lodash';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import useFormik from '../utility/useFormik';
import useFetchApiLoading from '../utility/useFetchApiLoading';
import { Z_BLOCK } from 'zlib';
import useT from '../utility/useT';

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

const extractValue = valueField => obj => {
  if (!valueField) return obj;
  return _.isFunction(valueField) ? valueField(obj) : _.get(obj, valueField);
};

const renderSuggestion = (primaryField, secondaryField, classes) => (suggestion, { query, isHighlighted }) => {
  const primaryLabel = extractValue(primaryField)(suggestion);
  const secondaryLabel = extractValue(secondaryField)(suggestion);

  return (
    <ListItem selected={isHighlighted} component="div">
      <ListItemText primary={primaryLabel} secondary={secondaryLabel} />
    </ListItem>
  );
};

const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

function FormAutocomplete({
  label,
  name,
  url,
  classes,
  onSelect,
  // onChangeRaw,
  shouldRenderSuggestions,
  primaryField,
  secondaryField,
  valueField,
  textField,
}) {
  const { values, setValues } = useFormik();
  const [containsFilter, setContainsFilter] = React.useState(null);
  const setDebouncedContainsFilter = React.useRef(_.debounce(({ value }) => setContainsFilter(value), 300));
  const formikContext = useFormik();
  const t = useT();

  const urlContains = containsFilter == null ? null : url(containsFilter, formikContext);
  const [data, isLoading] = useFetchApiLoading(urlContains);

  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <Autosuggest
        renderInputComponent={renderInputComponent}
        suggestions={data}
        onSuggestionsFetchRequested={setDebouncedContainsFilter.current}
        onSuggestionsClearRequested={() => setContainsFilter(null)}
        getSuggestionValue={extractValue(valueField)}
        renderSuggestion={renderSuggestion(primaryField, secondaryField, classes)}
        onSuggestionSelected={(ev, value) => {
          if (onSelect) onSelect(value.suggestion, formikContext);
        }}
        inputProps={{
          classes,
          placeholder: t('Search'),
          value:
            (_.isString(values[name]) || values[name] == null ? values[name] : extractValue(textField)(values[name])) ||
            '',
          onChange: (ev, { newValue }) => setValues({ ...values, [name]: newValue }),
        }}
        shouldRenderSuggestions={value => {
          if (shouldRenderSuggestions) return shouldRenderSuggestions(value, formikContext);
          return (value || '').length >= 1;
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </FormGroup>
  );
}

export default withStyles(styles)(FormAutocomplete);
