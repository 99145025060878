import React from 'react';
import _ from 'lodash';
import Map from 'pigeon-maps';
import useViewportSize from '../utility/useViewportSize';
import Marker from './Marker';
// import Overlay from 'pigeon-overlay';

export const mapProviders = {
  osm: (x, y, z) => {
    const s = String.fromCharCode(97 + ((x + y + z) % 3));
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
  },
  wikimedia: (x, y, z, dpr) => {
    return `https://maps.wikimedia.org/osm-intl/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.png`;
  },
  mapycz: (x, y, z) => {
    return `https://mapserver.mapy.cz/base-m/${z}-${x}-${y}`;
  },
  mapycz_turist: (x, y, z) => {
    return `https://mapserver.mapy.cz/turist-m/${z}-${x}-${y}`;
  },
  mapycz_photo: (x, y, z) => {
    return `https://mapserver.mapy.cz/bing/${z}-${x}-${y}`;
  },
};

export const mapAttribution = {
  mapycz: (
    <span>
      <a
        href="https://mapy.cz"
        style={{
          color: '#0078A8',
          textDecoration: 'none',
        }}
      >
        mapy.cz
      </a>
    </span>
  ),
};

function PointsMap({ onClick, points = [], isHighlighted }) {
  console.log('points', points);
  console.log('isHighlighted', isHighlighted);
  const size = useViewportSize();
  // const coords = locations.map(location => location.coordinates);

  const highlighted = isHighlighted ? points.find(isHighlighted) : undefined;

  const center = highlighted
    ? highlighted.coordinates
    : points.length > 0
    ? points[0].coordinates
    : [14.4248281, 50.1129869];

  return size.width > 0 && size.height > 0 && points.length > 0 ? (
    <Map
      defaultCenter={[...center].reverse()}
      defaultZoom={12}
      defaultWidth={size.width}
      height={size.height}
      provider={mapProviders.mapycz_turist}
      attribution={mapAttribution.mapycz}
      onClick={onClick}
    >
      {points.map(point => (
        point && point.coordinates &&
        <Marker
          anchor={[...point.coordinates].reverse()}
          payload={point.locationId}
          key={point.locationId}
          isHighlighted={isHighlighted && isHighlighted(point)}
        />
      ))}
    </Map>
  ) : (
    <div>No available map data</div>
  );
}

export default PointsMap;
