import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import DashboardCard from '../ui-elements/DashboardCard';
import DifficultyIcon from '../icons/Difficulty';
import { makeStyles } from '@material-ui/core/styles';
import { getWaterLevelColor, waterLevelTwoLetterNames } from '../icons/WaterLevel';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';

const useStyles = makeStyles(theme => ({
  flexLine: {
    display: 'flex',
  },
  // line1: {
  //   display: 'flex',
  // },
  danger: {
    color: 'red',
    fontFamily: theme.typography.fontFamilyCondensed,
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
  },
  // line2: {
  //   display: 'flex',
  //   // marginLeft: '25px',
  //   // justifyContent: 'flex-end',
  // },
  gray: {
    color: 'gray',
  },
  infoLine: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    margin: '10px',
    cursor: 'pointer',
  },
  infoValue: {
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexShrink: 1,
  },
  dateValue: {
    whiteSpace: 'nowrap',
  },
}));

function InfoLine({ infoItem, usedOn }) {
  const classes = useStyles();
  const { text, createTm, messageType, commentTarget, sectionId, startTm, date } = infoItem;
  const { history } = useReactRouter();

  return (
    <div
      className={classes.infoLine}
      onClick={() => {
        if (messageType == 'danger' || messageType == 'event') {
          history.push(`/event-group/${moment(startTm).format('YYYY-MM-DD')}/${sectionId}`);
        }
        if (messageType == 'comment') {
          if (commentTarget == 'event') {
            history.push(`/event-group/${date}/${sectionId}`);
          }
          if (commentTarget == 'section' && usedOn != 'section') {
            history.push(`/sections/detail/${sectionId}`);
          }
        }
      }}
    >
      <div className={clsx(messageType == 'danger' ? classes.danger : classes.gray, classes.infoValue)}>{text}</div>
      <div className={classes.dateValue}>{moment(createTm).format('D.M.YYYY')}</div>
    </div>
  );
}

export default function MessageAggregateDetailList({ messageAggregate, includeGrid, usedOn, limit = 5 }) {
  const t = useT();
  return (
    <DashboardCard includeGrid={includeGrid} title={t('Last messages')}>
      {(messageAggregate || []).slice(0, limit).map(item => (
        <InfoLine infoItem={item} usedOn={usedOn} />
      ))}
    </DashboardCard>
  );
}
