import React from 'react';

import FormTextField from '../forms/FormTextField';
import FormSelect from '../forms/FormSelect';
import FormDifficulty from '../forms/FormDifficulty';
import FormCheckBox from '../forms/FormCheckBox';
import FormRating from '../forms/FormRating';
import FormLineColorPicker from '../forms/FormLineColorPicker';
import PaperCard from '../ui-elements/PaperCard';
import MyProfileEditor from './MyProfileEditor';
import useT from '../utility/useT';

function EditProfilePage() {
  const t = useT();
  return (
    <MyProfileEditor t_title="Edit profile">
      <PaperCard>
        <FormTextField t_label="Display name" name="displayName" multiline />
        <FormTextField t_label="Nickname" fullwidth name="nickname" multiline />
        <FormTextField t_label="Sport club" name="sportClub" />
        <FormDifficulty label={t('My favorite difficulty')} name="difficulty" />
        <FormRating label={t('My default party level')} name="beerCount" image="beer" />
        <FormCheckBox
          label={t('Send logbook copy to email monthly')}
          name="sendLogBookCopyByEmail"
          defaultValue={true}
        />
      </PaperCard>
      <PaperCard>
        <FormSelect t_label="Boat type" name="boatType">
          <option value="">(not set)</option>
          <option>Kayak (K1)</option>
          <option>Single-canoe (C1)</option>
          <option>Raft</option>
          <option>Tourist canoe (C2)</option>
          <option>Other</option>
        </FormSelect>
        <FormTextField t_label="Boat model" name="boatModel" />
        <FormLineColorPicker
          label={t('Boat color')}
          name="boatColor"
          colors={['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#FFFFFF', '#404040', '#f9a602', '#00FFFF']}
        />
      </PaperCard>
    </MyProfileEditor>
  );
}

export default EditProfilePage;
