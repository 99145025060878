import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
  },
}));

function ChooseFromListDialog({ title, t_title, text, items, onClose, children, ...other }) {
  const classes = useStyles();
  const t = useT();
  function handleClose() {
    onClose();
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="confirm-dialog-title" {...other}>
      <DialogTitle id="confirm-dialog-title">{t_title ? t(t_title) : title}</DialogTitle>
      {text && <div className={classes.wrapper}>{text}</div>}
      <List>
        {items.map(item => {
          const Icon = item.icon;
          const content = (
            <>
              {item.icon && (
                <ListItemAvatar>
                  <Avatar>
                    <Icon />
                  </Avatar>
                </ListItemAvatar>
              )}
              <ListItemText primary={item.t_text ? t(item.t_text) : item.text} />
            </>
          );

          return item.onClick ? (
            <ListItem
              button
              onClick={() => {
                if (item.onClick) item.onClick();
                handleClose();
              }}
              key={item.key}
            >
              {content}
            </ListItem>
          ) : (
            <ListItem button component={Link} href={item.link} target={item.target} key={item.key}>
              {content}
            </ListItem>
          );
        })}
      </List>
      {children}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChooseFromListDialog;
