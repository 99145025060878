import React from 'react';

import MapIcon from '@material-ui/icons/Map';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import EditIcon from '@material-ui/icons/Edit';
import ShowChartIcon from '@material-ui/icons/ShowChart';

import ListControl from '../layout/ListControl';
import RiverListItem from './RiverListItem';

const groupDefinitions = [
  {
    t_title: 'country',
    icon: MapIcon,
    groupBy: 'country',
    translateGroupValue: true,
    default: true,
  },
];

const groupContentConfig = {
  countries: true,
  riverStats: true,
};

const filterDefinitions = [
  {
    t_title: 'with sections',
    icon: SubdirectoryArrowRightIcon,
    filterField: 'hasSection',
    filterValue: 'true',
  },
  {
    t_title: 'with events',
    icon: EditIcon,
    filterField: 'hasEvent',
    filterValue: 'true',
    default: true,
  },
  {
    t_title: 'with gauges',
    icon: ShowChartIcon,
    filterField: 'hasGauge',
    filterValue: 'true',
  },
];

export default function RiverListControl({ filterParams, isRawVariant, isInline, noDataContentCallback, ...other }) {
  return (
    <ListControl
      // idName="accountId"
      isInline={isInline}
      serviceName="river-info"
      filterParams={filterParams}
      noDataContentCallback={noDataContentCallback}
      findParams="sort=river"
      component={RiverListItem}
      showSearch={!isRawVariant}
      routerStateField="listRivers"
      groupDefinitions={isRawVariant ? [] : groupDefinitions}
      groupContentConfig={groupContentConfig}
      filterDefinitions={isRawVariant ? [] : filterDefinitions}
      {...other}
    />
  );
}
