import React from 'react';
import useFetchApi from '../utility/useFetchApi';
import moment from 'moment';
import DifficultyIcon from '../icons/Difficulty';
import { makeStyles } from '@material-ui/core/styles';
import median from '../utility/median';
import DashboardCard from '../ui-elements/DashboardCard';
import useT from '../utility/useT';
import AuthProvider from '../providers/AuthProvider';
import { getWaterLevelColor, waterLevelTwoLetterNames } from '../icons/WaterLevel';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import ImageBeers from '../icons/ImageBeers';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  flex: {
    display: 'flex',
  },
  runCount: {
    fontSize: '15pt',
    textAlign: 'center',
  },
  valueList: {},
  nameValue: {
    display: 'flex',
  },
  runWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: '1',
  },
  flex1: {
    flex: 1,
  },
  center: {
    textAlign: 'center',
  },
}));

function Value({ t_name, value }) {
  const classes = useStyles();
  const t = useT();

  return (
    <Grid container>
      <Grid item xs={7}>
        {t(t_name)}
      </Grid>
      <Grid item xs={5}>
        {value}
      </Grid>
    </Grid>
  );
}

export default function MyEventStatsWidget() {
  const [period, setPeriod] = React.useState(0);
  const t = useT();
  const periods = [t('Last month'), t('Last year'), t('This year'), t('Entire history'), t('Last week')];
  const { auth } = AuthProvider.useAuth();
  const { accountId } = auth || {};
  const from = React.useMemo(() => {
    switch (period) {
      case 0:
        return moment()
          .add(-1, 'month')
          .valueOf();
      case 1:
        return moment()
          .add(-1, 'year')
          .valueOf();
      case 2:
        return moment()
          .startOf('year')
          .valueOf();
      case 3:
        return 0;
      case 4:
        return moment()
          .add(-1, 'week')
          .valueOf();
      default:
        return 0;
    }
  }, [period]);

  const classes = useStyles();
  const eventStats = useFetchApi(`diary-info/stats?accountId=${accountId}&fromTm=${from}`, null);
  const { runCount, swimCount, eskimoRollCount, waterLevel, difficulty, beerCount, eventCount } = eventStats || {};
  return (
    <DashboardCard title={t('My logbook')} link="/events" clickOnWidget isLoading={eventStats == null}>
      <Grid container>
        <Grid item xs={5} className={classes.flex}>
          <div className={classes.runWrapper}>
            <div className={classes.flex1}>
              <div className={classes.center}>
                <Chip
                  label={periods[period]}
                  onClick={e => {
                    e.stopPropagation();
                    setPeriod(v => (v + 1) % periods.length);
                  }}
                  color="primary"
                />
              </div>
              <div className={classes.runCount}>{runCount}</div>
              <div className={classes.center}>
                <Trans>runs</Trans>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={7}>
          <Value t_name="Difficulty" value={difficulty} />
          <Value t_name="Eskimo rolls" value={eskimoRollCount} />
          <Value t_name="Swims" value={swimCount} />
          <Value t_name="Water level" value={waterLevelTwoLetterNames[waterLevel || 0]} />
          <Value t_name="Party level" value={<ImageBeers size={18} beers={beerCount} />} />
          <Value t_name="River runs" value={eventCount} />
        </Grid>
      </Grid>
    </DashboardCard>
  );
}
