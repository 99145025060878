import React from 'react';
import AppLayout from '../layout/AppLayout';
import TabControl from '../layout/RouteTabControl';
import ListControl from '../layout/ListControl';
import GaugeListItem from '../gauges/GaugeListItem';
import GaugeSubscriptionsView from './GaugeSubscriptionsView';
import GaugeListControl from './GaugeListControl';
import AuthProvider from '../providers/AuthProvider';

function MyGaugesPage() {
  const { auth } = AuthProvider.useAuth();
  return (
    <AppLayout t_title="My gauges" mainIconMeaning="top">
      <GaugeSubscriptionsView />
    </AppLayout>
  );
}

export default MyGaugesPage;
