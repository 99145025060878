import React from 'react';
import AppLayout from '../layout/AppLayout';
import moment from 'moment';
import _ from 'lodash';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import useFetchApi from '../utility/useFetchApi';
import { makeStyles } from '@material-ui/styles';
import EventDetailView from './EventDetailView';
import Button from '@material-ui/core/Button';
import { Trans } from 'react-i18next';
import AuthProvider from '../providers/AuthProvider';
import ChooseFromListDialog from '../ui-elements/ChooseFromListDialog';
import useStateDialog from '../utility/useStateDialog';
import PeopleIcon from '@material-ui/icons/People';
import HeaderImage from '../places/HeaderImage';
import AvatarBar from '../ui-elements/AvatarBar';
import AvatarChip from '../ui-elements/AvatarChip';
import CommentsListView from '../comments/CommentsListView';
import AvatarPhotoButtons from '../comments/AvatarPhotoButtons';
import LoadingInfo from '../ui-elements/LoadingInfo';

const useStyles = makeStyles(theme => ({
  root: {},

  flexLine: {
    display: 'flex',
  },
  dangerInfo: {
    fontWeight: 'bold',
    color: 'red',
  },
  avatar: {
    marginLeft: '5px',
  },
  grower: {
    flexGrow: 1,
  },
}));

function countGauge(allGauges, gauge) {
  const items = allGauges.filter(x => x.gauge == gauge);
  const heights = _.uniq(items.map(x => x.heightCentimeters)).sort();
  const flows = _.uniq(items.map(x => x.flowCumecs)).sort();
  const heightCentimeters = heights.length == 1 ? heights[0] : `${heights[0]}-${heights[heights.length - 1]}`;
  const flowCumecs = flows.length == 1 ? flows[0] : `${flows[0]}-${flows[flows.length - 1]}`;
  const res = {
    gauge,
    river: items[0].river,
    gaugeEngine: items[0].gaugeEngine,
    heightCentimeters,
    flowCumecs,
  };
  if (heights.length > 1) {
    res.heightCentimetersMin = heights[0];
    res.heightCentimetersMax = heights[heights.length - 1];
  }
  if (flows.length > 1) {
    res.flowCumecsMin = flows[0];
    res.flowCumecsMax = flows[flows.length - 1];
  }
  return res;
}

function EventGroupDetailPage() {
  const t = useT();
  const { history, match } = useReactRouter();
  const { day, sectionId } = match.params;
  const date = moment(`${day}T00:00:00`);
  const classes = useStyles();
  const { auth } = AuthProvider.useAuth();
  const [openCopy, copyDialogProps] = useStateDialog();

  const sectionDetailResp = useFetchApi(
    `section-info/find?sectionId=${sectionId}&joinPlaces=1&findMeasurement=1`,
    null
  );
  const eventStatsResp = useFetchApi(
    `diary-info/stats?sectionId=${sectionId}&day=${day}&joinAccountAvatar=1&includeEvents=1`,
    null
  );
  const sectionDetail = (sectionDetailResp || [])[0] || {};
  const eventStats = eventStatsResp || {};

  const diaryInfos = eventStats.events || [];

  const dangers = _.uniq(diaryInfos.map(x => x.dangerInfo).filter(x => x));

  const tripIds = _.uniq(diaryInfos.map(x => x.tripId).filter(x => x));
  const trips = tripIds.map(tripId => ({
    tripId,
    tripName: diaryInfos.find(x => x.tripId == tripId).tripName,
  }));
  const country = _.uniq(diaryInfos.map(x => x.country).filter(x => x)).join(', ');
  const allGauges = _.flatten(diaryInfos.map(x => x.gauges || {}));
  const gaugeNames = _.uniq(allGauges.map(x => x.gauge));
  const gauges = gaugeNames.map(gauge => countGauge(allGauges, gauge));

  const { runCount, swimCount, eskimoRollCount, lengthInKm } = eventStats;

  const startTimes = diaryInfos.map(x => x.startTm);

  const myEvent = diaryInfos.find(x => x.accountId == (auth && auth.accountId));
  const isMyLog = diaryInfos.length > 0 && !!myEvent;

  const copyFromItems = diaryInfos.map(event => ({
    t_text: event.accountName,
    link: `/events/copy/${event.diaryId}`,
    icon: PeopleIcon,
  }));

  if (!sectionDetailResp || !eventStatsResp) {
    return (
      <AppLayout t_title={'Page is loading'}>
        <LoadingInfo />
      </AppLayout>
    );
  }

  return (
    <AppLayout
      title={date.format('D. M. YYYY')}
      mainIconMeaning="back"
      customButtons={
        !!myEvent && (
          <IconButton color="inherit" onClick={() => history.push(`/events/edit/${myEvent && myEvent.diaryId}`)}>
            <EditIcon />
          </IconButton>
        )
      }
    >
      <HeaderImage image="event-group-header" label={t('Runs per day')} labelColor="#add" />
      <AvatarBar usage="event">
        {auth && !isMyLog && <AvatarChip label={t('Copy to My log')} onClick={openCopy} />}
        <AvatarPhotoButtons />
      </AvatarBar>

      <EventDetailView
        river={sectionDetail.river}
        section={sectionDetail.section}
        enjoyLevel={eventStats.enjoyLevel}
        waterLevel={eventStats.waterLevel}
        sectionId={sectionId}
        difficulty={eventStats.difficulty}
        diaryInfos={diaryInfos}
        country={country}
        trips={trips}
        dateText={date.format('D. M. YYYY')}
        descriptions={diaryInfos.filter(x => x.description)}
        dangers={dangers}
        runCount={runCount}
        eskimoRollCount={eskimoRollCount}
        swimCount={swimCount}
        lengthInKm={lengthInKm}
        gauges={gauges}
        fromTm={date.startOf('day').valueOf()}
        toTm={date.endOf('day').valueOf()}
        markerTm={_.uniq([_.min(startTimes), _.max(startTimes)])}
        sectionGauges={sectionDetail.gauges}
      />
      <CommentsListView
        context={{
          sectionId,
          date: date.format('YYYY-MM-DD'),
          target: 'event',
        }}
        isInlineView
      />

      <ChooseFromListDialog items={copyFromItems} {...copyDialogProps} t_title="Choose from who to copy" />
    </AppLayout>
  );
}

export default EventGroupDetailPage;
