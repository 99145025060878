import React from 'react';
import AppLayout from '../layout/AppLayout';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

import LayersIcon from '@material-ui/icons/Layers';
import useFetchApi from '../utility/useFetchApi';
import useReactRouter from 'use-react-router';
import Button from '@material-ui/core/Button';
import { Trans } from 'react-i18next';
import HeaderMap, { computeCenterZoom } from '../places/HeaderMap';
import CommentsListView from '../comments/CommentsListView';
import DetailPageLayout from '../layout/DetailPageLayout';
import AvatarBar from '../ui-elements/AvatarBar';
import AvatarButton from '../ui-elements/AvatarButton';
import useOnlineStatus from '../utility/useOnlineStatus';
import { useSetDetailMapType } from '../utility/globalstate';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PaperRows from '../ui-elements/PaperRows';
import ImageStars from '../icons/ImageStars';
import Flag from '../icons/Flag';
import DifficultyIcon from '../icons/Difficulty';
import TopEventsWidget from '../dashboard/TopEventsWidget';
import TopDangersWidget from '../dashboard/TopDangersWidget';
import PlacesWidget from '../dashboard/PlacesWidget';
import Chip from '@material-ui/core/Chip';
import AvatarChip from '../ui-elements/AvatarChip';
import RiverSectionsWidget from './RiverSectionsWidget';
import GaugeListWidget from '../gauges/GaugeListWidget';
import useT from '../utility/useT';
import { extractRiverParam } from '../utility/extractParams';
import AuthProvider from '../providers/AuthProvider';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import fetchApi from '../utility/fetchApi';

function RiverDetailPage() {
  const { history, match } = useReactRouter();
  const river = extractRiverParam(match);
  const riverDetail = useFetchApi.get('river-info/find', { river, joinPlaces: 1 }, [])[0];
  const { places } = riverDetail || [];
  const online = useOnlineStatus();
  const setDetailMapType = useSetDetailMapType();
  const theme = useTheme();
  const t = useT();
  const { auth, updateFavoriteRivers } = AuthProvider.useAuth();

  console.log('auth', auth);

  return (
    <AppLayout title={`${river}`} mainIconMeaning="back" parentRoute="/rivers">
      <HeaderMap places={places} label={t('River')} labelColor="#aad" />

      <AvatarBar usage="section">
        {/* {online && places && places.length && (
          <AvatarButton
            Icon={LayersIcon}
            onClick={() => setDetailMapType(x => (x == 'mapycz_photo' ? 'mapycz_turist' : 'mapycz_photo'))}
            color={theme.palette.primary.main}
          />
        )} */}

        {auth && (
          <AvatarChip
            label={t('Create section')}
            onClick={() =>
              history.push(
                `/sections/create-from-river/${encodeURIComponent(river)}/${encodeURIComponent(
                  _.get(riverDetail, 'countries[0]')
                )}`
              )
            }
          />
        )}
        {auth && (
          <AvatarButton
            Icon={(auth.favoriteRivers || []).includes(river) ? StarIcon : StarBorderIcon}
            color={theme.palette.primary.main}
            onClick={async () => {
              const method = (auth.favoriteRivers || []).includes(river)
                ? 'remove-favorite-river'
                : 'add-favorite-river';
              const favoriteRivers = await fetchApi.get(`account-info/${method}`, { accountId: auth.accountId, river });
              updateFavoriteRivers(favoriteRivers);
            }}
          />
        )}
      </AvatarBar>

      <Grid container>
        <RiverSectionsWidget river={river} includeGrid />
        <TopDangersWidget additionalFilter={{ river }} includeGrid showSection />
        <TopEventsWidget
          additionalFilter={{ river }}
          link={`/river-event-groups/${encodeURIComponent(river)}`}
          includeGrid
          variant="river"
        />
        <GaugeListWidget filter={{ river, isActive: 'true' }} includeGrid title={t('Active gauges')} />
        {/* <PlacesWidget places={places} includeGrid /> */}
        {/* <SectionGaugesWidget
          gauges={gauges}
          waterLevel={waterLevel}
          waterLevelPercent={waterLevelPercent}
          includeGrid
        />
        <PlacesWidget places={places} includeGrid />
        <SectionInfoWidget {...detailSafe} includeGrid /> */}
      </Grid>
    </AppLayout>
  );
}

export default RiverDetailPage;
