import _ from 'lodash';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AuthProvider from '../providers/AuthProvider';
import TabControl from '../layout/TabControl';
import ListControl from '../layout/ListControl';
import PlaceListItem from './PlaceListItem';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseButtonDialogTitle from '../ui-elements/CloseButtonDialogTitle';
import useLocationParam from '../utility/useLocationParam';

function ChoosePlaceDialog({ classes, onClose, onChange, stayOpenOnSelect, open, isCheckedFunc, ...other }) {
  const { auth } = AuthProvider.useAuth();
  const locationParam = useLocationParam({ locationRequired: open });
  const sortParam = locationParam;

  function handleChoosePlace(placeId) {
    if (onChange && _.isString(placeId)) onChange(placeId);
    if (!stayOpenOnSelect) onClose();
  }

  function handleClose() {
    onClose();
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="choose-place-dialog-title" open={open} {...other}>
      <CloseButtonDialogTitle id="choose-place-dialog-title" onClose={onClose}>
        Choose place
      </CloseButtonDialogTitle>

      <TabControl>
        <TabControl.Page label="Public">
          {locationParam == null ? (
            'Getting current position'
          ) : (
            <ListControl
              showSearch
              url={`place-info/find?isPublished=true&${sortParam}`}
              component={PlaceListItem.Custom(place => handleChoosePlace(place.placeId), isCheckedFunc)}
            />
          )}
        </TabControl.Page>
        {auth && (
          <TabControl.Page label="My">
            <ListControl
              showSearch
              url={`place-info/find?accountId=${auth.accountId}&${sortParam}`}
              component={PlaceListItem.Custom(place => handleChoosePlace(place.placeId), isCheckedFunc)}
            />
          </TabControl.Page>
        )}
      </TabControl>
    </Dialog>
  );
}

export default withMobileDialog()(ChoosePlaceDialog);
