import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import qs from 'qs';
import useReactRouter from 'use-react-router';
import { Trans } from 'react-i18next';
import NameValueView from '../ui-elements/NameValueView';

import AuthProvider from '../providers/AuthProvider';
import fetchApi, { getStorageBase } from '../utility/fetchApi';
import PaperCard from '../ui-elements/PaperCard';
import EditorPage from '../ui-elements/EditorPage';
import getAccountInfoName from '../utility/getAccountInfoName';

import FormNumberSelector from '../forms/FormNumberSelector';
import FormTextField from '../forms/FormTextField';
import useT from '../utility/useT';
import useFormik from '../utility/useFormik';
import moment from 'moment';
import FormDate from '../forms/FormDate';
import FormTime from '../forms/FormTime';

function trainingToForm(training) {
  const res = { ...training };
  return res;
}

function formToTraining(training) {
  const res = { ...training };
  return res;
}

function HeaderInfo() {
  const { values } = useFormik();
  const t = useT();

  return (
    <PaperCard>
      <NameValueView name={t('Playspot')} value={values.playspotName} />
      <FormDate label={t('Training date')} name="startTm" disableFuture />

      {/* <Grid container>
        <Grid item xs={6}>
          <FormTime label={t('DurationOfTrip')} name="durationMs" />
        </Grid>
        <Grid item xs={6}>
          <FormTextField label={t('Eskimo rolls')} name="eskimoRollCount" type="number" />
        </Grid>

        <FormNumberSelector label={t('Eskimo rolls')} name={'eskimoRollCount'} />
      </Grid> */}
    </PaperCard>
  );
}

function EditTrainingPage() {
  const { match, location } = useReactRouter();
  // const { sectionId: paramsSectionId } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { createFromPlayspotId, trainingId: paramsTrainingId } = match.params;
  const { auth } = AuthProvider.useAuth();
  const t = useT();

  async function fetchTraining() {
    if (createFromPlayspotId) {
      const playspotObject = ((await fetchApi(
        `playspot-info/find?playspotId=${createFromPlayspotId}&joinChallenges=1`,
        { auth }
      )) || [])[0];
      return {
        geotag: location.geotag,
        accountId: auth.accountId,
        accountName: getAccountInfoName(auth),
        playspotId: createFromPlayspotId,
        playspotName: playspotObject.name,
        startTm: new Date().getTime(),
        playspotObject,
      };
    } else {
      if (paramsTrainingId) {
        const res = ((await fetchApi(
          `training-info/find?trainingId=${paramsTrainingId}&joinPlayspot=1&joinChallenges=1`,
          { auth }
        )) || [])[0];
        return res;
      } else {
        return {
          accountId: auth.accountId,
          accountName: getAccountInfoName(auth),
        };
      }
    }
  }

  return (
    <EditorPage
      idName="trainingId"
      localStorageName="EditingTrainings"
      serviceName="training-info"
      objectTypeText={t('training')}
      fetchObject={fetchTraining}
      parentRoute="/trainings"
      storageToForm={trainingToForm}
      formToStorage={formToTraining}
      showDelete
      autoDiscard
    >
      <Grid container alignItems="stretch">
        <HeaderInfo />
        <PaperCard>
          <FormNumberSelector label={t('Eskimo rolls')} name={'eskimoRollCount'} />
          <FormNumberSelector
            label={t('DurationOfTraining')}
            name={'durationMs'}
            options={[
              { value: 30 * 60 * 1000, text: '30m' },
              { value: 60 * 60 * 1000, text: '1h' },
              { value: 90 * 60 * 1000, text: '1:30' },
              { value: 120 * 60 * 1000, text: '2h' },
              { value: 180 * 60 * 1000, text: '3h' },
            ]}
          />
        </PaperCard>
        <PaperCard>
          <FormTextField label={t('Description')} name="description" multiline />
        </PaperCard>
      </Grid>
    </EditorPage>
  );
}

export default EditTrainingPage;
