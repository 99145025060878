import React from 'react';
import _ from 'lodash';

import useFormik from '../utility/useFormik';
import NameValueView from '../ui-elements/NameValueView';
import FormTextField from './FormTextField';

export default function FormGaugeSelector({ name, label }) {
  const { values } = useFormik();
  const gauge = values[name];

  if (_.isPlainObject(gauge)) {
    return <NameValueView name={label} value={`${gauge.gauge} (${gauge.river})`} />;
  } else {
    return <FormTextField label={label} name={name} />;
  }
}
