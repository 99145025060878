import moment from 'moment';
import { waterLevelColors } from '../icons/WaterLevel';

export default function getSectionLines(gauge, chartLines) {
  if (!gauge) return;
  const limitItems = [];
  const { unit, lw, mw, hw } = gauge;
  if (lw) limitItems.push(`LW: ${lw} ${unit}`);
  if (mw) limitItems.push(`MW: ${mw} ${unit}`);
  if (hw) limitItems.push(`HW: ${hw} ${unit}`);

  if (lw) chartLines.push({ label: 'LW', color: waterLevelColors[1], unit, value: lw, isLimit: true });
  if (mw) chartLines.push({ label: 'MW', color: waterLevelColors[2], unit, value: mw, isLimit: true });
  if (hw) chartLines.push({ label: 'HW', color: waterLevelColors[3], unit, value: hw, isLimit: true });
}
