import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(2),
    // backgroundColor: theme.palette.background.paper,
  },
  message: {
    marginLeft: theme.spacing(2),
    fontSize: 14,
    position: 'relative',
    top: '-14px',
  },
}));

export default function LoadingInfo({ message }) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <CircularProgress />
      <span className={classes.message}>{message}</span>
    </div>
  );
}
