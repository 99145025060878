import { Chart } from 'react-chartjs-2';

Chart.pluginService.register({
  afterDraw: function(chart, easing) {
    if (chart.data.markerTm) {
      // const activePoint = chart.controller.tooltip._active[0];
      // console.log('chart.data', chart.data);
      // console.log('chart.scales', chart.scales);

      const { min, max, left, right } = chart.scales['x-axis-0'];

      for (const markerTm of chart.data.markerTm) {
        if (max > min) {
          const ctx = chart.ctx;
          const x = left + ((markerTm - min) / (max - min)) * (right - left);
          const topY = chart.scales['y-axis-0'].top;
          const bottomY = chart.scales['y-axis-0'].bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 2;
          // ctx.strokeStyle = '#e23fa9';
          ctx.strokeStyle = '#000';
          ctx.stroke();
          ctx.restore();
        }
      }
    }

    // console.log('CHART', chart);
    if (chart.tooltip._active && chart.tooltip._active.length && chart.config.type == 'line') {
      const activePoint = chart.controller.tooltip._active[0];
      const ctx = chart.ctx;
      const x = activePoint.tooltipPosition().x;
      const topY = chart.scales['y-axis-0'].top;
      const bottomY = chart.scales['y-axis-0'].bottom;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#e23fa9';
      ctx.stroke();
      ctx.restore();
    }
  },
});
