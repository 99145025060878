import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { isAndroid, isIOS } from 'react-device-detect';
import MapIcon from '@material-ui/icons/Map';
import ChooseFromListDialog from '../ui-elements/ChooseFromListDialog';
import useT from '../utility/useT';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: '20px',
  },
  section: {
    color: 'gray',
  },
  river: {
    fontWeight: 'bold',
  },
}));

export default function GpsDetailDialog({ dialogProps, coordinates, river, section, geotag }) {
  const t = useT();
  const classes = useStyles();
  if (!coordinates) return null;

  const menuItems = [
    {
      text: t('OpenGoogleMaps'),
      icon: DirectionsCarIcon,
      link: `http://maps.google.com/maps?daddr=${coordinates[1]},${coordinates[0]}`,
    },
  ];
  if (isAndroid) {
    menuItems.push({
      text: t('ChooseMap'),
      icon: MapIcon,
      link: `geo:${coordinates[1]},${coordinates[0]}`,
    });
  }
  if (isIOS) {
    menuItems.push({
      text: t('ChooseMap'),
      icon: MapIcon,
      link: `maps://maps.google.com/maps?daddr=${coordinates[1]},${coordinates[0]}`,
    });
  }

  const text = !!river && !!section && (
    <div>
      <div className={classes.river}>{river}</div>
      <div className={classes.section}>{section}</div>
    </div>
  );

  return (
    <ChooseFromListDialog items={menuItems} {...dialogProps} title={geotag} text={text}>
      <TextField
        value={`${Math.round(coordinates[1] * 1000000) / 1000000},${Math.round(coordinates[0] * 1000000) / 1000000}`}
        className={classes.textField}
      />
    </ChooseFromListDialog>
  );
}
