import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import useReactRouter from 'use-react-router';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import LoadingInfo from './LoadingInfo';

const useStyles = makeStyles(theme => ({
  root: {
    flex: '1',
    // maxWidth: 'calc(100vw - 10px)',
    maxWidth: '100vw',
    // margin: '5px',
  },
  title: {
    margin: '5px 0 0 0',
    // margin: '5px 5px 0px 5px',
    backgroundColor: '#ddd',
    // textAlign: 'center',
    // borderRadius: '5px 5px 0px 0px ',
    padding: '6px 0px 6px 6px',

    [theme.breakpoints.up('md')]: {
      borderLeft: '1px solid #ccc',
      borderRight: '1px solid #ccc',
      borderTop: '1px solid #ccc',
    },
  },
  titleText: {
    fontWeight: 'bold',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  content: {
    // margin: '0 0 5px 0',
    // margin: '0px 5px 5px 5px',
    // borderRadius: '0px 0px 5px 5px',
    // padding: '5px',
    // margin: '5px 0 5px 0',
    padding: '5px 0 5px 0',
    backgroundColor: 'white',
    // backgroundColor: 'yellow',

    // borderLeft: '1px solid #ccc',
    [theme.breakpoints.up('md')]: {
      borderLeft: '1px solid #ccc',
      borderRight: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
    },
  },
  linkHeader: {
    // color: theme.palette.primary.main,
    // color: 'gray',
    // color: 'rgba(0, 0, 0, 0.87)',
    // color: 'rgba(0.13, 0.13, 0.13, 1)',
    color: '#333',
    cursor: 'pointer',
  },
  headerLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function DashboardCard({
  title,
  children,
  link,
  includeGrid,
  clickOnWidget,
  preserveTitleCase,
  onReload,
  isLoading,
}) {
  const classes = useStyles();
  const { history } = useReactRouter();
  const body = (
    <div className={classes.root} onClick={clickOnWidget && link ? () => history.push(link) : undefined}>
      <div className={clsx(classes.title, classes.headerLine)}>
        <div
          className={clsx(classes.titleText, link && classes.linkHeader, !preserveTitleCase && classes.uppercase)}
          onClick={link ? () => history.push(link) : undefined}
          style={{ textDecoration: link ? 'underline' : 'none' }}
        >
          {title}
        </div>
        {onReload && (
          <div>
            <IconButton size="small" onClick={onReload}>
              <RefreshIcon />
            </IconButton>
          </div>
        )}
      </div>
      {isLoading ? <LoadingInfo /> : <div className={classes.content}>{children}</div>}
    </div>
  );

  if (includeGrid)
    return (
      <Grid item xs={12} md={6}>
        {body}
      </Grid>
    );
  return body;
}
