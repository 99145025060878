import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import useFormik from '../utility/useFormik';
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  stars: {
    // padding: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
    flex: '1',
    display: 'flex',
    // marginBottom: theme.spacing.unit,
    // paddingTop: theme.spacing.unit,
    // paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deleteButton: {
    alignSelf: 'center',
  },
  starsDiv: {
    alignSelf: 'center',
    display: 'flex',
  },
  mainFlex: {
    display: 'flex',
    margin: theme.spacing.unit,
  },
  circle: {
    margin: '2px',
  },
}));

function NumberCircle({ num, selected, onClick }) {
  const classes = useStyles();
  return (
    <Avatar style={{ backgroundColor: selected ? 'lime' : undefined }} className={classes.circle} onClick={onClick}>
      {num}
    </Avatar>
  );
}

function FormNumberSelector({ name, label, options }) {
  const { values, setFieldValue } = useFormik();
  const classes = useStyles();
  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <div className={classes.stars}>
        <div className={classes.starsDiv}>
          {options
            ? options.map(option => (
                <NumberCircle
                  num={option.text}
                  selected={values[name] == option.value}
                  onClick={() => setFieldValue(name, option.value)}
                />
              ))
            : _.range(1, 6).map(num => (
                <NumberCircle
                  num={num == 5 ? '5+' : `${num}x`}
                  selected={values[name] == num}
                  onClick={() => setFieldValue(name, num)}
                />
              ))}
        </div>
        {values[name] && (
          <IconButton
            aria-label="Delete"
            className={classes.deleteButton}
            disabled={!values[name]}
            onClick={() => setFieldValue(name, null)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    </FormGroup>
  );
}

export default FormNumberSelector;
