import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AuthProvider from '../providers/AuthProvider';
import { useSnackbar } from 'notistack';

import BoatIcon from '../icons/Boat';
import DifficultyIcon from '../icons/Difficulty';
import NameValueView from '../ui-elements/NameValueView';
import PaperCols from '../ui-elements/PaperCols';
import PaperRows from '../ui-elements/PaperRows';
import fetchApi from '../utility/fetchApi';
import processSaveResponse from '../utility/processSaveResponse';
import ImageBeers from '../icons/ImageBeers';
import { Trans } from 'react-i18next';

const styles = theme => ({
  avatar: {
    margin: theme.spacing.unit,
  },
  checkWrapper: {
    marginRight: '20px',
  },
  followsMe: {
    color: theme.palette.primary.main,
  },
  isFollowing: {
    color: theme.palette.secondary.main,
  },
});

function getLoginMethod(accountId) {
  if (accountId && accountId.startsWith('email:')) return 'E-mail';
  if (accountId && accountId.startsWith('google:')) return 'Google';
  return 'Unknown';
}

function UserProfile({
  accountId,
  boatType,
  boatColor,
  difficulty,
  classes,
  displayName,
  sportClub,
  nickname,
  boatModel,
  history,
  followedAccounts,
  beerCount,
  email,
  gradeNumber,
  myPoints,
  fullPoints,
}) {
  const { auth, setAuth } = AuthProvider.useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { accountId: authAccountId, followedAccounts: authFollowedAccounts } = auth || {};

  const isFollowed = (authFollowedAccounts || []).includes(accountId);
  const followsMe = (followedAccounts || []).includes(authAccountId);

  const saveFollowedAccounts = async followedAccounts => {
    const saveData = await fetchApi.post(
      `account-info/save`,
      { entity: { accountId: auth.accountId, followedAccounts } },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    processSaveResponse(saveData, enqueueSnackbar, 'Successfully saved follow state');
    if (saveData && saveData.authToken) {
      setAuth(saveData);
    }
  };

  const follow = async () => {
    const followedAccounts = auth.followedAccounts || [];
    await saveFollowedAccounts([...followedAccounts, accountId]);
  };

  const unfollow = async () => {
    const followedAccounts = auth.followedAccounts || [];
    await saveFollowedAccounts(followedAccounts.filter(x => x !== accountId));
  };

  return (
    <>
      <PaperCols>
        <div className={classes.avatar}>
          <Avatar style={{ backgroundColor: boatColor }}>
            <BoatIcon size={25} boatType={boatType} />
          </Avatar>
        </div>
        <div className={classes.avatar}>
          <DifficultyIcon difficulty={difficulty} />
        </div>
      </PaperCols>
      <PaperRows>
        <NameValueView t_name="Full name" value={displayName} />
        {accountId === authAccountId && <NameValueView t_name="E-mail" value={email} />}
        {accountId === authAccountId && <NameValueView t_name="Login method" value={getLoginMethod(accountId)} />}
        <NameValueView t_name="Boat model" value={boatModel} />
        <NameValueView t_name="Sport club" value={sportClub} />
        <NameValueView t_name="Nickname" value={nickname} />
        {beerCount && <NameValueView t_name="Default party level" value={<ImageBeers size={25} beers={beerCount} />} />}
      </PaperRows>
      <PaperCols>
        <div className={classes.checkWrapper}>
          {followsMe && (
            <div>
              <span className={classes.followsMe}>&#10004;</span> <Trans>Follows me</Trans>
            </div>
          )}
          {isFollowed && (
            <div>
              <span className={classes.isFollowing}>&#10004;</span> <Trans>I am following</Trans>
            </div>
          )}
        </div>
        {auth && auth.accountId === accountId && (
          <>
            <Button onClick={() => history.push('/profile/edit')} color="primary" variant="contained">
              <Trans>Edit my profile</Trans>
            </Button>
            <Button onClick={() => history.push('/profile/notifications')} color="primary" variant="contained">
              <Trans>Notifications</Trans>
            </Button>
          </>
        )}
        {auth && auth.accountId !== accountId && isFollowed && (
          <Button onClick={unfollow} color="primary" variant="contained">
            <Trans>Unfollow</Trans>
          </Button>
        )}
        {auth && auth.accountId !== accountId && !isFollowed && (
          <Button onClick={follow} color="primary" variant="contained">
            <Trans>Follow</Trans>
          </Button>
        )}
      </PaperCols>
    </>
  );
}

export default compose(
  withRouter,
  withStyles(styles)
)(UserProfile);
