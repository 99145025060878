import React from 'react';
import { Link } from 'react-router-dom';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';

import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import EditIcon from '@material-ui/icons/Edit';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import PublicIcon from '@material-ui/icons/Public';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import InfoIcon from '@material-ui/icons/Info';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import TerrainIcon from '@material-ui/icons/Terrain';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SatelliteIcon from '@material-ui/icons/Satellite';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CupIcon from '../icons/Cup';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

import AuthProvider from '../providers/AuthProvider';
import useRouterDialog from '../utility/useRouterDialog';
import useT from '../utility/useT';

function RouteMenuItem({ title, to, icon: Icon }) {
  return (
    <MenuItem component={Link} to={to}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </MenuItem>
  );
}

function ActionMenuItem({ title, action, icon: Icon }) {
  return (
    <MenuItem component={Link} onClick={action}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </MenuItem>
  );
}

function MainMenu({ openLogin, openSectionDialog }) {
  const t = useT();
  const { auth, logout } = AuthProvider.useAuth();
  // const [openSectionDialog, sectionDialogProps] = useRouterDialog('choose-section-main-menu');

  return (
    <div>
      <List>
        <RouteMenuItem to={auth ? '/home' : '/'} icon={HomeIcon} title={t('Home')} />
        {auth && <RouteMenuItem to="/dashboard" icon={DashboardIcon} title={t('Dashboard')} />}

        {auth && <RouteMenuItem to="/events" icon={EditIcon} title={t('My runs')} />}
        <RouteMenuItem to="/event-groups" icon={EditIcon} title={t('All runs')} />
        <RouteMenuItem to="/trainings" icon={DirectionsRunIcon} title={t('Trainings')} />
        {auth && <ActionMenuItem action={openSectionDialog} icon={AddLocationIcon} title={t('Log river run')} />}
        {auth && <RouteMenuItem to="/trips" icon={TerrainIcon} title={t('Trips')} />}
        {auth && <RouteMenuItem to="/notifications" icon={NotificationsIcon} title={t('Notifications')} />}
        {/* <RouteMenuItem
              to="/notifications"
              icon={() => (
                <Badge badgeContent={4} color="primary">
                  <NotificationsIcon />
                </Badge>
              )}
              title={t("Notifications")}
            /> */}

        <Divider />

        <RouteMenuItem to="/sections" icon={SubdirectoryArrowRightIcon} title={t('Sections')} />
        <RouteMenuItem to="/gauges" icon={ShowChartIcon} title={t('Gauges')} />
        <RouteMenuItem to="/my-gauges" icon={ShowChartIcon} title={t('My gauges')} />
        <RouteMenuItem to="/rivers" icon={DirectionsBoatIcon} title={t('Rivers')} />
        <RouteMenuItem to="/playspots" icon={DirectionsRunIcon} title={t('Playspots')} />

        <Divider />

        {!auth && <ActionMenuItem action={openLogin} icon={PersonIcon} title={t('Sign in')} />}
        {auth && <RouteMenuItem to="/my-profile" icon={ContactMailIcon} title={t('My profile')} />}
        <RouteMenuItem to="/people" icon={PeopleIcon} title={t('People')} />

        {/* {auth && <RouteMenuItem to="/my-locations" icon={MyLocationIcon} title={t('GPS log')} />} */}
        {auth && <RouteMenuItem to="/places" icon={LocationOnIcon} title={t('Places')} />}
        {/* {auth && <RouteMenuItem to="/paddlemeter" icon={QueryBuilderIcon} title={'Experimental - paddlemeter'} />} */}
        {auth && <ActionMenuItem action={logout} icon={PersonOutlineIcon} title={t('Sign out')} />}

        {/* {auth && <RouteMenuItem to="/all-places" icon={SatelliteIcon} title={t("Public places")} />} */}
        {/* <Divider />
      <RouteMenuItem to="/about" icon={InfoIcon} title={t("About")} /> */}
      </List>
    </div>
  );
}

export default MainMenu;
