import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useStorage from '../utility/useStorage';
import moment from 'moment';
import useGaugeData, { MS_PER_DAY } from './useGaugeData';
import { Trans } from 'react-i18next';
import useT from '../utility/useT';
import ErrorInfo from '../ui-elements/ErrorInfo';
import { makeStyles } from '@material-ui/core/styles';
import { Line } from 'react-chartjs-2';
import TimeIntervalDialog from '../ui-elements/TimeIntervalDialog';
import useStateDialog from '../utility/useStateDialog';
import './ChartPlugins';

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  river: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    margin: '0 5px 0 5px',
  },
  gauge: {
    fontFamily: theme.typography.fontFamily,
  },
  source: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'italic',
    margin: '0 5px 0 5px',
  },
  myGaugeInfo: {
    margin: '0 5px 0 5px',
  },
  timeLine: {
    margin: '0 5px 0 5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerDiv: {
    margin: '5px',
  },
  myGaugesInfo: {
    fontFamily: theme.typography.fontFamily,
  },
}));

function GaugeChart({
  river,
  engine,
  gauge,
  lines,
  additionalButtons = null,
  additionalSettingsKey = '',
  fromTm,
  toTm,
  markerTm,
}) {
  const classes = useStyles();
  const t = useT();

  const [openIntervalDialog, intervalDialogProps] = useStateDialog();

  let prefferedUnit = 'm3/s';
  if ((lines || []).find(x => x.unit == 'cm' && x.isLimit)) prefferedUnit = 'cm';

  const [gaugeSettings, setGaugeSettings] = useStorage(
    `gaugeSettings_${river}_${gauge}_${engine}_${additionalSettingsKey}`,
    localStorage,
    {
      displayedPeriod: 1,
      displayedUnit: prefferedUnit,
    },
    new Date().getTime() + 5 * MS_PER_DAY
  );

  const { displayedPeriod } = gaugeSettings;
  let { displayedUnit } = gaugeSettings;

  let fromDate, toDate;
  if (fromTm && toTm) {
    fromDate = new Date(fromTm);
    toDate = new Date(toTm);
  } else if (_.isNumber(displayedPeriod)) {
    toDate = moment()
      .endOf('hour')
      .toDate();
    fromDate = new Date(toDate.getTime() - MS_PER_DAY * displayedPeriod);
  } else {
    fromDate = new Date(displayedPeriod.startTm);
    toDate = new Date(displayedPeriod.endTm);
  }

  const gaugeData = useGaugeData(river, gauge, engine, fromDate, toDate);

  const availableHeight = _.isArray(gaugeData) && !!gaugeData.find(x => x.heightCentimeters > 0);
  const availableFlow = _.isArray(gaugeData) && !!gaugeData.find(x => x.flowCumecs > 0);

  if (!availableFlow) displayedUnit = 'cm';
  if (!availableHeight) displayedUnit = 'm3/s';

  const measuredTimes = _.isArray(gaugeData) ? gaugeData.map(x => x.measuredTm) : [];
  const minTm = _.min(measuredTimes);
  const maxTm = _.max(measuredTimes);

  const chartData = _.isArray(gaugeData)
    ? {
        markerTm,
        labels: gaugeData.map(x => new Date(x.measuredTm)),
        datasets: [
          {
            label: displayedUnit,
            data: gaugeData.map(x => ({
              t: new Date(x.measuredTm),
              y: displayedUnit === 'cm' ? x.heightCentimeters : x.flowCumecs,
            })),
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255,99,132,1)',
            ],
            borderWidth: 1,
          },
          ...(lines || [])
            .filter(line => line.unit == displayedUnit)
            .map(line => ({
              label: line.label,
              borderColor: line.color,
              backgroundColor: 'transparent',
              data: [{ t: new Date(minTm), y: line.value }, { t: new Date(maxTm), y: line.value }],
            })),
        ],
      }
    : null;

  const periodPopupState = usePopupState({ variant: 'popover', popupId: 'periodPopup' });
  const unitPopupState = usePopupState({ variant: 'popover', popupId: 'unitPopup' });

  const displayedPeriodText = _.isNumber(displayedPeriod)
    ? {
        1: t('24 hours'),
        3: t('3 days'),
        7: t('7 days'),
      }[displayedPeriod]
    : `${moment(displayedPeriod.startTm).format('D. M.')}-${moment(displayedPeriod.endTm).format('D. M.')}`;

  const setDisplayedPeriod = period => () => {
    setGaugeSettings({ ...gaugeSettings, displayedPeriod: period });
    periodPopupState.close();
  };

  const setDisplayedUnit = unit => () => {
    setGaugeSettings({ ...gaugeSettings, displayedUnit: unit });
    unitPopupState.close();
  };

  return (
    <>
      {gaugeData.length >= 2 ? (
        <Line
          data={chartData}
          options={{
            scales: {
              xAxes: [
                {
                  type: 'time',
                  distribution: 'linear',

                  time: {
                    tooltipFormat:'D. M. YYYY HH:mm',
                    displayFormats: {
                      millisecond: 'HH:mm:ss.SSS',
                      second: 'HH:mm:ss',
                      minute: 'HH:mm',
                      hour: 'D.M hA',
                      day: 'D. M.',
                      week: 'D. M. YYYY',
                      month: 'MM-YYYY',
                      quarter: '[Q]Q - YYYY',
                      year: 'YYYY',
                    },
                  },
                },
              ],
            },
            elements: {
              point: {
                radius: 0,
              },
            },
            tooltips: {
              mode: 'index',
              intersect: false,
            },
          }}
        />
      ) : (
        <ErrorInfo message={t('No chart data available')} />
      )}

      {(!fromTm || !toTm) && (
        <>
          <Button variant="contained" {...bindTrigger(periodPopupState)} className={classes.button}>
            {displayedPeriodText}
          </Button>
          <Menu {...bindMenu(periodPopupState)}>
            <MenuItem onClick={setDisplayedPeriod(1)}>
              <Trans>24 hours</Trans>
            </MenuItem>
            <MenuItem onClick={setDisplayedPeriod(3)}>
              <Trans>3 days</Trans>
            </MenuItem>
            <MenuItem onClick={setDisplayedPeriod(7)}>
              <Trans>7 days</Trans>
            </MenuItem>
            <MenuItem
              onClick={() => {
                periodPopupState.close();
                openIntervalDialog();
              }}
            >
              <Trans>Custom period</Trans>
            </MenuItem>
          </Menu>
        </>
      )}

      <Button variant="contained" {...bindTrigger(unitPopupState)} className={classes.button}>
        {displayedUnit}
      </Button>
      <Menu {...bindMenu(unitPopupState)}>
        <MenuItem onClick={setDisplayedUnit('m3/s')}>m3/s</MenuItem>
        <MenuItem onClick={setDisplayedUnit('cm')}>cm</MenuItem>
      </Menu>

      {intervalDialogProps.open && (
        <TimeIntervalDialog
          {...intervalDialogProps}
          startTm={fromDate.getTime()}
          endTm={toDate.getTime()}
          maxLength={MS_PER_DAY * 30}
          minLength={MS_PER_DAY / 2}
          onSelect={(startTm, endTm) => {
            setGaugeSettings({ ...gaugeSettings, displayedPeriod: { startTm, endTm } });
          }}
        />
      )}

      {additionalButtons}
    </>
  );
}

export default GaugeChart;
