import React from 'react';

const RefreshTimeContext = React.createContext();

export function RefreshTimeProvider({ children }) {
  const [time, setTime] = React.useState(new Date().getTime());
  return (
    <RefreshTimeContext.Provider value={[time, () => setTime(() => new Date().getTime())]}>
      {children}
    </RefreshTimeContext.Provider>
  );
}

export default function useRefreshTime() {
  return React.useContext(RefreshTimeContext);
}
