import React from 'react';
import _ from 'lodash';
import ChooseFromListDialog from '../ui-elements/ChooseFromListDialog';
import useT from '../utility/useT';
import useFetchApi from '../utility/useFetchApi';
import LocationOnIcon from '@material-ui/icons/LocationOn';

export default function ChoosePlayspotDialog({ error, onChoose, ...other }) {
  const playspots = useFetchApi('playspot-info/find?isActive=true');
  const t = useT();

  const items = playspots.map(playspot => ({
    text: playspot.name,
    onClick: () => onChoose(playspot),
    icon: LocationOnIcon,
  }));
  return <ChooseFromListDialog t_title="Choose playspot" text={t('YouAreNotAtPlayspot')} items={items} {...other} />;
}
