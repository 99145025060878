import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment, { duration } from 'moment';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import ImageStars from '../icons/ImageStars';
import PaperRows from '../ui-elements/PaperRows';
import ImageBeers from '../icons/ImageBeers';
import NameValueView from '../ui-elements/NameValueView';
import WaterLevel, { waterLevelNames } from '../icons/WaterLevel';
import PaperCard from '../ui-elements/PaperCard';
import TeamMembersView from '../people/TeamMembersView';
import Flag from '../icons/Flag';
import SmallPaddlerAvatar from '../people/SmallPaddlerAvatar';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import EmojiChooser from '../ui-elements/EmojiChooser';
import EventHeader from './EventHeader';
import DashboardCard from '../ui-elements/DashboardCard';
import ContentPadding from '../ui-elements/ContentPadding';
import { getGaugeValueText } from '../gauges/GaugeView';
import GaugeChart from '../gauges/GaugeChart';
import Difficulty from '../icons/Difficulty';
import getSectionLines from '../utility/getSectionLines';

const styles = theme => ({
  flexLine: {
    display: 'flex',
  },
  dangerInfo: {
    fontWeight: 'bold',
    color: 'red',
  },
  avatar: {
    marginRight: '5px',
  },
  grower: {
    flexGrow: 1,
  },
  difficulty: {
    marginLeft: '5px',
  },

  // river: {
  //   flexGrow: 1,
  // },
  // section:{
  //   flexGrow: 1,

  // }
});

function getChartLines(gauge, sectionGauges, t) {
  const sectionGauge = (sectionGauges || []).find(x => x.gauge == gauge.gauge);

  const {
    flowCumecs,
    flowCumecsMin,
    flowCumecsMax,
    heightCentimeters,
    heightCentimetersMin,
    heightCentimetersMax,
  } = gauge;

  const chartLines = [];

  getSectionLines(sectionGauge, chartLines);

  if (flowCumecsMin && flowCumecsMax) {
    chartLines.push({ label: 'min', color: '#000', unit: 'm3/s', value: flowCumecsMin });
    chartLines.push({ label: 'max', color: '#000', unit: 'm3/s', value: flowCumecsMax });
  } else if (flowCumecs) {
    chartLines.push({ label: t('run'), color: '#000', unit: 'm3/s', value: flowCumecs });
  }

  if (heightCentimetersMin && heightCentimetersMax) {
    chartLines.push({ label: 'min', color: '#000', unit: 'cm', value: heightCentimetersMin });
    chartLines.push({ label: 'max', color: '#000', unit: 'cm', value: heightCentimetersMax });
  } else if (heightCentimeters) {
    chartLines.push({ label: t('run'), color: '#000', unit: 'cm', value: heightCentimeters });
  }

  return chartLines;
}

function EventDetailView({
  classes,
  river,
  enjoyLevel,
  section,
  sectionId,
  difficulty,
  description,
  waterLevel,
  accountName,
  accountId,
  beerCount,
  gauges,
  dateText,
  team,
  dangerInfo,
  privateDescription,
  descriptions,
  runCount,
  swimCount,
  eskimoRollCount,
  lengthInKm,
  durationMs,
  boatModel,
  country,
  accountAvatar,
  diaryInfos,
  trips,
  dangers,
  fromTm,
  toTm,
  markerTm,
  sectionGauges,
  emotions,
}) {
  const t = useT();
  // const teamMembers = (team || []).map(x => x.displayName).join('; ');
  return (
    <>
      <EventHeader
        river={river}
        section={section}
        enjoyLevel={enjoyLevel}
        sectionId={sectionId}
        difficulty={difficulty}
        country={country}
        waterLevel={waterLevel}
      />

      <Grid container alignItems="stretch">
        {(gauges || []).map(gauge => (
          <DashboardCard title={gauge.gauge} includeGrid>
            <ContentPadding>
              <Typography component="div">{getGaugeValueText(gauge)} </Typography>
            </ContentPadding>
            <GaugeChart
              river={gauge.river}
              engine={gauge.gaugeEngine}
              gauge={gauge.gauge}
              fromTm={fromTm}
              toTm={toTm}
              markerTm={markerTm}
              lines={getChartLines(gauge, sectionGauges, t)}
            />
          </DashboardCard>
        ))}
        {(team || []).length > 0 && (
          <DashboardCard title={t('Team members')} includeGrid>
            <TeamMembersView team={team} />
          </DashboardCard>
        )}
        {diaryInfos && diaryInfos.length > 0 && (
          <DashboardCard title={t('Paddlers')} includeGrid>
            <ContentPadding>
              {diaryInfos.map(item => (
                <Typography component="div" className={classes.flexLine}>
                  <SmallPaddlerAvatar className={classes.avatar} {...item.accountAvatar} />
                  <Link component={RouterLink} to={`/people/detail/${item.accountId}`} className={classes.link1}>
                    {item.accountName}
                  </Link>{' '}
                  <Difficulty difficulty={item.difficulty} isTiny className={classes.difficulty} />
                  <ImageBeers size={20} beers={item.beerCount} />
                  <div className={classes.grower} />
                  <div>
                    <Link component={RouterLink} to={`/events/detail/${item.diaryId}`} className={classes.link1}>
                      {moment(item.startTm).format('HH:mm')}
                    </Link>
                  </div>
                </Typography>
              ))}
            </ContentPadding>
          </DashboardCard>
        )}
        {(description || dangerInfo || privateDescription || (descriptions && descriptions.length > 0)) && (
          <DashboardCard title={t('Description')} includeGrid>
            <NameValueView value={description} />
            <NameValueView name={t('Private description')} value={privateDescription} />
            {(descriptions || []).map(item => (
              <NameValueView name={item.accountName} value={item.description} />
            ))}
          </DashboardCard>
        )}
        {(dangerInfo || (dangers && dangers.length > 0)) && (
          <DashboardCard title={t('Danger info')} includeGrid>
            <NameValueView value={dangerInfo} className={classes.dangerInfo} />

            {(dangers || []).map(x => (
              <NameValueView value={x} className={classes.dangerInfo} />
            ))}
          </DashboardCard>
        )}
        {(runCount || eskimoRollCount || swimCount || durationMs || lengthInKm) && (
          <DashboardCard title={t('Statistics')} includeGrid>
            <NameValueView name={t('LengthInKm')} value={lengthInKm} />
            <NameValueView
              name={t('DurationOfTrip')}
              value={
                durationMs
                  ? moment(
                      moment()
                        .startOf('day')
                        .valueOf() + durationMs
                    ).format('HH:mm')
                  : null
              }
            />
            <NameValueView name={t('Runs')} value={runCount} />
            <NameValueView name={t('Eskimo rolls')} value={eskimoRollCount} />
            <NameValueView name={t('Swims')} value={swimCount} />
          </DashboardCard>
        )}
        <DashboardCard title={t('Info')} includeGrid>
          {(trips || []).map(trip => (
            <NameValueView
              name={t('Trip')}
              value={
                <Link component={RouterLink} to={`/trips/detail/${trip.tripId}`}>
                  {trip.tripName}
                </Link>
              }
            />
          ))}
          {country && (
            <NameValueView
              name={t('Country')}
              value={
                <span>
                  {country} <Flag name={country} size={20} />
                </span>
              }
            />
          )}
          {accountAvatar && (
            <NameValueView
              name={t('Paddler')}
              value={
                <div className={classes.flexLine}>
                  <Link component={RouterLink} to={`/people/detail/${accountId}`} className={classes.link1}>
                    {accountName}
                  </Link>{' '}
                  <SmallPaddlerAvatar className={classes.avatar} {...accountAvatar} />
                </div>
              }
            />
          )}
          <NameValueView name={t('Difficulty')} value={difficulty} />
          <NameValueView name={t('Date')} value={dateText} />
          <NameValueView name={t('Water level')} value={waterLevelNames[waterLevel]} />
          <NameValueView name={t('Boat model')} value={boatModel} />
          {beerCount && <NameValueView name={t('Party level')} value={<ImageBeers size={20} beers={beerCount} />} />}
        </DashboardCard>

        {/* {gauges && gauges.length > 0 && (
          <DashboardCard title={t('Gauges')}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Trans>Gauge</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Flow</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Height</Trans>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(gauges || []).map(({ gauge, heightCentimeters, flowCumecs }) => (
                  <TableRow>
                    <TableCell>{gauge}</TableCell>
                    <TableCell>{flowCumecs && `${flowCumecs} m3/s`}</TableCell>
                    <TableCell>{heightCentimeters && `${heightCentimeters} cm`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DashboardCard>
        )} */}
      </Grid>
    </>
  );
}

export default withStyles(styles)(EventDetailView);
