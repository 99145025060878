import React from 'react';
import useFetchApi from '../utility/useFetchApi';
import moment from 'moment';
import _ from 'lodash';
import DifficultyIcon from '../icons/Difficulty';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import DashboardCard from '../ui-elements/DashboardCard';
import useT from '../utility/useT';
import { getWaterLevelColor, waterLevelTwoLetterNames } from '../icons/WaterLevel';
import SmallPaddlerAvatar from '../people/SmallPaddlerAvatar';
import useReactRouter from 'use-react-router';
import AuthProvider from '../providers/AuthProvider';
import useStorage from '../utility/useStorage';

const useStyles = makeStyles(theme => ({
  smallAvatar: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
  root: {
    margin: '10px',
    cursor: 'pointer',
  },
  // container: {
  //   flex: 1,
  //   marginLeft: '5px',
  //   marginRight: '5px',
  //   overflow: 'hidden',
  //   alignSelf: 'center',
  // },
  // eventLine1: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  // },
  eventLine2: {
    display: 'flex',
    marginLeft: '25px',
    // justifyContent: 'flex-end',
  },
  // eventLine2Section: {
  //   display: 'flex',
  //   // marginLeft: '5px',
  //   // justifyContent: 'flex-end',
  // },
  flexLine: {
    display: 'flex',
  },
  accountLine: {
    display: 'flex',
    whitespace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  grower: {
    flexGrow: '1',
  },
  gaugeInfo: {
    fontWeight: 'bold',
  },
  river: {
    fontWeight: 'bold',
    // marginLeft: '5px',
    marginRight: '5px',
    flexShrink: 0,

    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  section: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexShrink: 1,
  },
  // sectionMargin: {
  //   textOverflow: 'ellipsis',
  //   whiteSpace: 'nowrap',
  //   overflow: 'hidden',
  //   flexShrink: 1,
  //   marginLeft: '5px',
  // },
  date: {
    whiteSpace: 'nowrap',
    marginLeft: '5px',
  },
  title: {
    fontWeight: 'bold',
    backgroundColor: '#ddd',
  },
  waterLevel: {
    marginLeft: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderRadius: '5px',
  },
  account: {
    // fontStyle: 'italic',
    color: 'gray',
    marginRight: 5,
    whiteSpace: 'nowrap',
  },
  accountNoMargin: {
    color: 'gray',
    whiteSpace: 'nowrap',
  },
  avatar: {
    marginRight: 5,
  },
  chips: {
    marginLeft: 15,
  },
}));

function AccountItem({ item }) {
  const classes = useStyles();

  return (
    <div className={classes.accountLine}>
      {item.accountAvatar && <SmallPaddlerAvatar className={classes.avatar} {...item.accountAvatar} />}
      <div className={classes.account}>{item.accountName}</div>
    </div>
  );
}

function AccountList({ items }) {
  const classes = useStyles();
  const { auth } = AuthProvider.useAuth();
  const t = useT();

  const newItemCount = items.length <= 2 ? items.length : 1;
  const newItems = items.filter(x => auth && x.accountId == auth.accountId);
  while (newItems.length < newItemCount) {
    newItems.push(items.find(x => !newItems.find(y => x.accountId == y.accountId)));
  }
  return (
    <>
      {newItems.map(item => (
        <AccountItem item={item} key={item.accountId} />
      ))}
      {items.length > 2 && (
        <>
          <Avatar className={classes.smallAvatar}>+</Avatar>
          <div className={classes.account}>{t('{{count}} paddlers', { count: items.length - newItems.length })}</div>
        </>
      )}
    </>
  );
}

function EventItem({ event, variant }) {
  const classes = useStyles();
  const { history } = useReactRouter();
  const { waterLevel } = event;

  const primaryGauges = _.compact(event.items.map(x => (x.gauges || [])[0]));
  const heights = _.compact(_.uniq(primaryGauges.map(x => x.heightCentimeters)).sort());
  const flows = _.compact(_.uniq(primaryGauges.map(x => x.flowCumecs)).sort());
  const heightCentimeters = heights.length <= 1 ? heights[0] : `${heights[0]}-${heights[heights.length - 1]}`;
  const flowCumecs = flows.length <= 1 ? flows[0] : `${flows[0]}-${flows[flows.length - 1]}`;
  // return {
  //   gauge,
  //   heightCentimeters,
  //   flowCumecs,
  // };
  const gaugeInfo = [];
  if (heightCentimeters) gaugeInfo.push(`${heightCentimeters} cm`);
  if (flowCumecs) gaugeInfo.push(`${flowCumecs} m3/s`);

  return (
    <div
      className={classes.root}
      onClick={() =>
        history.push(`/event-group/${moment(event.items[0].startTm).format('YYYY-MM-DD')}/${event.sectionId}`)
      }
    >
      <div className={classes.flexLine}>
        <AccountList items={_.uniqBy(event.items, 'accountId')} />
        <div className={classes.grower}> </div>
        <div className={classes.date}>{moment(event.items[0].startTm).format('D. M. YYYY')}</div>
      </div>
      {variant == 'section' && (
        <div className={classes.eventLine2}>
          <div className={classes.gaugeInfo}>{gaugeInfo.join(', ')}</div>
          {waterLevel && (
            <div className={classes.waterLevel} style={{ backgroundColor: getWaterLevelColor(waterLevel) }}>
              {waterLevelTwoLetterNames[waterLevel || 0]}
            </div>
          )}
        </div>
      )}
      {/* <>
          <div className={classes.flexLine}>
            <AccountList items={_.uniqBy(event.items, 'accountId')} />
            <div className={classes.grower}> </div>
            <div className={classes.date}>{moment(event.items[0].startTm).format('D. M.')}</div>
          </div>

          {/* <DifficultyIcon isSmall difficulty={event.difficulty} /> */}
      {variant != 'section' && (
        <div className={classes.eventLine2}>
          <>
            {variant != 'river' && <div className={classes.river}>{event.river}</div>}
            <div className={classes.section}>{event.section}</div>
          </>
          {waterLevel && (
            <div className={classes.waterLevel} style={{ backgroundColor: getWaterLevelColor(waterLevel) }}>
              {waterLevelTwoLetterNames[waterLevel || 0]}
            </div>
          )}
        </div>
      )}
      {/* <div className={classes.flexLine}>
              {_.uniqBy(event.items, 'accountId').map((item, index) => (
                <div className={classes.accountLine}>
                  {index > 0 ? <div className={classes.account}>,</div> : null}
                  <div className={classes.accountNoMargin}>{item.accountName}</div>
                </div>
              ))}
            </div> */}
    </div>
  );
}

export default function TopEventsWidget({ additionalFilter, link, includeGrid, variant }) {
  const classes = useStyles();
  const t = useT();
  const [filterMy, setFilterMy] = useStorage('DashboardFilterMyRuns', localStorage, false);
  const { auth } = AuthProvider.useAuth();
  const diaryInfos = useFetchApi.get(
    'diary-info/group',
    {
      by: 'sectionDay',
      limit: 5,
      joinAccountAvatar: 1,
      accountId: auth && filterMy ? auth.accountId : undefined,
      resetOnParamsChange: true,
      ...additionalFilter,
    },
    null
  );

  return (
    <DashboardCard
      title={t('Last runs')}
      link={link || '/event-groups'}
      includeGrid={includeGrid}
      isLoading={diaryInfos == null}
    >
      {auth && (
        <div className={classes.chips}>
          <Chip label={t('All runs')} color={filterMy ? undefined : 'primary'} onClick={() => setFilterMy(false)} />
          <Chip label={t('My runs')} color={filterMy ? 'primary' : undefined} onClick={() => setFilterMy(true)} />
        </div>
      )}
      {(diaryInfos || []).length == 0 && <div>{t('No runs yet')}</div>}
      {(diaryInfos || []).map(event => (
        <EventItem event={event} key={event.diaryId} variant={variant} />
      ))}
    </DashboardCard>
  );
}
