import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PaperRows from '../ui-elements/PaperRows';

const useStyles = makeStyles(theme => ({
  flexLine: {
    display: 'flex',
  },
}));

export default function PaperCard({ children }) {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6} lg={4} className={classes.flexLine}>
      <PaperRows>{children}</PaperRows>
    </Grid>
  );
}
