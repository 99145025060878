import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import AppLayout from '../layout/AppLayout';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import GaugeView from './GaugeView';

const styles = theme => ({
  root: {},
  button: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  river: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
  },
  gauge: {
    fontFamily: theme.typography.fontFamily,
  },
  source: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'italic',
  },
  headerDiv: {
    margin: '5px',
  },
});

function GaugeDetailPage({ match, classes, history }) {
  const { river, engine, gauge } = match.params;

  return (
    <AppLayout
      t_title="Gauge"
      mainIconMeaning="back"
      parentRoute={'/gauges'}
      showRefreshButton
      customButtons={
        <>
          {/* <Button type="submit" color="inherit" onClick={() => history.push(`/sections/edit/${sectionId}`)}>
            Edit
          </Button> */}
          {/* <Button color="inherit" onClick={() => formEl.current.onSubmit()}>
          Save
        </Button> */}
        </>
      }
    >
      <GaugeView river={river} engine={engine} gauge={gauge} showMyGaugesInfo />
    </AppLayout>
  );
}

export default compose(
  withRouter,
  withStyles(styles)
)(GaugeDetailPage);
