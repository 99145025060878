import React from 'react';
import moment from 'moment';
import { TimePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import useFormik from '../utility/useFormik';
import { makeStyles } from '@material-ui/core/styles';
import useT from '../utility/useT';

const useStyles = makeStyles(theme => ({
  field: {
    // margin: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
}));

function FormTime({ name, label, t_label, ...otherProps }) {
  const classes = useStyles();
  // return (
  //   <TextField name={name} {...otherProps} />
  // );
  const t = useT();

  const { handleChange, handleBlur, values, setFieldValue } = useFormik();

  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{t_label ? t(t_label) : label}</InputLabel>
      <TimePicker
        clearable
        ampm={false}
        minutesStep={5} 
        onChange={val =>
          setFieldValue(
            name,
            val == null
              ? null
              : moment(val.toDate()).valueOf() -
                  moment(val.toDate())
                    .startOf('day')
                    .valueOf()
          )
        }
        onBlur={handleBlur}
        className={classes.field}
        value={
          values[name] == null
            ? null
            : new Date(
                moment()
                  .startOf('day')
                  .valueOf() + (values[name] || 0)
              )
        }
        name={name}
        {...otherProps}
      />
    </FormGroup>
  );

  // return <TextField label="Section" fullwidth />;
  // return <input type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} name="name" />;
}

export default FormTime;
