import React from "react";

export default function River({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 1000 1000"
      enable-background="new 0 0 1000 1000"
    >
      <g>
        <path d="M710,898.7c0,0-252.6-20.5-198.5-149.3C550.7,655.9,864.1,576.5,737,511c-110.5-57-398.9-156.8-332-214.2c48.9-42,107.5-50.1,95-91.2c-14.9-49.4-85.2-61.3-85.2-61.3l-32.2,16.1c0,0,75.6,34.9,75.2,48.4c-0.5,19.9-156.4,98.3-161.1,129.1C281,440.3,640.8,520.2,618.9,567.5c-22,47.2-360.7,99.8-284.5,335.3c1.7,5.3,4.3,9.5,6.4,14.3C148.5,859.1,10,699.8,10,511.8C10,275,229.4,83,500,83c270.6,0,490,192,490,428.8C990,682.7,875.4,829.8,710,898.7z" />
      </g>
    </svg>
  );
}
