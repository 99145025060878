import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import WarningIcon from '@material-ui/icons/Warning';
import PoolIcon from '@material-ui/icons/Pool';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import TreeIcon from '../icons/Tree';

const geoTags = [
  {
    text: 'Other',
    icon: LocationOnIcon,
  },
  {
    text: 'Put In',
    icon: SubdirectoryArrowRightIcon,
  },
  {
    text: 'Take Out',
    icon: SubdirectoryArrowLeftIcon,
  },
  {
    text: 'Danger',
    icon: WarningIcon,
  },
  {
    text: 'Camp',
    icon: LocalHotelIcon,
  },
  {
    text: 'Rapid',
    icon: PoolIcon,
  },
  {
    text: 'Parking',
    icon: DirectionsCarIcon,
  },
  {
    text: 'Tree',
    icon: TreeIcon,
  },
];

export default geoTags;
