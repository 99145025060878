import React from "react";

export default function Flag({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 640 480">
      <g fill-rule="evenodd" stroke-width="1pt">
        <path fill="#d52b1e" d="M0 0h640v480H0z" />
        <g fill="#fff">
          <path d="M170 195h300v90H170z" />
          <path d="M275 90h90v300h-90z" />
        </g>
      </g>
    </svg>
  );
}
