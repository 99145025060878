import React from 'react';
import useReactRouter from 'use-react-router';
import AvatarButton from '../ui-elements/AvatarButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoIcon from '@material-ui/icons/Photo';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export default function AvatarPhotoButtons() {
  const theme = useTheme();
  const { match, history } = useReactRouter();

  return (
    <>
      <AvatarButton
        Icon={PhotoCameraIcon}
        color={theme.palette.primary.main}
        onClick={() => {
          history.push(history.location.pathname, {
            ...history.location.state,
            cameraIsOpen: true,
          });
        }}
      />
      <AvatarButton
        Icon={PhotoIcon}
        onClick={() => {}}
        color={theme.palette.primary.main}
        onClick={() => {
          document.getElementById('AddCommentTextField_uploadPhotoInput').click();
        }}
      />
    </>
  );
}
