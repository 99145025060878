import React from 'react';
import _ from 'lodash';
import AppLayout from '../layout/AppLayout';
import ListControl from '../layout/ListControl';
import useReactRouter from 'use-react-router';

import AuthProvider from '../providers/AuthProvider';
import useLocationParam from '../utility/useLocationParam';

import useT from '../utility/useT';
import PlayspotListItem from './PlayspotListItem';

function PlayspotsPage() {
  const { auth } = AuthProvider.useAuth();
  const { history } = useReactRouter();
  const t = useT();

  return (
    <AppLayout t_title="Playspots" mainIconMeaning="back" parentRoute="/trainings" showRefreshButton>
      <ListControl
        routerStateField="playspotsList"
        idName="playspotId"
        url={`playspot-info/find?joinChallenges=1`}
        component={PlayspotListItem}
        onAdd={() => history.push(`/playspots/create`)}
      />
    </AppLayout>
  );
}

export default PlayspotsPage;
