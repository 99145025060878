import _ from 'lodash';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import useReactRouter from 'use-react-router';
import { makeStyles } from '@material-ui/core/styles';

import AuthProvider from '../providers/AuthProvider';
import ListControl from '../layout/ListControl';
import CloseButtonDialogTitle from '../ui-elements/CloseButtonDialogTitle';
import GaugeListItem from './GaugeListItem';
import useT from '../utility/useT';

const useStyles = makeStyles(theme => ({
  field: {
    margin: theme.spacing.unit,
  },
}));

function ChooseGaugeDialog({ onClose, onChange, river, manualGauge, ...other }) {
  const { history } = useReactRouter();
  const { auth } = AuthProvider.useAuth();
  const classes = useStyles();
  const [useAllRivers, setUseAllRivers] = React.useState(false);
  const t = useT();
  const manualGaugeName = t('Manual gauge');

  function handleClose(...args) {
    if (onChange) onChange(...args);
    onClose();
  }

  const extraItems = [];
  if (manualGauge) {
    extraItems.push({
      gauge: manualGaugeName,
      sourceName: t('Use when there is no online gauge'),
    });
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="choose-gauge-dialog-title" {...other}>
      <CloseButtonDialogTitle id="choose-gauge-dialog-title" onClose={onClose}>
        Choose gauge
      </CloseButtonDialogTitle>

      {!!river && (
        <FormControlLabel
          control={
            <Checkbox
              checked={useAllRivers}
              onChange={(ev, value) => {
                setUseAllRivers(value);
              }}
            />
          }
          label={t('Show gauges from all rivers')}
          className={classes.field}
        />
      )}

      <ListControl
        showSearch
        url={
          !river || useAllRivers ? `last-measurement/find` : `last-measurement/find?river=${encodeURIComponent(river)}`
        }
        component={GaugeListItem.Custom(gauge => {
          if (gauge.gauge === manualGaugeName) handleClose('');
          else handleClose(gauge);
        })}
        extraItems={extraItems}
      />
    </Dialog>
  );
}

export default withMobileDialog()(ChooseGaugeDialog);
