import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import HeaderLabel from './HeaderLabel';

const useStyles = makeStyles(theme => ({
  headerImage: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      height: '250px',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      width: '100vw',
      height: '160px',
    },
  },
}));

export default function HeaderImage({ image, label, labelColor }) {
  const classes = useStyles();

  return (
    <div className={classes.headerImage} style={{ backgroundImage: `url(/images/${image}-small.jpg)` }}>
      <HeaderLabel label={label} labelColor={labelColor} />
    </div>
  );
}
