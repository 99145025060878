import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import useFormik from '../utility/useFormik';
import { withStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Difficulty from '../icons/Difficulty';
import useT from '../utility/useT';
import DifficultyDialog from './DifficultyDialog';

const styles = theme => ({
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  mainFlex: {
    display: 'flex',
    margin: theme.spacing.unit,
    justifyContent: 'space-between',
  },
});

function FormDifficulty({ name, classes, label }) {
  const { values, setFieldValue } = useFormik();
  const [isOpen, setIsOpen] = React.useState(false);
  const t = useT();
  return (
    <>
      <FormGroup>
        <InputLabel className={classes.inputLabel}>
          {label}: {values[name] || t('not set')}
        </InputLabel>
        <div className={classes.mainFlex}>
          <Difficulty difficulty={values[name]} />
          <Button onClick={() => setIsOpen(true)} variant="contained" color="primary">
            <Trans>Change</Trans>
          </Button>
        </div>
      </FormGroup>
      <DifficultyDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        value={values[name]}
        onChange={value => setFieldValue(name, value)}
      />
    </>
  );
}

export default withStyles(styles)(FormDifficulty);
