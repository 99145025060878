const data = `<li>All content on the application is provided without warranty and the owner is not
responsible for any damages caused by the use of the site, errors, outages or any of
the linked sites</li>
<li>The application may collects technical and personal data that are further processed
to improve the search results and make more relevant recommendations</li>
<li>Personal data are stored only on our servers and they aren't distributed</li>
<li>Only an anonymized data can be distributed to the third party</li>
<li>The website use Google Analytics to analyze webpages. Specifically, to monitoring
the traffic.</li>
<li>The website is using cookies to store user's session information (that you are logged
in) and for analyzing the application traffic</li>
<li>Data about rivers are distributed under CC BY-SA 4.0 license</li>
`
export default data;