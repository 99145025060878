import React from 'react';
import ListControl from '../layout/ListControl';
import EventListItem from './EventListItem';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WavesIcon from '@material-ui/icons/Waves';
import TerrainIcon from '@material-ui/icons/Terrain';
import PeopleIcon from '@material-ui/icons/People';
import WarningIcon from '@material-ui/icons/Warning';
import TimelineIcon from '@material-ui/icons/Timeline';
import MapIcon from '@material-ui/icons/Map';
import CommentIcon from '@material-ui/icons/Comment';
import AuthProvider from '../providers/AuthProvider';
import EditIcon from '@material-ui/icons/Edit';

const groupDefinitions = [
  {
    t_title: 'year',
    icon: AccessTimeIcon,
    groupBy: 'year',
  },
  {
    t_title: 'month',
    icon: AccessTimeIcon,
    groupBy: 'month',
  },
  {
    t_title: 'river',
    icon: WavesIcon,
    groupBy: 'river',
  },
  {
    t_title: 'account',
    icon: PeopleIcon,
    groupBy: 'accountName',
  },
  {
    t_title: 'trip',
    icon: TerrainIcon,
    groupBy: 'tripName',
  },
  {
    t_title: 'difficulty',
    icon: WarningIcon,
    groupBy: 'difficultyGroup',
  },
  {
    t_title: 'water level',
    icon: TimelineIcon,
    groupBy: 'waterLevel',
  },
  {
    t_title: 'country',
    icon: MapIcon,
    groupBy: 'country',
    translateGroupValue: true,
  },
];

const filterDefinitions = [
  {
    t_title: 'with comments',
    icon: CommentIcon,
    filterField: 'hasComments',
    filterValue: 'true',
  },
];

const groupContentConfig = {
  rivers: true,
  sections: true,
  eventStats: true,
  countries: true,
};

export default function EventListControl({ filterParams, ...other }) {
  const filterDefinitionsEx = [...filterDefinitions];
  const { auth } = AuthProvider.useAuth();
  if (auth && auth.accountId) {
    filterDefinitionsEx.push({
      t_title: 'only my',
      icon: EditIcon,
      filterField: 'accountId',
      filterValue: auth.accountId,
    });
  }

  return (
    <ListControl
      idName="diaryId"
      serviceName="diary-info"
      filterParams={filterParams}
      findParams="joinAccountAvatar=1&sort=-startTm"
      component={EventListItem}
      showSearch
      routerStateField="listLog"
      groupDefinitions={groupDefinitions}
      filterDefinitions={filterDefinitionsEx}
      groupContentConfig={groupContentConfig}
      {...other}
    />
  );
}
