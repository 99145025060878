import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import DifficultyIcon from '../icons/Difficulty';
import { makeStyles } from '@material-ui/core/styles';
import { getWaterLevelColor, waterLevelTwoLetterNames } from '../icons/WaterLevel';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';
import MessageAggregateWidgetList from '../sections/MessageAggregateWidgetList';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '10px',
    display: 'flex',
    cursor: 'pointer',
  },
  container: {
    flex: 1,
    marginLeft: '5px',
    marginRight: '5px',
    overflow: 'hidden',
    alignSelf: 'center',
  },
  river: {
    fontWeight: 'bold',
    // marginLeft: '5px',
    marginRight: '5px',
    flexShrink: 0,

    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  section: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  waterLevel: {
    marginLeft: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderRadius: '5px',
  },
  flexLine: {
    display: 'flex',
  },
  // line1: {
  //   display: 'flex',
  // },
  separator: {
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

// function Line2({ section }) {
//   const classes = useStyles();
//   const t = useT();
//   if (section.lastDangerInfo) {
//     return (
//       <div className={classes.flexLineSpace}>
//         <div className={classes.danger}>{section.lastDangerInfo}</div>
//         <div>{moment(section.lastDangerInfoTm).format('D. M. YYYY')}</div>
//       </div>
//     );
//   }
//   if (!section.lastDiaryTm) {
//     return <div className={classes.gray}>{t('No runs yet')}</div>;
//   }
//   return (
//     <div className={classes.flexLineSpace}>
//       <div className={classes.gray}>{section.lastDiaryAccountName}</div>
//       <div>{moment(section.lastDiaryTm).format('D. M. YYYY')}</div>
//     </div>
//   );
// }

function Separator() {
  const classes = useStyles();
  return <span className={classes.separator}>-</span>;
}

function GaugeLine({ section }) {
  const { gauges } = section;
  const [gauge0] = gauges || [];
  const { unit, lw, mw: mwOriginal, hw, heightCentimeters, flowCumecs, gauge, waterLevelPercent } = gauge0 || {};
  const gaugeValue = unit === 'cm' ? heightCentimeters : flowCumecs;
  const classes = useStyles();
  return (
    <div className={classes.flexLine}>
      <div>
        {gaugeValue} {unit}
      </div>
      {waterLevelPercent && (
        <div>
          <Separator />
          {waterLevelPercent} %
        </div>
      )}
      {section.waterLevel && <Separator />}
      {section.waterLevel && (
        <div className={classes.waterLevel} style={{ backgroundColor: getWaterLevelColor(section.waterLevel) }}>
          {waterLevelTwoLetterNames[section.waterLevel || 0]}
        </div>
      )}
    </div>
  );
}

export default function WidgetSectionList({ sections, hasEvents = true, hasDanger = false, skipRiverName = false }) {
  const classes = useStyles();
  const { history } = useReactRouter();
  const t = useT();

  if (!_.isArray(sections)) return null;
  // const has2lines = hasEvents == true || hasEvents == null;

  return (
    <>
      {sections.map(section => (
        <div
          // className={has2lines ? classes.eventsRoot : classes.unknownRoot}
          className={classes.root}
          onClick={() => history.push(`/sections/detail/${section.sectionId}`)}
        >
          <DifficultyIcon isSmall difficulty={section.difficulty} />
          <div className={classes.container}>
            <div className={classes.flexLine}>
              {!skipRiverName && <div className={classes.river}>{section.river}</div>}
              <div className={classes.section}>{section.section}</div>
            </div>
            <GaugeLine section={section} />
            <MessageAggregateWidgetList
              messageAggregate={section.messageAggregate}
              limit={3}
            />

            {/* {section.lastDangerInfo && hasDanger && (
              <div className={classes.flexLine}>
                <Line2 section={section} />
              </div>
            )}
            {!hasDanger && hasEvents && (
              <div className={classes.flexLine}>
                <Line2 section={section} />
              </div>
            )} */}
          </div>
        </div>
      ))}
    </>
  );
}
