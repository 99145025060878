import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import useReactRouter from 'use-react-router';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useStateDialog from '../utility/useStateDialog';

import MenuIcon from '@material-ui/icons/Menu';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CreateEventFromSectionDialog from '../events/CreateEventFromSectionDialog';

import MainMenu from './MainMenu';
import LoginDialog, { useLoginDialog } from '../ui-elements/LoginDialog';
import { drawerWidth } from '../utility/useViewportSize';
import useGroupNestingLevel from '../utility/useGroupNestingLevel';
import useRefreshTime from '../utility/useRefreshTime';
import { useTranslation } from 'react-i18next';
import Flag from '../icons/Flag';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  hide: {
    visibility: 'hidden',
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  content: {
    position: 'absolute',
    left: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  language: {
    marginTop: '5px',
    textTransform: 'uppercase',
    fontSize: '110%',
  },
}));

function getLanguage(i18n) {
  const { languages } = i18n;
  for (const lang of languages || []) {
    if (lang === 'cs' || lang === 'en') return lang;
  }
  return 'en';
}

const availableLanguages = [{ title: 'English', code: 'en' }, { title: 'Česky', code: 'cs' }];

function AppLayout({
  title,
  t_title,
  children,
  mainIconMeaning,
  customButtons,
  showRefreshButton,
  shareMenuItems,
  subMenuItems,
  parentRoute,
  showLanguageSelector,
  appBarOverlay,
  appBarBackground,
  appBarTextColor,
  absoluteAppBar,
  // appBarPosition = 'sticky',
}) {
  const classes = useStyles();
  const { history } = useReactRouter();
  const [isDrawerOpened, setIsDrawerOpened] = React.useState(false);
  const [openLogin, loginProps] = useLoginDialog();
  const groupNestingLevel = useGroupNestingLevel();
  const [, callRefresh] = useRefreshTime();
  const [shareAnchorEl, setShareAnchorEl] = React.useState(null);
  const [subAnchorEl, setSubAnchorEl] = React.useState(null);
  const [langAnchorEl, setLangAnchorEl] = React.useState(null);
  const shareMenuOpen = Boolean(shareAnchorEl);
  const subMenuOpen = Boolean(subAnchorEl);
  const langMenuOpen = Boolean(langAnchorEl);
  const [t, i18n] = useTranslation();
  const [openSectionDialog, sectionDialogProps] = useStateDialog();

  function handleOpenShareMenu(event) {
    setShareAnchorEl(event.currentTarget);
  }

  function handleCloseShareMenu() {
    setShareAnchorEl(null);
  }

  function handleOpenSubMenu(event) {
    setSubAnchorEl(event.currentTarget);
  }

  function handleCloseSubMenu() {
    setSubAnchorEl(null);
  }

  function handleOpenLangMenu(event) {
    setLangAnchorEl(event.currentTarget);
  }

  function handleCloseLangMenu() {
    setLangAnchorEl(null);
  }

  const handleMenuClick = () => {
    if (mainIconMeaning !== 'menu') {
      if (window.history.length > 2) history.goBack();
      else history.push('/');
      return;
    }

    // if (groupNestingLevel > 0 && mainIconMeaning !== 'menu') {
    //   history.goBack();
    //   return;
    // }

    switch (mainIconMeaning) {
      case 'back':
        // history.goBack();
        history.push(parentRoute);
        break;
      case 'menu':
        setIsDrawerOpened(true);
        break;
      case 'top':
        history.push('/dashboard');
        break;
      default:
        history.goBack();
        break;
    }
  };

  const language = getLanguage(i18n);

  return (
    <div className={classes.root}>
      {!appBarOverlay && (
        <div className={classes.hide}>
          <AppBar position="static">
            <Toolbar />
          </AppBar>
        </div>
      )}
      <AppBar
        position={absoluteAppBar ? 'absolute' : 'fixed'}
        className={classes.appBar}
        style={{ background: appBarBackground, color: appBarTextColor }}
      >
        <Toolbar>
          <Hidden mdUp>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={handleMenuClick}>
              {mainIconMeaning === 'menu' ? <MenuIcon /> : <ArrowBack />}
            </IconButton>
          </Hidden>
          <Hidden smDown>
            {mainIconMeaning !== 'menu' && (
              <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={handleMenuClick}>
                <ArrowBack />
              </IconButton>
            )}
          </Hidden>
          <Typography variant="h6" color="inherit" className={classes.title}>
            {t_title ? t(t_title) : title}
          </Typography>
          {showRefreshButton && (
            <IconButton onClick={callRefresh} color="inherit" title={t('Refresh')}>
              <RefreshIcon />
            </IconButton>
          )}
          {!!shareMenuItems && (
            <IconButton
              color="inherit"
              aria-controls="share-menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenShareMenu}
            >
              <ShareIcon />
            </IconButton>
          )}
          {!!subMenuItems && subMenuItems.length > 0 && (
            <IconButton
              color="inherit"
              aria-controls="sub-menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenSubMenu}
            >
              <MoreVertIcon />
            </IconButton>
          )}
          {showLanguageSelector && (
            <IconButton
              color="inherit"
              aria-controls="lang-menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenLangMenu}
              className={classes.language}
            >
              {language}
              {/* <Flag language={language} size={30} /> */}
            </IconButton>
          )}
          <div>{customButtons}</div>
        </Toolbar>
        {!!subMenuItems && (
          <Menu
            id="sub-menu-appbar"
            anchorEl={subAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={subMenuOpen}
            onClose={handleCloseSubMenu}
          >
            {subMenuItems.map(({ handler, label, t_label }) => (
              <MenuItem
                onClick={() => {
                  handleCloseSubMenu();
                  handler();
                }}
              >
                {t_label ? t(t_label) : label}
              </MenuItem>
            ))}
          </Menu>
        )}
        {!!shareMenuItems && (
          <Menu
            id="share-menu-appbar"
            anchorEl={shareAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={shareMenuOpen}
            onClose={handleCloseShareMenu}
          >
            {shareMenuItems.map(({ handler, label, t_label }) => (
              <MenuItem
                onClick={() => {
                  handleCloseShareMenu();
                  handler();
                }}
              >
                {t_label ? t(t_label) : label}
              </MenuItem>
            ))}
          </Menu>
        )}
        {showLanguageSelector && (
          <Menu
            id="lang-menu-appbar"
            anchorEl={langAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={langMenuOpen}
            onClose={handleCloseLangMenu}
          >
            {availableLanguages.map(({ title, code }) => (
              <MenuItem
                onClick={() => {
                  i18n.changeLanguage(code);
                  handleCloseLangMenu();
                }}
              >
                {title}
              </MenuItem>
            ))}
          </Menu>
        )}
      </AppBar>

      <div className={classes.content}>{children}</div>

      <Hidden mdUp>
        <SwipeableDrawer
          open={isDrawerOpened}
          onClose={() => setIsDrawerOpened(false)}
          onOpen={() => setIsDrawerOpened(true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={() => setIsDrawerOpened(false)}
            onKeyDown={() => setIsDrawerOpened(false)}
          >
            <MainMenu openLogin={openLogin} openSectionDialog={openSectionDialog} />
          </div>
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <Drawer variant="permanent" open>
          <div tabIndex={0} role="button" className={classes.drawer}>
            <MainMenu openLogin={openLogin} openSectionDialog={openSectionDialog} />
          </div>
        </Drawer>
      </Hidden>
      <LoginDialog {...loginProps} />
      <CreateEventFromSectionDialog {...sectionDialogProps} />
    </div>
  );
}

export default AppLayout;
