import _ from 'lodash';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseButtonDialogTitle from '../ui-elements/CloseButtonDialogTitle';
import { Trans } from 'react-i18next';
import PeopleListControl from './PeopleListControl';

function ShowEmotionPeopleDialog({ onClose, open, context, ...other }) {
  function handleClose() {
    onClose();
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="choose-section-dialog-title" open={open} {...other}>
      <CloseButtonDialogTitle id="choose-section-dialog-title" onClose={onClose}>
        <Trans>People</Trans>
      </CloseButtonDialogTitle>

      <PeopleListControl showSearch={false} filterParams={`&filterEmotions=1&target=${context.target}&diaryId=${context.diaryId}`} />
    </Dialog>
  );
}

export default withMobileDialog()(ShowEmotionPeopleDialog);
