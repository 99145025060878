import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import Button from '@material-ui/core/Button';
import useStateDialog from '../utility/useStateDialog';
import GpsDetailDialog from './GpsDetailDialog';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export function DriveToButton({ coordinates, className = undefined, ...other }) {
  const [openDialog, dialogProps] = useStateDialog();
  if (!coordinates) return null;

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={className}
        // @ts-ignore
        onClick={() => openDialog()}
      >
        <DirectionsCarIcon />
      </Button>
      <GpsDetailDialog coordinates={coordinates} dialogProps={dialogProps} {...other} />
    </>
  );
}

export function DriveToIconButton({ coordinates, className = undefined, ...other }) {
  const [openDialog, dialogProps] = useStateDialog();
  if (!coordinates) return null;

  return (
    <>
      <IconButton
        className={className}
        // @ts-ignore
        onClick={() => openDialog()}
      >
        <DirectionsCarIcon />
      </IconButton>
      <GpsDetailDialog coordinates={coordinates} dialogProps={dialogProps} {...other} />
    </>
  );
}

export function coordinatesToString(coordinates) {
  return `${Math.round(coordinates[1] * 10000) / 10000}, ${Math.round(coordinates[0] * 10000) / 10000}`;
}

export default function GpsView({ name, coordinates, ...other }) {
  const classes = useStyles();
  if (!coordinates) return null;
  return (
    <div className={classes.root}>
      <div className={classes.grow}>
        <span className={classes.bold}>{name}: </span>
        {coordinatesToString(coordinates)}
      </div>
      <DriveToButton className={classes.button} coordinates={coordinates} {...other} />
    </div>
  );
}
