import React from 'react';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import TabControl from '../layout/RouteTabControl';
import useFetchApi from '../utility/useFetchApi';
import AuthProvider from '../providers/AuthProvider';
import EventDetailView from './EventDetailView';
import LocationList from '../locations/LocationList';
import LocationsMap from '../locations/LocationsMap';
import useReactRouter from 'use-react-router';
import CommentsListView from '../comments/CommentsListView';
import DetailPageLayout from '../layout/DetailPageLayout';
import AppLayout from '../layout/AppLayout';
import { Trans } from 'react-i18next';
import useT from '../utility/useT';
import Paper from '@material-ui/core/Paper';
import { MS_PER_DAY } from '../gauges/useGaugeData';
import HeaderImage from '../places/HeaderImage';
import AvatarBar from '../ui-elements/AvatarBar';
import AvatarChip from '../ui-elements/AvatarChip';
import AvatarPhotoButtons from '../comments/AvatarPhotoButtons';
import LoadingInfo from '../ui-elements/LoadingInfo';

function EventDetailPage() {
  const { history, match } = useReactRouter();
  const { diaryId } = match.params;
  const t = useT();

  const detailList = useFetchApi(`diary-info/find?diaryId=${diaryId}&joinAccountAvatar=1&joinSection=1`, null);
  const detail = (detailList || [])[0];
  const isNotFound = detailList && detailList.length == 0;
  const detailSafe = detail || {};
  const { auth } = AuthProvider.useAuth();
  const { accountName, accountId, sectionId, section } = detailSafe;

  const isMyLog = auth && auth.accountId === accountId;

  let parentRoute = '/events';
  if (auth) {
    if (!isMyLog) {
      if ((auth.followedAccounts || []).includes(accountId)) parentRoute = '/events/followed';
      else parentRoute = '/events/all';
    }
  }

  if (isNotFound) {
    return (
      <AppLayout t_title={'Page not found'}>
        <Trans>Event not found, probably it was deleted</Trans>
      </AppLayout>
    );
  }
  if (detailList == null) {
    return (
      <AppLayout t_title={'Page is loading'}>
        <LoadingInfo />
      </AppLayout>
    );
  }
  return (
    <DetailPageLayout
      isWritable={isMyLog}
      editUrl={`/events/edit/${diaryId}`}
      idName="diaryId"
      parentRoute={parentRoute}
      idValue={diaryId}
      serviceName="diary-info"
      entityName="event"
      title={isMyLog ? t('My log') : accountName}
      mainIconMeaning="back"
      objectTitle={`event ${section}`}
    >
      <HeaderImage image="single-event-header" label={t('Single event')} labelColor="#ada" />
      <AvatarBar usage="event">
        {auth && !isMyLog && (
          <AvatarChip label={t('Copy to My log')} onClick={() => history.replace(`/events/copy/${diaryId}`)} />
        )}
        <AvatarPhotoButtons />
      </AvatarBar>

      <EventDetailView
        {...detailSafe}
        trips={detailSafe.tripId ? [{ tripId: detailSafe.tripId, tripName: detailSafe.tripName }] : []}
        dateText={moment(detailSafe.startTm).format('D. M. YYYY HH:mm')}
        fromTm={detailSafe.startTm - MS_PER_DAY}
        toTm={detailSafe.startTm + MS_PER_DAY}
        markerTm={[detailSafe.startTm]}
        sectionGauges={detailSafe.sectionObject && detailSafe.sectionObject.gauges}
      />
      <CommentsListView
        context={{
          diaryId,
          sectionId,
          date: moment(detailSafe.startTm).format('YYYY-MM-DD'),
          target: 'event',
        }}
        contextFilter={{
          diaryId,
          target: 'event',
        }}
        isInlineView
      />
    </DetailPageLayout>
  );
}

export default EventDetailPage;
