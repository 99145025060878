const requestNotifications = async (accountId, enqueueSnackbar) => {
  const permission = await window.Notification.requestPermission();
  if (permission !== 'granted') {
    console.log('permission not granted');
    if (enqueueSnackbar) enqueueSnackbar('Permission not granted', { variant: 'error' });
    return;
  }
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({ command: 'subscribe_notifications', accountId });
    if (enqueueSnackbar) enqueueSnackbar('Notification request sent', { variant: 'info' });
    console.log('sent subscribe_notifications');
  } else {
    if (enqueueSnackbar) enqueueSnackbar('Notification request cannot be sent', { variant: 'error' });
    console.log('serviceWorker not available');
  }
};

export default requestNotifications;
