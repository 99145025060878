import React from 'react';
import _ from 'lodash';
import useFetchApi from '../utility/useFetchApi';
import DashboardCard from '../ui-elements/DashboardCard';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';
import LocationContainer from '../ui-elements/LocationContainer';
import WidgetSectionList from '../dashboard/WidgetSectionList';
import { extractRiverParam } from '../utility/extractParams';

function SectionList({ location, river, hasEvents }) {
  const sections = useFetchApi(
    `section-info/find?latitude=${location.latitude}&longitude=${location.longitude}&river=${encodeURIComponent(
      river
    )}&findMeasurement=1`
  );
  if (!_.isArray(sections)) return null;
  return <WidgetSectionList sections={sections} hasEvents={false} hasDanger skipRiverName />;
}

export default function RiverSectionsWidget({ includeGrid }) {
  const { match, history } = useReactRouter();
  const river = extractRiverParam(match);
  const t = useT();
  return (
    <DashboardCard title={t('Sections')} link={`/river-sections/${encodeURIComponent(river)}`}>
      <LocationContainer
        allowFakeLocation
        includeGrid={includeGrid}
        renderContent={location => <SectionList location={location} river={river} />}
      />
    </DashboardCard>
  );
}
