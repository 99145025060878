import React from 'react';

const Context = React.createContext(null);

export default function ThemeSwitchContext({ children }) {
  const [themeStack, setThemeStack] = React.useState([]);
  const pushTheme = value => {
    setThemeStack([...themeStack, value]);
  };
  const popTheme = () => {
    setThemeStack(themeStack.slice(0, -1));
  };
  const currentTheme = themeStack[themeStack.length - 1];
  const value = {
    pushTheme,
    popTheme,
    currentTheme,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useThemeSwitch() {
  return React.useContext(Context);
}
