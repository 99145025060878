import React from 'react';
import ListControl from '../layout/ListControl';
import TrainingListItem from './TrainingListItem';
import { makeStyles } from '@material-ui/core/styles';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WavesIcon from '@material-ui/icons/Waves';
import TerrainIcon from '@material-ui/icons/Terrain';
import PeopleIcon from '@material-ui/icons/People';
import WarningIcon from '@material-ui/icons/Warning';
import TimelineIcon from '@material-ui/icons/Timeline';
import MapIcon from '@material-ui/icons/Map';
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
  },
}));

const groupDefinitions = [
  // {
  //   t_title: 'year',
  //   icon: AccessTimeIcon,
  //   groupBy: 'year',
  // },
  // {
  //   t_title: 'month',
  //   icon: AccessTimeIcon,
  //   groupBy: 'month',
  // },
  // {
  //   t_title: 'playspot',
  //   icon: WavesIcon,
  //   groupBy: 'playspotName',
  // },
  // {
  //   t_title: 'account',
  //   icon: PeopleIcon,
  //   groupBy: 'accountName',
  // },
];

const filterDefinitions = [];

const groupContentConfig = {};

export default function TrainingListControl({ filterParams, ...other }) {
  const classes = useStyles();
  return (
    <ListControl
      idName="trainingId"
      className={classes.root}
      serviceName="training-info"
      filterParams={filterParams}
      findParams="joinPlayspot=1&joinAccountAvatar=1&sort=-startTm"
      component={TrainingListItem}
      showSearch={false}
      routerStateField="listTrainings"
      groupDefinitions={groupDefinitions}
      filterDefinitions={filterDefinitions}
      groupContentConfig={groupContentConfig}
      {...other}
    />
  );
}
