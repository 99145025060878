import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import useFormik from '../utility/useFormik';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },

  picker: {
    display: 'flex',
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },

  color: {
    flexGrow: 1,
    height: '40px',
    borderWidth: '5px',
  },

  xmark: {
    textAlign: 'center',
    marginTop: '4px',
  },
});

function FormLineColorPicker({ name, classes, colors, label, ...otherProps }) {
  // return (
  //   <TextField name={name} {...otherProps} />
  // );

  const { values, setFieldValue } = useFormik();
  const value = values && values[name];

  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <div className={classes.picker}>
        {colors.map((color, colorIndex) => (
          <div
            onMouseDown={ev => setFieldValue(name, color)}
            style={{
              backgroundColor: color,
              // borderColor: colors[(colorIndex + 1) % colors.length],
              borderColor: 'gray',
              borderStyle: value === color ? 'inset' : 'none',
            }}
            className={classes.color}
          >
            {value === color && (
              <div className={classes.xmark} style={{ color: colors[(colorIndex + 1) % colors.length] }}>
                &#10006;
              </div>
            )}
          </div>
        ))}
      </div>
    </FormGroup>
  );

  // return <TextField label="Section" fullwidth />;
  // return <input type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} name="name" />;
}

export default withStyles(styles)(FormLineColorPicker);
