import React from 'react';

export default function Tree({ size = 30 }) {
  return (
    <svg width={size} height={size} viewBox="0 0 96 96" enable-background="new 0 0 96 96">
      <g>
        <path
          id="XMLID_4_"
          class="st0"
          fill='#FFFFFF'
          d="M72.6,67.3c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6H55c0,0.4,0.1,1.4,0.2,2.9
		c0.1,1.6,0.2,2.8,0.2,3.6c0,0.6-0.2,1-0.6,1.4S53.9,78,53.4,78H42.6c-0.6,0-1-0.2-1.4-0.6s-0.6-0.9-0.6-1.4c0-0.8,0.1-2.1,0.2-3.6
		s0.2-2.6,0.2-2.9H25.5c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5s0.2-1.1,0.6-1.5l13.5-13.5h-7.7c-0.6,0-1.1-0.2-1.5-0.6
		c-0.4-0.4-0.6-0.9-0.6-1.5c0-0.6,0.2-1.1,0.6-1.5l13.5-13.5h-6.6c-0.6,0-1.1-0.2-1.5-0.6C33.2,34.1,33,33.6,33,33s0.2-1.1,0.6-1.5
		l12.9-12.9c0.4-0.4,0.9-0.6,1.5-0.6s1.1,0.2,1.5,0.6l12.9,12.9c0.4,0.4,0.6,0.9,0.6,1.5s-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6
		h-6.6l13.5,13.5c0.4,0.4,0.6,0.9,0.6,1.5c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6h-7.7L72,65.8
		C72.4,66.2,72.6,66.7,72.6,67.3z"
        />
      </g>
    </svg>
  );
}
