import React from 'react';
import _ from 'lodash';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import useFormik from '../utility/useFormik';
import fetchApi from '../utility/fetchApi';
import useRouterDialog from '../utility/useRouterDialog';
import SmallItemListView from '../ui-elements/SmallItemListView';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  stars: {
    // padding: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
    flex: '1',
    display: 'flex',
    // marginBottom: theme.spacing.unit,
    // paddingTop: theme.spacing.unit,
    // paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  chooseButton: {
    marginBottom: theme.spacing.unit,
  },
  starsDiv: {
    alignSelf: 'center',
  },
  mainFlex: {
    display: 'flex',
    margin: theme.spacing.unit,
  },
}));

function FormItemList({
  label,
  dialog: Dialog,
  itemsField,
  idField,
  serviceName,
  pickFunc,
  getLinkFunc,
  renderItem,
  routerStateField,
}) {
  const classes = useStyles();
  const { values, setFieldValue } = useFormik();
  const [openDialog, dialogProps] = useRouterDialog(`${routerStateField}-dlg`);

  const items = values[itemsField] || [];

  const deleteItem = item => {
    setFieldValue(itemsField, items.filter(x => x !== item));
  };

  const clickItem = async itemId => {
    if (items.find(x => x[idField] === itemId)) {
      setFieldValue(itemsField, items.filter(x => x[idField] !== itemId));
    } else {
      const item = await fetchApi.get(`${serviceName}/find-by-id`, { [idField]: itemId });
      setFieldValue(itemsField, [...items, pickFunc(item)]);
    }
  };

  const clear = () => {
    setFieldValue(itemsField, []);
  };

  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <SmallItemListView
        items={items}
        onDelete={item => deleteItem(item)}
        getLinkFunc={getLinkFunc}
        renderItem={renderItem}
      />

      <Button variant="contained" color="primary" onClick={openDialog} className={classes.chooseButton}>
        <Trans>Edit</Trans>
      </Button>
      {items && items.length > 0 && (
        <Button variant="contained" color="primary" onClick={clear} className={classes.chooseButton}>
          <Trans>Clear</Trans>
        </Button>
      )}

      <Grid container>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Dialog
        {...dialogProps}
        onChange={clickItem}
        stayOpenOnSelect
        isCheckedFunc={props => items.find(x => x[idField] === props[idField])}
      />
    </FormGroup>
  );
}

export default FormItemList;
