import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import useLocationParam from '../utility/useLocationParam';
import { useSnackbar } from 'notistack';
import AuthProvider from '../providers/AuthProvider';
import fetchApi from '../utility/fetchApi';
import useReactRouter from 'use-react-router';
import { makeStyles } from '@material-ui/core/styles';
import CloseButtonDialogTitle from '../ui-elements/CloseButtonDialogTitle';
import ChoosePlayspotDialog from './ChoosePlayspotDialog';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  flexItem: {
    margin: theme.spacing(1),
  },
}));

function NewTrainingDialogCore({ onClose, onError }) {
  const locationParam = useLocationParam({ locationRequired: true, silent: true });
  const { auth } = AuthProvider.useAuth();
  const { history } = useReactRouter();
  const { enqueueSnackbar } = useSnackbar();
  const t = useT();
  const classes = useStyles();

  const handleInitialLoad = async () => {
    if (locationParam) {
      const playspots = await fetchApi(`playspot-info/find?limit=1&${locationParam}&isActive=true`);

      if (playspots && playspots.length > 0 && locationParam) {
        const { distance, playspotId, name } = playspots[0];

        if (playspotId && distance < 1) {
          history.push(`/trainings/create/${playspotId}`);
          return;
        }

        if (playspots.length == 0) {
          onError(t('No playspots found'));
          return;
        }
        if (playspots.length >= 0) {
          onError('1');
          return;
        }
      }
    }
    if (locationParam == '') {
      onError('1');
      return;
    }
  };

  React.useEffect(() => {
    handleInitialLoad();
  }, [locationParam]);

  return <React.Fragment />;
}

function NewTrainingDialog({ onClose, open, ...other }) {
  const { history } = useReactRouter();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  function handleClose() {
    onClose();
  }
  const [error, setError] = React.useState(null);
  const handleError = message => {
    if (message != '1') enqueueSnackbar(message, { variant: 'error' });
    setError(message);
  };
  const handleChoosePlayspot = playspot => {
    history.push(`/trainings/create/${playspot.playspotId}`);
  };

  return (
    <Dialog onClose={handleClose} open={open} aria-labelledby="confirm-dialog-title" {...other}>
      {open && <NewTrainingDialogCore onClose={handleClose} onError={handleError} />}
      <CloseButtonDialogTitle id="trainnig-dialog-title" onClose={handleClose}>
        <Trans>Add training</Trans>
      </CloseButtonDialogTitle>
      {error == null && (
        <div className={classes.wrapper}>
          <CircularProgress className={classes.flexItem} />
          <div className={classes.flexItem}>
            <Trans>Searching nearby playspot</Trans>
          </div>
        </div>
      )}
      {error && <ChoosePlayspotDialog open onClose={handleClose} onChoose={handleChoosePlayspot} />}
    </Dialog>
  );
}

export default NewTrainingDialog;
