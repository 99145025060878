import React from 'react';
import ChooseFromListDialog from '../ui-elements/ChooseFromListDialog';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MapIcon from '@material-ui/icons/Map';
import { getLocalStorage, setLocalStorage } from '../utility/storage-cache';
import ChooseGeoTagDialog from './ChooseGeoTagDialog';
import saveLocations from './saveLocations';
import { useSnackbar } from 'notistack';
import AuthProvider from '../providers/AuthProvider';
import fetchApi from '../utility/fetchApi';
import uuidv1 from 'uuid/v1';
import processSaveResponse from '../utility/processSaveResponse';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { isAndroid } from 'react-device-detect';
import _ from 'lodash';
import useRouterDialog from '../utility/useRouterDialog';

export default function ChooseLocationActionDialog({
  location,
  onHighlightOnMap,
  onChange,
  copyToSectionId,
  ...other
}) {
  const { locationId, coordinates } = location;
  const [openGeoTagDialog, geoTagDialogProps] = useRouterDialog('choose-geo-tag');
  const { enqueueSnackbar } = useSnackbar();
  const { auth } = AuthProvider.useAuth();

  // const copyToSection = async () => {
  //   const sectionObject = ((await fetchApi.get('section-info/find', { sectionId: copyToSectionId }, { auth })) ||
  //     [])[0];
  //   if (!sectionObject) return;

  //   const { sectionId, river, section } = sectionObject;

  //   const place = {
  //     placeId: uuidv1(),
  //     sectionId,
  //     river,
  //     section,
  //     location,
  //     accountId: auth.accountId,
  //   };

  //   const saveData = await fetchApi.post(
  //     `place-info/save`,
  //     { entity: place },
  //     {
  //       headers: { 'x-auth-token': auth.authToken },
  //       auth,
  //     }
  //   );
  //   processSaveResponse(saveData, enqueueSnackbar, 'Successfully saved place');
  // };

  const updateLocation = (name, fixValue) => async varValue => {
    const value = fixValue != null ? fixValue : varValue;
    const editingLocations = getLocalStorage('EditingLocations');
    if (editingLocations && editingLocations[locationId]) {
      editingLocations[locationId][name] = value;
      setLocalStorage('EditingLocations', editingLocations);
    } else {
      setLocalStorage('EditingLocations', {
        ...editingLocations,
        [locationId]: {
          ...location,
          [name]: value,
        },
      });
    }
    await saveLocations(enqueueSnackbar, auth);
    if (onChange) onChange();
  };

  const onDelete = updateLocation('isDeleted', true);
  const doChange = updateLocation('geotag');

  const items = _.compact([
    isAndroid
      ? {
          t_text: 'Navigate to (Choose navigation)',
          link: `geo:${coordinates[1]},${coordinates[0]}`,
          icon: DirectionsCarIcon,
        }
      : null,
    {
      t_text: 'Navigate to (Google maps)',
      link: `http://maps.google.com/maps?daddr=${coordinates[1]},${coordinates[0]}`,
      target: '_blank',
      icon: DirectionsCarIcon,
    },
    {
      t_text: 'Delete',
      icon: DeleteForeverIcon,
      onClick: onDelete,
    },
    {
      t_text: 'Change geo tag',
      icon: LocationOnIcon,
      onClick: openGeoTagDialog,
    },
    {
      t_text: 'Highlight on map',
      icon: MapIcon,
      onClick: onHighlightOnMap,
    },
    {
      t_text: 'Copy to My Places',
      link: `/places/create-from-location/${locationId}${copyToSectionId ? `?sectionId=${copyToSectionId}` : ''}`,
      icon: SubdirectoryArrowRightIcon,
    },
  ]);

  return (
    <>
      <ChooseFromListDialog items={items} {...other} />
      <ChooseGeoTagDialog {...geoTagDialogProps} onChoose={geotag => doChange(geotag)} t_title="Change geo tag" />
    </>
  );
}
