import React from 'react';
import useReactRouter from 'use-react-router';

import AppLayout from '../layout/AppLayout';
import TabControl from '../layout/RouteTabControl';
import useFetchApi from '../utility/useFetchApi';
import UserProfile from './UserProfile';
import EventListControl from '../events/EventListControl';
import TrainingListControl from '../trainings/TrainingListControl';

function PeopleDetailPage({ match }) {
  const { accountId } = match.params;
  const { history } = useReactRouter();

  const detail = useFetchApi(`account-info/find?accountId=${accountId}`)[0];
  const detailSafe = detail || {};
  const { displayName } = detailSafe;

  return (
    <AppLayout title={displayName} mainIconMeaning="back" parentRoute="/people">
      <TabControl>
        <TabControl.Page t_label="Profile" path={`/people/detail/${accountId}`}>
          <UserProfile {...detailSafe} />
        </TabControl.Page>
        <TabControl.Page t_label="River runs" path={`/people/detail/${accountId}/events`}>
          <EventListControl filterParams={`&accountId=${accountId}`} routerStateField="listEvents" />
        </TabControl.Page>
        <TabControl.Page t_label="Trainings" path={`/people/detail/${accountId}/trainings`}>
          <TrainingListControl filterParams={`&accountId=${accountId}`} routerStateField="listTrainings" />
        </TabControl.Page>
      </TabControl>
    </AppLayout>
  );
}

export default PeopleDetailPage;
