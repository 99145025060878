import React from 'react';
import LayersIcon from '@material-ui/icons/Layers';
import Grid from '@material-ui/core/Grid';

import DashboardCard from '../ui-elements/DashboardCard';
import useFetchApi from '../utility/useFetchApi';
import AuthProvider from '../providers/AuthProvider';
import useReactRouter from 'use-react-router';
import HeaderMap, { computeCenterZoom } from '../places/HeaderMap';
import CommentsListView from '../comments/CommentsListView';
import DetailPageLayout from '../layout/DetailPageLayout';
import AvatarBar from '../ui-elements/AvatarBar';
import AvatarButton from '../ui-elements/AvatarButton';
import useOnlineStatus from '../utility/useOnlineStatus';
import { useSetDetailMapType } from '../utility/globalstate';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Flag from '../icons/Flag';
import DifficultyIcon from '../icons/Difficulty';
import TopEventsWidget from '../dashboard/TopEventsWidget';
import TopDangersWidget from '../dashboard/TopDangersWidget';
import SectionGaugesWidget from './SectionGaugesWidget';
import PlacesWidget from '../dashboard/PlacesWidget';
import SectionInfoWidget from './SectionInfoWidget';
import AvatarChip from '../ui-elements/AvatarChip';
import InterestingSectionsNearby from '../dashboard/InterestingSectionsNearby';
import useT from '../utility/useT';
import useRefreshTime from '../utility/useRefreshTime';
import DescriptionDetailView from '../comments/DescriptionDetailView';
import AvatarPhotoButtons from '../comments/AvatarPhotoButtons';
import MessageAggregateDetailList from './MessageAggregateDetailList';
import AppLayout from '../layout/AppLayout';
import LoadingInfo from '../ui-elements/LoadingInfo';

const useStyles = makeStyles(theme => ({
  river: {
    fontSize: '150%',
    // marginRight: '2rem',
    fontFamily: theme.typography.fontFamily,
    flexGrow: 1,
    marginLeft: '10px',
  },
  section: {
    fontSize: '120%',
    // marginRight: '2rem',
    fontFamily: theme.typography.fontFamily,
    flexGrow: 1,
    color: theme.sectionColor,
    marginLeft: '10px',
  },

  flexLine: {
    display: 'flex',
  },
  avatar: {
    margin: theme.spacing.unit,
  },
  riverContainer: {
    display: 'flex',
    padding: '10px',
    backgroundColor: '#ccc',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  sectionContainer: {
    display: 'flex',
    padding: '10px',
    backgroundColor: '#ddd',
    alignItems: 'center',
    marginBottom: 15,
  },
}));

function SectionDetailPage({ match }) {
  const { sectionId } = match.params;
  const { history } = useReactRouter();
  const theme = useTheme();
  const classes = useStyles();

  const { auth } = AuthProvider.useAuth();

  const [refreshTime] = useRefreshTime();

  const detailResp = useFetchApi.get(
    'section-info/find',
    {
      sectionId,
      joinPlaces: 1,
      findMeasurement: 1,
      joinAccountWaterLevels: auth ? auth.accountId : '',
      resetOnParamsChange: true,
    },
    null,
    undefined,
    [refreshTime]
  );
  const detail = (detailResp || [])[0];
  const detailSafe = detail || {};
  const {
    accountId,
    places,
    section,
    river,
    enjoyLevel,
    difficulty,
    country,
    gauges,
    waterLevel,
    waterLevelPercent,
    importSource,
    coordinates,
    accountWaterLevels,
  } = detailSafe;
  const online = useOnlineStatus();

  const sectionUrlBase = `/sections/detail/${sectionId}`;
  const isMySection = auth && auth.accountId === accountId;
  const setDetailMapType = useSetDetailMapType();
  const t = useT();

  const [center, zoom] = computeCenterZoom(places);

  if (detailResp == null) {
    return (
      <AppLayout t_title={'Page is loading'}>
        <LoadingInfo />
      </AppLayout>
    );
  }

  return (
    <DetailPageLayout
      isWritable={isMySection}
      editUrl={`/sections/edit/${sectionId}`}
      parentRoute="/sections"
      idName="sectionId"
      idValue={sectionId}
      serviceName="section-info"
      entityName="section"
      title={section}
      objectTitle={`section ${section}`}
      mainIconMeaning="back"
      showRefreshButton
    >
      <HeaderMap places={places} label={t('Section')} labelColor="#aaa" />

      <AvatarBar usage="section">
        {/* {online && places && places.length && (
          <AvatarButton
            Icon={LayersIcon}
            onClick={() => setDetailMapType(x => (x ==                                                                                                                                                                                                       ? 'mapycz_turist' : 'mapycz_photo'))}
            color={theme.palette.primary.main}
          />
        )} */}
        {importSource == 'rivermap' && (
          <AvatarChip
            label="Rivermap"
            onClick={
              center
                ? () =>
                    window.open(
                      `https://www.rivermap.ch/map.html#lat=${center.latitude}&lng=${
                        center.longitude
                      }&sprache=en&styled=1&zoom=${zoom}`
                    )
                : null
            }
          />
        )}
        {auth && (
          <AvatarChip
            label={t('Log river run')}
            onClick={() => history.push(`/events/create-from-section/${sectionId}`)}
          />
        )}
        <AvatarPhotoButtons />
      </AvatarBar>

      <div className={classes.riverContainer} onClick={() => history.push(`/rivers/${encodeURIComponent(river)}`)}>
        <Flag name={country} size={40} />
        <div className={classes.river}>{river}</div>
      </div>

      <div className={classes.sectionContainer}>
        <DifficultyIcon difficulty={difficulty} />
        <div className={classes.section}>{section}</div>
      </div>

      <Grid container>
        <DashboardCard includeGrid title={t('Description')}>
          <DescriptionDetailView target="section" sectionId={sectionId} />
        </DashboardCard>

        <SectionGaugesWidget
          gauges={gauges}
          waterLevel={waterLevel}
          waterLevelPercent={waterLevelPercent}
          sectionId={sectionId}
          includeGrid
          accountWaterLevels={accountWaterLevels}
        />
        <MessageAggregateDetailList messageAggregate={detailSafe.messageAggregate} usedOn="section" />
        <TopDangersWidget additionalFilter={{ sectionId }} includeGrid />
        <TopEventsWidget
          additionalFilter={{ sectionId }}
          link={`/section-event-groups/${sectionId}`}
          includeGrid
          variant="section"
        />
        <PlacesWidget places={places} includeGrid />
        <SectionInfoWidget {...detailSafe} includeGrid />
        {coordinates && (
          <InterestingSectionsNearby
            includeGrid
            location={{ latitude: coordinates[1], longitude: coordinates[0] }}
            additionalFilter={{ nsectionId: sectionId }}
            title={t('Interesting sections nearby')}
          />
        )}
      </Grid>

      <CommentsListView context={{ sectionId, target: 'section' }} isInlineView />
    </DetailPageLayout>
  );
}

export default SectionDetailPage;
