import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { Trans } from 'react-i18next';
import useT from '../utility/useT';
import useRouterDialog from '../utility/useRouterDialog';
import useStateDialog from '../utility/useStateDialog';
import CreateEventFromSectionDialog from '../events/CreateEventFromSectionDialog';
import NewTrainingDialog from '../trainings/NewTrainingDialog';

import useReactRouter from 'use-react-router';

import PeopleIcon from '@material-ui/icons/People';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import TerrainIcon from '@material-ui/icons/Terrain';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import NativeRouterSearchField from '../ui-elements/NativeRouterSearchField';

const useStyles = makeStyles(theme => ({
  root: {},

  topFlexPosition: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '30px',
    // top: '15vh',
    height: '35vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  mainButton1: {
    fontWeight: 'bold',
    // textShadow: '1px 1px #000',
  },
  mainButton2: {
    backgroundColor: '#90CAA1',
    fontWeight: 'bold',
  },
  buttonLine: {
    padding: '10px 0px',
    display: 'flex',
    paddingLeft: '10vw',
    // justifyContent: 'space-evenly',
  },
  chipsLine: {
    padding: '20px 0px',
    display: 'flex',
    justifyContent: 'space-evenly',
    textTransform: 'uppercase',
  },
  linkChipsContainer: {
    left: 0,
    right: 0,
    // maxWidth: '1000px',
    textTransform: 'uppercase',
    color: theme.palette.text.dashboard,
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  // link1: {
  //   position: 'absolute',
  //   top: '44vh',
  //   left: '5vw',
  //   textTransform: 'uppercase',
  //   color: theme.palette.text.dashboard,
  //   // color: 'white',
  //   // backgroundColor: '#666',
  //   // textDecoration: 'underline',
  // },
  // link2: {
  //   position: 'absolute',
  //   top: '44vh',
  //   right: '5vw',
  //   textTransform: 'uppercase',
  //   color: theme.palette.text.dashboard,
  //   // color: 'white',
  //   // backgroundColor: '#666',
  //   // textDecoration: 'underline',
  // },
  // link3: {
  //   position: 'absolute',
  //   top: '44vh',
  //   left: '50vw',
  //   transform: 'translateX(-50%)',
  //   color: theme.palette.text.dashboard,
  //   // textDecoration: 'underline',
  //   textAlign: 'center',
  //   textTransform: 'uppercase',
  // },
  backgroundImageTop: {
    // position: 'absolute',
    backgroundImage: 'url(./images/dashboard-header-small.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    opacity: 0.8,
    top: 0,
    left: 0,
    right: 0,
    // height: '30vh',
    // zIndex: '-2',
    position: 'relative',
  },
  backgroundBlender: {
    position: 'absolute',
    backgroundColor: '#777',
    top: 0,
    left: 0,
    right: '40vw',
    bottom: 0,
    zIndex: '-1',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  backgroundImageBottom: {
    position: 'fixed',
    backgroundImage: 'url(./images/wave.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    opacity: 0.75,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: '-3',
  },
  searchbox: {
    fontSize: '1rem',
    padding: '15px',
    backgroundColor: '#FFFFFFCC',
    width: '80vw',
    // margin: '0 15vw',
    outline: 'none',
    border: '0px solid',
    borderRadius: '10px',
  },
  searchboxWrapper: {
    padding: '30px 10vw 20px 10vw',
    display: 'flex',
    // justifyContent: 'space-around',
  },
}));

export default function DashboardHeaderWidget({ onRunFilter, filter }) {
  const [openSectionDialog, sectionDialogProps] = useRouterDialog('choose-section');
  const [openTrainingDialog, trainingDialogProps] = useStateDialog();
  const t = useT();
  const { history } = useReactRouter();

  const classes = useStyles();
  return (
    <div>
      <div className={classes.backgroundImageTop}>
        <div className={classes.backgroundBlender} />

        <div className={classes.searchboxWrapper}>
          <NativeRouterSearchField
            routerStateField="homepage_search"
            onRunFilter={onRunFilter}
            className={classes.searchbox}
            placeholder={t('Search river or section')}
          />
        </div>

        {!filter && (
          <>
            <div className={classes.buttonLine}>
              <Button className={classes.mainButton1} variant="contained" color="primary" onClick={openSectionDialog}>
                <Trans>Log river run</Trans>
              </Button>
            </div>
            <div className={classes.buttonLine}>
              <Button className={classes.mainButton2} variant="contained" color="primary" onClick={openTrainingDialog}>
                <Trans>Log training</Trans>
              </Button>
            </div>

            <div className={classes.chipsLine}>
              <Chip icon={<PeopleIcon />} label={t('People')} onClick={() => history.push(`/people`)} />
              <Chip icon={<DirectionsBoatIcon />} label={t('Rivers')} onClick={() => history.push(`/rivers`)} />

              {/* <Chip icon={<ShowChartIcon />} label={t('Gauges')} onClick={() => history.push(`/gauges`)} /> */}

              <Chip icon={<DirectionsRunIcon />} label={t('Trainings')} onClick={() => history.push(`/trainings`)} />
            </div>
          </>
        )}
      </div>

      <CreateEventFromSectionDialog {...sectionDialogProps} />
      <NewTrainingDialog {...trainingDialogProps} />
    </div>
  );
}
