import React from "react";
import AppLayout from "../layout/AppLayout";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { CopyToClipboard } from "react-copy-to-clipboard";

import RiverIcon from "./River";
import BeerIcon from "./Beer";
import FavoriteIcon from "./Favorite";
import GoogleIcon from "./Google";
import BoatKayakIcon from "./BoatKayak";
import BoatCanoeIcon from "./BoatCanoe";
import BoatRaftIcon from "./BoatRaft";
import BoatTouristCanoeIcon from "./BoatTouristCanoe";
import BoatOtherIcon from "./BoatOther";

function IconItem({ name, desc, icon: Icon }) {
  return (
    <CopyToClipboard text={`import ${name}Icon from "../icons/${name}";`}>
      <ListItem alignItems="flex-start" button>
        <ListItemAvatar>
          <Icon size={40} />
        </ListItemAvatar>
        <ListItemText primary={name} secondary={desc} />
      </ListItem>
    </CopyToClipboard>
  );
}

function IconListPage() {
  return (
    <AppLayout title="Icon list">
      <List>
        <IconItem icon={RiverIcon} name="River" />
        <IconItem icon={BeerIcon} name="Beer" />
        <IconItem icon={FavoriteIcon} name="Favorite" />
        <IconItem icon={GoogleIcon} name="Google" />
        <IconItem icon={BoatKayakIcon} name="BoatKayak" />
        <IconItem icon={BoatCanoeIcon} name="BoatCanoe" />
        <IconItem icon={BoatRaftIcon} name="BoatRaft" />
        <IconItem icon={BoatTouristCanoeIcon} name="BoatTouristCanoe" />
        <IconItem icon={BoatOtherIcon} name="BoatOther" />
      </List>
    </AppLayout>
  );
}

export default IconListPage;
