import React from 'react';
import useReactRouter from 'use-react-router';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import copyToClipboard from 'copy-to-clipboard';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import AppLayout from './AppLayout';
import ConfirmDialog from '../ui-elements/ConfirmDialog';
import fetchApi from '../utility/fetchApi';
import useStateDialog from '../utility/useStateDialog';
import AuthProvider from '../providers/AuthProvider';
import useRefreshTime from '../utility/useRefreshTime';
import useRouterDialog from '../utility/useRouterDialog';
import AddTeamMembersDialog from '../people/AddTeamMembersDialog';
import processSaveResponse from '../utility/processSaveResponse';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  nowrap: {
    whiteSpace: 'nowrap',
  },
}));

export default function DetailPageLayout({
  children,
  isWritable,
  editUrl,
  idName,
  idValue,
  entityName,
  serviceName,
  customButtons,
  objectTitle,
  shareMenuItems,
  subMenuItems,
  ...other
}) {
  const { history, match } = useReactRouter();
  const [openConfirmDelete, confirmDeleteDialogProps] = useStateDialog();
  const { auth } = AuthProvider.useAuth();
  const [, callRefresh] = useRefreshTime();
  const [openTeamDialog, teamDialogProps] = useRouterDialog('select-team');
  const [recipients, setRecipients] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const t = useT();
  const classes = useStyles();

  function handleSendNotification() {
    openTeamDialog();
  }

  function doCopyToClipboard() {
    copyToClipboard(window.location.href);
    enqueueSnackbar('Page copied', { variant: 'info' });
  }

  async function doSendNotification() {
    const saveData = await fetchApi.post(
      `notification-info/send-link`,
      { link: history.location.pathname, objectTitle, recipients },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    if (!processSaveResponse(saveData, enqueueSnackbar, 'Page has been sent')) return;

    teamDialogProps.onClose();
  }

  const doDelete = async () => {
    const saveData = await fetchApi.post(
      `${serviceName}/save`,
      {
        entity: {
          [idName]: idValue,
          isDeleted: true,
        },
      },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    console.log('Delete response:', saveData);
    callRefresh();
    history.goBack();
  };

  return (
    <AppLayout
      customButtons={
        <div className={classes.nowrap}>
          {customButtons}
          {isWritable && (
            <IconButton color="inherit" onClick={() => history.replace(editUrl)}>
              <EditIcon />
            </IconButton>
          )}
          {isWritable && (
            <IconButton color="inherit" onClick={openConfirmDelete}>
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      }
      shareMenuItems={[
        { t_label: 'Send notification', handler: handleSendNotification },
        { t_label: 'Copy link to clipboard', handler: doCopyToClipboard },
        ...(shareMenuItems || []),
      ]}
      subMenuItems={subMenuItems}
      {...other}
    >
      {children}

      <ConfirmDialog
        title={t('Really delete {{entityName}}?', { entityName })}
        t_positiveButton="Delete"
        t_negativeButton="Cancel"
        onConfirm={doDelete}
        {...confirmDeleteDialogProps}
      />

      <AddTeamMembersDialog
        {...teamDialogProps}
        dialogTitle={t('Choose recipients')}
        team={recipients}
        onChange={team => setRecipients(team)}
        headerButtons={
          <Button color="inherit" onClick={doSendNotification}>
            <Trans>Send</Trans>
          </Button>
        }
      />
    </AppLayout>
  );
}
