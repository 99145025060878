import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SmileIcon from '../icons/Smile';
import fetchApi from '../utility/fetchApi';
import AuthProvider from '../providers/AuthProvider';
import getAccountInfoName from '../utility/getAccountInfoName';
import useStateDialog from '../utility/useStateDialog';
import ShowEmotionPeopleDialog from '../people/ShowEmotionPeopleDialog';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '16pt',
    display: 'flex',
  },
  chooser: {},
  chooserInList: {
    position: 'relative',
    left: '-65px',
  },
  grower: {
    flexGrow: 1,
  },
  dialogWrapper: {
    borderRadius: '10px',
    border: '1px solid #AAA',
    backgroundColor: '#DDD',
    display: 'flex',
    position: 'relative',
  },
  dialogWrapperInList: {
    left: '-70px',
  },
  emotionButton: {
    padding: '10px',
  },
}));

export const emotionTypes = {
  'clapping-hands': '👏',
  'thumbs-up': '👍',
  'oncoming-fist': '👊',
  'see-no-evil': '🙈',
  'fearful-face': '😨',
};

export default function EmojiChooser({ context, emotions, accountId, usedInList }) {
  const classes = useStyles();
  const [showChooser, setShowChooser] = React.useState(false);
  const [openPeopleDialog, peopleDialogProps] = useStateDialog();
  const [currentEmotions, setCurrentEmotions] = React.useState(emotions);
  const { auth } = AuthProvider.useAuth();
  if (!currentEmotions && emotions) setCurrentEmotions(emotions);

  const handleCloseEmoji = e => {
    e.stopPropagation();
    setShowChooser(false);
  };
  const handleShowChooser = e => {
    e.stopPropagation();
    setShowChooser(true);
  };
  const handleShowPeople = e => {
    e.stopPropagation();
    openPeopleDialog();
  };
  const handleSetEmotion = type => async e => {
    e.stopPropagation();
    setShowChooser(false);
    const resp = await fetchApi.post(
      'emotion-info/set',
      {
        ...context,
        type,
        accountId: auth.accountId,
        accountName: getAccountInfoName(auth),
      },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    setCurrentEmotions(resp);
  };

  return (
    <div className={classes.root}>
      {!showChooser && auth && auth.accountId != accountId && (
        <span
          className={usedInList ? classes.chooserInList : classes.chooser}
          role="img"
          aria-label="hands up"
          onClick={handleShowChooser}
        >
          <SmileIcon size={25} />
        </span>
      )}

      {showChooser && (
        <div className={clsx(classes.dialogWrapper, usedInList ? classes.dialogWrapperInList : null)}>
          {_.keys(emotionTypes).map(name => (
            <IconButton key={name} aria-label={name} className={classes.closeButton} onClick={handleSetEmotion(name)}>
              {emotionTypes[name]}
            </IconButton>
          ))}

          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleCloseEmoji}>
            <CloseIcon />
          </IconButton>
        </div>
      )}

      <div className={classes.grower}> </div>

      {!showChooser && (
        <div onClick={handleShowPeople}>
          {_.keys(emotionTypes).map(name =>
            currentEmotions && currentEmotions[name] ? (
              <>
                {emotionTypes[name]} {currentEmotions[name]}
              </>
            ) : null
          )}
        </div>
      )}

      <ShowEmotionPeopleDialog context={context} {...peopleDialogProps} />
    </div>
  );
}
