import React from 'react';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import useReactRouter from 'use-react-router';

import CommentIcon from '@material-ui/icons/Comment';
import PeopleIcon from '@material-ui/icons/People';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import TerrainIcon from '@material-ui/icons/Terrain';
import PagesIcon from '@material-ui/icons/Pages';
import SmileIcon from '../icons/Smile';
import PhotoIcon from '@material-ui/icons/Photo';

export default function NotificationListItem({ reason, primaryText, secondaryText, link, createTm }) {
  const { history } = useReactRouter();
  return (
    <ListItem button onClick={() => history.push(link)}>
      <ListItemAvatar>
        {reason === 'comment-added' && <CommentIcon />}
        {reason === 'photo-added' && <PhotoIcon />}
        {reason === 'event-team-member-added' && <PeopleIcon />}
        {reason === 'trip-team-member-added' && <PeopleIcon />}
        {reason === 'event-copied' && <FileCopyIcon />}
        {reason === 'follower-added' && <VisibilityIcon />}
        {reason === 'followed-event-added' && <EditIcon />}
        {reason === 'followed-trip-added' && <TerrainIcon />}
        {reason === 'page-sent' && <PagesIcon />}
        {reason === 'emotion-added' && <SmileIcon />}
      </ListItemAvatar>

      <ListItemText
        primary={primaryText}
        secondary={secondaryText ? `${moment(createTm).fromNow()} • ${secondaryText}` : moment(createTm).fromNow()}
      />
    </ListItem>
  );
}
