import React from 'react';
import Button from '@material-ui/core/Button';

import TabControl from '../layout/RouteTabControl';
import useFetchApi from '../utility/useFetchApi';
import AuthProvider from '../providers/AuthProvider';
import PlacesMap from '../places/PlacesMap';
import useReactRouter from 'use-react-router';
import PlaceDetailView from './PlaceDetailView';
import CommentsListView from '../comments/CommentsListView';
import DetailPageLayout from '../layout/DetailPageLayout';

function PlaceDetailPage() {
  const { history, match } = useReactRouter();
  const { placeId } = match.params;

  const detail = useFetchApi(`place-info/find?placeId=${placeId}&joinSection=1`)[0];
  const detailSafe = detail || {};
  const { auth } = AuthProvider.useAuth();
  const { accountId } = detailSafe;
  // const { accountId: loggedAccountId, displayName } = auth || {};

  const isMyPlace = auth && auth.accountId === accountId;

  return (
    <DetailPageLayout
      isWritable={isMyPlace}
      editUrl={`/places/edit/${placeId}`}
      parentRoute='/places'
      idName="placeId"
      idValue={placeId}
      serviceName="place-info"
      entityName="place"
      t_title="Places"
      mainIconMeaning="back"
      objectTitle="place"
      customButtons={
        <>
          <Button color="inherit" onClick={() => history.replace(`/places/copy/${placeId}`)}>
            Copy
          </Button>
        </>
      }
    >
      <TabControl>
        <TabControl.Page t_label="Place details" path={`/places/detail/${placeId}`}>
          <PlaceDetailView {...detailSafe} />
        </TabControl.Page>
        <TabControl.Page t_label="Comments" path={`/places/detail/${placeId}/comments`}>
          <CommentsListView context={{ placeId, target: 'place' }} />
        </TabControl.Page>
        <TabControl.Page t_label="Map" path={`/places/detail/${placeId}/map`}>
          <PlacesMap places={[detailSafe]} />
        </TabControl.Page>
      </TabControl>
    </DetailPageLayout>
  );
}

export default PlaceDetailPage;
