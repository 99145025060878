import React from 'react';
import Color from 'color';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';

//const difficultyColor_zw = "#bfb";
// const difficultyColor_ww6 = "#fbb";
//const difficultyColor_ww6 = "#bbf";

const difficultyColor_zw = '#66F9B2';
// const difficultyColor_ww6 = "#fbb";
const difficultyColor_ww6 = '#8366F9';

const difficultyColor_unknown = '#bbb';

const styles = {
  root: {
    color: '#000',
    fontSize: '90%',
    alignSelf: 'center',
  },
  smallAvatar: {
    width: '25px',
    // width: '20px',
    fontSize: '60%',
    height: '25px',
    // height: '20px',
  },
  tinyAvatar: {
    width: '20px',
    fontSize: '55%',
    height: '20px',
  },
};

export const difficultyNames = [
  'not set',
  'ZW',
  'WW I',
  'WW I+',
  'WW I/II',
  'WW II-',
  'WW II',
  'WW II+',
  'WW II/III',
  'WW III-',
  'WW III',
  'WW III+',
  'WW III/IV',
  'WW IV-',
  'WW IV',
  'WW IV+',
  'WW IV/V',
  'WW V-',
  'WW V',
  'WW V+',
  'WW V/VI',
  'WW VI-',
  'WW VI',
  'X',
];

export function getDifficultyColor(difficulty) {
  const index = difficultyNames.indexOf(difficulty);
  if (index < 1) {
    return difficultyColor_unknown;
  }
  const minColor = Color(difficultyColor_zw);
  const maxColor = Color(difficultyColor_ww6);
  const t = (index - 1) / (difficultyNames.length - 1);
  return minColor.mix(maxColor, t).string();
}

export function getShortDifficultyName(difficulty) {
  if (!difficulty || difficulty === 'not set') return '?';
  if (difficulty.startsWith('WW ')) return difficulty.substring(3);
  return difficulty;
}

export function getDifficultyDifference(dif1, dif2) {
  const index1 = difficultyNames.indexOf(dif1);
  const index2 = difficultyNames.indexOf(dif2);
  if (index1 <= 0 || index2 <= 0) return 100;
  return Math.abs(index1 - index2);
}

function Difficulty({ difficulty, isSmall, isTiny, classes, className, style = {} }) {
  return (
    <Avatar
      style={{ ...style, backgroundColor: getDifficultyColor(difficulty) }}
      className={clsx(
        className,
        isSmall ? classes.smallAvatar : null,
        isTiny ? classes.tinyAvatar : null,
        classes.root
      )}
    >
      {getShortDifficultyName(difficulty)}
    </Avatar>
  );
}

export default withStyles(styles)(Difficulty);
