import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import DifficultyIcon from '../icons/Difficulty';
import NameValueView from '../ui-elements/NameValueView';
import PaperCols from '../ui-elements/PaperCols';
import PaperRows from '../ui-elements/PaperRows';
import Flag from '../icons/Flag';
import ImageStars from '../icons/ImageStars';
import PaperCard from '../ui-elements/PaperCard';
import PlaceView from '../ui-elements/PlaceView';
import { waterLevelNames, getWaterLevelColor } from '../icons/WaterLevel';
import useT from '../utility/useT';
import DashboardCard from '../ui-elements/DashboardCard';
import intersperse from '../utility/intersperse';

const useStyles = makeStyles(theme => ({}));

function SectionInfoWidget({ importSource, description, putIn, takeOut, includeGrid }) {
  const classes = useStyles();
  const t = useT();
  if (importSource == 'rivermap') return null;
  if (!description && !putIn && !takeOut) return null;

  return (
    <DashboardCard title={t('Info')} includeGrid={includeGrid}>
      <NameValueView t_name="Description" value={description} />
      <NameValueView t_name="Put in" value={putIn} />
      <NameValueView t_name="Take out" value={takeOut} />
    </DashboardCard>
  );
}

export default SectionInfoWidget;
