import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import useFormik from '../utility/useFormik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  field: {
    // margin: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
}));

function parseCoordinates(text) {
  if (!text) return [undefined, undefined, text];
  const match = text.match(/(\d+\.\d+)N?,\s*(\d+\.\d+)E?/);
  if (match) return [parseFloat(match[2]), parseFloat(match[1]), text];
  return [undefined, undefined, text];
}

function coordinatesToString(coords) {
  if (!coords) return '';
  if (coords.length >= 3) return coords[2];
  return `${coords[1]},${coords[0]}`;
}

function FormCoordinates({ name, label }) {
  const classes = useStyles();
  const { values, setFieldValue } = useFormik();
  return (
    <FormGroup>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <TextField
        onChange={(e) => setFieldValue(name, parseCoordinates(e.target.value))}
        className={classes.field}
        value={values && coordinatesToString(values[name])}
        name={name}
      />
    </FormGroup>
  );
}

export default FormCoordinates;
