import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import uuidv1 from 'uuid/v1';

import useFetchApi from '../utility/useFetchApi';
import AuthProvider from '../providers/AuthProvider';
import ConfirmDialog, { useConfirmDialog } from '../ui-elements/ConfirmDialog';
import fetchApi from '../utility/fetchApi';
import moment from 'moment';
import PaperRows from '../ui-elements/PaperRows';
import { Trans } from 'react-i18next';
import useT from '../utility/useT';
import { makeStyles } from '@material-ui/core/styles';
import DashboardCard from '../ui-elements/DashboardCard';
import DeleteIcon from '@material-ui/icons/Delete';
import GaugeChart from './GaugeChart';
import WidgetSectionList from '../dashboard/WidgetSectionList';
import useRefreshTime from '../utility/useRefreshTime';

export function getGaugeValueText({ flowCumecs, heightCentimeters }) {
  const gaugeValue = [];
  if (flowCumecs) gaugeValue.push(`${flowCumecs} m3/s`);
  if (heightCentimeters) gaugeValue.push(`${heightCentimeters} cm`);
  return gaugeValue.join(', ');
}

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  river: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    margin: '0 5px 0 5px',
  },
  gauge: {
    fontFamily: theme.typography.fontFamily,
  },
  source: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'italic',
    margin: '0 5px 0 5px',
  },
  myGaugeInfo: {
    margin: '0 5px 0 5px',
  },
  timeLine: {
    margin: '0 5px 0 5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerDiv: {
    margin: '5px',
  },
  myGaugesInfo: {
    fontFamily: theme.typography.fontFamily,
  },
}));

export function GaugeSectionList({ river, engine, gauge }) {
  const urlParams = {
    gaugeRiver: river,
    gaugeEngine: engine,
    gaugeName: gauge,
    findMeasurement: 1,
  };
  const t = useT();
  const sections = useFetchApi.get('section-info/find', urlParams);
  if (!_.isArray(sections)) return null;
  return (
    <DashboardCard title={t('Sections')}>
      <WidgetSectionList sections={sections} />
    </DashboardCard>
  );
}

function GaugeView({
  river,
  engine,
  gauge,
  subscriptionId,
  paperClass: PaperClass = PaperRows,
  showMyGaugesInfo,
  showRemoveButton,
  onRemove,
}) {
  const classes = useStyles();
  const { auth } = AuthProvider.useAuth();
  const { accountId } = auth || {};
  const t = useT();

  const [addedSubscriptionId, setAddedSubscriptionId] = React.useState(null);

  const [openConfirmDelete, confirmDeleteDialogProps] = useConfirmDialog();
  const [refreshTime] = useRefreshTime();

  const lastMeasurement = useFetchApi(
    `last-measurement/find?river=${river}&gauge=${gauge}&engine=${engine}`,
    [],
    undefined,
    [refreshTime]
  )[0];
  const { sourceName, measuredTm, heightCentimeters, flowCumecs } = lastMeasurement || {};

  const handleAddToMyGauges = async () => {
    const subscriptionId = uuidv1();
    const newSubscription = await fetchApi.post(
      'gauge-subscription/save',
      {
        entity: {
          accountId,
          subscriptionId,
          river,
          gauge,
          engine,
          sourceName,
        },
      },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    setAddedSubscriptionId(newSubscription.subscriptionId);
  };

  const existingSubscription = useFetchApi.get('gauge-subscription/find', {
    reloadToken: addedSubscriptionId,
    accountId,
    river,
    gauge,
    engine,
  })[0];

  const handleRemoveFromMyGauges = async () => {
    await fetchApi.post(
      'gauge-subscription/save',
      {
        entity: {
          subscriptionId: existingSubscription.subscriptionId,
          isDeleted: true,
        },
      },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    setAddedSubscriptionId(null);
    if (onRemove) onRemove();
  };

  return (
    <>
      <DashboardCard title={gauge}>
        <div className={classes.river}>{river}</div>
        <div className={classes.source}>{sourceName}</div>
        <div className={classes.timeLine}>
          <div>{moment(measuredTm).format('DD.MM HH:mm')}</div>
          <div>{moment(measuredTm).fromNow()}</div>
        </div>
        {auth && showMyGaugesInfo && (
          <div className={classes.myGaugeInfo}>
            {existingSubscription ? (
              <div className={classes.myGaugesInfo}>
                <Trans>Gauge is in my gauges</Trans> (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    handleRemoveFromMyGauges();
                  }}
                >
                  <Trans>remove</Trans>
                </a>
                )
              </div>
            ) : (
              <div className={classes.myGaugesInfo}>
                <Trans>Gauge is not in my gauges</Trans> (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    handleAddToMyGauges();
                  }}
                >
                  <Trans>add</Trans>
                </a>
                )
              </div>
            )}
          </div>
        )}
      </DashboardCard>
      <DashboardCard title={getGaugeValueText({ flowCumecs, heightCentimeters })} preserveTitleCase>
        <GaugeChart
          river={river}
          engine={engine}
          gauge={gauge}
          subscriptionId={subscriptionId}
          additionalSettingsKey={subscriptionId}
          additionalButtons={
            showRemoveButton && (
              <Button variant="contained" className={classes.button} onClick={openConfirmDelete}>
                <DeleteIcon />
              </Button>
            )
          }
        />
      </DashboardCard>
      <GaugeSectionList river={river} gauge={gauge} engine={engine} />

      <ConfirmDialog
        t_title="Really remove gauge?"
        t_positiveButton="Remove"
        t_negativeButton="Cancel"
        onConfirm={handleRemoveFromMyGauges}
        {...confirmDeleteDialogProps}
      />
    </>
  );
}

export default GaugeView;
