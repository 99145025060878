import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import NameValueView from '../ui-elements/NameValueView';
import { waterLevelNames, getWaterLevelColor, waterLevelColors } from '../icons/WaterLevel';
import useT from '../utility/useT';
import DashboardCard from '../ui-elements/DashboardCard';
import intersperse from '../utility/intersperse';
import GaugeChart from '../gauges/GaugeChart';
import round10 from '../utility/round10';
import getSectionLines from '../utility/getSectionLines';

const useStyles = makeStyles(theme => ({
  river: {
    fontSize: '150%',
    // marginRight: '2rem',
    fontFamily: theme.typography.fontFamily,
    flexGrow: 1,
  },
  section: {
    fontSize: '150%',
    // marginRight: '2rem',
    fontFamily: theme.typography.fontFamily,
    flexGrow: 1,
    color: theme.sectionColor,
  },

  flexLine: {
    display: 'flex',
  },
  avatar: {
    margin: theme.spacing.unit,
  },
  waterLevelValue: {
    fontWeight: 'bold',
  },
  waterLevel: {
    paddingLeft: '5px',
    paddingRight: '5px',
    borderRadius: '5px',
  },
}));

function pushAccountLine(chartLines, accountWaterLevels, gauge, river, field, color) {
  if (!accountWaterLevels) return;
  const myLevel = accountWaterLevels.find(x => x.gauge == gauge && x.river == river);
  // console.log('accountWaterLevels', accountWaterLevels, gauge, river, myLevel);
  if (!myLevel) return;
  const current = myLevel[field];
  if (!current) return;
  if (current.heightCentimeters) {
    chartLines.push({
      label: `${moment(current.startTm).format('D.M.YYYY')}:${round10(current.heightCentimeters)}`,
      color,
      unit: 'cm',
      value: current.heightCentimeters,
    });
  }
  if (current.flowCumecs) {
    chartLines.push({
      label: `${moment(current.startTm).format('D.M.YYYY')}:${round10(current.flowCumecs)}`,
      color,
      unit: 'm3/s',
      value: current.flowCumecs,
    });
  }
}

function SectionGaugesWidget({ sectionId, gauges, waterLevel, waterLevelPercent, includeGrid, accountWaterLevels }) {
  const classes = useStyles();
  const t = useT();

  const waterLevelNameComposed = waterLevelNames[waterLevel] ? [waterLevelNames[waterLevel]] : [];
  if (waterLevelPercent) waterLevelNameComposed.push(`${waterLevelPercent}%`);

  return (
    <>
      {(gauges || []).map((gauge, index) => {
        const gaugeName = gauge.gauge;
        const { measuredTm, heightCentimeters, flowCumecs, gaugeEngine, river } = gauge;

        const valueItems = [];
        if (flowCumecs) {
          // valueItems.push(`${flowCumecs} m3/s`);
          valueItems.push(<span className={classes.waterLevelValue}>{flowCumecs} m3/s</span>);
        }
        if (heightCentimeters) {
          // valueItems.push(`${heightCentimeters} cm`);
          valueItems.push(<span className={classes.waterLevelValue}>{heightCentimeters} cm</span>);
        }
        if (measuredTm) {
          valueItems.push(moment(measuredTm).format('DD.MM HH:mm'));
        }

        const limitItems = [];
        const { unit, lw, mw, hw } = gauge;
        if (lw) limitItems.push(`LW: ${lw} ${unit}`);
        if (mw) limitItems.push(`MW: ${mw} ${unit}`);
        if (hw) limitItems.push(`HW: ${hw} ${unit}`);
        const limitValue = limitItems.length > 0 ? limitItems.join(', ') : undefined;

        const chartLines = [];
        getSectionLines(gauge, chartLines);

        pushAccountLine(chartLines, accountWaterLevels, gauge.gauge, river, 'min', '#600');
        pushAccountLine(chartLines, accountWaterLevels, gauge.gauge, river, 'max', '#060');
        pushAccountLine(chartLines, accountWaterLevels, gauge.gauge, river, 'last', '#006');

        return (
          <>
            <DashboardCard
              includeGrid={includeGrid}
              title={index === 0 ? t('Gauge (primary)') : t('Gauge {{index}}', { index: index + 1 })}
              link={`/gauges/${encodeURIComponent(gaugeEngine)}/${encodeURIComponent(river)}/${encodeURIComponent(
                gaugeName
              )}`}
            >
              <NameValueView t_name="Gauge" value={gaugeName} />
              <NameValueView
                t_name="Current value"
                value={valueItems.length > 0 ? <>{intersperse(valueItems, ', ')}</> : null}
              />
              <NameValueView
                t_name="Current water level"
                value={
                  waterLevelNameComposed.length > 0 ? (
                    <span className={classes.waterLevel} style={{ backgroundColor: getWaterLevelColor(waterLevel) }}>
                      {waterLevelNameComposed.join(': ')}
                    </span>
                  ) : null
                }
              />
              <NameValueView t_name="Limits" value={limitValue} />

              <GaugeChart
                river={river}
                engine={gaugeEngine}
                gauge={gaugeName}
                lines={chartLines}
                additionalSettingsKey={sectionId}
              />
            </DashboardCard>
          </>
        );
      })}
    </>
  );
}

export default SectionGaugesWidget;
