import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import useReactRouter from 'use-react-router';
import BoatIcon from '../icons/Boat';
import AuthProvider from '../providers/AuthProvider';
import { useSnackbar } from 'notistack';
import fetchApi from '../utility/fetchApi';
import processSaveResponse from '../utility/processSaveResponse';
import { withProps } from 'recompose';
import useT from '../utility/useT';
import { emotionTypes } from '../ui-elements/EmojiChooser';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 16px',
    width: '100%',
    position: 'relative',
  },
  flexLine: {
    display: 'flex',
    width: '100%',
  },
  displayName: {
    fontWeight: 'bold',
    flexGrow: 1,
  },
  sportClub: {
    flexGrow: 1,
  },
  difficulty: {
    flexGrow: 1,
  },
  nickname: {},
  eventCount: {},
  boatModel: {
    marginRight: '1rem',
  },
  followsMe: {
    color: theme.palette.primary.main,
    marginRight: '3px',
  },
  isFollowing: {
    color: theme.palette.secondary.main,
  },
  isNotFollowing: {
    color: '#AAAAAA',
  },
  followButton: {
    border: '1px solid gray',
    borderRadius: '15px',
    backgroundColor: '#EEEEEE',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  emotion: {
    fontSize: '16pt',
  },
}));

export default function PeopleListItem({
  displayName,
  boatModel,
  accountId,
  boatType,
  boatColor,
  diaryItemCount,
  sportClub,
  difficulty,
  nickname,
  followedAccounts,
  onClick,
  isCheckedFunc,
  emotionType,
}) {
  const t = useT();
  const classes = useStyles();
  const { history } = useReactRouter();
  const { auth, setAuth } = AuthProvider.useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const isFollowed = ((auth && auth.followedAccounts) || []).includes(accountId);
  const followsMe = (followedAccounts || []).includes(auth && auth.accountId);

  let fullName = `${nickname} (${displayName})`;
  if (!nickname) fullName = displayName;
  if (!displayName) fullName = displayName;

  let difficultyText = `${difficulty} (${boatModel})`;
  if (!difficulty) difficultyText = boatModel;
  if (!boatModel) difficultyText = difficulty;

  const saveFollowedAccounts = async followedAccounts => {
    const saveData = await fetchApi.post(
      `account-info/save`,
      { entity: { accountId: auth.accountId, followedAccounts } },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    processSaveResponse(saveData, enqueueSnackbar, 'Successfully saved follow state');
    if (saveData && saveData.authToken) {
      setAuth(saveData);
    }
  };

  const follow = async () => {
    const followedAccounts = auth.followedAccounts || [];
    await saveFollowedAccounts([...followedAccounts, accountId]);
  };

  const unfollow = async () => {
    const followedAccounts = auth.followedAccounts || [];
    await saveFollowedAccounts(followedAccounts.filter(x => x !== accountId));
  };

  const accountObject = { displayName, accountId, boatType, boatColor };

  const isChecked = isCheckedFunc && isCheckedFunc(accountObject);
  const checkedStyle = isChecked ? { border: '4px solid black' } : {};

  return (
    <ListItem
      alignItems="flex-start"
      button
      onClick={onClick ? () => onClick(accountObject) : () => history.push(`/people/detail/${accountId}`)}
    >
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: boatColor, ...checkedStyle }}>
          <BoatIcon size={25} boatType={boatType} />
        </Avatar>
      </ListItemAvatar>
      <div className={classes.root}>
        <div className={classes.flexLine}>
          <div className={classes.displayName}>{fullName}</div>

          {/* {!isFollowed && (
            <Button
              size="small"
              onClick={e => {
                follow();
                e.stopPropagation();
              }}
            >
              Follow
            </Button>
          )}
          {isFollowed && (
            <Button
              size="small"
              onClick={e => {
                unfollow();
                e.stopPropagation();
              }}
            >
              Unfollow
            </Button>
          )} */}
          {followsMe && <span className={classes.followsMe}>&#10004;</span>}
          {isFollowed && !!onClick && <span className={classes.isFollowing}>&#10004;</span>}
          {!onClick && !emotionType && !isFollowed && (
            <span
              className={`${classes.isNotFollowing} ${classes.followButton}`}
              onClick={e => {
                follow();
                e.stopPropagation();
              }}
            >
              F
            </span>
          )}
          {!onClick && !emotionType && isFollowed && (
            <span
              className={`${classes.isFollowing} ${classes.followButton}`}
              onClick={e => {
                unfollow();
                e.stopPropagation();
              }}
            >
              &#10004;
            </span>
          )}
          <span className={classes.emotion} >{(emotionType && emotionTypes[emotionType]) || null}</span>
        </div>
        <div className={classes.flexLine}>
          <div className={classes.sportClub}>{sportClub}</div>
        </div>
        {/* <div className={classes.flexLine}>
          <div className={classes.nickname}>
            {nickname} {followsMe && <span className={classes.followsMe}>&#10004;</span>}
            {isFollowed && <span className={classes.isFollowing}>&#10004;</span>}
          </div>
        </div> */}
        <div className={classes.flexLine}>
          <Hidden xsDown>
            <div className={classes.difficulty}>{difficultyText}</div>
          </Hidden>
          <Hidden smUp>
            <div className={classes.difficulty}>{difficulty}</div>
          </Hidden>
          <div className={classes.eventCount}>{t('{{count}} events', { count: diaryItemCount })}</div>
        </div>
      </div>
      {/* <ListItemText
        primary={displayName}
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              className={classes.inline}
              color="textPrimary"
            >
              {boatModel}, {difficulty}
            </Typography>
            <span>{`${diaryItemCount} events`}</span>
          </React.Fragment>
        }
      /> */}

      {/* <Avatar style={{backgroundColor: boatColor}}>
          <BoatIcon size={25} boatType={boatType} />
        </Avatar> 
        <div>{displayName}</div>
        */}
    </ListItem>
  );
}

PeopleListItem.Custom = (onClick, isCheckedFunc) => withProps({ onClick, isCheckedFunc })(PeopleListItem);
