import React from 'react';
import AppLayout from '../layout/AppLayout';
import EventGroupListControl from './EventGroupListControl';
import moment from 'moment';
import _ from 'lodash';
import AuthProvider from '../providers/AuthProvider';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';
import useFetchApi from '../utility/useFetchApi';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import ImageStars from '../icons/ImageStars';
import PaperRows from '../ui-elements/PaperRows';
import ImageBeers from '../icons/ImageBeers';
import NameValueView from '../ui-elements/NameValueView';
import WaterLevel, { waterLevelNames, getWaterLevelColor } from '../icons/WaterLevel';
import PaperCard from '../ui-elements/PaperCard';
import TeamMembersView from '../people/TeamMembersView';
import Flag from '../icons/Flag';
import SmallPaddlerAvatar from '../people/SmallPaddlerAvatar';
import { Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import DifficultyIcon from '../icons/Difficulty';
import median from '../utility/median';

const useStyles = makeStyles(theme => ({
  flexLine: {
    display: 'flex',
  },
  grower: {
    flexGrow: 1,
  },

  river: {
    fontSize: '150%',
    // marginRight: '2rem',
    fontFamily: theme.typography.fontFamily,
    flexGrow: 1,
    marginLeft: '10px',
  },
  section: {
    fontSize: '120%',
    // marginRight: '2rem',
    fontFamily: theme.typography.fontFamily,
    flexGrow: 1,
    // color: theme.sectionColor,
    marginLeft: '10px',
  },

  riverContainer: {
    display: 'flex',
    padding: '10px',
    backgroundColor: '#ccc',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  sectionContainer: {
    display: 'flex',
    padding: '10px',
    backgroundColor: '#ddd',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },

  waterLevel: {
    marginBottom: 15,
    textAlign: 'center',
  },
}));

function EventHeader({ river, section, sectionId, difficulty, enjoyLevel, country, waterLevel }) {
  const classes = useStyles();
  const { history } = useReactRouter();
  return (
    <>
      <div className={classes.riverContainer} onClick={() => history.push(`/rivers/${encodeURIComponent(river)}`)}>
        <Flag name={country} size={40} />
        <div className={classes.river}>{river}</div>
      </div>

      <div className={classes.sectionContainer} onClick={() => history.push(`/sections/detail/${sectionId}`)}>
        <DifficultyIcon difficulty={difficulty} />
        <div className={classes.section}>{section}</div>
        <ImageStars size={25} stars={enjoyLevel} />
      </div>
      <div className={classes.waterLevel} style={{ backgroundColor: getWaterLevelColor(waterLevel) }}>
        {waterLevelNames[waterLevel]}
      </div>
    </>
  );
}

export default EventHeader;
