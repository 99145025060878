import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import uuidv1 from 'uuid/v1';
import useReactRouter from 'use-react-router';
import { Trans } from 'react-i18next';
import NameValueView from '../ui-elements/NameValueView';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from '@material-ui/icons/Backspace';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';

import AuthProvider from '../providers/AuthProvider';
import fetchApi, { getApiBase, getStorageBase } from '../utility/fetchApi';
import PaperCard from '../ui-elements/PaperCard';
import EditorPage from '../ui-elements/EditorPage';
import getAccountInfoName from '../utility/getAccountInfoName';

import FormTextField from '../forms/FormTextField';
import useT from '../utility/useT';
import useLocationParam, { getCurrentLocation, parseGps } from '../utility/useLocationParam';

function playspotToForm(playspot) {
  const { coordinates } = playspot;
  const gps = coordinates ? `${coordinates[1]}, ${coordinates[0]}` : '';
  return {
    ...playspot,
    gps,
  };
}

function formToPlayspot(playspot) {
  const { gps } = playspot;
  return {
    ..._.omit(playspot, ['gps']),
    coordinates: parseGps(gps),
  };
}

function EditPlayspotPage() {
  const { match, history } = useReactRouter();
  const { playspotId: paramsPlayspotId } = match.params;
  const { auth } = AuthProvider.useAuth();
  const t = useT();

  async function fetchPlayspot() {
    if (paramsPlayspotId) {
      const res = ((await fetchApi(`playspot-info/find?playspotId=${paramsPlayspotId}`, {
        auth,
      })) || [])[0];
      return res;
    } else {
      const location = await getCurrentLocation();
      return {
        accountId: auth.accountId,
        accountName: getAccountInfoName(auth),
        coordinates: location ? [location.longitude, location.latitude] : undefined,
      };
    }
  }

  return (
    <EditorPage
      idName="playspotId"
      localStorageName="EditingPlayspot"
      serviceName="playspot-info"
      objectTypeText={t('playspot')}
      fetchObject={fetchPlayspot}
      detailRoute={id => `/playspots/detail/${id}`}
      parentRoute={paramsPlayspotId ? `/playspots/detail/${paramsPlayspotId}` : '/playspots'}
      storageToForm={playspotToForm}
      formToStorage={formToPlayspot}
      showDelete
      autoDiscard
    >
      <Grid container alignItems="stretch">
        <PaperCard>
          <FormTextField label={t('Name')} name="name" />
          <FormTextField label={t('GPS')} name="gps" />
        </PaperCard>
      </Grid>
    </EditorPage>
  );
}

export default EditPlayspotPage;
