import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import RiverIcon from '../icons/River';
import useReactRouter from 'use-react-router';
import useT from '../utility/useT';

export default function RiverListItem({ river, diaryItemCount, gaugeCount, sectionCount }) {
  const { history } = useReactRouter();
  const t = useT();
  const items = [
    t('{{count}} gauges', { count: gaugeCount }),
    t('{{count}} sections', { count: sectionCount }),
    t('{{count}} events', { count: diaryItemCount }),
  ];
  return (
    <ListItem button alignItems="flex-start" onClick={() => history.push(`/rivers/${encodeURIComponent(river)}`)}>
      <ListItemAvatar>
        <RiverIcon size={40} />
      </ListItemAvatar>
      <ListItemText primary={river} secondary={items.join(', ')} />
    </ListItem>
  );
}
