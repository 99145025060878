import React from 'react';
import NameValueView from '../ui-elements/NameValueView';
import useFetchApi from '../utility/useFetchApi';
import { Trans } from 'react-i18next';
import Button from '@material-ui/core/Button';
import AuthProvider from '../providers/AuthProvider';
import fetchApi from '../utility/fetchApi';
import EditDescriptionDialog from './EditDescriptionDialog';
import getAccountInfoName from '../utility/getAccountInfoName';
import useT from '../utility/useT';

export default function DescriptionDetailView({ target, sectionId, buttonText, labelText, showOnlyMy }) {
  const [reloadToken, setReloadToken] = React.useState(0);
  const t = useT();
  const { auth } = AuthProvider.useAuth();
  const items = useFetchApi(`description-info/find?target=${target}&sectionId=${sectionId || ''}`, [], undefined, [
    reloadToken,
    auth,
  ]);
  const itemsFiltered = showOnlyMy ? (items || []).filter(x => x.accountId == (auth && auth.accountId)) : items || [];

  const [isEditorVisible, setIsEditorVisible] = React.useState(false);

  return (
    <>
      {itemsFiltered.map(item => (
        <NameValueView name={labelText || item.accountName} value={item.text} />
      ))}

      <Button variant="contained" color="primary" onClick={() => setIsEditorVisible(true)}>
        {buttonText || t('Set my description')}
      </Button>

      {isEditorVisible && auth && (
        <EditDescriptionDialog
          open
          value={(itemsFiltered.find(x => x.accountId == auth.accountId) || {}).text || ''}
          onClose={() => setIsEditorVisible(false)}
          onDelete={async () => {
            await fetchApi.post(`description-info/unset`, {
              accountId: auth.accountId,
              target,
              sectionId,
            });
            setReloadToken(x => x + 1);
          }}
          onConfirm={async text => {
            const saveData = await fetchApi.post(
              `description-info/set`,
              {
                accountId: auth.accountId,
                accountName: getAccountInfoName(auth),
                target,
                text,
                sectionId,
              },
              {
                headers: { 'x-auth-token': auth.authToken },
                auth,
              }
            );
            setReloadToken(x => x + 1);
          }}
        />
      )}
    </>
  );
}
