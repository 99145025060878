import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import useT from '../utility/useT';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing.unit,
  },
  name: {
    color: 'gray',
  },
  value: {
    color: 'black',
    // color: theme.palette.text.main,
  },
}));

export default function NameValueView({ t_name, name, value, className, showEditor }) {
  const classes = useStyles();
  const t = useT();
  if (!value) return null;
  return (
    <div className={classes.root}>
      <Typography component="div">
        <div variant="body1" className={classes.name}>
          {t_name ? t(t_name) : name}
        </div>
        {showEditor ? (
          <TextField
            value={value}
            InputProps={{
              readOnly: true,
            }}
          />
        ) : (
          <div variant="body2" className={`${className || ''} ${classes.value}`}>
            {React.isValidElement(value)
              ? value
              : (value || '')
                  .toString()
                  .split('\n')
                  .map(line => <div>{line}</div>)}
          </div>
        )}
      </Typography>
    </div>
  );
}
