import React from 'react';
import uuidv1 from 'uuid/v1';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import stableStringify from 'json-stable-stringify';

import { getLocalStorage, setLocalStorage } from '../utility/storage-cache';
import AppLayout from '../layout/AppLayout';
import AuthProvider from '../providers/AuthProvider';
import FormikForm from '../forms/FormikForm';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import fetchApi from '../utility/fetchApi';
import ConfirmDialog, { useConfirmDialog } from '../ui-elements/ConfirmDialog';
import processSaveResponse from '../utility/processSaveResponse';
import useReactRouter from 'use-react-router';
import useRefreshTime from '../utility/useRefreshTime';
import getAccountInfoName from '../utility/getAccountInfoName';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  nowrap: {
    whiteSpace: 'nowrap',
  },
}));

function EditorPage({
  idName,
  localStorageName,
  serviceName,
  detailRoute,
  objectTypeText,
  storageToForm,
  formToStorage,
  children,
  fetchObject,
  containsSection,
  parentRoute,
  showDelete,
  autoDiscard,
  beforeSave,
}) {
  const t = useT();
  const classes = useStyles();
  async function callStorageToForm(object) {
    const res = storageToForm ? storageToForm(object) : object;
    if (containsSection && res.sectionId && !res.sectionObject) {
      res.sectionObject = ((await fetchApi(`section-info/find?sectionId=${res.sectionId}&findMeasurement=1`, {
        auth,
      })) || [])[0];
    }
    return res;
  }

  function callFormToStorage(object, idValue, accountId, accountName) {
    let sectionData = {};
    if (containsSection) {
      const { sectionObject } = object;
      if (sectionObject) {
        sectionData = {
          sectionId: sectionObject.sectionId,
          section: sectionObject.section,
          river: sectionObject.river,
        };
      }
    }

    const res = {
      ...(formToStorage ? formToStorage(object, t) : object),
      ...sectionData,
    };
    if (containsSection) {
      delete res.sectionObject;
    }
    if (!res[idName]) {
      res[idName] = idValue;
      res.accountId = accountId;
      res.accountName = accountName;
    }

    return res;
  }

  async function defaultFetchObject(idValue) {
    return ((await fetchApi(`${serviceName}/find?${idName}=${idValue}`, { auth })) || [])[0];
  }

  const { history, match } = useReactRouter();
  const paramsObjectId = match.params[idName];
  const paramsIdRef = React.useRef(paramsObjectId || uuidv1());
  const objectId = paramsIdRef.current;
  const skipSaveToStorage = React.useRef();
  const { auth } = AuthProvider.useAuth();
  const [openConfirmDiscard, confirmDiscardDialogProps] = useConfirmDialog();
  const [openConfirmDelete, confirmDeleteDialogProps] = useConfirmDialog();
  const { enqueueSnackbar } = useSnackbar();
  const originalObjectRef = React.useRef('');
  const [, callRefresh] = useRefreshTime();

  const loadObject = async ({ values, setValues }) => {
    const editingObjects = getLocalStorage(localStorageName);
    const object =
      editingObjects && editingObjects[objectId]
        ? editingObjects[objectId]
        : await (fetchObject || defaultFetchObject)(objectId);
    if (object) {
      const formObject = await callStorageToForm(object);
      originalObjectRef.current = stableStringify(formObject);
      setValues(formObject);
    }
  };

  const unloadObject = async ({ values }) => {
    if (skipSaveToStorage.current) return;
    if (autoDiscard) return;
    if (originalObjectRef.current === stableStringify(values)) return;
    const editingObjects = getLocalStorage(localStorageName);

    const newObjects = {
      ...editingObjects,
    };
    newObjects[objectId] = callFormToStorage(values, objectId, auth.accountId, getAccountInfoName(auth));
    setLocalStorage(localStorageName, newObjects);
  };

  const discard = () => {
    const editingObjects = getLocalStorage(localStorageName);
    if (editingObjects) {
      delete editingObjects[objectId];
      setLocalStorage(localStorageName, editingObjects);
    }
    skipSaveToStorage.current = true;
    history.replace(detailRoute ? detailRoute(objectId) : parentRoute);
  };

  const save = async values => {
    try {
      let entity = callFormToStorage(values, objectId, auth.accountId, getAccountInfoName(auth));
      if (beforeSave) {
        entity = await beforeSave(entity);
      }

      console.log('SAVING ENTITY', entity);
      const saveData = await fetchApi.post(
        `${serviceName}/save`,
        { entity },
        {
          headers: { 'x-auth-token': auth.authToken },
          auth,
        }
      );
      console.log('SAVE RESP', saveData);
      processSaveResponse(saveData, enqueueSnackbar, t('Successfully saved {{arg}}', { arg: objectTypeText }));
      return true;
    } catch (err) {
      console.log('SAVE ERROR', err);
      enqueueSnackbar(err.message, { variant: 'error' });
      return false;
    }
  };

  const doDelete = async () => {
    const saveData = await fetchApi.post(
      `${serviceName}/save`,
      {
        entity: {
          [idName]: objectId,
          isDeleted: true,
        },
      },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    console.log('Delete response:', saveData);
    callRefresh();
    history.goBack();
  };

  return (
    <FormikForm
      initialValues={{}}
      onSubmit={async (values, actions) => {
        skipSaveToStorage.current = true;
        if (await save(values)) {
          callRefresh();
          discard();
        }
      }}
      onLoad={loadObject}
      onUnload={unloadObject}
    >
      <AppLayout
        title={paramsObjectId ? `${t('Edit')} ${objectTypeText}` : `${t('Create')} ${objectTypeText}`}
        mainIconMeaning="back"
        parentRoute={parentRoute}
        customButtons={
          <div className={classes.nowrap}>
            <IconButton color="inherit" type="submit">
              <DoneIcon />
            </IconButton>
            {!autoDiscard && (
              <IconButton color="inherit" onClick={openConfirmDiscard}>
                <CloseIcon />
              </IconButton>
            )}
            {showDelete && (
              <IconButton color="inherit" onClick={openConfirmDelete}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        }
      >
        <Grid container alignItems="stretch">
          {children}
        </Grid>
        <ConfirmDialog
          t_title="Really discard changes?"
          t_positiveButton="Discard"
          t_negativeButton="Cancel"
          onConfirm={discard}
          {...confirmDiscardDialogProps}
        />
        <ConfirmDialog
          title={t('Really delete {{entityName}}?', { entityName: objectTypeText })}
          t_positiveButton="Delete"
          t_negativeButton="Cancel"
          onConfirm={doDelete}
          {...confirmDeleteDialogProps}
        />
      </AppLayout>
    </FormikForm>
  );
}

export default EditorPage;
