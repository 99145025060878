import React from 'react';
import _ from 'lodash';
import AppLayout from '../layout/AppLayout';
import ListControl from '../layout/ListControl';
import IconButton from '@material-ui/core/IconButton';
import useReactRouter from 'use-react-router';

import SettingsIcon from '@material-ui/icons/Settings';

import AuthProvider from '../providers/AuthProvider';

import NotificationListItem from './NotificationListItem';
import fetchApi from '../utility/fetchApi';
import requestNotifications from './requestNotifications';

function NotificationsPage() {
  const { auth } = AuthProvider.useAuth();
  const { accountId } = auth || {};
  const { history } = useReactRouter();

  React.useEffect(() => {
    if (accountId) {
      fetchApi.post(`notification-info/reset-for-user?accountId=${accountId}`);
    }
    requestNotifications(accountId);
  }, []);

  return (
    <AppLayout
      t_title="Notifications"
      mainIconMeaning="top"
      showRefreshButton
      customButtons={
        <>
          <IconButton color="inherit" onClick={() => history.push('/profile/notifications')}>
            <SettingsIcon />
          </IconButton>
        </>
      }
    >
      <ListControl
        routerStateField="notifications"
        url={`notification-info/find?targetAccountId=${auth.accountId}&sort=-createTm`}
        component={NotificationListItem}
      />
    </AppLayout>
  );
}

export default NotificationsPage;
