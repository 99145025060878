import React from 'react';
import fetchApi from './fetchApi';
import AuthProvider from '../providers/AuthProvider';

export default function useFetchApiLoading(url, defValue = []) {
  const [value, setValue] = React.useState(defValue);
  const [loading, setLoading] = React.useState(true);
  const { auth } = AuthProvider.useAuth();

  async function loadValue() {
    setLoading(true);
    const data = await fetchApi(url, { auth });
    setValue(data === undefined ? defValue : data);
    setLoading(false);
  }
  React.useEffect(() => {
    loadValue();
  }, [url]);

  return [value, loading];
}
