import React from 'react';
import { Button } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

import AppLayout from '../layout/AppLayout';
import AuthProvider from '../providers/AuthProvider';
import FormikForm from '../forms/FormikForm';
import FormTextField from '../forms/FormTextField';
import useFetchApi from '../utility/useFetchApi';
import PaperRows from '../ui-elements/PaperRows';

const useStyles = makeStyles(theme => ({
  info: {
    margin: '15px',
  },
}));

function ResetPasswordPage() {
  const { match, history } = useReactRouter();
  const { accountId, resetToken } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { callDoResetPassword } = AuthProvider.useAuth();

  const accountInfo = useFetchApi.get('account-info/find-by-id', { accountId }, {});

  return (
    <AppLayout title="Reset password" mainIconMeaning="top">
      <FormikForm
        onSubmit={async values => {
          const { password, password2 } = values;
          if (await callDoResetPassword(accountId, resetToken, password, password2, enqueueSnackbar)) {
            history.push('/');
          }
        }}
      >
        <PaperRows>
          <div className={classes.info}>You have requested password reset for account {accountInfo.displayName}</div>
          <FormTextField label="New password" name="password" type="password" />
          <FormTextField label="Retype password" name="password2" type="password" />
          <Button type="submit" color="primary" variant="contained">
            Reset password
          </Button>
        </PaperRows>
      </FormikForm>
    </AppLayout>
  );
}

export default ResetPasswordPage;
