import React from 'react';
import DashboardCard from '../ui-elements/DashboardCard';
import useT from '../utility/useT';
import useFetchApi from '../utility/useFetchApi';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import useReactRouter from 'use-react-router';

const useStyles = makeStyles(theme => ({
  gaugeRoot: {
    margin: '10px',
    cursor: 'pointer',
  },
  line2: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  line1: {
    display: 'flex',
  },
  source: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexShrink: 1,
    color: 'gray',
  },
  time: {
    whiteSpace: 'nowrap',
  },
  value: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  grower: {
    flexGrow: 1,
  },
  gauge: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  separator: {
    margin: '0px 3px',
  },
}));

function GaugeItem({ gaugeObj, showRiver }) {
  const classes = useStyles();
  const { match, history } = useReactRouter();
  const infoData = [];
  const { gauge, engine, river, heightCentimeters, flowCumecs, measuredTm, sourceName } = gaugeObj;
  if (flowCumecs) infoData.push(`${flowCumecs} m3/s`);
  if (heightCentimeters) infoData.push(`${heightCentimeters} cm`);

  return (
    <div className={classes.gaugeRoot} onClick={() => history.push(`/gauges/${engine}/${river}/${gauge}`)}>
      <div className={classes.line1}>
        <div className={classes.gauge}>{gauge}</div>
        {showRiver && <div className={classes.separator}> - </div>}
        {showRiver && <div className={classes.river}>{river}</div>}
        <div className={classes.grower} />
        <div className={classes.value}>{infoData.join(', ')}</div>
      </div>
      <div className={classes.line2}>
        <div className={classes.source}>{sourceName}</div>
        <div className={classes.time}>{moment(measuredTm).fromNow()}</div>
      </div>
    </div>
  );
}

export default function GaugeListWidget({ filter, includeGrid, showRiver, title, link, emptyContent }) {
  const t = useT();
  const [reloadToken, setReloadToken] = React.useState(0);
  const gauges = useFetchApi.get('last-measurement/find', { ...filter, reloadToken }, null);
  return (
    <DashboardCard
      title={title || t('Gauges')}
      includeGrid={includeGrid}
      link={link}
      onReload={() => setReloadToken(x => x + 1)}
      isLoading={gauges == null}
    >
      {(gauges || []).map(gauge => (
        <GaugeItem key={`${gauge.river}|${gauge.gauge}|${gauge.engine}`} gaugeObj={gauge} showRiver={showRiver} />
      ))}
      {!!gauges && gauges.length == 0 && emptyContent}
    </DashboardCard>
  );
}
