import React from 'react';
import _ from 'lodash';

import ChooseSectionDialog from '../sections/ChooseSectionDialog';
import FormItemList from './FormItemList';

function FormSectionList({ label }) {
  return (
    <FormItemList
      routerStateField="choose-sections"
      label={label}
      dialog={ChooseSectionDialog}
      itemsField="sections"
      idField="sectionId"
      serviceName="section-info"
      pickFunc={section => _.pick(section, ['section', 'river', 'sectionId'])}
      getLinkFunc={section => `/sections/detail/${section.sectionId}`}
      renderItem={section => `${section.river} - ${section.section}`}
    />
  );
}

export default FormSectionList;
