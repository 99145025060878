import React from 'react';
import useReactRouter from 'use-react-router';
import AppLayout from '../layout/AppLayout';
import getEditingObjects from '../utility/getEditingObjects';
import TrainingListControl from './TrainingListControl';
import AuthProvider from '../providers/AuthProvider';
import Grid from '@material-ui/core/Grid';
import MyTrainingsWidget from '../dashboard/MyTrainingsWidget';
import useT from '../utility/useT';
import { makeStyles, useTheme, withTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import useFetchApi from '../utility/useFetchApi';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import useStateDialog from '../utility/useStateDialog';
import NewTrainingDialog from './NewTrainingDialog';

const useStyles = makeStyles(theme => ({
  root: {},

  fab: {
    margin: theme.spacing.unit,
    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
  },
}));

function TrainingsPage() {
  const { history } = useReactRouter();
  const { auth } = AuthProvider.useAuth();
  const classes = useStyles();
  const { accountId } = auth || {};
  // const [openSectionDialog, sectionDialogProps] = useRouterDialog('choose-section');
  const t = useT();
  const [openTrainingDialog, trainingDialogProps] = useStateDialog();

  return (
    <AppLayout title={t('Trainings')} mainIconMeaning="top" appBarPosition="fixed">
      <Hidden smDown>
        <MyTrainingsWidget count={8} />
      </Hidden>
      <Hidden mdUp>
        <MyTrainingsWidget count={4} />
      </Hidden>
      {/* <Grid item xs={12} md={6}>
        <MyTrainingsWidget />
      </Grid> */}

      <TrainingListControl
        routerStateField="listLog"
        filterParams={`&accountId=${accountId}`}
        extraItems={getEditingObjects('EditingTrainings', 'trainingId')}
      />

      <Fab color="secondary" aria-label="Add" className={classes.fab} onClick={openTrainingDialog}>
        <AddIcon />
      </Fab>
      <NewTrainingDialog {...trainingDialogProps} />
    </AppLayout>
  );
}

export default TrainingsPage;
