import React from 'react';
import AppLayout from '../layout/AppLayout';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import useReactRouter from 'use-react-router';
import Grid from '@material-ui/core/Grid';
import NotificationsIcon from '@material-ui/icons/Notifications';

import useOnlineStatus from '../utility/useOnlineStatus';
import TopEventsWidget from './TopEventsWidget';
import MyEventStatsWidget from './MyEventStatsWidget';
import InterestingSectionsWidget from './InterestingSectionsWidget';
import DashboardHeaderWidget from './DashboardHeaderWidget';
import NearestTripWidget from './NearestTripWidget';
import GaugeListWidget from '../gauges/GaugeListWidget';
import AuthProvider from '../providers/AuthProvider';
import useT from '../utility/useT';

import useFetchApi from '../utility/useFetchApi';
import MyTrainingsWidget from './MyTrainingsWidget';
import RiverSectionSearchList from './RiverSectionSearchList';
import RiversWidget from './RiversWidget';

function getSearchValue(history, routerStateField) {
  const search = history.location && history.location.state && history.location.state[`${routerStateField}_search`];
  return search;
}

function DashboardPage() {
  const { history } = useReactRouter();
  const { auth } = AuthProvider.useAuth();
  const t = useT();
  const { accountId } = auth || {};
  const dashboardInfo = useFetchApi.get(
    'v1/dashboard/notifications',
    {
      accountId,
    },
    {}
  );

  const [filter, setFilter] = React.useState(getSearchValue(history, 'homepage'));

  const handleRunFilter = filter => {
    setFilter(filter);
  };

  const online = useOnlineStatus();

  return (
    <AppLayout
      title={t('RiverLog')}
      mainIconMeaning={filter ? 'back' : 'menu'}
      showLanguageSelector
      customButtons={
        <>
          <IconButton color="inherit" onClick={() => history.push('/notifications')}>
            <Badge badgeContent={dashboardInfo.notificationCount || 0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </>
      }
    >
      <DashboardHeaderWidget onRunFilter={handleRunFilter} filter={filter} />

      {!filter && (
        <Grid container alignItems="stretch">
          <Grid item xs={12} md={6}>
            <MyEventStatsWidget />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <MyTrainingsWidget />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TopEventsWidget />
          </Grid>
          <Grid item xs={12} md={6}>
            <RiversWidget
              filter={{ favoriteAccountId: auth.accountId }}
              title={t('Favorite rivers')}
              emptyContent={<div>{t('You have no favorite rivers')}</div>}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InterestingSectionsWidget hasEvents />
          </Grid>
          <Grid item xs={12} md={6}>
            <InterestingSectionsWidget hasEvents={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <GaugeListWidget
              filter={{ subscriptionAccountId: auth.accountId }}
              title={t('My gauges')}
              link="/my-gauges"
              showRiver
              emptyContent={<div>{t('You have no favorite gauges')}</div>}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NearestTripWidget />
          </Grid>
        </Grid>
      )}

      <RiverSectionSearchList filter={filter} />
    </AppLayout>
  );
}

export default DashboardPage;
