import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing.unit,
  },
  name: {
    color: 'gray',
  },
  value: {
    color: 'black',
    // color: theme.palette.text.main,
  },
  flexLine: {
    display: 'flex',
  },
}));

export default function SmallItemListView({ items, label, onDelete, getLinkFunc, renderItem }) {
  const classes = useStyles();
  if (!items || items.length === 0) return null;
  return (
    <div className={classes.root}>
      <Typography component="div">
        {label && (
          <div variant="body1" className={classes.name}>
            {label}
          </div>
        )}
        <div variant="body2" className={classes.value}>
          {items.map(item => (
            <div className={classes.flexLine}>
              {!onDelete ? <Link to={getLinkFunc(item)}>renderItem(item)</Link> : renderItem(item)}
              {onDelete && (
                <IconButton
                  aria-label="Delete"
                  className={classes.deleteButton}
                  onClick={() => onDelete(item)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          ))}
        </div>
      </Typography>
    </div>
  );
}
