import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import useT from '../utility/useT';
import TextField from '@material-ui/core/TextField';

function EditDescriptionDialog({ value, onClose, onConfirm, onDelete, ...other }) {
  const [current, setCurrent] = React.useState(value);

  const t = useT();

  return (
    <Dialog onClose={onClose} aria-labelledby="confirm-dialog-title" {...other}>
      <DialogTitle id="confirm-dialog-title">{t('Description')}</DialogTitle>
      <DialogContent>
        <TextField
          onChange={e => setCurrent(e.target.value)}
          value={current}
          multiline
          inputRef={input => {
            if (input) setTimeout(() => input.focus(), 100);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('Cancel')}
        </Button>
        <Button
          onClick={() => {
            onClose();
            onDelete();
          }}
          color="primary"
        >
          {t('Delete')}
        </Button>
        <Button
          onClick={() => {
            onConfirm(current);
            onClose();
          }}
          color="primary"
          autoFocus
        >
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditDescriptionDialog;
