import React from 'react';
import AppLayout from '../layout/AppLayout';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';
import SectionListControl from './SectionListControl';

function SectionsNearPage() {
  const t = useT();
  const { history, match } = useReactRouter();
  const { latitude, longitude } = match.params;

  return (
    <AppLayout title={t('Interesting sections nearby')} mainIconMeaning="top">
      <SectionListControl
        routerStateField="listAll"
        filterParams={`&latitude=${latitude}&longitude=${longitude}`}
        defaultFilters={{ isRunnable: 'true' }}
      />
    </AppLayout>
  );
}

export default SectionsNearPage;
