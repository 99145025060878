import React from 'react';
import AppLayout from '../layout/AppLayout';
import getEditingObjects from '../utility/getEditingObjects';
import EventListControl from './EventListControl';
import AuthProvider from '../providers/AuthProvider';
import CreateEventFromSectionDialog from './CreateEventFromSectionDialog';
import TabControl from '../layout/RouteTabControl';
import useRouterDialog from '../utility/useRouterDialog';
import { getApiBase } from '../utility/fetchApi';
import useT from '../utility/useT';

function EventsPage() {
  const { auth } = AuthProvider.useAuth();
  const { accountId } = auth || {};
  const [openSectionDialog, sectionDialogProps] = useRouterDialog('choose-section');
  const t = useT();

  return (
    <AppLayout
      title={t('Runs')}
      mainIconMeaning="top"
      showRefreshButton
      shareMenuItems={[
        {
          t_label: 'Export to Excel',
          handler: () => window.open(`${getApiBase()}/excel-export/events?accountId=${accountId}&pi=3.14`),
        },
      ]}
    >
      {auth ? (
        <EventListControl
          routerStateField="listLog"
          filterParams={`&accountId=${accountId}`}
          extraItems={getEditingObjects('EditingEvents', 'diaryId')}
          onAdd={openSectionDialog}
        />
      ) : null}
      <CreateEventFromSectionDialog
        {...sectionDialogProps}
        // skipRedirectToEvents
      />
    </AppLayout>
  );
}

export default EventsPage;
