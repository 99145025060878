import React from 'react';
import Grid from '@material-ui/core/Grid';

import FormTextField from '../forms/FormTextField';
import FormCheckBox from '../forms/FormCheckBox';
import FormAutocomplete from '../forms/FormAutocomplete';
import FormDifficulty from '../forms/FormDifficulty';
import FormRating from '../forms/FormRating';
import FormSelect from '../forms/FormSelect';
import PaperCard from '../ui-elements/PaperCard';
import EditorPage from '../ui-elements/EditorPage';
import GaugeListEditor from '../gauges/GaugeListEditor';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';

const CALIBRATION_FIELDS = ['unit', 'lw', 'mwMin', 'mw', 'mwMAx', 'hw'];

function sectionToForm(section) {
  return GaugeListEditor.storageToForm(CALIBRATION_FIELDS)(section);
}

function formToSection(section) {
  return GaugeListEditor.formToStorage(CALIBRATION_FIELDS)(section);
}

function GaugeEditControl({ name }) {
  const t = useT();
  return (
    <Grid container>
      <Grid item xs={4}>
        <FormSelect label="Unit" name={`${name}_unit`}>
          <option value="">({t('not set')})</option>
          <option>cm</option>
          <option>m3/s</option>
        </FormSelect>
      </Grid>
      <Grid item xs={4}>
        <FormTextField label="LW" name={`${name}_lw`} type="number" />
      </Grid>
      <Grid item xs={4}>
        <FormTextField label="HW" name={`${name}_hw`} type="number" />
      </Grid>

      <Grid item xs={4}>
        <FormTextField label="MW min" name={`${name}_mwMin`} type="number" />
      </Grid>
      <Grid item xs={4}>
        <FormTextField label="MW" name={`${name}_mw`} type="number" />
      </Grid>
      <Grid item xs={4}>
        <FormTextField label="MW max" name={`${name}_mwMax`} type="number" />
      </Grid>
    </Grid>
  );
}

function EditSectionPage() {
  const { history, match } = useReactRouter();
  const { createFromRiverName, createFromRiverCountry } = match.params;
  const t = useT();
  return (
    <EditorPage
      idName="sectionId"
      localStorageName="EditingSections"
      serviceName="section-info"
      detailRoute={id => `/sections/detail/${id}`}
      objectTypeText={t('section')}
      storageToForm={sectionToForm}
      formToStorage={formToSection}
      fetchObject={createFromRiverName ? () => ({ river: createFromRiverName, country: createFromRiverCountry }) : null}
      parentRoute="/sections"
    >
      <Grid container alignItems="stretch">
        <PaperCard>
          <FormAutocomplete
            label={t('River')}
            name="river"
            url={value => `river-info/find?contains=${value}&limit=10`}
            primaryField="river"
            secondaryField={obj => obj.countries && obj.countries[0]}
            valueField="river"
            onSelect={(obj, { setFieldValue }) => setFieldValue('country', obj.countries && obj.countries[0])}
          />
          <FormTextField label={t('Section')} name="section" />
          <FormTextField label={t('Country')} name="country" />
          <br />
        </PaperCard>
        <PaperCard>
          <FormTextField label={t('Description')} name="description" multiline />
          <FormTextField label={t('Private description')} fullwidth name="privateDescription" multiline />
          <FormTextField label={t('Put in')} name="putIn" />
          <FormTextField label={t('Take out')} name="takeOut" />
          <FormCheckBox label={t('Publish (this section will be visible for all paddlers)')} name="isPublished" />
        </PaperCard>
        <PaperCard>
          <GaugeListEditor fieldList={CALIBRATION_FIELDS} editControl={GaugeEditControl} />
        </PaperCard>
        <PaperCard>
          <FormDifficulty label={t('Difficulty')} name="difficulty" />
        </PaperCard>
        <PaperCard>
          <FormRating label={t('Quality of section')} name="enjoyLevel" />
        </PaperCard>
      </Grid>
    </EditorPage>
  );
}

export default EditSectionPage;
