import React from 'react';
import AppLayout from '../layout/AppLayout';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';
import SectionListControl from './SectionListControl';
import { extractRiverParam } from '../utility/extractParams';

function RiverSectionsPage() {
  const t = useT();
  const { history, match } = useReactRouter();
  const river = extractRiverParam(match);

  return (
    <AppLayout title={t('Sections')} mainIconMeaning="top" showRefreshButton>
      <SectionListControl routerStateField="listAll" filterParams={`&river=${encodeURIComponent(river)}`} />
    </AppLayout>
  );
}

export default RiverSectionsPage;
