import React from 'react';
import GoogleAnalytics from 'react-ga';
import useReactRouter from 'use-react-router';

GoogleAnalytics.initialize('UA-148471516-1');

export default function GAListener({ children }) {
  const { history } = useReactRouter();
  const lastLocationRef = React.useRef();

  function sendPageView(location) {
    if (window.location.origin.includes('://localhost:')) return;

    if (lastLocationRef.current === location.pathname) return;
    GoogleAnalytics.set({ page: location.pathname });
    GoogleAnalytics.pageview(location.pathname);
    lastLocationRef.current = location.pathname;
  }

  React.useEffect(() => {
    sendPageView(history.location);
    history.listen(sendPageView);
  }, []);

  return children;
}
