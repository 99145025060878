import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import useFetchApi from '../utility/useFetchApi';
import DashboardCard from '../ui-elements/DashboardCard';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';
import LocationContainer from '../ui-elements/LocationContainer';
import AuthProvider from '../providers/AuthProvider';
import WidgetSectionList from './WidgetSectionList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  date: {
    fontStyle: 'italic',
    marginLeft: '5px',
  },
}));

function formatTripInfo(trip, t) {
  if (!trip) {
    return {
      title: `(${t('loading')}...)`,
      link: '/trips',
    };
  }
  if (!trip.tripId) {
    return {
      title: t('No near trips'),
      link: '/trips',
    };
  }

  const nowTm = new Date().getTime();
  const { startTm, endTm, tripId, name } = trip;

  if (nowTm >= startTm && nowTm <= endTm) {
    return {
      title: t('Current trip'),
      name,
      link: `/trips/detail/${tripId}`,
      date: `${moment(startTm).format('D. M.')} - ${moment(endTm).format('D. M.')}`,
    };
  }

  if (nowTm > endTm) {
    return {
      title: t('Last trip'),
      name,
      link: `/trips/detail/${tripId}`,
      date: `${moment(endTm).fromNow()}`,
    };
  }

  return {
    title: t('Next trip'),
    name,
    link: `/trips/detail/${tripId}`,
    date: `${moment(startTm).fromNow()}`,
  };
}

export default function NearestTripWidget() {
  const t = useT();
  const { auth } = AuthProvider.useAuth();
  const { accountId } = auth || {};
  const trip = useFetchApi(`trip-info/nearest-trip?accountId=${accountId}&populateSections=1&findMeasurement=1`);
  const { title, name, link, date } = formatTripInfo(trip, t);
  const classes = useStyles();
  const { history } = useReactRouter();

  if (!trip) return null;
  return (
    <DashboardCard title={`${title}${name ? ': ' : ''}${name || ''}`} link={link}>
      {date && <div className={classes.date}>{date}</div>}
      {!date && (
        <div className={classes.date} onClick={() => history.push('/trips')}>
          {t('View all trips')}
        </div>
      )}
      <WidgetSectionList sections={trip.sections} hasEvents />
    </DashboardCard>
  );
}
