import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import MarkerIcon from '../icons/Marker';
import clsx from 'clsx';
import geoTags from './geoTags';

const geoTagIcons = _.mapValues(_.keyBy(geoTags, 'text'), 'icon');

const useStyles = makeStyles(theme => ({
  marker: {
    position: 'absolute',
    transform: `translate(-20px, -40px)`,
  },
  subicon: {
    position: 'absolute',
    left: 7,
    top: 5,
  },
}));

export default function Marker({ isHighlighted, anchor, left, top, geotag, onClick }) {
  const classes = useStyles();
  const style = {
    left,
    top,
  };
  const Icon = geotag ? geoTagIcons[geotag] : null;
  return (
    <div style={style} anchor={anchor} className={clsx('pigeon-click-block', classes.marker)} onClick={onClick}>
      <MarkerIcon size={40} />
      {Icon ? <Icon className={classes.subicon} /> : null}
    </div>
  );
}

// color={isHighlighted ? 'blue' : 'red'}
