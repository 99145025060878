import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

export default function AvatarButton({ Icon, onClick, color = undefined }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Avatar onClick={onClick} style={color ? { backgroundColor: color } : undefined}>
        <Icon />
      </Avatar>
    </div>
  );
}
