import React from "react";

export default function BoatCanoe({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32">
      <path
        d="M 13.5 6 C 11.578848 6 10 7.5788481 10 9.5 C 10 11.421152 11.578848 13 13.5 13 C 15.421152 13 17 11.421152 17 9.5 C 17 7.5788481 15.421152 6 13.5 6 z M 13.5 8 C 14.340272 8 15 8.6597281 15 9.5 C 15 10.340272 14.340272 11 13.5 11 C 12.659728 11 12 10.340272 12 9.5 C 12 8.6597281 12.659728 8 13.5 8 z M 21.6875 10.34375 L 21.6875 10.375 L 16.53125 13.40625 L 18.59375 14.3125 L 21.1875 12.78125 L 20.40625 16.53125 L 14.1875 14 C 13.122558 13.56533 11.882791 14.124628 11.53125 15.21875 L 10.03125 20 L 5 20 C 4.074321 20 3.499988 19.80403 3.15625 19.625 C 2.812512 19.44597 2.78125 19.34375 2.78125 19.34375 L 1 17.25 L 1 20 C 1 22.626061 1.8413027 24.409465 2.90625 25.5 C 3.9711973 26.590535 5.1124021 27 6 27 L 8 27 L 15.3125 27 L 15.90625 25 L 8 25 L 6 25 C 5.8795979 25 5.0268027 24.824465 4.34375 24.125 C 3.8681185 23.63794 3.4865697 22.740415 3.25 21.59375 C 3.7812542 21.759894 4.2221152 22 5 22 L 11 22 L 17.5 22 L 17.9375 20 L 12.09375 20 L 13.4375 15.84375 L 19.96875 18.5 L 18.9375 23.4375 C 18.599463 23.498707 18.268652 23.623907 17.96875 23.8125 C 17.35275 24.1995 16.92525 24.7895 16.78125 25.4375 L 15.71875 29 L 17.8125 29 L 18.6875 25.96875 C 18.75425 25.67625 19.004125 25.456172 19.28125 25.40625 L 19.3125 25.40625 L 19.78125 25.5 C 19.79983 25.510994 19.826371 25.518779 19.84375 25.53125 C 20.073625 25.696203 20.18875 26 20.125 26.28125 L 19.4375 29 L 21.5 29 L 22 27 L 26 27 C 26.87129 27 28.0235 26.617209 29.09375 25.53125 C 30.164 24.445291 31 22.646079 31 20 L 31 17.90625 L 29.375 19.21875 C 29.375 19.21875 28.416528 20 26.84375 20 L 21.6875 20 L 23.625 10.75 L 21.6875 10.34375 z M 28.75 21.5625 C 28.514797 22.741441 28.138862 23.635304 27.65625 24.125 C 26.97225 24.819041 26.11171 25 26 25 L 21.875 25 C 21.661223 24.52726 21.317837 24.131183 20.875 23.84375 L 21.28125 22 L 26.84375 22 C 27.629928 22 28.175653 21.754403 28.75 21.5625 z"
      />
    </svg>
  );
}
