import React from 'react';
import ListControl from '../layout/ListControl';
import PeopleListItem from './PeopleListItem';
import WarningIcon from '@material-ui/icons/Warning';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import AuthProvider from '../providers/AuthProvider';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PeopleIcon from '@material-ui/icons/People';

const groupDefinitions = [
  {
    t_title: 'difficulty',
    icon: WarningIcon,
    groupBy: 'difficultyGroup',
  },
];

const filterDefinitions = [
  {
    t_title: 'active',
    icon: DirectionsBoatIcon,
    filterField: 'isActiveEventCreator',
    filterValue: 'true',
  },
];

const groupContentConfig = {
  accountStats: true,
};

export default function PeopleListControl({ filterParams, ...other }) {
  const { auth } = AuthProvider.useAuth();
  const addFilterDefinitions = auth
    ? [
        {
          t_title: 'Followed',
          icon: PeopleIcon,
          filterField: 'followedBy',
          filterValue: auth.accountId,
        },
        {
          t_title: 'Follows me',
          icon: VisibilityIcon,
          filterField: 'follows',
          filterValue: auth.accountId,
        },
      ]
    : [];
  return (
    <ListControl
      idName="accountId"
      serviceName="account-info"
      filterParams={filterParams}
      findParams="sort=-rating"
      component={PeopleListItem}
      showSearch
      routerStateField="listPeople"
      groupDefinitions={groupDefinitions}
      groupContentConfig={groupContentConfig}
      filterDefinitions={[...filterDefinitions, ...addFilterDefinitions]}
      {...other}
    />
  );
}
