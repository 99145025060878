import _ from 'lodash';
import React from 'react';
import useReactRouter from 'use-react-router';

export default function NativeRouterSearchField({ routerStateField, onRunFilter, className, placeholder }) {
  const { history } = useReactRouter();

  const search = (history.location && history.location.state && history.location.state[routerStateField]) || '';

  const setSearch = (value) => {
    if (search) {
      if (value) history.replace(history.location.pathname, { ...history.location.state, [routerStateField]: value });
      else history.goBack();
    } else {
      if (value) history.push(history.location.pathname, { ...history.location.state, [routerStateField]: value });
    }
  };

  const setDebouncedFilter = React.useRef(
    // @ts-ignore
    _.debounce((value) => onRunFilter(value), 500)
  );

  React.useEffect(() => {
    setDebouncedFilter.current(search);
    if (!search) onRunFilter(search);
  }, [search]);

  const applyFilter = () => {
    onRunFilter(search);
  };
  const clearFilter = () => {
    history.goBack();
    onRunFilter('');
  };

  return (
    <input
      type="text"
      className={className}
      placeholder={placeholder}
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
        // onRunFilter(e.target.value);
        // setDebouncedFilter.current(e.target.value);
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') applyFilter();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Escape') clearFilter();
      }}
      autoComplete="off"
    />
  );
}
