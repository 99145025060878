import React from 'react';
import _ from 'lodash';
import { withProps } from 'recompose';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { findGeoTagIcon } from '../locations/ChooseGeoTagDialog';
import useReactRouter from 'use-react-router';
import { makeStyles } from '@material-ui/core/styles';
import Flag from '../icons/Flag';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    alignSelf: 'flex-end',
    width: '100%',
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
    color: theme.typography.color,
    padding: '0 16px',
  },
  description: {
    // fontStyle: 'italic',
    fontFamily: theme.typography.fontFamilyCondensed,
  },
  river: {
    // fontWeight: 'bold',
    flexGrow: 1,
  },
  stars: {
    marginLeft: '5px',
  },
  section: {
    flexGrow: 1,
    fontWeight: 'bold',
    color: theme.sectionColor,
  },
  dangerInfo: {
    fontWeight: 'bold',
    color: 'red',
  },
  flexLine: {
    display: 'flex',
    // width: '100%',
  },
  flagImage: {
    alignSelf: 'center',
  },
  eventCount: {},
  editing: {
    color: theme.editingColor,
    fontStyle: 'italic',
  },
}));

export default function PlaceListItem({ onClick, ...place }) {
  const {
    placeId,
    geotag,
    description,
    section,
    river,
    geocoding,
    isPublished,
    distance,
    isEditing,
    isFakeCoordinates,
    isCheckedFunc,
  } = place;
  const { formattedAddress, city, country, countryCode } = geocoding || {};
  const { history } = useReactRouter();
  const classes = useStyles();

  const placeObject = { placeId, geotag, section };

  const isChecked = isCheckedFunc && isCheckedFunc(placeObject);
  const checkedStyle = isChecked ? { border: '4px solid black' } : {};

  const Icon = findGeoTagIcon(geotag);
  return (
    <ListItem
      button
      alignItems="flex-start"
      onClick={
        onClick
          ? () => onClick(place)
          : () => {
              history.push(isEditing ? `/places/edit/${placeId}` : `/places/detail/${placeId}`);
            }
      }
    >
      <ListItemAvatar>
        <Avatar style={{ ...checkedStyle, backgroundColor: isPublished ? '#AAAACC' : undefined }}>
          <Icon />
        </Avatar>
      </ListItemAvatar>
      <div className={classes.root}>
        <div>{country || city ? `${country}, ${city}` : geotag}</div>
        {distance && !isFakeCoordinates && <div>{distance.toFixed(1)} km</div>}
        <div className={classes.flexLine}>
          <div className={classes.section}>{section}</div>
        </div>
        <div className={classes.flexLine}>
          <div className={classes.river}>{_.isString(river) ? river : JSON.stringify(river)}</div>
        </div>
        <div className={classes.description}>{description}</div>
        {isEditing && (
          <div className={classes.editing}>
            <Trans>Editing</Trans>
          </div>
        )}
      </div>
      <div className={classes.flagImage}>
        <Flag code={countryCode} size={30} />
      </div>
      {/* 
      <ListItemText
        primary={country || city ? `${country}, ${city}` : geotag}
        secondary={`${description || ''} (${geotag})`}
      /> */}
    </ListItem>
  );
}

PlaceListItem.Custom = (onClick, isCheckedFunc) => withProps({ onClick, isCheckedFunc })(PlaceListItem);
