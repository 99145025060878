import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AppLayout from '../layout/AppLayout';
import useT from '../utility/useT';

function Page({ title, children }) {
  return children;
}

function TabContainer({ children }) {
  return <Typography component="div">{children}</Typography>;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

function TabControlInner({ classes, children }) {
  const childrenArray = Array.isArray(children) ? children : [children];
  const [value, setValue] = React.useState(0);
  const t = useT();
  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Tabs value={value} onChange={(event, value) => setValue(value)}>
          {childrenArray.map(tab => (
            <Tab label={tab.props.t_label ? t(tab.props.t_label) : tab.props.label} />
          ))}
        </Tabs>
      </AppBar>
      {<TabContainer key={value}>{childrenArray[value].props.children}</TabContainer>}
    </div>
  );
}

// class TabControlInner extends React.Component {
//   state = {
//     value: 0
//   };

//   handleChange = (event, value) => {
//     this.setState({ value });
//   };

//   render() {
//     const { classes, children } = this.props;
//     const { value } = this.state;

//     return (
//       <div className={classes.root}>
//         <AppBar position="static">
//           <Tabs value={value} onChange={this.handleChange}>
//             {children.map(tab => (
//               <Tab label={tab.props.label} />
//             ))}
//           </Tabs>
//         </AppBar>
//         {
//           <TabContainer key={value}>
//             {children[value].props.children}
//           </TabContainer>
//         }
//       </div>
//     );
//   }
// }

const TabControl = withStyles(styles)(TabControlInner);

TabControl.Page = Page;
export default TabControl;
