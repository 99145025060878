import React from 'react';
import _ from 'lodash';

import ListControl from '../layout/ListControl';
import AuthProvider from '../providers/AuthProvider';
import LocationListItem from './LocationListItem';
import ChooseLocationActionDialog from './ChooseLocationActionDialog';
import ChooseGeoTagDialog from './ChooseGeoTagDialog';
import getEditingObjects from '../utility/getEditingObjects';
import useRouterDialog from '../utility/useRouterDialog';

export default function LocationList({ url, onHighlightOnMap, allowAddNew, copyToSectionId }) {
  const [openLocationDialog, locationDialogProps] = useRouterDialog('choose-location');
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [auxState, setAuxState] = React.useState(null);
  const { auth } = AuthProvider.useAuth();
  const [openGeoTagDialog, geoTagDialogProps] = useRouterDialog('choose-geo-tag');

  return (
    <>
      <ListControl
        routerStateField="listMy"
        auxState={auxState}
        extraItems={_.sortBy(getEditingObjects('EditingLocations', 'locationId'), 'startTm').reverse()}
        idName="locationId"
        url={url}
        component={LocationListItem(location => {
          setSelectedLocation(location);
          openLocationDialog();
        })}
        // component={LocationListItem(location => {})}
        onAdd={allowAddNew ? openGeoTagDialog : undefined}
      />
      {selectedLocation && (
        <ChooseLocationActionDialog
          {...locationDialogProps}
          location={selectedLocation}
          onChange={() => setAuxState(new Date().getTime())}
          onHighlightOnMap={onHighlightOnMap ? () => onHighlightOnMap(selectedLocation) : undefined}
          title="Choose location action"
          copyToSectionId={copyToSectionId}
        />
      )}
      <ChooseGeoTagDialog
        {...geoTagDialogProps}
        onChange={() => setAuxState(new Date().getTime())}
        addOnChoose
        title="Add current position"
      />
    </>
  );
}
