import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { useInView } from 'react-intersection-observer';
import useReactRouter from 'use-react-router';
import { useSnackbar } from 'notistack';
import Chip from '@material-ui/core/Chip';
import Icon from '@mdi/react';
import { mdiFilter, mdiGroup } from '@mdi/js';

import AddIcon from '@material-ui/icons/Add';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import fetchApi from '../utility/fetchApi';
import AuthProvider from '../providers/AuthProvider';
import useRefreshTime from '../utility/useRefreshTime';
import ChooseFromListDialog from '../ui-elements/ChooseFromListDialog';
import useStateDialog from '../utility/useStateDialog';
import useGroupNestingLevel from '../utility/useGroupNestingLevel';
import GroupListItem from '../ui-elements/GroupListItem';
import { waterLevelTwoLetterNames } from '../icons/WaterLevel';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    // backgroundColor: '#E5E5E5',

    marginBottom: '3rem',
  },
  rootInline: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    // backgroundColor: '#E5E5E5',
  },
  inline: {
    display: 'inline',
  },
  fab: {
    margin: theme.spacing.unit,
    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
  },
  searchField: {
    marginLeft: '1rem',
    // marginRight: '1rem',
    marginTop: '1rem',
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemWrapper: {
    margin: '6px',
  },
  flexLine: {
    display: 'flex',
  },
}));

function addUrlParam(url, name, value) {
  if (!value) return url;
  if (url.includes('?')) return `${url}&${name}=${encodeURIComponent(value)}`;
  return `${url}?${name}=${encodeURIComponent(value)}`;
}
function addUrlParams(url, params) {
  for (const param of Object.keys(params)) {
    url = addUrlParam(url, param, params[param]);
  }
  return url;
}

function startCase(str) {
  return str[0].toUpperCase() + str.substring(1);
}

const mapChooseItem = (history, routerStateField, applyFilter) => item => {
  const { groupBy, filterField, filterValue } = item;
  if (groupBy) {
    return {
      ...item,
      onClick: () => {
        history.replace(history.location.pathname, {
          ...history.location.state,
          [`${routerStateField}_filter_groupBy`]: groupBy,
        });
        applyFilter();
      },
    };
  }
  if (filterField) {
    return {
      ...item,
      onClick: () => {
        history.replace(history.location.pathname, {
          ...history.location.state,
          [`${routerStateField}_filter_${filterField}`]: filterValue,
        });
        applyFilter();
      },
    };
  }
  return item;
};

const formatGroupChipValue = (name, value) => {
  if (name === 'waterLevel') return waterLevelTwoLetterNames[value || 0];
  return value;
};

function ListControlInner({
  // create read url source
  url,
  urlFunc,
  serviceName,
  filterParams,
  findParams,
  groupParams,
  methodName = 'find',
  component,
  componentFunc,
  onAdd,
  routerStateField,
  extraItems = [],
  showSearch,
  flatTheme,
  disablePaging,
  auxState,
  idName,
  getItemStyle,
  // chooseItems = [],
  groupDefinitions = [],
  filterDefinitions = [],
  groupContentConfig = {},
  className,
  noDataContent,
  defaultFilters,
  searchValue,
  isInline,
  noDataContentCallback,
}) {
  const t = useT();
  const { enqueueSnackbar } = useSnackbar();
  const { auth } = AuthProvider.useAuth();
  const { history } = useReactRouter();
  const [lastAuxState, setLastAuxState] = React.useState(auxState);
  const classes = useStyles();
  const [searchInState, setSearchInState] = React.useState('');
  const [refreshTime] = useRefreshTime();
  const loadedTimeRef = React.useRef();

  const [openAddFilter, addFilterDialogProps] = useStateDialog();
  const [openAddGroup, addGroupDialogProps] = useStateDialog();

  const search = routerStateField
    ? history.location && history.location.state && history.location.state[`${routerStateField}_search`]
    : searchInState;

  const setSearch = value =>
    routerStateField
      ? history.replace(history.location.pathname, { ...history.location.state, [`${routerStateField}_search`]: value })
      : setSearchInState(value);

  const initialLoadedInfo = (routerStateField &&
    history.location &&
    history.location.state &&
    history.location.state[`${routerStateField}_loadedInfo`]) || {
    loadedRows: [],
    isLoadedAll: false,
    loadedTime: new Date().getTime(),
  };

  const [loadProps, setLoadProps] = React.useState({
    isLoading: false,
    filter: search,
    ...initialLoadedInfo,
  });
  const { isLoading, loadedRows, filter, isLoadedAll, loadedTime } = loadProps;
  const setDebouncedFilter = React.useRef(
    _.debounce(value => {
      setLoadProps({
        isLoading: false,
        loadedRows: [],
        filter: value,
        isLoadedAll: false,
      });
    }, 500)
  );

  const defaultGroup = groupDefinitions.find(x => x.default);
  const filterKeyPrefix = `${routerStateField}_filter_`;
  const filterValues = _.omitBy(
    {
      ...(defaultGroup ? { groupBy: defaultGroup.groupBy } : {}),
      ..._.fromPairs(
        filterDefinitions
          .filter(
            x =>
              x.default ||
              (defaultFilters && defaultFilters[x.filterField] && defaultFilters[x.filterField] == x.filterValue)
          )
          .map(x => [x.filterField, x.filterValue])
      ),
      ..._.mapKeys(_.pickBy(history.location.state, (v, k) => k.startsWith(filterKeyPrefix)), (v, k) =>
        k.substring(filterKeyPrefix.length)
      ),
    },
    v => v == '#DELETED#'
  );

  const getUrlBase = () => {
    if (urlFunc) return urlFunc(filterValues);
    if (url) return url;
    if (serviceName) {
      if (filterValues.groupBy) {
        return `${serviceName}/group?by=${filterValues.groupBy}${groupParams || ''}${filterParams || ''}`;
      }
      return `${serviceName}/${methodName || 'find'}?${findParams || ''}${filterParams || ''}`;
    }
  };

  const urlBase = getUrlBase();
  const [lastUrlBase, setLastUrlBase] = React.useState(urlBase);

  if (auxState != lastAuxState || refreshTime > loadedTime || urlBase != lastUrlBase) {
    setLastAuxState(auxState);
    setLoadProps({
      isLoading: false,
      loadedRows: [],
      filter,
      isLoadedAll: false,
      loadedTime: new Date().getTime(),
    });
    setLastUrlBase(urlBase);
  }

  const loadNextData = async () => {
    if (isLoading) return;
    if (!urlBase) {
      setLoadProps({
        ...loadProps,
        loadedRows: [],
        loadedTime: new Date().getTime(),
        isLoadedAll: true,
      });
      return;
    }
    if (disablePaging && loadedRows.length > 0) return;
    setLoadProps({
      ...loadProps,
      isLoading: true,
    });
    const loadStart = new Date().getTime();
    loadedTimeRef.current = loadStart;
    let nextRows = await fetchApi(
      addUrlParams(urlBase, {
        contains: filter,
        limit: disablePaging ? undefined : 10,
        offset: disablePaging ? undefined : loadedRows.length,
        ...(groupDefinitions && _.pick(filterValues, groupDefinitions.map(x => x.groupBy))),
        ...(filterDefinitions && _.pick(filterValues, filterDefinitions.map(x => x.filterField))),
      }),
      { auth }
    );
    if (loadedTimeRef.current !== loadStart) {
      // new load was dispatched
      return;
    }
    if (!_.isArray(nextRows)) {
      console.log('Error loading data from server', nextRows);
      enqueueSnackbar('Error loading data from server', { variant: 'error' });
      nextRows = [];
    }
    const loadedInfo = {
      loadedRows: [...loadedRows, ...nextRows],
      loadedTime,
      isLoadedAll: nextRows.length === 0,
    };
    setLoadProps({
      ...loadProps,
      isLoading: false,
      ...loadedInfo,
    });
    if (routerStateField) {
      history.replace(history.location.pathname, {
        ...history.location.state,
        [`${routerStateField}_loadedInfo`]: loadedInfo,
      });
    }
  };

  const [loadNextRef, loadNextInView] = useInView({ threshold: 0.25 });

  React.useEffect(() => {
    if (loadNextInView && !isLoading && !isLoadedAll) {
      loadNextData();
    }
  });

  const allRows = [...extraItems, ...loadedRows];

  const extraIdSet = idName ? _.keyBy(extraItems || [], idName) : {};

  const applyFilter = () => {
    setLoadProps({
      isLoading: false,
      loadedRows: [],
      filter: search,
      isLoadedAll: false,
      loadedTime: new Date().getTime(),
    });
  };
  const clearFilter = () => {
    setLoadProps({
      isLoading: false,
      loadedRows: [],
      filter: '',
      isLoadedAll: false,
      loadedTime: new Date().getTime(),
    });
    setSearch('');
  };

  React.useEffect(() => {
    if (searchValue) {
      setLoadProps({
        isLoading: false,
        loadedRows: [],
        filter: searchValue,
        isLoadedAll: false,
        loadedTime: new Date().getTime(),
      });
    }
  }, [searchValue]);

  React.useEffect(() => {
    if (noDataContentCallback) {
      noDataContentCallback(!isLoading && allRows.length === 0);
    }
  }, [!isLoading && allRows.length === 0]);

  const handleDeleteFilterProps = (...filterValues) => () => {
    const newState = {
      ...history.location.state,
      ..._.fromPairs(filterValues.map(x => [`${routerStateField}_filter_${x}`, '#DELETED#'])),
    };
    history.replace(history.location.pathname, newState);
    setLoadProps({
      isLoading: false,
      loadedRows: [],
      filter: search,
      isLoadedAll: false,
      loadedTime: new Date().getTime(),
    });
  };

  const filterItemsMapped = filterDefinitions
    .filter(x => filterValues[x.filterField] != x.filterValue)
    .map(filter => ({ ...filter, text: t(filter.t_title) }))
    .map(mapChooseItem(history, routerStateField, applyFilter));

  const groupItemsMapped = groupDefinitions
    .filter(x => x.groupBy != filterValues.groupBy)
    .map(group => ({ ...group, text: t(group.t_title) }))
    .map(mapChooseItem(history, routerStateField, applyFilter));

  // const chooseItemsMapped = [
  //   ...chooseItems,
  //   ...groupDefinitions.map(group => ({ ...group, text: t('Group by {{arg}}', { arg: t(group.t_title) }) })),
  //   ...filterDefinitions.map(filter => ({ ...filter, text: t('Filter {{arg}}', { arg: t(filter.t_title) }) })),
  // ].map(mapChooseItem(history, routerStateField, applyFilter));

  const chips = [];
  if (filterValues.groupBy) {
    const group = groupDefinitions.find(x => x.groupBy === filterValues.groupBy);
    chips.push(
      <Chip
        label={t('Grouping={{arg}}', { arg: group && t(group.t_title) })}
        onDelete={handleDeleteFilterProps('groupBy')}
      />
    );
  }
  for (const group of groupDefinitions) {
    if (filterValues[group.groupBy]) {
      chips.push(
        <Chip
          label={`${startCase(t(group.t_title))}: ${formatGroupChipValue(
            group.groupBy,
            group.translateGroupValue ? t(filterValues[group.groupBy]) : filterValues[group.groupBy]
          )}`}
          onDelete={handleDeleteFilterProps(group.groupBy)}
        />
      );
    }
  }
  for (const filter of filterDefinitions) {
    if (filterValues[filter.filterField] && filterValues[filter.filterField] == filter.filterValue) {
      chips.push(<Chip label={startCase(t(filter.t_title))} onDelete={handleDeleteFilterProps(filter.filterField)} />);
    }
  }
  if (groupItemsMapped.length > 0) {
    chips.push(
      <Chip label={t('Group')} icon={<Icon path={mdiGroup} size={0.8} />} color="primary" onClick={openAddGroup} />
    );
  }
  if (filterItemsMapped.length > 0) {
    chips.push(
      <Chip label={t('Filter')} icon={<Icon path={mdiFilter} size={0.8} />} color="primary" onClick={openAddFilter} />
    );
  }

  return (
    <div className={classes.container}>
      {showSearch && (
        <div className={classes.flexLine}>
          <TextField
            id="outlined-with-placeholder"
            placeholder={t('Search')}
            className={classes.searchField}
            value={search}
            autoComplete="off"
            onChange={e => {
              setSearch(e.target.value);
              setDebouncedFilter.current(e.target.value);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') applyFilter();
            }}
            onKeyDown={e => {
              if (e.key === 'Escape') clearFilter();
            }}
          />
          {/* {chooseItemsMapped.length > 0 && (
            <IconButton aria-label="menu" color="primary" onClick={openAddFilter}>
              <AddCircleIcon />
            </IconButton>
          )} */}
          {!!filter && (
            <IconButton aria-label="menu" color="primary" onClick={clearFilter}>
              <CancelIcon />
            </IconButton>
          )}
        </div>
      )}
      {chips.length > 0 && <div className={classes.itemWrapper}>{chips}</div>}
      <List className={clsx(isInline ? classes.rootInline : classes.root, className)}>
        {allRows.map((row, index) => {
          if (index >= extraItems.length && idName && row[idName] in extraIdSet) {
            return null;
          }
          const Component = componentFunc ? componentFunc(row) : filterValues.groupBy ? GroupListItem : component;
          const renderedComponent = (
            <Component
              {...row}
              groupDefinitions={groupDefinitions}
              routerStateField={routerStateField}
              groupContentConfig={groupContentConfig}
              {..._.pick(filterValues, ['groupBy'])}
            />
          );

          return flatTheme ? (
            renderedComponent
          ) : (
            <Paper className={classes.itemWrapper} style={getItemStyle ? getItemStyle(row) : {}}>
              {renderedComponent}
            </Paper>
          );
        })}
        {!isLoading &&
          allRows.length === 0 &&
          !isInline &&
          (noDataContent || (
            <div>
              <Trans>There are no data to be displayed</Trans>
            </div>
          ))}
        {isLoading && (
          <div>
            <Trans>Loading</Trans>...
          </div>
        )}
        <div ref={loadNextRef} style={{ height: '1px' }} />
        {!isLoading && !isLoadedAll && !disablePaging && (
          <Button onClick={loadNextData}>
            <Trans>Load next</Trans>
          </Button>
        )}
      </List>
      {!!onAdd && (
        <Fab color="secondary" aria-label="Add" className={classes.fab} onClick={onAdd}>
          <AddIcon />
        </Fab>
      )}
      <ChooseFromListDialog items={filterItemsMapped} {...addFilterDialogProps} t_title="Choose filter" />
      <ChooseFromListDialog items={groupItemsMapped} {...addGroupDialogProps} t_title="Choose grouping" />
    </div>
  );
}

function ListControl({ routerStateField, ...other }) {
  const groupNestingLevel = useGroupNestingLevel();

  return <ListControlInner key={groupNestingLevel} routerStateField={routerStateField} {...other} />;
}

export default ListControl;
