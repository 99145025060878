import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AppLayout from '../layout/AppLayout';
import TabControl from '../layout/RouteTabControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withRouter } from 'react-router-dom';
import RiverIcon from '../icons/River';
import ListControl from '../layout/ListControl';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { compose } from 'recompose';
import { withProps } from 'recompose';

const styles = theme => ({
  root: {
    alignSelf: 'flex-end',
    width: '100%',
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
    color: theme.typography.color,
    padding: '0 16px',
  },
  gauge: {
    fontWeight: 'bold',
    flexGrow: 1,
  },
  river: {},
  infoData: {
    flexGrow: 1,
  },
  date: {},
  flexLine: {
    display: 'flex',
    width: '100%',
  },
  sourceName: {
    fontStyle: 'italic',
  },
});

function GaugeListItem({
  gauge,
  engine,
  river,
  heightCentimeters,
  flowCumecs,
  measuredTm,
  sourceName,
  history,
  classes,
  onClick,
}) {
  const infoData = [];
  if (flowCumecs) infoData.push(`${flowCumecs} m3/s`);
  if (heightCentimeters) infoData.push(`${heightCentimeters} cm`);
  // infoData.push(new Date(measuredTm).toLocaleString());
  return (
    <ListItem
      alignItems="flex-start"
      button
      onClick={
        onClick
          ? () => onClick({ river, gauge, engine, sourceName })
          : () => history.push(`/gauges/${engine}/${river}/${gauge}`)
      }
    >
      <ListItemAvatar>
        <ShowChartIcon size={40} />
      </ListItemAvatar>

      <div className={classes.root}>
        <div className={classes.flexLine}>
          <div className={classes.gauge}>{gauge}</div>
          <div className={classes.river}>{river}</div>
        </div>
        {(infoData.length > 0 || measuredTm) && (
          <div className={classes.flexLine}>
            <div className={classes.infoData}>{infoData.join(', ')}</div>
            <div className={classes.date}>{new Date(measuredTm).toLocaleString()}</div>
          </div>
        )}
        <div className={classes.sourceName}>{sourceName}</div>
      </div>
    </ListItem>
  );
}

const GaugeListItemComposed = compose(
  withRouter,
  withStyles(styles)
)(GaugeListItem);

GaugeListItemComposed.Custom = onClick => withProps({ onClick })(GaugeListItemComposed);

export default GaugeListItemComposed;
