import React from 'react';
import useFetchApi from '../utility/useFetchApi';
import moment from 'moment';
import _ from 'lodash';
import DifficultyIcon from '../icons/Difficulty';
import { makeStyles } from '@material-ui/core/styles';
import DashboardCard from '../ui-elements/DashboardCard';
import useT from '../utility/useT';
import { getWaterLevelColor, waterLevelTwoLetterNames } from '../icons/WaterLevel';
import SmallPaddlerAvatar from '../people/SmallPaddlerAvatar';
import useReactRouter from 'use-react-router';

const useStyles = makeStyles(theme => ({
  eventRoot: {
    margin: '10px',
    cursor: 'pointer',
  },
  // eventLine1: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  // },
  flexLine: {
    display: 'flex',
  },
  grower: {
    flexGrow: '1',
  },
  date: {
    whiteSpace: 'nowrap',
    marginLeft: '5px',
  },
  waterLevel: {
    marginLeft: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderRadius: '5px',
  },
  account: {
    marginLeft: '5px',
    // fontStyle: 'italic',
    // color: 'gray',
  },
  section: {
    fontStyle: 'italic',
    marginLeft: '25px',
  },
  danger: {
    color: 'red',
    marginLeft: '25px',
  },
}));

function EventItem({ event, showSection }) {
  const classes = useStyles();
  const { history } = useReactRouter();

  const { waterLevel } = event;
  return (
    <div className={classes.eventRoot} onClick={() => history.push(`/events/detail/${event.diaryId}`)}>
      <div className={classes.flexLine}>
        {event.accountAvatar && <SmallPaddlerAvatar className={classes.avatar} {...event.accountAvatar} />}
        <div className={classes.account}>{event.accountName}</div>
        {waterLevel && (
          <div className={classes.waterLevel} style={{ backgroundColor: getWaterLevelColor(waterLevel) }}>
            {waterLevelTwoLetterNames[waterLevel || 0]}
          </div>
        )}
        <div className={classes.grower}> </div>
        <div className={classes.date}>{moment(event.startTm).format('D. M. YYYY')}</div>
      </div>
      {showSection && <div className={classes.section}>{event.section}</div>}

      <div className={classes.danger}>{event.dangerInfo}</div>
    </div>
  );
}

export default function TopDangersWidget({ additionalFilter, includeGrid, showSection, link }) {
  const classes = useStyles();
  const t = useT();
  const diaryInfos = useFetchApi.get('diary-info/find', {
    limit: 5,
    hasDanger: 1,
    sort: '-startTm',
    joinAccountAvatar: 1,
    ...additionalFilter,
  });
  if (!_.isArray(diaryInfos) || diaryInfos.length == 0) return null;
  return (
    <DashboardCard title={t('Last danger reports')} includeGrid={includeGrid}>
      {diaryInfos.map(event => (
        <EventItem event={event} key={event.diaryId} showSection={showSection} />
      ))}
    </DashboardCard>
  );
}
