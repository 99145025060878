import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ImageStars from '../icons/ImageStars';
import PaperRows from '../ui-elements/PaperRows';
import ImageBeers from '../icons/ImageBeers';
import NameValueView from '../ui-elements/NameValueView';
import WaterLevel, { waterLevelNames } from '../icons/WaterLevel';
import PaperCard from '../ui-elements/PaperCard';
import TeamMembersView from '../people/TeamMembersView';
import { AndroidView } from 'react-device-detect';
import Button from '@material-ui/core/Button';
import { Trans } from 'react-i18next';

function PlaceDetailView({ river, section, description, geocoding, coordinates, geotag }) {
  const { formattedAddress } = geocoding || {};
  // const teamMembers = (team || []).map(x => x.displayName).join('; ');
  return (
    <Grid container alignItems="stretch">
      <PaperCard>
        <NameValueView t_name="River" value={river} />
        <NameValueView t_name="Section" value={section} />
        <NameValueView t_name="Geo tag" value={geotag} />
        <NameValueView t_name="Description" value={description} />
        <NameValueView t_name="Address" value={formattedAddress} />
        {coordinates && (
          <>
            <NameValueView t_name="GPS" value={`${coordinates[1]},${coordinates[0]}`} showEditor />
            <Button
              color="primary"
              variant="contained"
              href={`http://maps.google.com/maps?daddr=${coordinates[1]},${coordinates[0]}`}
              target="_blank"
            >
              <Trans>Google maps</Trans>
            </Button>
            <AndroidView>
              <Button color="primary" variant="contained" href={`geo:${coordinates[1]},${coordinates[0]}`}>
              <Trans>Choose map</Trans>
              </Button>
            </AndroidView>
          </>
        )}
      </PaperCard>
    </Grid>
  );
}

export default PlaceDetailView;
