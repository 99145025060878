import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing.unit,
    // ...theme.mixins.gutters(),
    // paddingTop: theme.spacing.unit,
    // paddingBottom: theme.spacing.unit,
    margin: theme.spacing.unit,
    flex: 1,
    // backgroundColor: '#E5E5E5',
  },
}));

function PaperRows({ children, className, ...other }) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper + ' ' + className} {...other}>
      {children}
    </Paper>
  );
}

export default PaperRows;
