const cache = {};

export function getLocalStorage(key) {
  if (key in cache) return cache[key];
  const item = localStorage.getItem(key);
  if (item) {
    const res = JSON.parse(item);
    cache[key] = res;
    return res;
  }
  return undefined;
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
  delete cache[key];
}

export function removeLocalStorage(key) {
  localStorage.removeItem(key);
  delete cache[key];
}

export function deleteLocalStorageExpired() {
  const tm = new Date().getTime();
  const toDelete = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.startsWith('expirable_')) toDelete.push(key);
    const expiration = getLocalStorage(`expiration_${key}`);
    if (expiration && tm > expiration) toDelete.push(key);
  }
  for (const key of toDelete) {
    localStorage.removeItem(key);
    delete cache[key];
  }
}
