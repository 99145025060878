import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useSnackbar } from 'notistack';
import uuidv1 from 'uuid/v1';
import useReactRouter from 'use-react-router';
import { makeStyles } from '@material-ui/core/styles';

import ChooseSectionDialog from '../sections/ChooseSectionDialog';
import fetchApi from '../utility/fetchApi';
import getAccountInfoName from '../utility/getAccountInfoName';
import processSaveResponse from '../utility/processSaveResponse';
import AuthProvider from '../providers/AuthProvider';
import useRefreshTime from '../utility/useRefreshTime';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  buttonWrapperTop: {
    margin: '20px 20px 10px 20px',
  },
  buttonWrapperBottom: {
    margin: '10px 20px 20px 20px',
  },
  button: {
    width: '13em',
  },
}));

export default function CreateEventFromSectionDialog({ onClose, skipRedirectToEvents, ...props }) {
  const { history } = useReactRouter();
  const { auth } = AuthProvider.useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [openChooser, setOpenChooser] = React.useState(false);
  const [sectionId, setSectionId] = React.useState();
  const classes = useStyles();
  const t = useT();

  const handleCloseChooser = () => setOpenChooser(false);
  const [, callRefresh] = useRefreshTime();

  const createEvent = async () => {
    const sectionObject = ((await fetchApi(`section-info/find?sectionId=${sectionId}&findMeasurement=1`)) || [])[0];
    const startTm = new Date().getTime();

    const { river, section, difficulty, waterLevel } = sectionObject;

    const diaryEvent = {
      diaryId: uuidv1(),
      sectionId,
      section,
      river,
      difficulty,
      accountId: auth.accountId,
      accountName: getAccountInfoName(auth),
      startTm,
      beerCount: auth.beerCount,
      boatModel: auth.boatModel,
      waterLevel,
    };

    const { gauges } = sectionObject;
    if (gauges && gauges.length > 0) {
      diaryEvent.isAutoGauge = true;
      let gaugeIndex = 0;
      const eventGauges = [];
      for (const { gauge, river, gaugeEngine } of gauges) {
        gaugeIndex++;
        const measurement = await fetchApi(
          `measurement/compute?engine=${gaugeEngine}&river=${encodeURIComponent(river)}&gauge=${encodeURIComponent(
            gauge
          )}&time=${startTm}`
        );
        if (measurement) {
          const { heightCentimeters, flowCumecs } = measurement;
          eventGauges.push({
            gauge,
            river,
            gaugeIndex,
            heightCentimeters,
            flowCumecs,
          });
        } else {
          diaryEvent.isAutoGauge = false;
          enqueueSnackbar(t('Failed to load gauge {{gauge}}', { gauge }), { variant: 'warning' });
        }
      }
      diaryEvent.gauges = eventGauges;
    }

    const saveData = await fetchApi.post(
      `diary-info/save`,
      {
        entity: diaryEvent,
      },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    setOpenChooser(false);
    // onClose();
    processSaveResponse(
      saveData,
      enqueueSnackbar,
      t('Successfully saved {{river}} - {{section}} event', { river, section })
    );
    callRefresh();

    // close router dialog
    history.replace(history.location.pathname, {
      ...history.location.state,
      'choose-section_dialogIsOpen': false,
    });

    history.push(`/events/detail/${diaryEvent.diaryId}`);
  };

  const editDetails = () => {
    setOpenChooser(false);
    onClose();
    setTimeout(() => history.push(`/events/create-from-section/${sectionId}`), 1);
  };

  return (
    <>
      <Dialog onClose={handleCloseChooser} aria-labelledby="event-dialog-choose-method" open={openChooser}>
        <DialogTitle id="login-dialog-chooser-title">
          <Trans>Log river run</Trans>
        </DialogTitle>
        <div className={classes.buttonWrapperTop}>
          <Button variant="contained" onClick={createEvent} color="secondary" className={classes.button}>
            <Trans>Create now</Trans>
          </Button>
        </div>
        <div className={classes.buttonWrapperBottom}>
          <Button variant="contained" onClick={editDetails} color="secondary" className={classes.button}>
            <Trans>Edit details</Trans>
          </Button>
        </div>
      </Dialog>

      <ChooseSectionDialog
        {...props}
        stayOpenOnSelect
        onClose={onClose}
        onChange={sectionId => {
          setSectionId(sectionId);
          setOpenChooser(true);
        }}
      />
    </>
  );
}
