import _ from 'lodash';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import useReactRouter from 'use-react-router';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import AppLayout from '../layout/AppLayout';
import AuthProvider from '../providers/AuthProvider';
import FormikForm from '../forms/FormikForm';
import fetchApi from '../utility/fetchApi';
import processSaveResponse from '../utility/processSaveResponse';
import MyProfileEditor from './MyProfileEditor';
import PaperCard from '../ui-elements/PaperCard';
import useFormik from '../utility/useFormik';
import NameValueView from '../ui-elements/NameValueView';
import PaperRows from '../ui-elements/PaperRows';
import requestNotifications from '../notifications/requestNotifications';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';

function NotificationControl({ type, title, t_title }) {
  const { values, handleChange } = useFormik();
  const t = useT();

  const value = values[`pushNotification:${type}`];
  const checkedValue = value == null ? true : value;

  return (
    <PaperCard>
      <Typography>{t_title ? t(t_title) : title}</Typography>

      <FormControlLabel
        control={<Switch checked={checkedValue} onChange={handleChange(`pushNotification:${type}`)} />}
        label={t('Mobile/desktop notification')}
      />
    </PaperCard>
  );
}

function formToStorage(profile) {
  const pushNotifications = {};
  const profileCopy = { ...profile };
  for (const key of _.keys(profile)) {
    if (key.startsWith('pushNotification:')) {
      pushNotifications[key.substring('pushNotification:'.length)] = profile[key];
      delete profileCopy[key];
    }
  }
  profileCopy.pushNotifications = pushNotifications;
  return profileCopy;
}

function storageToForm(profile) {
  const profileCopy = { ...profile };
  const { pushNotifications } = profileCopy;
  if (pushNotifications) {
    for (const key of _.keys(pushNotifications)) {
      profileCopy[`pushNotification:${key}`] = pushNotifications[key];
    }
    delete profileCopy.pushNotifications;
  }
  return profileCopy;
}

function EditNotificationsPage() {
  const { history } = useReactRouter();
  const { auth, setAuth } = AuthProvider.useAuth();
  const { accountId } = auth;
  const { enqueueSnackbar } = useSnackbar();
  const t = useT();

  const permission = window.Notification && window.Notification.permission;

  const permissionText = {
    granted: t('Permissions granted'),
    denied: t('Permissions denied'),
    default: t('Asking for permissions'),
  };

  return (
    <MyProfileEditor t_title="Notifications settings" formToStorage={formToStorage} storageToForm={storageToForm}>
      <Grid item xs={12}>
        <PaperRows>
          <NameValueView t_name="Desktop/mobile notification status" value={permissionText[permission]} />

          <Grid container alignItems="stretch">
            <Button
              onClick={() => requestNotifications(accountId, enqueueSnackbar)}
              variant="contained"
              color="primary"
            >
              <Trans>Request notifications</Trans>
            </Button>
          </Grid>
        </PaperRows>
      </Grid>
      <NotificationControl type="event-copied" t_title="Copy my event" />
      <NotificationControl type="event-team-member-added" t_title="Add to event team" />
      <NotificationControl type="trip-team-member-added" t_title="Add to trip team" />
      <NotificationControl type="comment-added" t_title="Added new comment" />
      <NotificationControl type="photo-added" t_title="Added new photo" />
      <NotificationControl type="follower-added" t_title="Added new follower" />
      <NotificationControl type="followed-event-added" t_title="Added new event" />
      <NotificationControl type="followed-trip-added" t_title="Added new trip" />
      <NotificationControl type="page-sent" t_title="Page sent to me" />
      <NotificationControl type="emotion-added" t_title="Reaction added (like)" />
    </MyProfileEditor>
  );
}

export default EditNotificationsPage;
