import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';

import useFormik from '../utility/useFormik';
import FormGaugeSelector from '../forms/FormGaugeSelector';
import ChooseGaugeDialog from '../gauges/ChooseGaugeDialog';
import useStateDialog from '../utility/useStateDialog';
import { Trans } from 'react-i18next';
import useT from '../utility/useT';

const useStyles = makeStyles(theme => ({
  addGaugeButton: {
    marginTop: theme.spacing.unit,
  },
}));

function GaugeSelector({
  label,
  name,
  index,
  fieldList,
  editControl: EditControl,
  beforeEditControl: BeforeEditControl,
}) {
  const { values, setFieldValue } = useFormik();
  const { gaugeCount } = values;

  const exchangeFields = (field1, field2) => {
    const tmp = values[field1];
    setFieldValue(field1, values[field2]);
    setFieldValue(field2, tmp);
  };

  const exchangeGauges = (index1, index2) => {
    exchangeFields(`gauge${index1}`, `gauge${index2}`);

    for (const fld of fieldList) {
      exchangeFields(`gauge${index1}_${fld}`, `gauge${index2}_${fld}`);
    }
  };

  return (
    <>
      {BeforeEditControl && <BeforeEditControl name={name} />}
      <FormGaugeSelector label={label} name={name} />
      <EditControl name={name} />

      <Button
        variant="contained"
        onClick={() => {
          for (let i = index; i < gaugeCount - 1; i += 1) {
            exchangeGauges(i, i + 1);
          }
          setFieldValue('gaugeCount', gaugeCount - 1);
        }}
      >
        <Trans>Remove</Trans>
      </Button>
      {index < gaugeCount - 1 && (
        <Button variant="contained" onClick={() => exchangeGauges(index, index + 1)}>
          <Trans>Move down</Trans>
        </Button>
      )}
      {index > 0 && (
        <Button variant="contained" onClick={() => exchangeGauges(index, index - 1)}>
          <Trans>Move up</Trans>
        </Button>
      )}
    </>
  );
}

export default function GaugeListEditor({ fieldList, editControl, beforeEditControl, newGaugeFields = {} }) {
  const { values, setFieldValue } = useFormik();
  const [openGaugeDialog, gaugeDialogProps] = useStateDialog();
  const classes = useStyles();
  const t = useT();

  const { gaugeCount } = values;
  const onAddGauge = gaugeObj => {
    if (_.isString(gaugeObj)) {
      setFieldValue(`gauge${gaugeCount}`, gaugeObj);
    } else {
      const { river, gauge, engine } = gaugeObj;
      setFieldValue(`gauge${gaugeCount}`, { river, gauge, gaugeEngine: engine });
      for (const key of _.keys(newGaugeFields)) {
        setFieldValue(`gauge${gaugeCount}_${key}`, newGaugeFields[key]);
      }
    }
    setFieldValue('gaugeCount', (gaugeCount || 0) + 1);
  };

  return (
    <>
      {_.range(gaugeCount).map(i => (
        <GaugeSelector
          label={i === 0 ? t('Gauge (primary)') : t('Gauge {{index}}', { index: i + 1 })}
          name={`gauge${i}`}
          index={i}
          editControl={editControl}
          beforeEditControl={beforeEditControl}
          fieldList={fieldList}
        />
      ))}

      <FormGroup className={classes.addGaugeButton}>
        <Button variant="contained" onClick={openGaugeDialog} color="primary">
          <Trans>Add gauge</Trans>
        </Button>
      </FormGroup>
      <ChooseGaugeDialog {...gaugeDialogProps} onChange={onAddGauge} river={values.river} manualGauge />
    </>
  );
}

GaugeListEditor.storageToForm = fieldList => gaugesContainer => {
  const { gauges } = gaugesContainer;
  const res = { ...gaugesContainer };
  if (gauges) {
    for (let i = 0; i < gauges.length; i++) {
      if (gauges[i]) {
        const gauge = gauges[i];
        res[`gauge${i}`] = gauge;
        if (_.isPlainObject(gauge) && !gauge.gaugeEngine) res[`gauge${i}`] = gauge.gauge;
        for (const field of fieldList) {
          res[`gauge${i}_${field}`] = gauge[field];
        }
      }
    }
    res.gaugeCount = gauges.length;
  } else {
    res.gaugeCount = 0;
  }
  return res;
};

GaugeListEditor.formToStorage = (fieldList, omitList = []) => gaugesContainer => {
  const { gaugeCount } = gaugesContainer;
  const gauges = [];
  for (let i = 0; i < gaugeCount; i++) {
    const gaugeSrc = gaugesContainer[`gauge${i}`];
    const gauge = _.isString(gaugeSrc) ? { gauge: gaugeSrc } : { ...gaugeSrc };
    for (const field of fieldList) {
      gauge[field] = gaugesContainer[`gauge${i}_${field}`];
    }
    if (gauge && gauge.gauge) gauges.push(_.omit(gauge, omitList));
  }

  const res = {
    ..._.omit(
      gaugesContainer,
      _.flattenDeep([
        _.range(gaugeCount).map(i => `gauge${i}`),
        _.range(gaugeCount).map(i => fieldList.map(field => `gauge${i}_${field}`)),
        _.range(gaugeCount).map(i => omitList.map(field => `gauge${i}_${field}`)),
        'gaugeCount',
      ])
    ),
    gauges,
  };

  return res;
};
