import React from 'react';
import AppLayout from '../layout/AppLayout';
import RiverListControl from './RiverListControl';

function RiversPage() {
  return (
    <AppLayout t_title="Rivers" mainIconMeaning="top" >
      <RiverListControl />
    </AppLayout>
  );
}

export default RiversPage;
