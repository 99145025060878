import _ from 'lodash';
import uuidv1 from 'uuid/v1';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseButtonDialogTitle from '../ui-elements/CloseButtonDialogTitle';
import { Trans } from 'react-i18next';
import useT from '../utility/useT';
import { difficultyNames, getDifficultyColor } from '../icons/Difficulty';
import { makeStyles } from '@material-ui/core/styles';
import FormTextField from '../forms/FormTextField';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import fetchApi, { getApiBase } from '../utility/fetchApi';
import AuthProvider from '../providers/AuthProvider';
import getAccountInfoName from '../utility/getAccountInfoName';

const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
  },
  image: {
    maxWidth: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonWrap: {
    margin: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
}));

function dataUrltoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

function PhotoEditorDialog({ onClose, onChange, comment, context, imageUrl, open, onAdded, ...other }) {
  const classes = useStyles();
  const t = useT();
  const { auth } = AuthProvider.useAuth();

  function handleClose() {
    onClose();
  }

  const [value, setValue] = React.useState(comment);

  const uploadPhoto = async () => {
    const generatedId = uuidv1();
    const fileId = `${generatedId}.jpg`;
    const thumbnailFileId = `${generatedId}-thumbnail.jpg`;
    const apikey = '0abad3c2-5595-4653-bcf2-06f29a848d2b';
    const resp = await fetch(`${getApiBase()}/upload-file?fileId=${fileId}&thumbnailFileId=${thumbnailFileId}`, {
      method: 'PUT',
      headers: {
        'x-api-key': apikey,
        'content-type': 'image/jpeg',
      },
      body: dataUrltoBlob(imageUrl),
    });

    const saveData = await fetchApi.post(
      `comment-info/save`,
      {
        entity: {
          ...context,
          text: value,
          accountId: auth.accountId,
          accountName: getAccountInfoName(auth),
          commentId: uuidv1(),
          fileId,
          thumbnailFileId,
        },
      },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    onAdded(saveData);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="choose-section-dialog-title" open={open} {...other}>
      <CloseButtonDialogTitle id="choose-section-dialog-title" onClose={onClose}>
        <Trans>Upload photo</Trans>
      </CloseButtonDialogTitle>

      <FormGroup>
        <InputLabel className={classes.inputLabel}>{t('Description')}</InputLabel>
        <TextField onChange={e => setValue(e.target.value)} className={classes.textField} value={value} />
      </FormGroup>

      <div className={classes.buttons}>
        <div className={classes.buttonWrap}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              uploadPhoto();
              onClose();
            }}
          >
            <Trans>Upload photo</Trans>
          </Button>
        </div>

        <div className={classes.buttonWrap}>
          <Button variant="contained" color="primary" onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
        </div>
      </div>

      <img src={imageUrl} className={classes.image} />
    </Dialog>
  );
}

// export default DifficultyDialog;
export default withMobileDialog()(PhotoEditorDialog);
