import React from 'react';
import _ from 'lodash';
import useFetchApi from '../utility/useFetchApi';
import DashboardCard from '../ui-elements/DashboardCard';
import useT from '../utility/useT';
import WidgetSectionList from './WidgetSectionList';
import LoadingInfo from '../ui-elements/LoadingInfo';

export function SectionList({ location, hasEvents, additionalFilter, reloadToken }) {
  const urlParams = {
    latitude: location.latitude,
    longitude: location.longitude,
    limit: 5,
    isRunnable: 1,
    findMeasurement: true,
    reloadToken,
    ...additionalFilter,
  };
  //let url = `section-info/find?latitude=${location.latitude}&longitude=${location.longitude}&limit=5&isRunnable=1`;
  if (hasEvents != null) urlParams.hasEvents = hasEvents ? 'true' : 'false';
  //url += `&hasEvents=${hasEvents ? 'true' : 'false'}`;
  const sections = useFetchApi.get('section-info/find', urlParams, null);
  if (sections == null) return <LoadingInfo />;
  if (!_.isArray(sections)) return null;
  return <WidgetSectionList sections={sections || []} hasEvents={hasEvents} />;
}

export default function InterestingSectionsNearby({ hasEvents, location, includeGrid, additionalFilter, title }) {
  const t = useT();
  const [reloadToken, setReloadToken] = React.useState(0);
  return (
    <DashboardCard
      title={
        title ||
        (hasEvents == true || hasEvents == null
          ? t('Interesting sections nearby')
          : t('Not yet logged nearby sections'))
      }
      includeGrid={includeGrid}
      link={`/sections-near/${location.latitude}/${location.longitude}`}
      onReload={() => setReloadToken(x => x + 1)}
    >
      <SectionList
        location={location}
        hasEvents={hasEvents}
        additionalFilter={additionalFilter}
        reloadToken={reloadToken}
      />
    </DashboardCard>
  );
}
