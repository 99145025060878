import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import DifficultyIcon from '../icons/Difficulty';
import { makeStyles } from '@material-ui/core/styles';
import { getWaterLevelColor, waterLevelTwoLetterNames } from '../icons/WaterLevel';
import useT from '../utility/useT';
import useReactRouter from 'use-react-router';

const useStyles = makeStyles(theme => ({
  flexLine: {
    display: 'flex',
  },
  // line1: {
  //   display: 'flex',
  // },
  danger: {
    color: 'red',
    fontFamily: theme.typography.fontFamilyCondensed,
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
  },
  // line2: {
  //   display: 'flex',
  //   // marginLeft: '25px',
  //   // justifyContent: 'flex-end',
  // },
  gray: {
    color: 'gray',
  },
  infoLine: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  infoValue: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexShrink: 1,
  },
  dateValue: {
    whiteSpace: 'nowrap',
  },
}));

function InfoLine({ infoItem }) {
  const classes = useStyles();
  const { text, createTm, messageType } = infoItem;

  return (
    <div className={classes.infoLine}>
      <div className={clsx(messageType == 'danger' ? classes.danger : classes.gray, classes.infoValue)}>{text}</div>
      <div className={classes.dateValue}>{moment(createTm).format('D.M.YYYY')}</div>
    </div>
  );
}

export default function MessageAggregateWidgetList({ messageAggregate, limit = 5 }) {
  return (
    <div>
      {(messageAggregate || []).slice(0, limit).map(item => (
        <InfoLine infoItem={item} />
      ))}
    </div>
  );
}
