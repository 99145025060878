import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    color: '#000',
    fontSize: '90%',
    alignSelf: 'center',
  },
};

export function getWaterLevelColor(waterLevel) {
  return waterLevelColors[waterLevel - 1] || '#fff';
}

function getShortWaterLevelName(waterLevel) {
  return waterLevelTwoLetterNames[waterLevel] || '??';
}

function WaterLevel({ waterLevel, size, selected, selectable, onClick, classes }) {
  return (
    <Avatar
      style={{
        backgroundColor: getWaterLevelColor(waterLevel),
        margin: selectable && !selected ? '3px' : undefined,
        border: selected ? '3px solid black' : undefined,
      }}
      className={classes.root}
      onClick={onClick}
    >
      {getShortWaterLevelName(waterLevel)}
    </Avatar>
  );
}

// export const waterLevelIndexes = {
//   unknown: 0,
//   n: 1,
//   lw: 2,
//   mw: 3,
//   hw: 4,
//   xh: 5,
// };

export const waterLevelNames = ['not set', 'Empty', 'Low water', 'Medium water', 'High water', 'Extreme high water'];
export const waterLevelShortNames = ['not set', 'Empty', 'Low', 'Medium', 'High', 'Extreme'];
export const waterLevelTwoLetterNames = ['??', 'N', 'LW', 'MW', 'HW', 'XH'];
// export const waterLevelColors = ['#bbb', '#1071fa', '#58d647', '#fd2121', '#96383b'];
export const waterLevelColors = ['#bbb', '#75C1D5', '#7DFA98', '#F87A7A', '#96383b'];

export default withStyles(styles)(WaterLevel);
