import React from 'react';
import { useSnackbar } from 'notistack';
import useReactRouter from 'use-react-router';
import useRefreshTime from './useRefreshTime';

export function handleGeolocationError(err, enqueueSnackbar) {
  if (err.code === err.PERMISSION_DENIED) {
    enqueueSnackbar(`Using geolocation is not permitted, please allow it in Chrome Settings/Web Settings/Location`, {
      variant: 'warning',
    });
  } else {
    console.error(err);
    enqueueSnackbar(`Error getting current location: ${err && err.message}`, { variant: 'error' });
  }
}

export function parseGps(gps) {
  const m = (gps || '').match(/([0-9]+\.[0-9]+)\s*,\s*([0-9]+\.[0-9]+)/);
  if (m) return [parseFloat(m[2]), parseFloat(m[1])];
  return null;
}

export function getCurrentLocation() {
  return new Promise((resolve, reject) => {
    const receivePosition = position => {
      const { latitude, longitude } = position.coords;
      resolve({ latitude, longitude });
    };
    const onError = err => {
      reject(err.message);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(receivePosition, onError);
    } else {
      resolve(null);
    }
  });
}

export default function useLocationParam({ locationRequired = true, silent = false } = {}) {
  const { history } = useReactRouter();
  const [refreshTime] = useRefreshTime();
  const [locationProps, setlocationProps] = React.useState(
    history.location.state && history.location.state.currentLocationCache
  );
  const { enqueueSnackbar } = useSnackbar();

  const { value: locationParam, time: locationParamTime } = locationProps || {};

  const callSetLocationParam = value => {
    const props = { value, time: new Date().getTime() };
    setlocationProps(props);
    history.replace(history.location.pathname, {
      ...history.location.state,
      currentLocationCache: props,
    });
  };

  React.useEffect(() => {
    if (locationRequired && locationParam == null) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(receivePosition, onError);
      } else {
        callSetLocationParam('');
      }
    }
  }, [locationRequired]);

  const receivePosition = position => {
    const { latitude, longitude } = position.coords;
    callSetLocationParam(`latitude=${latitude}&longitude=${longitude}`);
  };

  const onError = err => {
    if (!silent) handleGeolocationError(err, enqueueSnackbar);
    callSetLocationParam('');
  };

  if (refreshTime > locationParamTime) {
    if (locationRequired) {
      callSetLocationParam(null);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(receivePosition, onError);
      } else {
        callSetLocationParam('');
      }
    }
  }

  return locationParam;
}
