import React from "react";

export default function Flag({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 640 480">
      <path fill="#ffce00" d="M0 320h640v160H0z" />
      <path d="M0 0h640v160H0z" />
      <path fill="#d00" d="M0 160h640v160H0z" />{" "}
    </svg>
  );
}
