import React from 'react';

import AppLayout from '../layout/AppLayout';
import TabControl from '../layout/RouteTabControl';
import useReactRouter from 'use-react-router';

import AuthProvider from '../providers/AuthProvider';
import LocationsMap from './LocationsMap';

import LocationList from './LocationList';

function LocationsPage() {
  const { auth } = AuthProvider.useAuth();
  const { history } = useReactRouter();
  const [highlightedLocation, setHighlightedLocation] = React.useState(false);

  const highlightOnMap = location => {
    setHighlightedLocation(location);
    history.replace('/my-locations/map');
  };

  return (
    <AppLayout t_title="GPS log" mainIconMeaning="top" showRefreshButton>
      <TabControl>
        <TabControl.Page t_label="List" path={`/my-locations`}>
          <LocationList
            url={`location-info/find?accountId=${auth.accountId}&sort=-createTm`}
            onHighlightOnMap={highlightOnMap}
            allowAddNew
          />
        </TabControl.Page>
        <TabControl.Page t_label="Map" path={`/my-locations/map`}>
          <LocationsMap
            url={`location-info/find?accountId=${auth.accountId}&limit=100&sort=-createTm`}
            isHighlighted={location => highlightedLocation && location.locationId === highlightedLocation.locationId}
          />
        </TabControl.Page>
      </TabControl>
    </AppLayout>
  );
}

export default LocationsPage;
