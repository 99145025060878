import React from "react";

export default function Favorite({ size }) {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 501.28 501.28"
      enable-background="new 0 0 501.28 501.28;"
      width={size}
      height={size}
    >
      <g>
        <polygon
          fill="#FFCD00"
          points="501.28,194.37 335.26,159.33 250.64,12.27 250.64,419.77 405.54,489.01 387.56,320.29 	"
        />
        <polygon
          fill="#FFDA44"
          points="166.02,159.33 0,194.37 113.72,320.29 95.74,489.01 250.64,419.77 250.64,12.27 	"
        />
      </g>
    </svg>
  );
}
