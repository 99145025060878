import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import useT from '../utility/useT';

function ConfirmDialog({
  title,
  t_title,
  description,
  t_description,
  positiveButton,
  t_positiveButton,
  negativeButton,
  t_negativeButton,
  onConfirm,
  classes,
  onClose,
  ...other
}) {
  function handleClose() {
    onClose();
  }

  function handleConfirm() {
    onClose();
    if (onConfirm) onConfirm();
  }

  const t = useT();

  return (
    <Dialog onClose={handleClose} aria-labelledby="confirm-dialog-title" {...other}>
      <DialogTitle id="confirm-dialog-title">{t_title ? t(t_title) : title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-title">
          {t_description ? t(t_description) : description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t_negativeButton ? t(t_negativeButton) : negativeButton}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {t_positiveButton ? t(t_positiveButton) : positiveButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function useConfirmDialog() {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  const onClose = () => {
    setOpen(false);
  };

  const dialogProps = {
    open,
    onClose,
  };

  return [handleClickOpen, dialogProps];
}

export default ConfirmDialog;
