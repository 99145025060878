import React from 'react';
import uuidv1 from 'uuid/v1';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';

import useReactRouter from 'use-react-router';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoIcon from '@material-ui/icons/Photo';
import { makeStyles } from '@material-ui/core/styles';
import useFetchApi from '../utility/useFetchApi';
import FormikForm from '../forms/FormikForm';
import FormTextField from '../forms/FormTextField';
import useFormik from '../utility/useFormik';
import fetchApi, { getApiBase } from '../utility/fetchApi';
import processSaveResponse from '../utility/processSaveResponse';
import { useSnackbar } from 'notistack';
import AuthProvider from '../providers/AuthProvider';
import getAccountInfoName from '../utility/getAccountInfoName';
import useViewportSize from '../utility/useViewportSize';
import ConfirmDialog, { useConfirmDialog } from '../ui-elements/ConfirmDialog';
import CommentTextView from './CommentTextView';
import CommentArticleView from './CommentArticleView';
import PhotoEditorDialog from './PhotoEditorDialog';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const useStyles = makeStyles(theme => ({
  textField: {
    // marginLeft: '20px',
    marginRight: '20px',
  },
  textFieldFormGroup: {
    flexGrow: 1,
  },
  textFieldWrapper: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  displayNone: {
    display: 'none',
  },
}));

function AddCommentTextField({ context, onAdded, isInlineView }) {
  const t = useT();
  const { values, setFieldValue } = useFormik();
  const { auth } = AuthProvider.useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { match, history } = useReactRouter();
  const [photoUrl, setPhotoUrl] = React.useState(null);
  const inputFileRef = React.useRef();
  const addComment = async () => {
    const saveData = await fetchApi.post(
      `comment-info/save`,
      {
        entity: {
          ...context,
          text: values.commentValue,
          accountId: auth.accountId,
          accountName: getAccountInfoName(auth),
          commentId: uuidv1(),
        },
      },
      {
        headers: { 'x-auth-token': auth.authToken },
        auth,
      }
    );
    if (processSaveResponse(saveData, enqueueSnackbar, null)) {
      onAdded(saveData);
      setFieldValue('commentValue', '');
    }
  };
  const cameraIsOpen = history.location.state && history.location.state.cameraIsOpen;
  const photoIsOpen = history.location.state && history.location.state.photoIsOpen;

  const openCamera = () =>
    history.push(history.location.pathname, {
      ...history.location.state,
      cameraIsOpen: true,
    });

  const openPhotoDialog = () =>
    history.push(history.location.pathname, {
      ...history.location.state,
      photoIsOpen: true,
    });

  const closeCameraAndOpenPhotoDialog = () =>
    history.replace(history.location.pathname, {
      ...history.location.state,
      cameraIsOpen: false,
      photoIsOpen: true,
    });

  const classes = useStyles({ isInlineView });
  return (
    <div className={classes.textFieldWrapper}>
      <IconButton>
        <PhotoIcon
          onClick={() => {
            inputFileRef.current.click();
          }}
        />
      </IconButton>
      <IconButton>
        <PhotoCameraIcon onClick={openCamera} />
      </IconButton>
      <FormTextField
        name="commentValue"
        autoComplete="off"
        multiline
        margin="normal"
        variant="outlined"
        className={classes.textField}
        formGroupClassName={classes.textFieldFormGroup}
        placeholder={t('Write comment')}
        onKeyPress={e => {
          if (e.key === 'Enter') addComment();
        }}
      />

      <Dialog onClose={() => history.goBack()} aria-labelledby="camera-dialog-title" open={cameraIsOpen} fullScreen>
        <Avatar onClick={() => history.goBack()} className={classes.closeButton}>
          <CloseIcon />
        </Avatar>
        <Camera
          onTakePhoto={dataUri => {
            // setImageUrls(value => [...value, dataUri]);
            setPhotoUrl(dataUri);
            closeCameraAndOpenPhotoDialog();
          }}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          imageType={IMAGE_TYPES.JPG}
          idealResolution={{ width: 1200, height: 900 }}
        />
      </Dialog>

      <PhotoEditorDialog
        open={photoIsOpen}
        onClose={() => history.goBack()}
        imageUrl={photoUrl}
        context={context}
        onAdded={onAdded}
      />

      <input
        type="file"
        accept="image/*"
        className={classes.displayNone}
        ref={inputFileRef}
        id='AddCommentTextField_uploadPhotoInput'
        onChange={e1 => {
          const dialog = e1.target;
          if (dialog.files && dialog.files[0]) {
            const reader = new FileReader();
            reader.onload = e2 => {
              // setImageUrls(value => [...value, e2.target.result]);
              setPhotoUrl(e2.target.result);
              openPhotoDialog();
            };
            reader.readAsDataURL(dialog.files[0]);
          }
        }}
      />
    </div>
  );
}

export default AddCommentTextField;
