import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import qs from 'qs';

import AuthProvider from '../providers/AuthProvider';
import FormTextField from '../forms/FormTextField';
import FormCheckBox from '../forms/FormCheckBox';
import fetchApi from '../utility/fetchApi';
import PaperCard from '../ui-elements/PaperCard';
import useReactRouter from 'use-react-router';
import FormSectionSelector from '../forms/FormSectionSelector';
import TabControl from '../layout/TabControl';
import FormCoordinates from '../forms/FormCoordinates';
import MapPointEditor from './MapPointEditor';
import FormSelect from '../forms/FormSelect';
import geoTags from '../locations/geoTags';
import EditorPage from '../ui-elements/EditorPage';
import getAccountInfoName from '../utility/getAccountInfoName';
import useT from '../utility/useT';

function formToPlace(place, t) {
  if (!place.coordinates || place.coordinates[0] == undefined || place.coordinates[1] == undefined)
    throw new Error(t('MissingGpsError'));
  return {
    ...place,
    coordinates: place.coordinates && place.coordinates.slice(0, 2),
  };
}

function EditPlacePage() {
  const { match, location } = useReactRouter();
  const { sectionId: paramsSectionId } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { placeId: paramsPlaceId, createFromLocationId, createFromPlaceId, createFromSectionId } = match.params;
  const { auth } = AuthProvider.useAuth();
  const t = useT();

  async function fetchPlace() {
    if (createFromLocationId) {
      const location = ((await fetchApi(`location-info/find?locationId=${createFromLocationId}`, { auth })) || [])[0];
      let sectionData = {};
      if (paramsSectionId) {
        const sectionObject = ((await fetchApi(`section-info/find?sectionId=${paramsSectionId}`, { auth })) || [])[0];
        if (sectionObject) {
          sectionData = {
            sectionObject,
          };
        }
      }
      return {
        coordinates: location.coordinates,
        geotag: location.geotag,
        accountId: auth.accountId,
        accountName: getAccountInfoName(auth),
        ...sectionData,
      };
    } else if (createFromPlaceId) {
      const res = ((await fetchApi(`place-info/find?placeId=${createFromPlaceId}&joinSection=1`, { auth })) || [])[0];
      return {
        ...res,
        placeId: null,
        accountId: auth.accountId,
        accountName: getAccountInfoName(auth),
      };
    } else if (createFromSectionId) {
      const sectionObject = ((await fetchApi(`section-info/find?sectionId=${createFromSectionId}`, { auth })) || [])[0];
      return {
        sectionObject,
        coordinates: !sectionObject.isFakeCoordinates && sectionObject.coordinates,
        accountId: auth.accountId,
        accountName: getAccountInfoName(auth),
      };
    } else {
      if (paramsPlaceId) {
        const res = ((await fetchApi(`place-info/find?placeId=${paramsPlaceId}&joinSection=1`, { auth })) || [])[0];
        return res;
      } else {
        return {
          accountId: auth.accountId,
          accountName: getAccountInfoName(auth),
        };
      }
    }
  }

  return (
    <EditorPage
      idName="placeId"
      localStorageName="EditingPlaces"
      serviceName="place-info"
      detailRoute={id => `/places/detail/${id}`}
      objectTypeText={t('place')}
      fetchObject={fetchPlace}
      formToStorage={formToPlace}
      containsSection
      parentRoute="/places"
    >
      <TabControl>
        <TabControl.Page label="Place details">
          <Grid container alignItems="stretch">
            <PaperCard>
              <FormSectionSelector label="Section" name="sectionId" sectionObjectField="sectionObject" copyNames />
            </PaperCard>
          </Grid>
          <PaperCard>
            <FormTextField label="Description" name="description" multiline />
            <FormCoordinates label="GPS" name="coordinates" />
            <FormSelect label="Geo tag" name="geotag">
              {geoTags.map(x => (
                <option>{x.text}</option>
              ))}
            </FormSelect>
            <FormCheckBox label="Publish (this place will be visible for all paddlers)" name="isPublished" />
          </PaperCard>
        </TabControl.Page>
        <TabControl.Page label="Map">
          <MapPointEditor name="coordinates" />
        </TabControl.Page>
      </TabControl>
    </EditorPage>
  );
}

export default EditPlacePage;
